// Dependencies
import React, {useCallback, useMemo} from 'react';

// Components
import DiscordFollowAction from './partials/DiscordFollowAction';

// Styles
import styles from './DiscordQuest.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useQuests from '@hooks/useQuests';

// Types
import {DiscordQuestProps} from './types';

// Assets
// import {RiFoldersLine, RiSendPlane2Line} from 'react-icons/ri';

function DiscordQuest({collectionName, quest, isMobile, checkEnabled}: DiscordQuestProps): React.ReactElement {
  const {discordAuthQuest, checkQuestActionIsCompleted} = useQuests();
  const {user} = useAuth();

  const handleClickConnect = useCallback(() => {
    discordAuthQuest(collectionName, quest.id);
  }, []);

  const handleClickAction = useCallback(() => {
    const discordUrl = `https://discord.com/invite/${quest.handler}`;

    window.open(discordUrl, '_blank');
  }, []);

  const handleClickCheckAction = useCallback(() => {
    checkQuestActionIsCompleted(quest);
  }, []);

  const renderQuestAction = useMemo(() => {
    switch (quest.questActionType.id) {
      case 8:
        return (
          <DiscordFollowAction
            avatar={quest.collection?.avatar}
            creator={quest.collection?.user.name}
            userToFollow={quest.extraHandler as string}
            checkEnabled={checkEnabled}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
            onConnection={handleClickConnect}
            user={user}
          />
        );
    }
  }, [quest.questActionType.id]);

  return (
    <div className={styles.layout}>
      {renderQuestAction}
    </div>
  );
}
export default DiscordQuest;
