// @ts-nocheck
// Dependencies
import React from 'react';

// Types
import {IModalState} from '@store/ui/modal/types';
import {ModalsId} from '@type/modals';

// Modals
import VerifyEmailSignup from '../id/VerifyEmailSignup';
import SendNFT from '../id/SendNFT';
import ConfirmMessage from '../id/ConfirmMessageModal';
import CollectionPublishingModal from '../id/CollectionPublishingModal';
import ClaimAirdropCollectionNFTModal from '../id/ClaimAirdropCollectionNFTModal';
import GiftPublishingModal from '../id/GiftPublishingModal';
import EditLinksModal from '../id/EditLinksModal';
import SimpleMessage from '../id/SimpleMessage';
import EditProfileModal from '../id/EditProfileModal';
import ClaimRewardModal from '../id/ClaimRewardModal';
import RequestResetPasswordModal from '../id/RequestResetPasswordModal';
import UserNavigationMenuModal from '../id/UserNavigationMenuModal';
import ContactSalesModal from '../id/ContactSalesModal';
import CreateOrEditNFTModal from '../id/CreateOrEditNFTModal';
import CreateOrEditRewardModal from '../id/CreateOrEditRewardModal';
import CreateOrEditQuestModal from '../id/CreateNewQuestModal';
import DeleteNFTModal from '../id/DeleteNFTModal';
import DeleteCollectionModal from '../id/DeleteCollectionModal';
import ConfirmClaimAirdropNFTModal from '../id/ConfirmClaimAirdropNFTModal';
import InstallWalletModal from '../id/InstallWalletModal';
import ImportNFTModal from '../id/ImportNFTModal';
import SendMultipleNFTsModal from '../id/SendMultipleNFTsModal';
import StartGivitNFTModal from '../id/StartGivitNFTModal';
import CompleteQuestModal from '../id/CompleteQuestModal';
import MintOnMobileModal from '../id/MintOnMobileModal';
import CreatorWelcomeModal from '../id/CreatorWelcomeModal';
import BuyLicenseModal from '../id/BuyLicenseModal';
import ShowQRCodeModal from '../id/ShowQRCodeModal';
import GetPassModal from '../id/GetPassModal';

function RenderModal({id, data}: IModalState): React.ReactElement | null {
  switch (id) {
    case ModalsId.DELETE_COLLECTION:
      return (
        <DeleteCollectionModal
          collectionId={data?.id}
        />
      );

    case ModalsId.DELETE_NFT:
      return (
        <DeleteNFTModal
          collectionId={data?.collectionId}
          nft={data?.nft}
        />
      );

    case ModalsId.EDIT_PROFILE:
      return (
        <EditProfileModal
          initialValues={data?.initialValues}
        />
      );

    case ModalsId.CONTACT_SALES:
      return (
        <ContactSalesModal />
      );

    case ModalsId.USER_NAVIGATION_MENU:
      return (
        <UserNavigationMenuModal />
      );

    case ModalsId.EDIT_COLLECTION_LINKS:
      return (
        <EditLinksModal
          initialValues={data?.initialValues}
          onSave={data?.onSave}
        />
      );

    case ModalsId.PUBLISHING_COLLECTION:
      return (
        <CollectionPublishingModal
          type={data?.type}
          action={data?.action}
        />
      );

    case ModalsId.CLAIMING_AIRDROP_COLLECTION_NFT:
      return (
        <ClaimAirdropCollectionNFTModal
          type={data?.type}
          nftUrl={data?.nftUrl}
          action={data?.action}
        />
      );

    case ModalsId.PUBLISHING_GIFT:
      return (
        <GiftPublishingModal
          type={data?.type}
          action={data?.action}
        />
      );

    case ModalsId.CONFIRM_MESSAGE:
      return (
        <ConfirmMessage
          title={data?.title}
          description={data?.description}
          action={data?.action}
        />
      );

    case ModalsId.VERIFY_EMAIL_SIGNUP:
      return (
        <VerifyEmailSignup email={data?.email ?? ''} />
      );

    case ModalsId.SEND_NFT:
      return (
        <SendNFT
          nft={data?.nft}
          isComplete={data?.isComplete}
          email={data?.email}
        />
      );

    case ModalsId.CREATE_OR_EDIT_NFT:
      return (
        <CreateOrEditNFTModal
          collectionId={data?.collectionId}
          nft={data?.nft}
        />
      );

    case ModalsId.CREATE_OR_EDIT_REWARD:
      return (
        <CreateOrEditRewardModal
          collectionId={data?.collectionId}
          reward={data?.reward}
        />
      );

    case ModalsId.CREATE_OR_EDIT_QUEST:
      return (
        <CreateOrEditQuestModal
          collectionId={data?.collectionId}
          quest={data?.quest}
        />
      );

    case ModalsId.SIMPLE_MESSAGE:
      return (
        <SimpleMessage
          title={data?.title}
          description={data?.description}
          buttonCaption={data?.buttonCaption}
          action={data?.action}
        />
      );

    case ModalsId.CLAIM_REWARD:
      return (
        <ClaimRewardModal
          reward={data?.reward}
        />
      );

    case ModalsId.REQUEST_RESET_PASSWORD:
      return (
        <RequestResetPasswordModal />
      );

    case ModalsId.CONFIRM_CLAIM_AIRDROP_NFT:
      return (
        <ConfirmClaimAirdropNFTModal
          nft={data?.nft}
          action={data?.action}
        />
      );

    case ModalsId.INSTALL_WALLET:
      return (
        <InstallWalletModal
          action={data?.action}
        />
      );

    case ModalsId.IMPORT_NFT:
      return (
        <ImportNFTModal
          action={data?.action}
        />
      );

    case ModalsId.SEND_MULTIPLE_NFTS:
      return (
        <SendMultipleNFTsModal
          nft={data?.nft}
          isComplete={data?.isComplete}
        />
      );

    case ModalsId.START_GIVITNFT:
      return (
        <StartGivitNFTModal
          action={data?.action}
        />
      );

    case ModalsId.COMPLETE_QUEST:
      return (
        <CompleteQuestModal
          collectionName={data?.collectionName}
          quest={data?.quest}
          status={data?.status}
          checkEnabled={data?.checkEnabled}
        />
      );

    case ModalsId.MINT_ON_MOBILE:
      return (
        <MintOnMobileModal
          action={data?.action}
        />
      );

    case ModalsId.CREATOR_WELCOME:
      return (
        <CreatorWelcomeModal
          action={data?.action}
        />
      );

    case ModalsId.BUY_LICENSE:
      return (
        <BuyLicenseModal
          type={data?.type}
          action={data?.action} />
      );

    case ModalsId.SHARE_SHOW_QR_CODE:
      return (
        <ShowQRCodeModal
          nft={data.nft}
          collection={data.collection}
          action={data.action}
        />
      );

    case ModalsId.GET_NFT_PASS:
      return (
        <GetPassModal
          nft={data.nft}
          isComplete={data.isComplete}
        />
      );

    default:
      return null;
  }
}

export default RenderModal;
