// Dependencies
import React from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';
import {TailSpin} from 'react-loader-spinner';

// Types
import {ConfirmMessageProps} from './types';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';

// StyleSheet
import styles from './CollectionPublishingModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Assets
import verifiedLottie from '@assets/lotties/verified-lottie.json';

import language_es from 'src/locales/es/modals/collectionPublishing.json';
import language_en from 'src/locales/en/modals/collectionPublishing.json';

function CollectionPublishingModal({
  type,
  action = undefined
}: ConfirmMessageProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.container}>
        {type === 'completed' ? (
          <>
            <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.congratulations}</Typography>
            <div className={styles.lottie}>
              <Lottie
                animationData={verifiedLottie}
                loop={false}
                autoplay={true}
              />
            </div>
            <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.collectionReady}</Typography>
          </>
        ) : (
          <>
            <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.publishingCollection}</Typography>
            <div className={styles.text}>
              <TailSpin
                width={64}
                height={64}
                color={'#1B2C71'}
              />
            </div>
            <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.publishingProcessInfo}</Typography>
          </>
        )}
      </div>
      {type === 'completed' && (
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.goToNewCollection}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CollectionPublishingModal;
