// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setUserCollectionNFTsList} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';

// Services
import * as collectionServices from '@services/collection';

// Types
import {GetUserCollectionNFTsListResponse} from '@services/collection/types';
import {FetchUserCollectionNFTsListAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchUserCollectionNFTsProcess(collectionId)
 * @description Fetching Collection List
 */
function * fetchUserCollectionNFTsProcess({payload: {collectionId}}: FetchUserCollectionNFTsListAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, true));

    const response: GetUserCollectionNFTsListResponse = yield call(collectionServices.getUserCollectionNFTsList, collectionId);

    yield put(setUserCollectionNFTsList(response.userCollectionNfts));

    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchUserCollectionNFTsProcess;
