// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {DownloadActivityDataAction} from '@store/collection/types';
import {IActivityDataCSV} from '@type/collection';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function downloadActivityDataProcess()
 * @description Download the data of the NFTs and users
 */
function * downloadActivityDataProcess({payload: {collectionId}}: DownloadActivityDataAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_ACTIVITY_DATA, true));

    const response: IActivityDataCSV = yield call(services.getActivityData, collectionId);

    yield call(() => {
      const url = window.URL.createObjectURL(new Blob([response.csv], {type: 'text/plain'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'givitpass.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_ACTIVITY_DATA, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_ACTIVITY_DATA, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default downloadActivityDataProcess;
