// Dependencies
import {useCallback, useEffect, useRef, useState} from 'react';
import MetaMaskOnboarding, {PLATFORM} from '@utils/onBoarding';

interface IUseOnBoarding {
  isInstalled: boolean;
  isMobile: boolean;
  handleInstall: () => void;
  handleLaunch: (token: string) => void;
}

function useOnBoarding(): IUseOnBoarding {
  const onBoarding = useRef<MetaMaskOnboarding>();
  const [isInstalled, setIsInstalled] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const openLink: string = String(process.env.REACT_APP_METAMASK_DEEPLINK);

  useEffect(() => {
    if (!onBoarding.current) {
      onBoarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding._detectPlatform() === PLATFORM.MOBILE) setIsMobile(true);
    if (MetaMaskOnboarding.isMetaMaskInstalled()) setIsInstalled(true);
  }, [isInstalled]);

  const handleInstall = useCallback(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      onBoarding.current?.startOnboarding();
    }
  }, []);

  const handleLaunch = useCallback((token: string) => {
    const metamaskLink = `${openLink}/mobile/${token}`;
    window.location.assign(metamaskLink);
  }, []);

  return {
    isInstalled,
    isMobile,
    handleInstall,
    handleLaunch
  };
}

export default useOnBoarding;
