// @ts-nocheck
export function normalizeState(data: T, typeId = 'id'): {[key: string]: T} {
  const items = {};
  data.forEach((d) => {
    items[d[typeId]] = d;
  });

  return items;
}

export const unNormalizeState = (data): T[] =>
  Object.keys(data).map((id) => data[id]);

export function isUrlWithDomain(text?: string, domain: string): boolean {
  if (!text) {
    return true;
  }

  if (!text.startsWith('https://')) {
    return false;
  }

  try {
    const url = new URL(text);
    return url.hostname.endsWith(domain);
  } catch (e) {
    return false;
  }
}
