// Dependencies
import React from 'react';
import {FormikProps, useFormik} from 'formik';
import classNames from 'clsx';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Typography from '@components/Typography';

// Types
import {EditLinksFormProps, FormValues} from './types';

// StyleSheet
import styles from './EditLinksForm.module.scss';

// Validation
import EditLinksFormValidation from './validations';

// Assets
import {FaDiscord, FaInstagram, FaGlobe} from 'react-icons/fa';
import OpenSeaIcon from '@assets/images/opensea-logo-mark.svg';
import CalendyIcon from '@assets/images/calendly.svg';
import XIcon from '@assets/icons/x-logo-tw.svg';

import language_es from 'src/locales/es/forms/editLinksForm.json';
import language_en from 'src/locales/en/forms/editLinksForm.json';

function EditLinksForm({
  initialValues,
  onSubmit,
  onCancel
}: EditLinksFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      discord: initialValues?.discord ?? '',
      instagram: initialValues?.instagram ?? '',
      url: initialValues?.url ?? '',
      openSea: initialValues?.openSea ?? '',
      twitter: initialValues?.twitter ?? '',
      calendy: initialValues?.calendy ?? ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: EditLinksFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.brand}>
            <FaDiscord color={'#5865F2'} />
            <Typography>https://discord.gg/</Typography>
          </div>
          <TextInput
            name={'discord'}
            error={getErrorFromField('discord')}
            value={formik.values.discord}
            onChangeText={(text) => handleChangeField('discord', text)}
            placeholder={'channel-name'}
          />
        </div>

        <div className={styles.item}>
          <div className={styles.brand}>
            <XIcon />
            <Typography>https://x.com/</Typography>
          </div>
          <TextInput
            name={'twitter'}
            error={getErrorFromField('twitter')}
            value={formik.values.twitter}
            onChangeText={(text) => handleChangeField('twitter', text)}
            placeholder={'@username'}
          />
        </div>

        <div className={styles.item}>
          <div className={styles.brand}>
            <FaInstagram />
            <Typography>https://instagram.com/</Typography>
          </div>
          <TextInput
            name={'instagram'}
            error={getErrorFromField('instagram')}
            value={formik.values.instagram}
            onChangeText={(text) => handleChangeField('instagram', text)}
            placeholder={'instagram-name'}
          />
        </div>

        <div className={styles.item}>
          <div className={styles.brand}>
            <OpenSeaIcon />
            <Typography>https://opensea.io/</Typography>
          </div>
          <TextInput
            name={'openSea'}
            error={getErrorFromField('openSea')}
            value={formik.values.openSea}
            onChangeText={(text) => handleChangeField('openSea', text)}
            placeholder={'opensea-collection'}
          />
        </div>

        <div className={styles.item}>
          <div className={styles.brand}>
            <CalendyIcon />
            <Typography>https://calendly.com/</Typography>
          </div>
          <TextInput
            name={'calendy'}
            error={getErrorFromField('calendy')}
            value={formik.values.calendy}
            onChangeText={(text) => handleChangeField('calendy', text)}
            placeholder={'calendy-url'}
          />
        </div>

        <div className={classNames([styles.item, styles.short])}>
          <div className={styles.brand}>
            <FaGlobe />
          </div>
          <TextInput
            name={'url'}
            error={getErrorFromField('url')}
            value={formik.values.url}
            onChangeText={(text) => handleChangeField('url', text)}
            placeholder={'https://your-collection-page.com/'}
          />
        </div>

      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button type={'button'} variant={'outline'} color={'primary'} onClick={onCancel}>
            {language.cancelButton}
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} disabled={!formik.isValid}>
            {language.saveChangesButton}
          </Button>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default EditLinksForm;
