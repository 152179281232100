import * as Yup from 'yup';

export const NewRewardStep1Validation = Yup.object().shape({
  type: Yup.object({
    value: Yup.number()
      .required('Enter the type of the gift')
      .min(1, 'Type must be at least 1')
  }).required('Enter the type of the gift'),
  description: Yup.string().required('The gift description is required'),
  rewardContent: Yup.mixed().when('type', {
    is: 5,
    then: Yup.mixed()
      .required('This field is required')
      .test('fileExists', 'Please attach an image', (value) => {
        return value && value.size > 0;
      }),
    otherwise: Yup.string().required('This field is required')
  }),
  availableFrom: Yup.date()
    .required('Date is required')
    .typeError('Invalid date format')
});

export const NewRewardStep2Validation = Yup.object().shape({
  notificationTitle: Yup.string().required('This field is required'),
  notificationDescription: Yup.string().required('This field description is required')
});
