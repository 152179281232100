// Dependencies
import React from 'react';
import {FormikProps, FormikHelpers, useFormik} from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';

// Styles
import styles from './ResetPasswordForm.module.scss';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Typography from '@components/Typography';

// Types
import {ResetPasswordFormProps, FormValues} from './types';

// Hooks
import useForm from '@hooks/useForm';

// Validation
import ResetPasswordValidation from './validations';

import language_es from 'src/locales/es/forms/resetPasswordForm.json';
import language_en from 'src/locales/en/forms/resetPasswordForm.json';

function ResetPasswordForm({onSubmit, isLoading}: ResetPasswordFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      password: '',
      confirmPassword: '',
      score: ''
    },
    validateOnMount: true,
    validationSchema: ResetPasswordValidation,
    onSubmit
  });

  const {getErrorFromField, handleChangeField} = useForm<FormValues>(formik);

  return (
    <form className={styles.layout} onSubmit={formik.handleSubmit}>
      <div className={styles.container}>
        {/* Password */}
        <div className={styles.field}>
          <TextInput
            label={language.passwordLabel}
            name={'password'}
            type={'password'}
            value={formik.values.password}
            onChangeText={(password) => handleChangeField('password', password)}
            placeholder={language.passwordPlaceholder}
            error={getErrorFromField('password') ?? getErrorFromField('score')}
          />

          {formik.values.password.length > 0 && (
            <div className={styles.security}>
              <Typography component={'h4'} variant={'text'} weight={'medium'} size={'sm'} className={styles.securityTitle}>{language.passwordStrengthTitle}</Typography>
              <Typography variant={'text'} size={'sm'}>{language.passwordStrengthDescription}</Typography>

              <PasswordStrengthBar
                password={formik.values.password}
                barColors={['#e3e0ff', '#ff6e5e', '#ffca77', '#59afff', '#5ED24EFF']}
                scoreWords={['Unsafe', 'Weak', 'Okay', 'Good', 'Strong']}
                shortScoreWord={''}
                scoreWordStyle={{
                  fontSize: 12,
                  marginTop: 8
                }}
                onChangeScore={(score) => handleChangeField('score', score.toString())}
                className={styles.scoreBoard}
              />
            </div>
          )}
        </div>
        {/* Confirm Password */}
        <div className={styles.field}>
          <TextInput
            name={'confirmPassword'}
            type={'password'}
            label={language.confirmPasswordLabel}
            value={formik.values.confirmPassword}
            onChangeText={(confirmPassword) => handleChangeField('confirmPassword', confirmPassword)}
            placeholder={language.confirmPasswordPlaceholder}
            error={getErrorFromField('confirmPassword')}
          />
        </div>
        <Button
          onClick={() => formik.handleSubmit()}
          isLoading={isLoading}
          disabled={!formik.isValid}>
          {language.setNewPasswordButton}
        </Button>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default ResetPasswordForm;
