// Dependencies
import React from 'react';
import {FormikProps, FormikHelpers, useFormik} from 'formik';

// Styles
import styles from './ResetPasswordForm.module.scss';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// Types
import {RequestResetPasswordFormProps, FormValues} from './types';

// Hooks
import useForm from '@hooks/useForm';

// Validation
import RequestResetPasswordValidation from './validations';

import language_es from 'src/locales/es/forms/requestResetPasswordForm.json';
import language_en from 'src/locales/en/forms/requestResetPasswordForm.json';

function RequestResetPasswordForm({onSubmit, onCancel, isLoading}: RequestResetPasswordFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      email: ''
    },
    validateOnMount: true,
    validationSchema: RequestResetPasswordValidation,
    onSubmit
  });

  const {getErrorFromField, handleChangeField} = useForm<FormValues>(formik);

  return (
    <form className={styles.layout} onSubmit={formik.handleSubmit}>
      <div className={styles.container}>
        {/* Password */}
        <div className={styles.field}>
          <TextInput
            name={'email'}
            type={'email'}
            value={formik.values.email}
            onChangeText={(email) => handleChangeField('email', email)}
            placeholder={language.emailPlaceholder}
            error={getErrorFromField('email')}
          />
        </div>
        <div className={styles.actions}>
          <Button
            variant={'outline'}
            color={'primary'}
            onClick={onCancel}>
            {language.cancelButton}
          </Button>
          <Button
            variant={'solid'}
            color={'primary'}
            onClick={() => formik.handleSubmit()}
            isLoading={isLoading}
            disabled={!formik.isValid}>
            {language.resetPasswordButton}
          </Button>
        </div>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default RequestResetPasswordForm;
