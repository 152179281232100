// Dependencies
import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'clsx';

// StyleSheets
import styles from './Breadcrumbs.module.scss';

// Components
import Typography from '@components/Typography';

// Types
import {BreadcrumbsProps} from './types';

// Assets
import {RiArrowRightSLine} from 'react-icons/ri';

function Breadcrumbs({
  links = []
}: BreadcrumbsProps): React.ReactElement {
  return (
    <div className={styles.body}>
      {links.map((link, index: number) => (
        <div key={`--breadcrumbs-${index.toString()}`} className={styles.breadcrumb}>
          {link.icon ? (
            <Link to={link.path}>
              {link.icon}
            </Link>
          ) : (
            <>
              <Link to={link.path} className={classNames([styles.link, {[styles.last]: index === links.length - 1}])}>
                <Typography variant={'text'} size={'sm'} weight={'semiBold'}>{link.label}</Typography>
              </Link>
            </>
          )}
          {index !== links.length - 1 && (
            <div className={styles.separator}>
              <RiArrowRightSLine size={'20px'} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumbs;
