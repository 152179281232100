// Dependencies
import React from 'react';
import ReactQuill, {ReactQuillProps} from 'react-quill';
import classNames from 'clsx';

// StyleSheet
import styles from './QuillTextInput.module.scss';

// Components
import Typography from '@components/Typography';

// Types
interface QuillTextInputProps extends ReactQuillProps {
  label?: string;
  error?: string;
}

function QuillTextInput({label, error, placeholder, value, className, onChange, ...props}: QuillTextInputProps): React.ReactElement {
  return (
    <div className={classNames([styles.layout, {[styles.withError]: error}])}>
      {(label) && (
        <label htmlFor={label} className={styles.label}>{label}</label>
      )}
      <ReactQuill
        placeholder={placeholder}
        theme={'snow'}
        value={value}
        onChange={onChange}
        className={classNames([styles.container, className, 'notranslate'])}
        {...props}
      />
      {error && (
        <Typography variant={'text'} size={'sm'} className={styles.error}>{error}</Typography>
      )}
    </div>
  );
}

export default QuillTextInput;
