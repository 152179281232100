// Dependencies
import React, {useCallback, useEffect, useMemo} from 'react';
import classNames from 'clsx';
import {useNavigate, useParams} from 'react-router-dom';

// Types
import {INFT} from '@type/nft';
import {IQuest} from '@type/quest';

// Assets
import {RiAddLine, RiImageLine, RiGift2Line} from 'react-icons/ri';

// Hooks
import useCollections from '@hooks/useCollections';
import useQuests from '@hooks/useQuests';
import useGifts from '@hooks/useGifts';
import useModal, {ModalsId} from '@hooks/useModal';
import useResponsive from '@hooks/useResponsive';

// StyleSheet
import styles from './CollectionPreview.module.scss';

// Components
import Typography from '@components/Typography';
import SocialLinks, {generateLinksItem} from '@components/SocialLinks';
import PageTitle from '@components/PageTitle';
import EmptyState from '@components/EmptyState';
import Button from '@components/Button';
import CreatorNFTCard from '@components/cards/CreatorNFTCard';
import CreatorQuestCard from '@components/cards/CreatorQuestCard';
import GiftCard from '@components/cards/GiftCard';
import useLoader, {LoadersId} from '@hooks/useLoader';
import QuillTextInput from '@components/QuillTextInput';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/pages/creator/collectionPreview.json';
import language_en from 'src/locales/en/pages/creator/collectionPreview.json';

function CollectionPreview(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {id} = useParams<{id: string}>();
  const {
    myCollectionsList,
    fetchCollectionById,
    publishCollection
  } = useCollections();
  const {quests, fetchQuests, deleteQuest} = useQuests();
  const {giftListByCollectionId, fetchGiftListByCollectionId} = useGifts(Number(id));
  const navigate = useNavigate();
  const {openModal, closeModal} = useModal();
  const {isMobile} = useResponsive();

  const {isLoading: isLoadingDeletingCollection} = useLoader(LoadersId.IS_DELETING_COLLECTION);
  const {isLoading: isLoadingPublishingCollection} = useLoader(LoadersId.IS_PUBLISHING_COLLECTION);
  const {isLoading: isLoadingCollection} = useLoader(LoadersId.FETCH_COLLECTION_BY_ID);
  const {isLoading: isLoadingQuests} = useLoader(LoadersId.FETCH_QUESTS);

  useEffect(() => {
    fetchCollectionById(Number(id));
    fetchGiftListByCollectionId(Number(id), false);
    fetchQuests(Number(id));
  }, [id]);

  /**
   * Memoizes the collection based on the provided 'id' and 'myCollectionsList'.
   * If the collection with the given 'id' exists in 'myCollectionsList', it returns that collection;
   * otherwise, it returns 'undefined'.
   *
   * @function
   * @name collection
   * @returns {Object|undefined} The memoized collection or 'undefined' if not found.
   */
  const collection = useMemo(() =>
    myCollectionsList?.[Number(id)] ?? undefined,
  [id, myCollectionsList]);

  /**
   * Navigate to the edit page of a collection when the edit button is clicked.
   *
   * @function
   * @name handleClickEditCollection
   * @returns {void}
   */
  const handleClickEditCollection = useCallback(() => {
    navigate(`/creator/collection/${collection?.id}/edit`);
  }, [collection]);

  /**
   * Callback function to handle clicking the delete button for an NFT.
   *
   * @function
   * @name handleClickDeleteNFT
   * @param {Object} nft - The NFT object to be deleted, of type INFT.
   * @returns {void}
   */
  const handleClickDeleteNFT = useCallback((nft: INFT) => {
    if (nft?.id) {
      openModal(ModalsId.DELETE_NFT, {
        collectionId: id,
        nft: {
          id: nft.id,
          name: nft.name
        }
      });
    }
  }, []);

  /**
   * Callback function to handle editing an NFT.
   *
   * @function
   * @name handleClickEditNFT
   * @param {Object} nft - The NFT object to be edited, of type INFT.
   * @returns {void}
   */
  const handleClickEditNFT = useCallback((nft: INFT) => {
    if (nft?.id) {
      openModal(ModalsId.CREATE_OR_EDIT_NFT, {
        collectionId: id,
        nft: {
          id: nft.id,
          image: nft.image,
          imageTypeId: nft.imageTypeId,
          name: nft.name,
          passBackgroundColor: nft.passBackgroundColor,
          passForegroundColor: nft.passForegroundColor,
          passLabelColor: nft.passLabelColor,
          welcomeMessage: nft.welcomeMessage
        }
      });
    }
  }, []);

  /**
   * Callback function to handle clicking the "New NFT" button.
   *
   * @function
   * @name handleCLickNewNFT
   * @returns {void}
   */
  const handleCLickNewNFT = useCallback(() => {
    openModal(ModalsId.CREATE_OR_EDIT_NFT, {
      collectionId: id
    });
  }, []);

  const handleClickNewQuest = useCallback(() => {
    openModal(ModalsId.CREATE_OR_EDIT_QUEST, {
      collectionId: id
    });
  }, []);

  const handleClickEditQuest = useCallback((quest: IQuest) => {
    openModal(ModalsId.CREATE_OR_EDIT_QUEST, {
      collectionId: id,
      quest
    });
  }, []);

  const handleClickDeleteQuest = useCallback((quest: IQuest) => {
    if (quest?.id) {
      openModal(ModalsId.CONFIRM_MESSAGE, {
        title: 'Are you sure you want to delete this quest?',
        description: ['Once deleted, you couldn`t recover it'],
        action: () => {
          closeModal();
          deleteQuest(quest.id);
        }
      });
    }
  }, []);

  /**
   * Callback function to handle the click event for adding a new reward (NFT) to a collection.
   *
   * When invoked, this function navigates to the page for creating a new reward (NFT) associated with a specific collection.
   *
   * @function
   * @name handleClickNewReward
   * @returns {void}
   */
  const handleClickNewReward = useCallback(() => {
    openModal(ModalsId.CREATE_OR_EDIT_REWARD, {
      collectionId: id
    });
  }, [id]);

  const renderQuestsList = useMemo(() => (
    collection && quests?.length ? (
      <div className={classNames([styles.grid, styles.quests])}>
        {quests?.map((quest, index) => (
          <CreatorQuestCard
            key={`--collection-quest-card-${index.toString()}`}
            quest={quest}
            isMobile={isMobile}
            canEdit={!collection.published}
            onPressEdit={() => handleClickEditQuest(quest)}
            onPressDelete={() => handleClickDeleteQuest(quest)}
          />
        ))}
      </div>
    ) : (
      <div className={styles.center}>
        <EmptyState
          icon={<RiImageLine />}
          text={language.questsEmptyState}
        />
      </div>
    )
  ), [quests]);

  /**
   * Renders the list of NFTs (Non-Fungible Tokens) associated with a collection.
   * This function uses the `useMemo` hook to memoize the rendering logic, improving performance by preventing unnecessary re-renders.
   * It generates a JSX element representing the list of NFTs, either displaying the NFT cards if there are NFTs in the collection or an empty state message if there are no NFTs.
   *
   * @function
   * @name renderNFTsList
   * @returns {React.ReactElement} The JSX element representing the list of NFTs or the empty state message.
   */
  const renderNFTsList = useMemo(() => (
    collection?.nfts?.length ? (
      <div className={classNames([styles.grid, styles.nfts])}>
        {collection?.nfts?.map((nft, index) => (
          <CreatorNFTCard
            key={`--collection-nft-card-${index.toString()}`}
            name={nft.name}
            image={nft.image}
            imageTypeId={nft.imageTypeId}
            welcomeMessage={nft.welcomeMessage}
            isMobile={isMobile}
            canEdit={!collection.published}
            onPressEdit={() => handleClickEditNFT(nft)}
            onPressDelete={() => handleClickDeleteNFT(nft)}
          />
        ))}
      </div>
    ) : (
      <div className={styles.center}>
        <EmptyState
          icon={<RiImageLine />}
          text={language.nftsEmptyState}
        />
      </div>
    )
  ), [collection?.nfts]);

  /**
   * Renders the list of rewards (gifts) associated with a collection.
   *
   * This function uses the `useMemo` hook to memoize the rendering logic, improving performance by preventing unnecessary re-renders.
   * It generates a JSX element representing the list of rewards, either displaying the gift cards if there are gifts in the collection or an empty state message if there are no gifts.
   *
   * @function
   * @name renderRewardsList
   * @returns {React.ReactElement} The JSX element representing the list of rewards or the empty state message.
   */
  const renderRewardsList = useMemo(() => (
    giftListByCollectionId && giftListByCollectionId.length > 0 ? (
        <div className={classNames([styles.grid, styles.rewards])}>
          {giftListByCollectionId?.map((gift, index) => (
            gift.published ? (
              <GiftCard
                key={`--gift-card-${index.toString()}`}
                isMobile={isMobile}
                isCreator={true}
                collectionId={collection?.id as number}
                {...gift}
              />
            ) : (
              <GiftCard
                key={`--gift-card-${index.toString()}`}
                isMobile={isMobile}
                isCreator={true}
                collectionId={collection?.id as number}
                {...gift}
              />
            )

          ))}
        </div>
    ) : (
        <div className={styles.center}>
          <EmptyState
            icon={<RiGift2Line />}
            text={language.rewardsEmptyState}
          />
        </div>
    )
  ), [giftListByCollectionId]);

  /**
   * @const handleDeleteCollection
   * @description Delete a collection of the creator.
   */
  const handleDeleteCollection = useCallback(() => {
    openModal(ModalsId.DELETE_COLLECTION, {
      id: collection?.id
    });
  }, [collection?.id]);

  /**
     * @const handleClickPublishCollection
     * @description Publish a collection of the creator.
     */
  const handlePublishCollection = useCallback(() => {
    if (collection?.id) {
      openModal(ModalsId.CONFIRM_MESSAGE, {
        title: 'Publish Collection',
        description: ['Are you sure you want to publish the collection?', 'Remember that once the publication has been made, you will not be able to modify it.'],
        action: () => {
          closeModal();
          publishCollection(collection.id);
        }
      });
    }
  }, [collection?.id]);

  if (!collection || isLoadingCollection || isLoadingQuests) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.banner}>
        <img src={collection?.banner} alt={collection?.name} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <img src={collection?.avatar} alt={collection?.name} />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h2'} weight={'semiBold'}>{collection?.name}</Typography>
            <div className={styles.social}>
              <SocialLinks
                data={generateLinksItem({
                  discord: collection?.discord ?? '',
                  instagram: collection?.instagramUrl ?? '',
                  openSea: collection?.openSeaUrl ?? '',
                  twitter: collection?.twitter ?? '',
                  url: collection?.url ?? '',
                  calendy: collection?.calendy ?? ''
                })}
                showAdd={false}
              />
            </div>
          </div>
          {!collection?.published ? (<div className={classNames([styles.row, styles.actions])}>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} color={'tertiary'} onClick={handleClickEditCollection}>{language.editCollection}</Button>
            </div>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} color={'error'} onClick={handleDeleteCollection} isLoading={isLoadingDeletingCollection}>{language.delete}</Button>
            </div>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} onClick={handlePublishCollection} isLoading={isLoadingPublishingCollection}>{language.publish}</Button>
            </div>
          </div>) : (
            <div>
              <Button
                size={'md'}
                variant={'solid'}
                onClick={handleClickNewReward}
                iconLeft={(<RiAddLine />)}>
                {language.newReward}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.basicDataField}>
          <div className={styles.inline}>
            <div className={styles.row}>
              <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.deploymentNetwork}</Typography>
              <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{collection?.chain?.chain}</Typography>
            </div>
            <div className={styles.row}>
              <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.published}</Typography>
              <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{collection?.published ? language.yes : language.no}</Typography>
            </div>
          </div>

          <div className={styles.inline}>
            {collection?.givitUrl && (
              <div className={styles.row}>
                <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.url}</Typography>
                <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{process.env.REACT_APP_ENVIRONMENT_URL}/collection/{collection?.givitUrl}</Typography>
              </div>
            )}
          </div>

          <div className={styles.row}>
            <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.description}</Typography>
            <QuillTextInput
              theme='snow'
              value={collection?.description}
              readOnly={true}
              modules={{toolbar: false}}
            />
          </div>
        </div>

        {collection?.quests && (
          <div className={styles.field}>
            <PageTitle
              text={language.questsTitle}
              rightElement={!collection?.published && (
                <div>
                  <Button
                    variant={'solid'}
                    onClick={handleClickNewQuest}
                    iconLeft={(<RiAddLine />)}>
                    {language.addNewQuest}
                  </Button>
                </div>
              )}
            />
            {renderQuestsList}
          </div>
        )}

        <div className={styles.field}>
          <PageTitle
            text={language.nftsTitle}
            rightElement={!collection?.published && (
              <div>
                <Button
                  variant={'solid'}
                  onClick={handleCLickNewNFT}
                  iconLeft={(<RiAddLine />)}>
                  {isMobile ? language.addNft : language.addNftToCollection}
                </Button>
              </div>
            )}
          />
          {renderNFTsList}
        </div>

        <div className={styles.field}>
          <PageTitle
            text={language.rewardsTitle}
            rightElement={(
              <div>
                <Button
                  size={'md'}
                  variant={'solid'}
                  onClick={handleClickNewReward}
                  iconLeft={(<RiAddLine />)}>
                  New reward
                </Button>
              </div>
            )}
          />
          {renderRewardsList}
        </div>
      </div>
    </div>
  );
}

export default CollectionPreview;
