// API
import request, {requestWithFile} from '../api';

// Types
import {
  GetCollectionListResponse,
  GetUserCollectionNFTsListResponse,
  CreateNewCollectionResponse,
  GetCollectionByIdResponse,
  EditNFTResponse,
  EditCollectionResponse,
  DeleteCollectionResponse,
  PublishCollectionResponse,
  ClaimAirdropCollectionNFTResponse,
  ClaimQuestCollectionNFTResponse,
  GetActivityCSVResponse
} from './types';
import {INFTCollection} from '@type/nftCollection';

/**
 * @function getCollectionList()
 */
export const getCollectionList = async(): Promise<GetCollectionListResponse> =>
  await request('/collections/user/user', {
    method: 'GET',
    params: {
      page: '0',
      size: '30'
    }
  });

/**
 * @function getCollectionNFTsList(collectionId)
 */
export const getCollectionNFTsList = async(collectionId: number): Promise<INFTCollection[]> =>
  await request('/collections/' + collectionId.toString() + '/nfts', {
    method: 'GET',
    params: {
      page: '0',
      size: '30'
    }
  });

export const getUserCollectionNFTsList = async(collectionId: number): Promise<GetUserCollectionNFTsListResponse> =>
  await request('/collections/' + collectionId.toString() + '/nfts/user', {
    method: 'GET'
  });

/**
 * @function createNewCollection
 * @param formData
 */
export const createNewCollection = async(formData: {
  logo: File,
  banner: File,
  collection: {
    name: string,
    description: string,
  }
}): Promise<CreateNewCollectionResponse> =>
  await requestWithFile('POST', '/collections', formData);

/**
 * @function editCollection
 * @param collectionId
 * @param formData
 */
export const editCollection = async(collectionId: number, formData: {
  logo: File | string,
  banner: File | string,
  collection: {
    name: string,
    description: string,
    discord: string,
    instagramUrl: string,
    openSeaUrl: string,
    twitter: string,
    url: string,
    xp: number
  }
}): Promise<EditCollectionResponse> =>
  await requestWithFile('PUT', `/collections/${collectionId}`, formData);

/**
 * @function createNFT
 * @param collectionId
 * @param formData
 */
export const createNFT = async(collectionId: number, formData: {
  logo: File,
  banner: File,
  collection: {
    name: string,
    welcomeMessage: string,
  }
}): Promise<CreateNewCollectionResponse> =>
  await requestWithFile('POST', `/nfts/collection/${collectionId}`, formData);

/**
 * @function editNFT
 * @param nftId
 * @param formData
 */
export const editNFT = async(nftId: number, formData: {
  logo: File | string,
  banner: File | string,
  collection: {
    name: string,
    welcomeMessage: string,
  }
}): Promise<EditNFTResponse> =>
  await requestWithFile('PUT', `/nfts/${nftId.toString()}`, formData);

/**
 * @function getCollectionById(collectionId)
 */
export const getCollectionById = async(collectionId: number): Promise<GetCollectionByIdResponse> =>
  await request(`/collections/${collectionId}`, {
    method: 'GET'
  });

/**
 * @function getCollectionByName(collectionName)
 */
export const getCollectionByName = async(name: string): Promise<GetCollectionByIdResponse> =>
  await request(`/collections/${name}`, {
    method: 'GET'
  });

/**
 * @function deleteNFT(collectionId, nftId)
 */
export const deleteNFT = async(collectionId: number, nftId: number): Promise<INFTCollection[]> =>
  await request('/nfts/' + nftId.toString(), {
    method: 'DELETE'
  });

/**
 * @function deleteCollection(collectionId)
 */
export const deleteCollection = async(collectionId: number): Promise<DeleteCollectionResponse> =>
  await request('/collections/' + collectionId.toString(), {
    method: 'DELETE'
  });

/**
 * @function publishCollection(collectionId)
 */
export const publishCollection = async(collectionId: number): Promise<PublishCollectionResponse> =>
  await request('/collections/publish/' + collectionId.toString(), {
    method: 'POST'
  });

/**
 * @function claimAirdropCollectionNFT(nftId)
 */
export const claimAirdropCollectionNFT = async(nftId: number): Promise<ClaimAirdropCollectionNFTResponse> =>
  await request('/user-nfts/' + nftId.toString(), {
    method: 'POST',
    body: {
      fromQuest: 'false'
    }
  });

/**
 * @function claimQuestCollectionNFT(nftId)
 */
export const claimQuestCollectionNFT = async(nftId: number): Promise<ClaimQuestCollectionNFTResponse> =>
  await request('/user-nfts/' + nftId.toString(), {
    method: 'POST',
    body: {
      fromQuest: 'true'
    }
  });

/**
 * @function getActivityData()
 */
export const getActivityData = async(collectionId: number): Promise<GetActivityCSVResponse> =>
  await request('/collections/' + collectionId.toString() + '/csv', {
    method: 'GET'
  });
