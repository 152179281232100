// API
import request from '../api';

// Types
import {
  GetMeResponse,
  LogInPayload,
  LogInResponse,
  LogInWithProviderPayload,
  SignUpPayload,
  SignUpResponse,
  UpdateSigningMessagePayload,
  MobileLoginPayload,
  MobileLoginResponse
} from './types';

/**
 * @function getMe()
 */
export const getMe = async(): Promise<GetMeResponse> =>
  await request('/users/me', {
    method: 'GET'
  });

/**
 * @function verifyAccount()
 * @param token User JWT Token from validator.
 */
export const verifyAccount = async(token: string): Promise<number> =>
  await request('/users/validate', {
    method: 'POST',
    body: {
      token
    }
  });

/**
 * @function signUp()
 * @param address Wallet address.
 */
export const signUp = async({
  email,
  password
}: SignUpPayload): Promise<SignUpResponse> =>
  await request('/users/register', {
    method: 'POST',
    body: {
      email,
      password
    }
  });

/**
 * @function logIn()
 */
export const logIn = async({email, password}: LogInPayload): Promise<LogInResponse> =>
  await request('/users/auth', {
    method: 'POST',
    body: {
      email,
      password
    }
  });

/**
 * @function logIn()
 */
export const logInWithProvider = async({provider, accessToken}: LogInWithProviderPayload): Promise<LogInResponse> =>
  await request(`/users/auth-${provider}`, {
    method: 'POST',
    body: {
      accessToken
    }
  });

/**
 * @function updateSigningMessage()
 * @description Update web3 signature of the account.
 */
export const updateSigningMessage = async({signature, message}: UpdateSigningMessagePayload): Promise<void> =>
  await request('/users/update-message', {
    method: 'PATCH',
    body: {
      signature,
      message
    }
  });

/**
 * @function askMobileToken()
 * @description Google auth doesn't work from Metamask, so, we generate a temp login token to pass login when opening from Metamask
 */
export const askMobileToken = async(): Promise<LogInResponse> => {
  return await request('/tokens/', {
    method: 'POST'
  });
};

/**
 * @function mobileLogin()
 * @description Google auth doesn't work from Metamask, so, we generate a temp login token to pass login when opening from Metamask
 */
export const mobileLogin = async({token}: MobileLoginPayload): Promise<MobileLoginResponse> => {
  return await request(`/tokens/verify/${token}`, {
    method: 'GET'
  });
};

export const requestResetPassword = async(email: string): Promise<void> => {
  return await request('/users/request-reset-password', {
    method: 'POST',
    body: {
      email
    }
  });
};

export const logOut = async(): Promise<void> => {
  return await request('/users/logout', {
    method: 'POST'
  });
};

export const resetPassword = async(token: string, password: string): Promise<void> => {
  return await request('/users/reset-password', {
    method: 'POST',
    body: {
      token,
      password
    }
  });
};

export const resendVerifyEmail = async(email: string): Promise<void> => {
  return await request('/users/resend-verify-email', {
    method: 'POST',
    body: {
      email
    }
  });
};

/**
 * @function editUser()
 * @description This function is used to edit the user's profile.
 * @param {string} name - The name of the creator.
 * @returns {Promise<void>}
 */
export const editUser = async(name: string): Promise<void> => {
  return await request('/users', {
    method: 'PUT',
    body: {
      name
    }
  });
};
