// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';

// Services
import * as passServices from '@services/pass';

// Types
import {GeneratePassPayload} from '@services/pass/types';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {GeneratePassAction} from '@store/pass/types';

function * generatePassProcess({payload: {nftItemId, pass}}: GeneratePassAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_GENERATING_PASS, true));

    const blob: GeneratePassPayload['response'] = yield call(passServices.generateNFTApplePass, nftItemId);

    const downloadFile = async() => {
      const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/vnd.apple.pkpass'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pass.passName + '.pkpass');
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    yield call(downloadFile);

    yield put(setIsLoading(LoadersId.IS_GENERATING_PASS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_GENERATING_PASS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default generatePassProcess;
