// Dependencies
import {CollectionActions, collectionActionsTypes, ICollectionState} from './types';
import {INFT} from '@type/nft';

const initialCollectionState: ICollectionState = {
  list: {},
  nftList: {},
  userNftIds: [],
  myCollections: {}
};

function collectionReducer(state: ICollectionState = initialCollectionState, action: CollectionActions): ICollectionState {
  switch (action.type) {
    case collectionActionsTypes.SET_COLLECTION_LIST:
      return {
        ...state,
        list: action.payload.list
      };

    case collectionActionsTypes.SET_COLLECTION_NFTS_LIST:
      return {
        ...state,
        nftList: action.payload.list
      };

    case collectionActionsTypes.SET_USER_COLLECTION_NFTS_LIST:
      return {
        ...state,
        userNftIds: action.payload.list
      };

    case collectionActionsTypes.SET_NFT_TO_MY_COLLECTION: {
      const collectionId = action.payload.collectionId;
      const nft = action.payload.nft;
      const existingNFTs = state.myCollections?.[collectionId]?.nfts ?? [];
      const updatedNFTList = [...existingNFTs, nft];

      return {
        ...state,
        myCollections: {
          ...state.myCollections,
          [collectionId]: {
            ...state.myCollections?.[collectionId],
            nfts: updatedNFTList
          }
        }
      };
    }

    case collectionActionsTypes.SET_NFT_DATA_FROM_MY_COLLECTION: {
      const updatedNFTList = state.myCollections?.[action.payload.collectionId]?.nfts?.map((nft): INFT => {
        if (nft.id === action.payload.nft.id) {
          return {
            ...nft,
            ...action.payload.nft
          };
        }
        return nft;
      });

      return {
        ...state,
        myCollections: {
          ...state.myCollections,
          [action.payload.collectionId]: {
            ...state.myCollections?.[action.payload.collectionId],
            nfts: updatedNFTList
          }
        }
      };
    }

    case collectionActionsTypes.UNSET_NFT_FROM_MY_COLLECTION: {
      const newRecords = state.myCollections?.[action.payload.collectionId].nfts?.filter(e => e.id !== action.payload.nftId);
      return {
        ...state,
        myCollections: {
          ...state.myCollections,
          [action.payload.collectionId]: {
            ...state.myCollections?.[action.payload.collectionId],
            nfts: newRecords
          }
        }
      };
    }

    case collectionActionsTypes.SET_NEW_COLLECTION:
      return {
        ...state,
        myCollections: {
          ...state.myCollections,
          [action.payload.id]: action.payload
        }
      };

    case collectionActionsTypes.SET_COLLECTION_DATA: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.collectionId]: {
            ...state.list?.[action.payload.collectionId],
            ...action.payload.data
          }
        },
        myCollections: {
          ...state.myCollections,
          [action.payload.collectionId]: {
            ...state.myCollections?.[action.payload.collectionId],
            ...action.payload.data
          }
        }
      };
    }

    case collectionActionsTypes.SET_COLLECTION_CONTRACT: {
      const {collectionId, contract} = action.payload;

      // Updating the contract field in the list
      const updatedList = {
        ...state.list,
        [collectionId]: {
          ...state.list?.[collectionId],
          contract
        }
      };

      // Updating the contract field in the myCollections
      const updatedMyCollections = {
        ...state.myCollections,
        [collectionId]: {
          ...state.myCollections?.[collectionId],
          contract
        }
      };

      return {
        ...state,
        list: updatedList,
        myCollections: updatedMyCollections
      };
    }

    default:
      return state;
  }
}

export default collectionReducer;
