import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

interface Storage {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<string>;
  removeItem: (key: string) => Promise<void>;
}

const createNoopStorage = (): Storage => {
  return {
    async getItem(_key: string) {
      return await Promise.resolve(null);
    },
    async setItem(_key: string, value: string) {
      return await Promise.resolve(value);
    },
    async removeItem(_key: string) {
      return await Promise.resolve();
    }
  };
};

export default typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();
