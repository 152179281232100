// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {ImportNFTProps} from './types';

// Assets
import {RiQuestionnaireLine} from 'react-icons/ri';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';

// Forms
import ImportNFTForm, {FormValues} from '@forms/ImportNFTForm';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal from '@hooks/useModal';
import useMetamask from '@utils/connectors/metamask';
import useNFTs from '@hooks/useNFTs';
import {useWeb3React} from '@web3-react/core';

// StyleSheet
import styles from './ImportNFTModal.module.scss';
import modalStyle from '../../modals.module.scss';

import language_es from 'src/locales/es/modals/importNFT.json';
import language_en from 'src/locales/en/modals/importNFT.json';

function ImportNFTModal({
  action
}: ImportNFTProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const metamask = useMetamask();
  const {activate} = useWeb3React();
  const {isLoading} = useLoader(LoadersId.IS_IMPORTING_NFT);
  const {importNFT} = useNFTs();

  const handleImportNFTForm = useCallback(({chain, collectionAddress, nftId}: FormValues) => {
    void activate(metamask, () => null, true)
      .then(async() => {
        importNFT(chain.value as number, collectionAddress, nftId as number);
        action();
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'yellow'} icon={(<RiQuestionnaireLine />)} />

      <div className={styles.field}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.title}</Typography>
        <Typography variant={'text'} size={'md'} weight={'regular'}>{language.description1}</Typography>
        <Typography variant={'text'} size={'md'} weight={'regular'}>{language.description2}</Typography>
      </div>

      <ImportNFTForm
        isLoading={isLoading}
        onSubmit={handleImportNFTForm}
        onCancel={closeModal}
      />
    </div>
  );
}

export default ImportNFTModal;
