// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {DeleteCollectionModalProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Typography from '@components/Typography';
import Button from '@components/Button';

// StyleSheet
import styles from './DeleteCollectionModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useCollections from '@hooks/useCollections';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal from '@hooks/useModal';

// Assets
import {RiQuestionnaireLine} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/deleteCollection.json';
import language_en from 'src/locales/en/modals/deleteCollection.json';

function DeleteCollectionModal({collectionId}: DeleteCollectionModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {deleteCollection} = useCollections();
  const {isLoading} = useLoader(LoadersId.IS_DELETING_COLLECTION);
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'yellow'} icon={(<RiQuestionnaireLine />)} />

      <div className={styles.field}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.confirmDelete}</Typography>
        <Typography variant={'text'} size={'md'} weight={'regular'}>{language.irreversibleWarning}</Typography>
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button onClick={() => closeModal()} type={'button'} variant={'outline'} color={'primary'}>
            {language.cancel}
          </Button>
          <Button
            type={'submit'}
            variant={'solid'}
            color={'error'}
            onClick={() => deleteCollection(collectionId)}
            isLoading={isLoading}>
						  {language.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteCollectionModal;
