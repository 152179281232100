// API
import {request} from '../api';

// Types
import {
  ClaimNFTAirDropPayload,
  ClaimNFTResponse,
  ImportNFTPayload,
  ImportNFTResponse,
  GetNFTListPayload,
  GetNFTListResponse,
  GetNFTTransactionStateResponse,
  SendNFTResponse,
  SendMultipleNFTResponse,
  GetNFTByIdPayload
} from './types';

/**
 * Fetches a list of NFTs.
 * @function
 * @async
 * @param {GetNFTListPayload} payload - The parameters for the request.
 * @param {string} searchName - The nft name to filter
 * @param {number} payload.page - The page number to retrieve.
 * @param {number} [payload.size=20] - The number of items per page.
 * @returns {Promise<GetNFTListResponse>} The response from the API.
 * @throws {Error} If the request fails.
 */
export const getNFTList = async({searchName, page, size = 20}: GetNFTListPayload): Promise<GetNFTListResponse> =>
  await request('/users/nfts', {
    method: 'GET',
    params: {
      searchName,
      page: page.toString(),
      size: size.toString()
    }
  });

/**
 * Claims an NFT.
 * @function
 * @async
 * @param {number} id - The ID of the NFT to claim.
 * @returns {Promise<ClaimNFTResponse>} The response from the API.
 * @throws {Error} If the request fails.
 */
export const claimNFT = async(id: number): Promise<ClaimNFTResponse> =>
  await request(`/airdrops/${id}`, {
    method: 'GET'
  });

/**
 * Sends an NFT.
 * @function
 * @async
 * @param {number} id - The ID of the NFT to send.
 * @param {string} email - The email address to send the NFT to.
 * @param {string} text - The text to include with the NFT.
 * @returns {Promise<SendNFTResponse>} The response from the API.
 * @throws {Error} If the request fails.
 */
export const sendNFT = async(id: number, email: string, text: string): Promise<SendNFTResponse> =>
  await request(`/airdrops/transfer-unclaimed/${id}`, {
    method: 'POST',
    body: {
      email,
      text
    }
  });

export const sendMultipleNFTs = async(id: number, emails: string[], text: string): Promise<SendMultipleNFTResponse> =>
  await request(`/airdrops/transfer-unclaimed-multiple/${id}`, {
    method: 'POST',
    body: {
      emails,
      text
    }
  });

/**
 * Retrieves the transaction state of an NFT.
 * @function
 * @async
 * @param {number} id - The ID of the NFT to check the transaction state for.
 * @returns {Promise<GetNFTTransactionStateResponse>} The response from the API.
 * @throws {Error} If the request fails.
 */
export const getNFTTransactionState = async(id: number): Promise<GetNFTTransactionStateResponse> =>
  await request(`/airdrops/check-claim/${id}`, {
    method: 'PUT'
  });

/**
 * Retrieves the number of pending NFTs for the user.
 * @function
 * @async
 * @returns {Promise<number>} The number of pending NFTs.
 * @throws {Error} If the request fails.
 */
export const getPendingNFTs = async(): Promise<number> =>
  await request('/user-nfts/pending', {
    method: 'GET'
  });

/**
 * Claims an NFT from an airdrop.
 * @function
 * @async
 * @param {ClaimNFTAirDropPayload} payload - The parameters for the claim.
 * @param {number} payload.id - The ID of the NFT to claim.
 * @param {string} payload.nftRequest - The request for the NFT.
 * @param {string} payload.message - The message to include with the claim.
 * @param {string} payload.signature - The signature for the claim.
 * @returns {Promise<ClaimNFTResponse>} The response from the API.
 * @throws {Error} If the request fails.
 */
export const claimNFTAirDrop = async({id, requestData, message, signature}: ClaimNFTAirDropPayload): Promise<ClaimNFTResponse> =>
  await request(`/airdrops/claim/${id}`, {
    method: 'POST',
    body: {
      request: requestData,
      message,
      signature
    }
  });

export const importNFT = async({chainId, collectionAddress, nftId, message, signature}: ImportNFTPayload): Promise<ImportNFTResponse> =>
  await request('/user-nfts/import', {
    method: 'POST',
    body: {
      chainId: chainId?.toString(),
      collectionAddress,
      nftId: nftId.toString(),
      message,
      signature
    }
  });

/**
 * Retrieves the NFT details by ID.
 * @function
 * @async
 * @param {number} id - The ID of the NFT to retrieve.
 * @returns {Promise<INFT>} The NFT details.
 * @throws {Error} If the request fails.
 */
export const getNFTById = async(id: GetNFTByIdPayload['payload']['id']): Promise<GetNFTByIdPayload['response']> =>
  await request(`/user-nfts/${id}`, {
    method: 'GET'
  });
