// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, delay} from 'redux-saga/effects';

// Types
import {PublishGiftAction} from '@store/gift/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/gift';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {openModal, closeModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * Handles the process of publishing a gift.
 * @generator
 * @function
 * @param {PublishGiftAction} action - The action object with the type and payload.
 * @param {number} action.payload.giftId - The ID of the gift to be published.
 * @returns {SagaIterator<void>} A saga iterator that handles the publishing process of the gift.
 */
function * publishGiftProcess({payload: {giftId}}: PublishGiftAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_PUBLISHING_GIFT, true));
    yield put(openModal(ModalsId.PUBLISHING_GIFT, {
      type: 'loading'
    }));

    yield call(services.publishGift, giftId);

    yield put(closeModal());
    yield put(openModal(ModalsId.PUBLISHING_GIFT, {
      type: 'completed'
    }));

    yield put(setIsLoading(LoadersId.IS_PUBLISHING_GIFT, false));
  } catch (e) {
    yield delay(1000);
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_PUBLISHING_GIFT, false));
    yield put(closeModal());
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default publishGiftProcess;
