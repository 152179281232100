// Dependencies
import React, {useCallback, useEffect} from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';

// Types
import {GetPassProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './GetPassModal.module.scss';

// Assets
import sentEmailLottie from '@assets/lotties/sent-email.json';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import PassCard from '@components/cards/PassCard';
import Loader from '@components/Loader';

// Hooks
import useModal from '@hooks/useModal';
import useResponsive from '@hooks/useResponsive';
import usePass from '@hooks/usePass';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Types
import {IPass} from '@type/pass';

import language_es from 'src/locales/es/modals/getPass.json';
import language_en from 'src/locales/en/modals/getPass.json';

function GetPassModal({nft, isComplete}: GetPassProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {pass, fetchPassByQrCode, generatePass} = usePass();
  const {isMobile} = useResponsive();
  const {isLoading} = useLoader(LoadersId.IS_GENERATING_PASS);

  useEffect(() => {
    fetchPassByQrCode(nft.generatePassQrCode);
  }, [nft]);

  /**
   * @function handleLoginForm():
   */
  const handleGetPass = useCallback(() => {
    generatePass(nft.nftItemId, pass as IPass);
  }, [nft, pass]);

  if (!pass) return <Loader />;

  return (
    <div className={classNames([modalStyle.modal, styles.layout, {[styles.complete]: isComplete}])}>
      {isComplete ? (
        <div className={styles.center}>
          <div className={styles.lottie}>
            <Lottie
              animationData={sentEmailLottie}
              loop={false}
              autoplay={true}
            />
          </div>
          <div className={classNames([styles.field, styles.confirm])}>
            <div className={styles.message}>
              <Typography component={'h1'} variant={'display'} textAlign={'center'} size={'sm'}>{language.nftHasBeenSent.replace('{{nftName}}', nft.name)}</Typography>
            </div>
            <div className={styles.action}>
              <Button variant={'solid'} color={'primary'} onClick={closeModal}>{language.closeButton}</Button>
            </div>
          </div>
        </div>
      ) : (
          <>
            <div className={styles.field}>
              <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.sendNFTTitle}</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'}>{language.sendNFTDescription}</Typography>
            </div>
            <div className={styles.field}>
              <PassCard
                isMobile={isMobile}
                {...pass}
              />
            </div>
            <div className={styles.field}>
              <Button
                className={styles.claimNFTButton}
                onClick={handleGetPass}
                isLoading={isLoading}
                showTextOnLoading={true}>
                {language.getPass.replace('{{name}}', nft.collectionName)}
              </Button>
            </div>
            <div className={styles.field}>
              <Button
                className={styles.closeButton}
                onClick={closeModal}>
                {language.closeButton}
              </Button>
            </div>
          </>
      )}
    </div>
  );
}

export default GetPassModal;
