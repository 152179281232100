// Dependencies
import React, {useCallback, useState} from 'react';
import classNames from 'clsx';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './StartGivitNFTModal.module.scss';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';
import Button from '@components/Button';

// Forms
import ContactSalesForm, {FormValues} from '@forms/ContactSalesForm';

// Assets
import {RiArrowRightLine, RiMailLine} from 'react-icons/ri';

// Services
import {createContact} from '@services/contact';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal from '@hooks/useModal';

import language_es from 'src/locales/es/modals/startGivitNFT.json';
import language_en from 'src/locales/en/modals/startGivitNFT.json';

function StartGivitNFTModal(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const [isComplete, setCompleted] = useState<boolean>(false);
  const {isLoading, setLoading} = useLoader(LoadersId.IS_SENDING_CONTACT_SALES);

  /**
   * A callback function for handling the submission of the contact sales form.
   *
   * @param {Object} formData - The form data containing the email and company details for contacting sales.
   * @param {string} formData.email - The email address of the user for sales communication.
   * @param {string} formData.company - The company name of the user, if applicable.
   * @param {string} formData.question - The question of the user, if applicable.
   */
  const handleContactSalesForm = useCallback(({email, company, question}: FormValues) => {
    setLoading(LoadersId.IS_SENDING_CONTACT_SALES, true);
    void createContact(email, company, question)
      .then(() => {
        setLoading(LoadersId.IS_SENDING_CONTACT_SALES, false);
        setCompleted(true);
      });
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon
        variant={'green'}
        icon={(<RiMailLine />)}
      />
      {isComplete ? (
        <>
          <div className={styles.field}>
            <Typography variant={'display'} size={'xs'} weight={'semiBold'}>{language.sales_contact_complete_title}</Typography>
            <Typography variant={'text'} size={'sm'}>{language.sales_contact_complete_message}</Typography>
          </div>
          <div className={styles.action}>
            <div>
              <Button onClick={closeModal} iconRight={(<RiArrowRightLine />)}>{language.continue_button}</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.field}>
            <Typography variant={'display'} size={'xs'} weight={'semiBold'}>{language.sales_contact_title}</Typography>
            <Typography variant={'text'} size={'sm'}>{language.sales_contact_description}</Typography>
          </div>
          <ContactSalesForm
            isLoading={isLoading}
            onSubmit={handleContactSalesForm}
            onCancel={closeModal}
          />
        </>
      )}
    </div>
  );
}

export default StartGivitNFTModal;
