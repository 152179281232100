// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';

// Types
import {SendNFTProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './SendNFT.module.scss';

// Assets
import sentEmailLottie from '@assets/lotties/sent-email.json';

// Components
import Typography from '@components/Typography';
import SendNFTForm, {FormValues} from '@forms/SendNFTForm';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Hooks
import useModal from '@hooks/useModal';
import useNFTs from '@hooks/useNFTs';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useResponsive from '@hooks/useResponsive';

import language_es from 'src/locales/es/modals/sendNFT.json';
import language_en from 'src/locales/en/modals/sendNFT.json';

function SendNFT({nft, email, isComplete}: SendNFTProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {sendNFT} = useNFTs();
  const {isLoading} = useLoader(LoadersId.IS_SENDING_NFT);
  const {isMobile} = useResponsive();

  /**
   * @function handleLoginForm():
   */
  const handleSendNFTForm = useCallback((values: FormValues) => {
    const {email, text} = values;
    sendNFT(nft, email, text);
  }, [nft]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout, {[styles.complete]: isComplete}])}>
      {isComplete ? (
        <div className={styles.center}>
          <div className={styles.lottie}>
            <Lottie
              animationData={sentEmailLottie}
              loop={false}
              autoplay={true}
            />
          </div>
          <div className={classNames([styles.field, styles.confirm])}>
            <div className={styles.message}>
              <Typography component={'h1'} variant={'display'} textAlign={'center'} size={'sm'}>{language.nftHasBeenSent.replace('{{nftName}}', nft.name)}</Typography>
              <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.willReceiveItShortly.replace('{{email}}', email as string)}</Typography>
            </div>
            <div className={styles.action}>
              <Button variant={'solid'} color={'primary'} onClick={closeModal}>{language.closeButton}</Button>
            </div>
          </div>
        </div>
      ) : (
          <>
            <div className={styles.field}>
              <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.sendNFTTitle}</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'}>{language.sendNFTDescription}</Typography>
            </div>
            <div className={styles.field}>
              <NFTViewer
                onClick={() => {}}
                isMobile={isMobile}
                mediaSrc={nft?.image}
                mediaTypeId={nft?.imageTypeId}
              />
            </div>

            <div className={styles.field}>
              <SendNFTForm
                onSubmit={handleSendNFTForm}
                isLoading={isLoading}
                onCancel={closeModal}
              />
            </div>
          </>
      )}
    </div>
  );
}

export default SendNFT;
