// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';
import {FormikProps, FormikHelpers, useFormik} from 'formik';

// Types
import {FormValues, LoginFormProps} from './types';
import {ModalsId} from '@type/modals';

// Styles
import styles from './LoginForm.module.scss';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Typography from '@components/Typography';

// Assets
import GoogleLogo from '@assets/images/google-logo.svg';
import DiscordLogo from '@assets/images/discord-logo.svg';

// Validation
import LoginSchema from './validations';

// Hooks
import useForm from '@hooks/useForm';
import useModal from '@hooks/useModal';

import language_es from 'src/locales/es/forms/loginForm.json';
import language_en from 'src/locales/en/forms/loginForm.json';

function LoginForm({onSubmit, redirect, isLoading}: LoginFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnMount: true,
    validationSchema: LoginSchema,
    onSubmit
  });

  const {getErrorFromField, handleChangeField} = useForm<FormValues>(formik);
  const {openModal} = useModal();

  /**
   * Handles the action to reset the password by opening the modal for password reset request.
   * This function is memoized using useCallback to prevent unnecessary re-renders.
   */
  const handleResetPassword = useCallback(() => {
    openModal(ModalsId.REQUEST_RESET_PASSWORD);
  }, [openModal]);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.layout}>
      {/* Email */}
      <div className={styles.field}>
        <TextInput
          name={'email'}
          type={'email'}
          placeholder={language.email.placeholder}
          value={formik.values.email}
          onChangeText={(email) => handleChangeField('email', email)}
          disabled={isLoading}
          error={language[getErrorFromField('email') as keyof typeof language] as string}
        />
      </div>
      {/* Password */}
      <div className={styles.field}>
        <TextInput
          name={'password'}
          type={'password'}
          placeholder={language.password.placeholder}
          secureEntry={true}
          value={formik.values.password}
          onChangeText={(password) => handleChangeField('password', password)}
          disabled={isLoading}
          error={language[getErrorFromField('password') as keyof typeof language] as string}
        />
      </div>
      {/* Field */}
      <div className={classNames([styles.field, styles.right])}>
        <Typography variant={'text'} size={'sm'} weight={'semiBold'}>
          <p onClick={handleResetPassword} className={styles.link}>{language['forgot-password']}</p>
        </Typography>
      </div>

      <div className={classNames([styles.field, styles.actions])}>
        <Button
          type={'submit'}
          size={'lg'}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
          isLoading={isLoading}>
          {language['sign-in']}
        </Button>

        <a href={`${process.env.REACT_APP_API_BASE_URL}/users/google?state=${redirect}`}>
          <Button
            type={'button'}
            size={'lg'}
            iconLeft={(<GoogleLogo />)}
            disabled={isLoading}
            className={styles.social}>
            {language['sign-in-with-google']}
          </Button>
        </a>
        <a href={`${process.env.REACT_APP_API_BASE_URL}/users/discord?state=${redirect}`}>
          <Button
            type={'button'}
            size={'lg'}
            iconLeft={(<DiscordLogo />)}
            disabled={isLoading}
            className={styles.social}>
            {language['sign-in-with-discord']}
          </Button>
        </a>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default LoginForm;
