// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setPassCreated} from '../actions';

// Services
import * as passServices from '@services/pass';

// Types
import {CreateNewPassPayload} from '@services/pass/types';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {CreateNewPassAction} from '@store/pass/types';

function * createNewPassProcess({payload: {email, passType, pass}}: CreateNewPassAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATING_NEW_PASS, true));

    if (passType === 1) {
      const response = yield call(passServices.createNewApplePass, email, passType, pass);

      const downloadFile = async() => {
        const url = window.URL.createObjectURL(new Blob([response], {type: 'application/vnd.apple.pkpass'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pass.passName + '.pkpass');
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      yield call(downloadFile);
    } else {
      const response: CreateNewPassPayload['responseGoogle'] = yield call(passServices.createNewGooglePass, email, passType, pass);
      yield call(() => {
        window.open(response, '_blank');
      });
    }

    yield put(setPassCreated(true));

    yield put(setIsLoading(LoadersId.IS_CREATING_NEW_PASS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATING_NEW_PASS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default createNewPassProcess;
