// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {ShowQRCodeModalProps} from './types';

// Components
import Button from '@components/Button';
import QRCode from 'react-qr-code';
import Loader from '@components/Loader';

// StyleSheet
import styles from './ShowQRCodeModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useModal from '@hooks/useModal';

import language_es from 'src/locales/es/modals/showQrCodeModal.json';
import language_en from 'src/locales/en/modals/showQrCodeModal.json';

function ShowQRCodeModal({nft, collection, action}: ShowQRCodeModalProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  if (!collection || !nft) return <Loader />;

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.banner}>
        <img src={collection?.banner} alt={collection?.name} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.container}>
        <div style={{height: 'auto', margin: '0 auto', maxWidth: 192, width: '100%'}}>
          <QRCode
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={`${window.location.protocol}//${window.location.host}/collection/${collection.givitUrl}/qr/${nft.generatePassQrCode}`}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} color={'primary'} onClick={closeModal}>
              {language.closeButton}
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.copyLinkButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowQRCodeModal;
