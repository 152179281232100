import language_es from 'src/locales/es/pages/creator/createOrEditReward.json';
import language_en from 'src/locales/en/pages/creator/createOrEditReward.json';

export interface IRewardType {
  id: number;
  name: string;
  icon: string;
}

const language = navigator.language.startsWith('es') ? language_es : language_en;

export const getRewardMessage = (rewardTypeId: number) => {
  switch (rewardTypeId) {
    case 1:
      return {
        value: 1,
        label: language.onlineDiscount,
        description: language.onlineDiscountDescription,
        icon: ''
      };
    case 2:
      return {
        value: 2,
        label: language.physicalDiscount,
        description: language.physicalDiscountDescription,
        icon: ''
      };
    case 3:
      return {
        value: 3,
        label: language.physicalEvent,
        description: language.physicalEventDescription,
        icon: ''
      };
    case 4:
      return {
        value: 4,
        label: language.onlineEvent,
        description: language.onlineEventDescription,
        icon: ''
      };
    case 5:
      return {
        value: 5,
        label: language.exclusiveContent,
        description: language.exclusiveContentDescription,
        icon: ''
      };
    default:
      return {
        value: 0,
        label: '',
        description: '',
        icon: ''
      };
  }
};
