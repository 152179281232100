// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './SocialLinks.module.scss';

// Types
import {DataItem, SocialLinksProps} from './types';

// Assets
import {FaDiscord, FaInstagram, FaPlus, FaGlobe} from 'react-icons/fa';
import OpenSeaIcon from '@assets/images/opensea-logo-mark.svg';
import XIcon from '@assets/icons/x-logo-tw.svg';
import CalendyIcon from '@assets/images/calendly.svg';
import EtherscanIcon from '@assets/icons/etherscan.svg';

function SocialLinks({data = [], showAdd = true, onClickAdd}: SocialLinksProps): React.ReactElement {
  /**
   * @const icons
   * @description Indicate what icon should be used in every link type.
   */
  const icons: {[key: string]: React.ReactElement} = {
    discord: (
      <FaDiscord color={'#5865F2'} />
    ),
    twitter: (
      <XIcon />
    ),
    openSea: (
      <OpenSeaIcon />
    ),
    instagram: (
      <FaInstagram />
    ),
    url: (
      <FaGlobe color={'#0E4ECE'} />
    ),
    etherscan: (
      <EtherscanIcon />
    ),
    calendy: (
      <CalendyIcon />
    )
  };

  const addSocialNetworkPrefix = (link: DataItem): string => {
    if (link.type === 'discord') return 'https://discord.gg/' + link.url;
    else if (link.type === 'twitter') return 'https://x.com/' + link.url;
    else if (link.type === 'instagram') return 'https://instagram.com/' + link.url;
    else if (link.type === 'openSea') return 'https://opensea.io/' + link.url;
    else if (link.type === 'calendy') return 'https://calendly.com/' + link.url;
    else return link.url;
  };

  return (
    <div className={styles.layout}>
      <nav className={styles.list}>
        {data.map((link: DataItem, index: number) => (
          <a key={`--link-${index.toString()}`} className={styles.item} href={addSocialNetworkPrefix(link)} rel="noreferrer" target="_blank">
            {icons[link.type]}
          </a>
        ))}
      </nav>
      {showAdd && (
        <button type={'button'} className={classNames([styles.item, styles.add])} onClick={onClickAdd}>
          <FaPlus />
        </button>
      )}
    </div>
  );
}

export function generateLinksItem(obj: {[key in DataItem['type']]?: string}): DataItem[] {
  const resultArray: DataItem[] = [];
  for (const key in obj) {
    if (obj[key as DataItem['type']] !== '') {
      resultArray.push({
        type: key as DataItem['type'],
        url: obj[key as DataItem['type']] ?? ''
      });
    }
  }
  return resultArray;
}

export type {DataItem};
export default SocialLinks;
