// Dependencies
import React from 'react';

// StyleSheet
import styles from './EmptyState.module.scss';

// Types
import {EmptyStateProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Typography from '@components/Typography';

const EmptyState = ({icon, text}: EmptyStateProps): React.ReactElement => (
  <div className={styles.layout}>
    <FeaturedIcon icon={icon} />
    <Typography variant={'text'} size={'sm'} textAlign={'center'}>
      <span dangerouslySetInnerHTML={{__html: (text as string)}} />
    </Typography>
  </div>
);

export default EmptyState;
