// Types
import {
  questActionsTypes,
  FetchQuestTypesAction,
  SetQuestTypesAction,
  FetchQuestsAction,
  SetQuestsAction,
  SetQuestAction,
  UnsetQuestAction,
  SetUpdateQuestAction,
  AddQuestAction,
  UpdateQuestAction,
  DeleteQuestAction,
  SetUserQuestsAction,
  FetchUserQuestsAction,
  TwitterAuthQuestAction,
  TwitterAuthSetDataAction,
  DiscordAuthQuestAction,
  InstagramAuthQuestAction,
  GoogleAuthQuestAction,
  TikTokAuthQuestAction,
  CheckQuestActionIsCompletedAction
} from './types';

import {IQuest, IQuestType, IQuestActionType, IUserQuest} from '@type/quest';

export function fetchQuestTypes(): FetchQuestTypesAction {
  return {
    type: questActionsTypes.FETCH_QUEST_TYPES,
    payload: {
    }
  };
}

export function setQuestTypes(questTypes: IQuestType[], questActionTypes: IQuestActionType[]): SetQuestTypesAction {
  return {
    type: questActionsTypes.SET_QUEST_TYPES,
    payload: {
      questTypes,
      questActionTypes
    }
  };
}

export function fetchQuests(collectionId: number): FetchQuestsAction {
  return {
    type: questActionsTypes.FETCH_QUESTS,
    payload: {
      collectionId
    }
  };
}

export function fetchUserQuests(collectionId: number): FetchUserQuestsAction {
  return {
    type: questActionsTypes.FETCH_USER_QUESTS,
    payload: {
      collectionId
    }
  };
}

export function setQuests(quests: IQuest[]): SetQuestsAction {
  return {
    type: questActionsTypes.SET_QUESTS,
    payload: {
      quests
    }
  };
}

export function setQuest(quest: IQuest): SetQuestAction {
  return {
    type: questActionsTypes.SET_QUEST,
    payload: {
      quest
    }
  };
}

export function unsetQuest(id: number): UnsetQuestAction {
  return {
    type: questActionsTypes.UNSET_QUEST,
    payload: {
      id
    }
  };
}

export function setUpdateQuest(quest: IQuest): SetUpdateQuestAction {
  return {
    type: questActionsTypes.SET_UPDATE_QUEST,
    payload: {
      quest
    }
  };
}

export function setUserQuests(quests: IUserQuest[]): SetUserQuestsAction {
  return {
    type: questActionsTypes.SET_USER_QUESTS,
    payload: {
      quests
    }
  };
}

export function addQuest(collectionId: number, quest: IQuest): AddQuestAction {
  return {
    type: questActionsTypes.ADD_QUEST,
    payload: {
      collectionId,
      quest
    }
  };
}

export function updateQuest(quest: IQuest): UpdateQuestAction {
  return {
    type: questActionsTypes.UPDATE_QUEST,
    payload: {
      quest
    }
  };
}

export function deleteQuest(questId: number): DeleteQuestAction {
  return {
    type: questActionsTypes.DELETE_QUEST,
    payload: {
      questId
    }
  };
}

export function twitterAuthQuest(collectionName: string, questId: number): TwitterAuthQuestAction {
  return {
    type: questActionsTypes.TWITTER_AUTH_QUEST,
    payload: {
      collectionName,
      questId
    }
  };
}

export function twitterAuthSetData(codeVerifier: string, state: string, url: string): TwitterAuthSetDataAction {
  return {
    type: questActionsTypes.SET_TWITTER_AUTH_DATA,
    payload: {
      codeVerifier,
      state,
      url
    }
  };
}

export function discordAuthQuest(collectionName: string, questId: number): DiscordAuthQuestAction {
  return {
    type: questActionsTypes.DISCORD_AUTH_QUEST,
    payload: {
      collectionName,
      questId
    }
  };
}

export function instagramAuthQuest(collectionName: string, questId: number): InstagramAuthQuestAction {
  return {
    type: questActionsTypes.INSTAGRAM_AUTH_QUEST,
    payload: {
      collectionName,
      questId
    }
  };
}

export function googleAuthQuest(collectionName: string, questId: number): GoogleAuthQuestAction {
  return {
    type: questActionsTypes.GOOGLE_AUTH_QUEST,
    payload: {
      collectionName,
      questId
    }
  };
}

export function tiktokAuthQuest(collectionName: string, questId: number): TikTokAuthQuestAction {
  return {
    type: questActionsTypes.TIKTOK_AUTH_QUEST,
    payload: {
      collectionName,
      questId
    }
  };
}

export function checkQuestActionIsCompleted(quest: IQuest): CheckQuestActionIsCompletedAction {
  return {
    type: questActionsTypes.CHECK_QUEST_ACTION_IS_COMPLETED,
    payload: {
      quest
    }
  };
}
