// Dependencies
import {PassState, PassActions, passActionsTypes} from './types';

const initialPassState: PassState = {
  pass: undefined,
  passCreated: false
};

function passReducer(state: PassState = initialPassState, action: PassActions): PassState {
  switch (action.type) {
    case passActionsTypes.SET_PASS_STATE:
      return {
        ...state,
        pass: action.payload.pass
      };
    case passActionsTypes.SET_PASS_CREATED:
      return {
        ...state,
        passCreated: action.payload.passCreated
      };
    default:
      return state;
  }
}

export default passReducer;
