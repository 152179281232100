// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {CreateRewardProps} from './types';
import {getRewardMessage} from '@type/rewardType';

// Hooks
import useGifts from '@hooks/useGifts';
import useLoader, {LoadersId} from '@hooks/useLoader';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CreateOrEditRewardModal.module.scss';

// Components
import Typography from '@components/Typography';

// Forms
import CreateOrEditRewardForm, {FormValues} from '@forms/CreateOrEditRewardForm';

import language_es from 'src/locales/es/pages/creator/createOrEditReward.json';
import language_en from 'src/locales/en/pages/creator/createOrEditReward.json';

function CreateOrEditRewardModal({collectionId, reward}: CreateRewardProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {createGift, editGift} = useGifts();
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_REWARD);

  /**
   * Callback function to handle submitting the creation of a new NFT.
   *
   * @function
   * @name handleSubmitCreateGift
   * @param {Object} values - Form values object with properties: image {File}, name {string},
   * description {string}, copies {string}, supply {string}.
   * @returns {void}
   */
  const handleSubmitCreateGift = useCallback((values: FormValues) => {
    if (reward) {
      editGift(reward.id, {
        description: values.description,
        type: values.type,
        availableFrom: values.availableFrom,
        availableUntil: values.availableUntil,
        rewardContent: values.rewardContent,
        notificationTitle: values.notificationTitle,
        notificationDescription: values.notificationDescription
      });
    } else {
      createGift(collectionId, {
        description: values.description,
        type: values.type,
        availableFrom: values.availableFrom,
        availableUntil: values.availableUntil,
        rewardContent: values.rewardContent,
        notificationTitle: values.notificationTitle,
        notificationDescription: values.notificationDescription
      });
    }
  }, [collectionId, reward]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.row}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.createReward}</Typography>
        <Typography>{language.createRewardDescription}</Typography>
      </div>
      <div className={styles.row}>
        <CreateOrEditRewardForm
          isLoading={isLoading}
          {...reward ? ({
            initialValues: {
              description: reward.description,
              type: getRewardMessage(reward.rewardTypeId),
              rewardContent: reward.rewardContent,
              availableFrom: reward.availableFrom,
              availableUntil: reward.availableUntil,
              notificationTitle: reward.notificationTitle,
              notificationDescription: reward.notificationDescription
            }
          }) : ({
            initialValues: {
              description: '',
              type: {
                value: 0,
                label: '',
                icon: ''
              },
              rewardContent: '',
              availableFrom: '',
              availableUntil: '',
              notificationTitle: '',
              notificationDescription: ''
            }
          })}
          onSubmit={handleSubmitCreateGift}
        />
      </div>
    </div>
  );
}

export default CreateOrEditRewardModal;
