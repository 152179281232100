// API
import request from '../api';

export const buyLicense = async(collectionId: number, description: string): Promise<boolean> =>
  await request('/licenses/buy', {
    method: 'POST',
    body: {
      collectionId: collectionId.toString(),
      description
    }
  });

export const hasValidLicense = async(): Promise<boolean> =>
  await request('/licenses/valid', {
    method: 'GET'
  });
