// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, select, put, delay} from 'redux-saga/effects';

// Types
import {LoadersId} from '@type/loaders';
import {selectWeb3Provider} from '@store/auth/selectors';

// Services
import * as nftServices from '@services/nft';

// Actions
import {closeModal} from '@store/ui/modal/actions';
import {ImportNFTAction} from '@store/nft/types';
import {setIsLoading} from '@store/ui/loaders/actions';
import {addNFT} from '@store/nft/actions';
import {createToast} from '@store/ui/toast/actions';

// Utils
import Web3Utils from '@utils/web3';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function importNFTProcess
 */
function * importNFTProcess({payload: {chainId, collectionAddress, nftId}}: ImportNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_IMPORTING_NFT, true));

    let provider = yield select(selectWeb3Provider);
    if (!provider) {
      yield (delay(1000));
      provider = yield select(selectWeb3Provider);
    }

    const {message, signature} = yield call(Web3Utils.getAuthenticationSigning, provider);
    const userNFT = yield call(nftServices.importNFT, {chainId, collectionAddress, nftId, message, signature});

    yield put(createToast('success', 'NFT imported properly!'));

    yield put(addNFT(userNFT));

    yield put(closeModal());
    yield put(setIsLoading(LoadersId.IS_IMPORTING_NFT, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_IMPORTING_NFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default importNFTProcess;
