// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {licenseActionTypes} from '../types';

// Sagas
import buyLicenseProcess from './buyLicenseProcess';

function * license(): SagaIterator<void> {
  yield takeLatest(licenseActionTypes.BUY_LICENSE, buyLicenseProcess);
}

export default license;
