// Dependencies
import React from 'react';
import {TailSpin} from 'react-loader-spinner';

// Types
import {QuestCallbackTemplateProps} from './types';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// StyleSheet
import styles from './QuestCallbackTemplate.module.scss';

// Assets
import GivitLogo from '@assets/images/logo.png';

const QuestCallbackTemplate = ({message, error, onClick}: QuestCallbackTemplateProps): React.ReactElement => (
  <div className={styles.layout}>
    <div className={styles.header}>
      <img src={GivitLogo} width={162} />
    </div>
    <TailSpin width={64} height={64} color={'#2E4CBF'} />

    <div className={styles.body}>
      <Typography variant={'display'} textAlign={'center'} size={'xs'}>{message}</Typography>
      {error && (
        <Button onClick={onClick} variant={'solid'} color={'error'} size={'xl'}>Click to back to the application</Button>
      )}
    </div>
  </div>
);

export default QuestCallbackTemplate;
