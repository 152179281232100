// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {CreatorWelcomeModalProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Button from '@components/Button';
import Typography from '@components/Typography';

// StyleSheet
import styles from './CreatorWelcomeModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useModal from '@hooks/useModal';

// Assets
import {RiAccountBoxFill} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/creatorWelcome.json';
import language_en from 'src/locales/en/modals/creatorWelcome.json';

function CreatorWelcomeModal({
  action = undefined
}: CreatorWelcomeModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.text}><FeaturedIcon variant={'green'} icon={(<RiAccountBoxFill />)} /></div>
      <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.title}</Typography>
      <div className={styles.container}>
        <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.description1}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.reminder}</Typography>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} color={'primary'} onClick={closeModal}>
              {language.notNowButton}
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.editProfileButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorWelcomeModal;
