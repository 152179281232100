// Dependencies
import React from 'react';
import classNames from 'clsx';
import {useNavigate} from 'react-router-dom';

// StylesSheet
import styles from './Home.module.scss';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import FeatureCard from './partials/FeatureCard';
import AudienceCard from './partials/AudienceCard';

// Assets
import {
  RiArrowRightLine,
  RiTicket2Line,
  RiChat1Line,
  RiGift2Line,
  RiGroupLine, RiStarLine,
  RiTrelloLine
} from 'react-icons/ri';

// Assets
import FeatureImage1 from '@assets/images/features/1.png';
import FeatureImage2 from '@assets/images/features/2.png';
import FeatureImage3 from '@assets/images/features/3.png';
import FeatureImage4 from '@assets/images/features/4.png';
import FeatureImage5 from '@assets/images/features/5.png';
import FeatureImage6 from '@assets/images/features/6.png';
import FeatureImage7 from '@assets/images/features/7.png';
import FeatureImage8 from '@assets/images/features/8.png';
import FeatureImage9 from '@assets/images/features/9.png';
import FeatureImage10 from '@assets/images/features/10.png';
import FeatureImage11 from '@assets/images/features/11.png';
import FeatureImage12 from '@assets/images/features/12.png';
import ImageNFTPlaceholderImage from '@assets/images/image-nft-placeholder.png';

// Hooks
import useResponsive from '@hooks/useResponsive';
import useModal, {ModalsId} from '@hooks/useModal';

// Import languages
import language_es from '../../../locales/es/pages/main/home.json';
import language_en from '../../../locales/en/pages/main/home.json';

function Home(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isMobile, isTabletVertical} = useResponsive();
  const navigate = useNavigate();
  const {openModal} = useModal();

  return (
    <div className={styles.layout}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperContent}>
          <Typography variant={'display'} size={isMobile ? 'lg' : 'xxl'} weight={'bold'}>{language.startBuilding}</Typography>
          <div className={styles.wrapperDescription}>
            <Typography variant={'text'} size={'lg'} weight={'regular'}>{language.description}</Typography>
          </div>
        </div>

        <div className={styles.action}>
          <div>
            <Button onClick={() => navigate('/login')} variant={'solid'} color={'primary'} size={'xxl'}>{language.signInButton}</Button>
          </div>
          <div>
            <Button onClick={() => navigate('/sign-in')} variant={'outline'} color={'primary'} size={'xxl'}>{language.signUpButton}</Button>
          </div>
        </div>

        {/* Description */}
        <div className={styles.wrapperImage} />
      </div>

      <div className={styles.sections}>

        <div className={styles.field}>
          <div className={styles.star}>
            <RiStarLine size={'24px'} />
          </div>
          <div className={styles.audienceImage} />
          <div className={styles.audience}>
            <Typography variant={'display'} size={'md'} weight={'semiBold'} textAlign={(isMobile || isTabletVertical) ? 'center' : 'left'}>{language.exchangeValue}</Typography>
            <Typography variant={'display'} size={'xxl'} weight={'bold'} textAlign={(isMobile || isTabletVertical) ? 'center' : 'left'}>{language.withYourAudience}</Typography>

            <div className={classNames([styles.grid, styles.audienceRow])}>
              <div className={styles.audienceGrid}>
                <AudienceCard
                  icon={(
                    <RiGroupLine />
                  )}
                  title={language.buildYourSocialNetworks}
                  description={language.buildYourSocialNetworksDescription}
                />
                <AudienceCard
                  icon={(
                    <RiGift2Line />
                  )}
                  title={language.rewardYourCommunity}
                  description={language.rewardYourCommunityDescription}
                />
              </div>
              <div className={styles.audienceGrid}>
                <div className={styles.audiencePlaceholder}>
                  <img src={ImageNFTPlaceholderImage} width={140} height={140} />
                </div>
                <AudienceCard
                  icon={(
                    <RiTrelloLine />
                  )}
                  title={language.trackEngagement}
                  description={language.trackEngagementDescription}
                />
                <AudienceCard
                  icon={(
                    <RiTicket2Line />
                  )}
                  title={language.createExclusiveEvents}
                  description={language.createExclusiveEventsDescription}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.title}>
            <RiChat1Line size={24} />
            <Typography variant={'display'} size={'md'} weight={'bold'}>{language.howItWorks}</Typography>
          </div>
          <div className={styles.grid}>
            <FeatureCard
              number={1}
              title={language.buildYourProjectPage}
              text={language.buildYourProjectPageText}
              images={[
                FeatureImage1,
                FeatureImage2,
                FeatureImage3,
                FeatureImage4
              ]}
            />
            <FeatureCard
              number={2}
              title={language.trackInterest}
              text={language.trackInterestText}
              images={[
                FeatureImage5,
                FeatureImage6,
                FeatureImage7,
                FeatureImage8
              ]}
            />
            <FeatureCard
              number={3}
              title={language.buildAndRewardCommunity}
              text={language.buildAndRewardCommunityText}
              images={[
                FeatureImage9,
                FeatureImage10,
                FeatureImage11,
                FeatureImage12
              ]}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.newsletter}>
            <Typography variant={'display'} size={'md'} weight={'bold'}>{language.interestedInFeaturing}</Typography>
            <div className={styles.sales}>
              <Button
                variant={'solid'}
                size={'xxl'}
                onClick={() => openModal(ModalsId.CONTACT_SALES)}
                iconRight={(<RiArrowRightLine />)}
                color={'primary'}>
                {language.contactUs}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
