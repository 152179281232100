import {CreateToastAction, DeleteToastAction, ToastActions} from './types';
import {ToastTypeEnum} from '@type/toasts';

/**
 * @function createToast():
 * @description Open a modal in the interface.
 * @param type Type of Toast (error, success);
 * @param message Message to show.
 */
export function createToast(type: ToastTypeEnum, message: string): CreateToastAction {
  return {
    type: ToastActions.CREATE_TOAST,
    payload: {
      type,
      message
    }
  };
}

/**
 * @function closeModal():
 * @description Close the current modal in the interface.
 */
export function deleteToast(id: number): DeleteToastAction {
  return {
    type: ToastActions.DELETE_TOAST,
    payload: {
      id
    }
  };
}
