// Dependencies
import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// StyleSheet
import styles from './MyCollections.module.scss';

// Collections
import useCollections from '@hooks/useCollections';

// Components
import PageTitle from '@components/PageTitle';
import CollectionCard from '@components/cards/CollectionCard';
import EmptyState from '@components/EmptyState';
import Alert from '@components/Alert';
import Loader from '@components/Loader';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useAuth from '@hooks/useAuth';
import useModal, {ModalsId} from '@hooks/useModal';

// Assets
import {FaUsers} from 'react-icons/fa';

// Types
import {ICollection} from '@type/collection';

import language_es from 'src/locales/es/pages/user/myCollections.json';
import language_en from 'src/locales/en/pages/user/myCollections.json';

function MyCollections(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {collectionList, fetchCollectionList} = useCollections();
  const navigate = useNavigate();
  const {user} = useAuth();
  const {openModal} = useModal();
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_COLLECTION_LIST);

  useEffect(() => {
    fetchCollectionList();
  }, []);

  const handleClickShareQr = useCallback((collection: ICollection) => {
    if (collection.nfts) {
      openModal(ModalsId.SHARE_SHOW_QR_CODE, {
        nft: collection.nfts[0],
        collection,
        action: () => {
          if (collection.nfts && collection.nfts.length > 0) {
            const nft = collection.nfts[0];
            if (nft) {
              const textarea = document.createElement('textarea');
              textarea.value = `${window.location.protocol}//${window.location.host}/collection/${collection.givitUrl}/qr/${nft.generatePassQrCode}`;
              document.body.appendChild(textarea);
              textarea.select();
              textarea.setSelectionRange(0, textarea.value.length);
              document.execCommand('copy');
              document.body.removeChild(textarea);
            }
          }
        }
      });
    }
  }, []);

  if (!user || isLoading) return <Loader />;

  return (
    <div className={styles.layout}>
      <PageTitle
        text={language.collectionsTitle}
      />
      <Alert
        variant={'message'}
        title={language.uniqueCommunityMessage}
        description={language.questCompletionDescription}
      />

      {collectionList?.length ? (
        <div className={styles.grid}>
          {collectionList?.map((collection, index) => (
            <CollectionCard
              key={`--collection-card-${index.toString()}`}
              onClick={() => navigate(`/collection/${collection.givitUrl}`)}
              onShareClick={() => { handleClickShareQr(collection); }}
              description={collection.description}
              bannerUrl={collection.banner}
              name={collection.name}
              published={collection.published}
              creator={{
                name: collection.user.name,
                photoUrl: collection.avatar,
                isCreator: collection.user.id === user.id
              }}
              nfts={collection.nfts ? collection.nfts.length : 0}
            />
          ))}
        </div>
      ) : (
        <div className={styles.empty}>
          <EmptyState
            icon={<FaUsers />}
            text={language.noCollectionsAvailable}
          />
        </div>
      )}
    </div>
  );
}

export default MyCollections;
