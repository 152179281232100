// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setGiftListCollection} from '@store/gift/actions';

// Services
import * as giftServices from '@services/gift';

// Types
import {GetGiftCollectionListResponse} from '@services/gift/types';
import {IGift} from '@type/gift';
import {ErrorMessage} from '@type/error';
import {FetchGiftListByCollectionIdAction} from '../types';

// Utils
import {normalizeState} from '@utils/state';
import errorHandler from '@store/errorHandler';

/**
 * Handles the process of fetching a list of gifts by collection ID.
 * @generator
 * @function
 * @param {FetchGiftListByCollectionIdAction} action - The action object with the type and payload.
 * @param {number} action.payload.collectionId - The ID of the collection for which to fetch the gifts.
 * @param {boolean} action.payload.onlyPublished - Whether to only fetch published gifts.
 * @returns {SagaIterator<void>} A saga iterator that handles the fetching process of the gift list.
 */
function * fetchGiftListByCollectionIdProcess({payload: {collectionId, onlyPublished}}: FetchGiftListByCollectionIdAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, true));

    const response: GetGiftCollectionListResponse = yield call(giftServices.getGiftListByColectionId, collectionId, onlyPublished);

    const normalizedItems: {[key: string]: IGift} = normalizeState(
      response?.rows.map((gift) => ({
        ...gift
      }))
      , 'id');

    yield put(setGiftListCollection(normalizedItems));

    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchGiftListByCollectionIdProcess;
