// Dependencies
import React, {useState} from 'react';
import {FormikProps, FormikHelpers, useFormik} from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import classNames from 'clsx';

// Styles
import styles from './SignInForm.module.scss';

// Assets
import GoogleLogo from '@assets/images/google-logo.svg';
import DiscordLogo from '@assets/images/discord-logo.svg';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import CheckInput from '@components/CheckInput';
import Typography from '@components/Typography';

// Types
import {SignInFormProps, FormValues} from './types';

// Hooks
import useForm from '@hooks/useForm';

// Validation
import SignInValidation from './validations';

import language_es from 'src/locales/es/forms/signInForm.json';
import language_en from 'src/locales/en/forms/signInForm.json';

function SignInForm({onSubmit, isLoading}: SignInFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const [isPasswordFocused, setPasswordFocused] = useState(false);
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      score: 0
    },
    validateOnMount: true,
    validationSchema: SignInValidation,
    onSubmit
  });

  const {getErrorFromField, handleChangeField} = useForm<FormValues>(formik);
  const [checked, setChecked] = useState<boolean>(false);

  const handleSocialLoginClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (!checked) {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.layout}>
      {/* Email */}
      <div className={styles.field}>
        <TextInput
          name={'email'}
          value={formik.values.email}
          onChangeText={(email) => handleChangeField('email', email)}
          placeholder={language.emailPlaceholder}
          error={getErrorFromField('email')}
        />
      </div>
      {/* Password */}
      <div className={styles.field}>
        <TextInput
          name={'password'}
          type={'password'}
          onFocus={() => setPasswordFocused(true)}
          onBlur={() => setPasswordFocused(false)}
          secureEntry={true}
          value={formik.values.password}
          onChangeText={(password) => handleChangeField('password', password)}
          placeholder={language.passwordPlaceholder}
          error={getErrorFromField('password') ?? getErrorFromField('score')}
        />

        {isPasswordFocused && (formik.values.password.length > 0) && (
          <div className={styles.security}>
            <Typography component={'h4'} variant={'text'} weight={'medium'} size={'sm'}>{language.passwordStrengthTitle}</Typography>
            <Typography variant={'text'} size={'sm'}>{language.passwordStrengthDescription}</Typography>

            <PasswordStrengthBar
              password={formik.values.password}
              barColors={['#e3e0ff', '#ff6e5e', '#ffca77', '#59afff', '#5ED24EFF']}
              scoreWords={['Unsafe', 'Weak', 'Okay', 'Good', 'Strong']}
              shortScoreWord={''}
              scoreWordStyle={{
                fontSize: 12,
                marginTop: 8
              }}
              onChangeScore={(score) => handleChangeField('score', score.toString())}
              className={styles.scoreBoard}
            />
          </div>
        )}
      </div>
      <div className={styles.field}>
        <TextInput
          name={'confirmPassword'}
          type={'password'}
          secureEntry={true}
          value={formik.values.confirmPassword}
          onChangeText={(confirmPassword) => handleChangeField('confirmPassword', confirmPassword)}
          placeholder={language.confirmPasswordPlaceholder}
          error={getErrorFromField('confirmPassword')}
        />
      </div>
      <div className={styles.field}>
        <CheckInput
          labelWithLink={
            <Typography variant={'text'} size={'sm'} weight={'medium'}>
              {language.termsAndConditions1}<a className={styles.link} href={'/use-terms'} rel="noreferrer" target="_blank">{language.termsAndConditions2}</a>{language.termsAndConditions3}<a className={styles.link} rel="noreferrer" target="_blank" href={'/privacy-policy'}>{language.termsAndConditions4}</a>
            </Typography>
          }
          checked={checked}
          disabled={false}
          onChange={() => { setChecked(!checked); }}
        />
      </div>
      <div className={classNames([styles.field, styles.actions])}>
        <Button
          type={'submit'}
          size={'lg'}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid || !checked}
          isLoading={isLoading}>
          {language.signUpButton}
        </Button>

        <a href={`${process.env.REACT_APP_API_BASE_URL}/users/google`} onClick={handleSocialLoginClick}>
          <Button
            type={'button'}
            size={'lg'}
            iconLeft={(<GoogleLogo />)}
            disabled={isLoading}
            className={styles.social}>
            {language.signUpGoogleButton}
          </Button>
        </a>
        <a href={`${process.env.REACT_APP_API_BASE_URL}/users/discord`} onClick={handleSocialLoginClick}>
          <Button
            type={'button'}
            size={'lg'}
            iconLeft={(<DiscordLogo />)}
            disabled={isLoading}
            className={styles.social}>
            {language.signUpDiscordButton}
          </Button>
        </a>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default SignInForm;
