// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './Loader.module.scss';

// Components
import {TailSpin} from 'react-loader-spinner';

// Types
import {LoaderProps} from './types';

function Loader({variant = 'full', size = 64, color = '#2E4CBF'}: LoaderProps): React.ReactElement {
  return (
    <div className={classNames([styles.loader, styles[variant]])}>
      <TailSpin
        width={size}
        height={size}
        color={color}
      />
    </div>
  );
}

export default Loader;
