// Types
import {
  collectionActionsTypes,
  CreateNewCollectionAction,
  CreateNewCollectionActionProps,
  CreateNFTAction,
  CreateNFTActionProps,
  DeleteCollectionAction,
  DeleteNFTAction,
  EditCollectionAction,
  EditCollectionActionProps,
  EditNFTAction,
  EditNFTActionProps,
  FetchCollectionByIdAction,
  FetchCollectionByNameAction,
  FetchCollectionListAction,
  FetchCollectionNFTsListAction,
  FetchUserCollectionNFTsListAction,
  PublishCollectionAction,
  SetCollectionDataAction,
  SetCollectionListAction,
  SetCollectionNFTsListAction,
  SetUserCollectionNFTsListAction,
  SetNFTDataFromMyCollectionAction,
  SetNFTToMyCollectionAction,
  UnSetNFTFromMyCollectionAction,
  ClaimAirdropCollectionNFTAction,
  ClaimQuestCollectionNFTAction,
  DownloadActivityDataAction,
  SetCollectionContractAction
} from './types';

import {ICollection} from '@type/collection';
import {INFTCollection} from '@type/nftCollection';
import {INFT} from '@type/nft';

/**
 * ======================
 *  Fetch Collection list
 * ====================== */
export function fetchCollectionList(): FetchCollectionListAction {
  return {
    type: collectionActionsTypes.FETCH_COLLECTION_LIST
  };
}

/**
 * ======================
 *    Set Collection List.
 * ====================== */
export function setCollectionList(list?: {[key: string]: ICollection}): SetCollectionListAction {
  return {
    type: collectionActionsTypes.SET_COLLECTION_LIST,
    payload: {
      list
    }
  };
}

/**
 * ======================
 *  Fetch Collection NFTs list
 * ====================== */
export function fetchCollectionNFTsList(collectionId: number): FetchCollectionNFTsListAction {
  return {
    type: collectionActionsTypes.FETCH_COLLECTION_NFTS_LIST,
    payload: {
      collectionId
    }
  };
}

export function fetchUserCollectionNFTsList(collectionId: number): FetchUserCollectionNFTsListAction {
  return {
    type: collectionActionsTypes.FETCH_USER_COLLECTION_NFTS_LIST,
    payload: {
      collectionId
    }
  };
}

/**
 * ======================
 * Set Collection NFTs List.
 * ====================== */
export function setCollectionNFTsList(list?: {[key: string]: INFTCollection}): SetCollectionNFTsListAction {
  return {
    type: collectionActionsTypes.SET_COLLECTION_NFTS_LIST,
    payload: {
      list
    }
  };
}

export function setUserCollectionNFTsList(list: number[]): SetUserCollectionNFTsListAction {
  return {
    type: collectionActionsTypes.SET_USER_COLLECTION_NFTS_LIST,
    payload: {
      list
    }
  };
}

/**
 * ======================
 * Fetch Collection By Id
 * ====================== */
export function fetchCollectionById(id: number): FetchCollectionByIdAction {
  return {
    type: collectionActionsTypes.FETCH_COLLECTION_BY_ID,
    payload: {
      id
    }
  };
}

/**
 * ======================
 * Fetch Collection By Name
 * ====================== */
export function fetchCollectionByName(name: string): FetchCollectionByNameAction {
  return {
    type: collectionActionsTypes.FETCH_COLLECTION_BY_NAME,
    payload: {
      name
    }
  };
}

/**
 * ======================
 *  Create Collection
 * ====================== */
export function createNewCollection({
  chainId,
  avatar,
  banner,
  name,
  description,
  isPublic,
  instagramUrl,
  discord,
  url,
  givitUrl,
  openSeaUrl,
  twitter,
  quests
}: CreateNewCollectionActionProps): CreateNewCollectionAction {
  return {
    type: collectionActionsTypes.CREATE_NEW_COLLECTION,
    payload: {
      chainId,
      avatar,
      givitUrl,
      banner,
      name,
      description,
      isPublic,
      instagramUrl,
      discord,
      twitter,
      url,
      openSeaUrl,
      quests
    }
  };
}

export function setNewCollection<T>(collection: T) {
  return {
    type: collectionActionsTypes.SET_NEW_COLLECTION,
    payload: collection
  };
}

/**
 * ======================
 *  Edit Collection
 * ====================== */
export function editCollection(collectionId: number, data: EditCollectionActionProps['data']): EditCollectionAction {
  return {
    type: collectionActionsTypes.EDIT_COLLECTION,
    payload: {
      collectionId,
      data
    }
  };
}

/**
 * Create NFT
 */
export function createNFT(collectionId: number, nft: CreateNFTActionProps['nft']): CreateNFTAction {
  return {
    type: collectionActionsTypes.CREATE_NFT,
    payload: {
      collectionId,
      nft
    }
  };
}

export function editNFT(collectionId: number, nft: EditNFTActionProps['nft']): EditNFTAction {
  return {
    type: collectionActionsTypes.EDIT_NFT,
    payload: {
      collectionId,
      nft
    }
  };
}

export function setNFTToMyCollection(collectionId: number, nft: INFT): SetNFTToMyCollectionAction {
  return {
    type: collectionActionsTypes.SET_NFT_TO_MY_COLLECTION,
    payload: {
      collectionId,
      nft
    }
  };
}

export function setNFTDataFromMyCollection(collectionId: number, nft: INFT): SetNFTDataFromMyCollectionAction {
  return {
    type: collectionActionsTypes.SET_NFT_DATA_FROM_MY_COLLECTION,
    payload: {
      collectionId,
      nft
    }
  };
}

export function setCollectionData(collectionId: number, data: ICollection): SetCollectionDataAction {
  return {
    type: collectionActionsTypes.SET_COLLECTION_DATA,
    payload: {
      collectionId,
      data
    }
  };
}

/**
 * Delete NFT
 */
export function deleteNFT(collectionId: number, nftId: number): DeleteNFTAction {
  return {
    type: collectionActionsTypes.DELETE_NFT,
    payload: {
      collectionId,
      nftId
    }
  };
}

export function unSetNFTToMyCollection(collectionId: number, nftId: number): UnSetNFTFromMyCollectionAction {
  return {
    type: collectionActionsTypes.UNSET_NFT_FROM_MY_COLLECTION,
    payload: {
      collectionId,
      nftId
    }
  };
}

export function publishCollection(collectionId: number): PublishCollectionAction {
  return {
    type: collectionActionsTypes.PUBLISH_COLLECTION,
    payload: {
      collectionId
    }
  };
}

export function deleteCollection(collectionId: number): DeleteCollectionAction {
  return {
    type: collectionActionsTypes.DELETE_COLLECTION,
    payload: {
      collectionId
    }
  };
}

export function claimAirdropCollectionNFT(nftId: number, nftUrl: string): ClaimAirdropCollectionNFTAction {
  return {
    type: collectionActionsTypes.CLAIM_AIRDROP_COLLECTION_NFT,
    payload: {
      nftId,
      nftUrl
    }
  };
}

export function claimQuestCollectionNFT(nftId: number, nftUrl: string): ClaimQuestCollectionNFTAction {
  return {
    type: collectionActionsTypes.CLAIM_QUEST_COLLECTION_NFT,
    payload: {
      nftId,
      nftUrl
    }
  };
}

export function downloadActivityCSV(collectionId: number): DownloadActivityDataAction {
  return {
    type: collectionActionsTypes.DOWNLOAD_ACTIVITY_DATA,
    payload: {
      collectionId
    }
  };
}

export function setCollectionContract(collectionId: number, contract: string): SetCollectionContractAction {
  return {
    type: collectionActionsTypes.SET_COLLECTION_CONTRACT,
    payload: {
      collectionId,
      contract
    }
  };
}
