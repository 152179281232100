// Dependencies
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// Types
import {UIManagerProps} from './types';

// Components
import SuperModal from '../../modals/SuperModal';
import SuperToast from '../../toasts/SuperToast';

// Hooks
import useSettings from '@hooks/useSettings';

function UIManager({children}: UIManagerProps): React.ReactElement {
  const navigate = useNavigate();
  const {setRouter} = useSettings();

  useEffect(() => {
    setRouter(navigate);
  }, [navigate]);

  return (
    <React.Fragment>
      <SuperModal />
      <SuperToast />
      {children}
    </React.Fragment>
  );
}

export default UIManager;
