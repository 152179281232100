// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {closeModal, openModal} from '@store/ui/modal/actions';

// Types
import {OpenModalAction, CloseModalAction, IModalState, ModalData} from '@store/ui/modal/types';
import {ModalsId} from '@type/modals';

// Selectors
import {selectCurrentModal} from '@store/ui/modal/selectors';
import {RootState} from '@store/reducer';

interface ModalState {
  id: ModalsId | null;
  data?: ModalData ;
}

interface IUseModal {
  modal: IModalState,
  openModal: (id: ModalsId, data?: ModalData) => OpenModalAction<ModalData>;
  closeModal: () => CloseModalAction;
}

function useModal(): IUseModal {
  const dispatch = useDispatch();
  const modal = useSelector<RootState, ModalState>(selectCurrentModal);

  return {
    modal,
    openModal: (id, data) => dispatch(openModal(id, data ?? {})),
    closeModal: () => dispatch(closeModal())
  };
}

export {ModalsId};

export default useModal;
