// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './BannerUpload.module.scss';

// Types
import {BannerUploadProps} from './types';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';
import {useDropzone} from 'react-dropzone';

// Assets
import {RiUploadCloud2Line} from 'react-icons/ri';

// Config
import {IMAGE_UPLOAD_FORMATS} from '@config/index';

import language_es from 'src/locales/es/components/bannerUpload.json';
import language_en from 'src/locales/en/components/bannerUpload.json';

function BannerUpload({photo, className = '', onDrop}: BannerUploadProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': IMAGE_UPLOAD_FORMATS
    }
  });

  return (
    <div className={classNames([styles.banner, {[styles.active]: isDragActive}, className])}>
      <div className={styles.image} {...getRootProps()}>
        <input {...getInputProps()} />
        {!photo ? (
          <div className={styles.container}>
            <Typography variant={'text'} className={styles.dropText} size={'sm'} textAlign={'center'}>
              <b>{language.uploadBanner}</b>
            </Typography>
            <FeaturedIcon icon={(<RiUploadCloud2Line />)} />
            <div className={styles.text}>
              <Typography variant={'text'} className={styles.dropText} size={'sm'} textAlign={'center'}>
                <b>{language.clickUpload}</b> {language.dragDrop}
              </Typography>
              <Typography variant={'text'} size={'xs'} weight={'regular'} textAlign={'center'}>SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
            </div>
          </div>
        ) : (
          <img src={typeof photo === 'string' ? photo : photo.preview} alt={'Collection Preview'} />
        )}
      </div>
    </div>
  );
}

export default BannerUpload;
