// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';

// Types
import {SendMultipleNFTsProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './SendMultipleNFTsModal.module.scss';

// Assets
import sentEmailLottie from '@assets/lotties/sent-email.json';

// Components
import Typography from '@components/Typography';
import SendMultipleNFTsForm, {FormValues} from '@forms/SendMultipleNFTsForm';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Hooks
import useModal from '@hooks/useModal';
import useNFTs from '@hooks/useNFTs';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useResponsive from '@hooks/useResponsive';

import language_es from 'src/locales/es/modals/sendMultipleNFTs.json';
import language_en from 'src/locales/en/modals/sendMultipleNFTs.json';

function SendMultipleNFTsModal({nft, isComplete}: SendMultipleNFTsProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {sendMultipleNFTs} = useNFTs();
  const {isLoading} = useLoader(LoadersId.IS_SENDING_MULTIPLE_NFTS);
  const {isMobile} = useResponsive();

  /**
   * @function handleLoginForm():
   */
  const handleSendNFTForm = useCallback((values: FormValues) => {
    const {emails, text} = values;
    sendMultipleNFTs(nft, emails, text);
  }, [nft]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout, {[styles.complete]: isComplete}])}>
      {isComplete ? (
        <div className={styles.center}>
          <div className={styles.lottie}>
            <Lottie
              animationData={sentEmailLottie}
              loop={false}
              autoplay={true}
            />
          </div>
          <div className={classNames([styles.field, styles.confirm])}>
            <div className={styles.message}>
              <Typography component={'h1'} variant={'display'} textAlign={'center'} size={'sm'}>{language.nft_sent_confirmation_title.replace('{{nftName}}', nft.name)}</Typography>
              <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.nft_sent_confirmation_message}</Typography>
            </div>
            <div className={styles.action}>
              <Button variant={'solid'} color={'primary'} onClick={closeModal}>{language.close_button}</Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.field}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.send_nft_title}</Typography>
            <Typography variant={'text'} size={'md'} weight={'regular'}>{language.send_nft_instructions}</Typography>
          </div>
          <div className={styles.field}>
            <NFTViewer
              onClick={() => {}}
              isMobile={isMobile}
              mediaSrc={nft?.image}
              mediaTypeId={nft?.imageTypeId}
            />
          </div>

          <div className={styles.field}>
            <SendMultipleNFTsForm
              onSubmit={handleSendNFTForm}
              isLoading={isLoading}
              onCancel={closeModal}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default SendMultipleNFTsModal;
