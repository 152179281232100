// Action Types
import {IQuest, IQuestType, IQuestActionType, IUserQuest, ITwitterAuthData} from '@type/quest';

export enum questActionsTypes {
  FETCH_QUEST_TYPES = 'FETCH_QUEST_TYPES',
  SET_QUEST_TYPES = 'SET_QUEST_TYPES',
  SET_QUEST_ACTION_TYPES = 'SET_QUEST_ACTION_TYPES',
  FETCH_QUESTS = 'FETCH_QUESTS',
  SET_QUESTS = 'SET_QUESTS',
  SET_QUEST = 'SET_QUEST',
  UNSET_QUEST = 'UNSET_QUEST',
  SET_UPDATE_QUEST = 'SET_UPDATE_QUEST',
  ADD_QUEST = 'ADD_QUEST',
  UPDATE_QUEST = 'UPDATE_QUEST',
  DELETE_QUEST = 'DELETE_QUEST',
  FETCH_USER_QUESTS = 'FETCH_USER_QUESTS',
  SET_USER_QUESTS = 'SET_USER_QUESTS',
  TWITTER_AUTH_QUEST = 'TWITTER_AUTH_QUEST',
  SET_TWITTER_AUTH_DATA = 'SET_TWITTER_AUTH_DATA',
  DISCORD_AUTH_QUEST = 'DISCORD_AUTH_QUEST',
  INSTAGRAM_AUTH_QUEST = 'INSTAGRAM_AUTH_QUEST',
  GOOGLE_AUTH_QUEST = 'GOOGLE_AUTH_QUEST',
  TIKTOK_AUTH_QUEST = 'TIKTOK_AUTH_QUEST',
  SET_INSTAGRAM_AUTH_DATA = 'SET_INSTAGRAM_AUTH_DATA',
  CHECK_QUEST_ACTION_IS_COMPLETED = 'CHECK_QUEST_ACTION_IS_COMPLETED'
}

// State Management
export interface IQuestState {
  quests: IQuest[];
  questTypes: IQuestType[];
  questActionTypes: IQuestActionType[];
  userQuests: IUserQuest[];
  twitterAuth: ITwitterAuthData;
}

export interface FetchQuestTypesAction {
  type: typeof questActionsTypes.FETCH_QUEST_TYPES;
  payload: {
  }
}

export interface SetQuestTypesAction {
  type: typeof questActionsTypes.SET_QUEST_TYPES;
  payload: {
    questTypes: IQuestType[];
    questActionTypes: IQuestActionType[];
  }
}

export interface FetchQuestsAction {
  type: typeof questActionsTypes.FETCH_QUESTS;
  payload: {
    collectionId: number;
  }
}

export interface SetQuestsAction {
  type: typeof questActionsTypes.SET_QUESTS;
  payload: {
    quests: IQuest[];
  }
}

export interface SetQuestAction {
  type: typeof questActionsTypes.SET_QUEST;
  payload: {
    quest: IQuest;
  }
}

export interface UnsetQuestAction {
  type: typeof questActionsTypes.UNSET_QUEST;
  payload: {
    id: number;
  }
}

export interface SetUpdateQuestAction {
  type: typeof questActionsTypes.SET_UPDATE_QUEST;
  payload: {
    quest: IQuest;
  }
}

export interface AddQuestAction {
  type: typeof questActionsTypes.ADD_QUEST;
  payload: {
    collectionId: number;
    quest: IQuest;
  }
}

export interface UpdateQuestAction {
  type: typeof questActionsTypes.UPDATE_QUEST;
  payload: {
    quest: IQuest;
  }
}

export interface DeleteQuestAction {
  type: typeof questActionsTypes.DELETE_QUEST;
  payload: {
    questId: number;
  }
}

export interface FetchUserQuestsAction {
  type: typeof questActionsTypes.FETCH_USER_QUESTS;
  payload: {
    collectionId: number;
  }
}

export interface SetUserQuestsAction {
  type: typeof questActionsTypes.SET_USER_QUESTS;
  payload: {
    quests: IUserQuest[];
  }
}

export interface TwitterAuthQuestAction {
  type: typeof questActionsTypes.TWITTER_AUTH_QUEST;
  payload: {
    collectionName: string;
    questId: number;
  }
}

export interface TwitterAuthSetDataAction {
  type: typeof questActionsTypes.SET_TWITTER_AUTH_DATA;
  payload: {
    codeVerifier: string;
    state: string;
    url: string;
  }
}

export interface DiscordAuthQuestAction {
  type: typeof questActionsTypes.DISCORD_AUTH_QUEST;
  payload: {
    collectionName: string;
    questId: number;
  }
}

export interface InstagramAuthQuestAction {
  type: typeof questActionsTypes.INSTAGRAM_AUTH_QUEST;
  payload: {
    collectionName: string;
    questId: number;
  }
}

export interface GoogleAuthQuestAction {
  type: typeof questActionsTypes.GOOGLE_AUTH_QUEST;
  payload: {
    collectionName: string;
    questId: number;
  }
}

export interface TikTokAuthQuestAction {
  type: typeof questActionsTypes.TIKTOK_AUTH_QUEST;
  payload: {
    collectionName: string;
    questId: number;
  }
}

export interface CheckQuestActionIsCompletedAction {
  type: typeof questActionsTypes.CHECK_QUEST_ACTION_IS_COMPLETED;
  payload: {
    quest: IQuest
  }
}

export type QuestActions =
  FetchQuestTypesAction |
  SetQuestTypesAction |
  FetchQuestsAction |
  SetQuestsAction |
  SetQuestAction |
  UnsetQuestAction |
  SetUpdateQuestAction |
  AddQuestAction |
  UpdateQuestAction |
  DeleteQuestAction |
  FetchUserQuestsAction |
  SetUserQuestsAction |
  TwitterAuthQuestAction |
  TwitterAuthSetDataAction |
  CheckQuestActionIsCompletedAction;
