// Dependencies
import React, {useState} from 'react';
import classNames from 'clsx';

// Types
import {ConfirmClaimAirdropNFTProps} from './types';

// Assets
import {RiQuestionnaireLine} from 'react-icons/ri';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import CheckInput from '@components/CheckInput';
import FeaturedIcon from '@components/FeaturedIcon';
import NFTViewer from '@components/NFTViewer';

// Hooks
import useModal from '@hooks/useModal';
import useResponsive from '@hooks/useResponsive';

// StyleSheet
import styles from './ConfirmClaimAirdropNFTModal.module.scss';
import modalStyle from '../../modals.module.scss';

import language_es from 'src/locales/es/modals/confirmClaimAirdropNFT.json';
import language_en from 'src/locales/en/modals/confirmClaimAirdropNFT.json';

function ConfirmClaimAirdropNFTModal({
  nft,
  action
}: ConfirmClaimAirdropNFTProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const [confirm, setConfirm] = useState<boolean>(false);
  const {isMobile} = useResponsive();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'yellow'} icon={(<RiQuestionnaireLine />)} />

      <div className={styles.field}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.claimNFTAirdropTitle}</Typography>
        <Typography variant={'text'} size={'md'} weight={'regular'}>{language.claimNFTAirdropDescription}</Typography>
      </div>

      <div className={styles.field}>
        <NFTViewer
          onClick={() => {}}
          isMobile={isMobile}
          mediaSrc={nft?.image}
          mediaTypeId={nft?.imageTypeId}
        />
      </div>

      <div className={styles.field}>
        <CheckInput
          labelWithLink={<Typography variant={'text'} size={'sm'} weight={'regular'}>{language.agreeTermsAndConditions1} <a className={styles.link} href={'/nft-claim-terms'} rel="noreferrer" target="_blank">{language.agreeTermsAndConditions2}</a></Typography>}
          checked={confirm}
          disabled={false}
          onChange={() => { setConfirm(!confirm); }}
        />
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button onClick={closeModal} type={'button'} variant={'outline'} color={'primary'}>
            {language.cancelButton}
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} disabled={!confirm} onClick={action}>
            {language.confirmButton}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmClaimAirdropNFTModal;
