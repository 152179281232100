// Dependencies
import React from 'react';

// StylesSheet
import styles from './NFTClaimTerms.module.scss';

function NFTClaimTerms(): React.ReactElement {
  return (
    <React.Fragment>
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.field}>
            <div className={styles.row}>
              <p>La información contenida en este Website (en adelante web), es la Sociedad BLOCKRIGHTS SL (en adelante, BLOCKRIGHTS o la Sociedad). El acceso a esta web de BLOCKRIGHTS y a la información contenida en la misma supone la aceptación de las condiciones de uso previstas en el presente aviso legal, siendo el acceso responsabilidad del usuario.</p>
              <p>Los contenidos de la web no están dirigidos a aquellos usuarios que residan en jurisdicciones en las que el contenido de esta web no esté autorizado o permitido. La Sociedad se reserva el derecho a modificar unilateralmente las presentes condiciones de uso de su web en cualquier momento, que entrarán en vigor desde su publicación y serán aplicables a todos los usuarios de la página web desde esa fecha. </p>
              <h3>Datos de la sociedad</h3>
              <p>BLOCKRIGHTS SL, con CIF número B02779924, está inscrita en el Reg. Merc. Madrid. T41052. F115. Sección 8 Ins1. Hoja M-728054. BLOCKRIGHTS tiene su domicilio social en Calle Pez Austral 4, 7D 28007 Madrid.</p>
              <h3>Contenidos de las páginas web</h3>
              <p>Contenido y usos</p>
              <p>Los contenidos de esta página web de BLOCKRIGHTS han sido elaborados con el propósito comercial y para dar información general sobre determinados productos de la Sociedad a los usuarios de la misma. BLOCKRIGHTS se reserva el derecho a introducir modificaciones u omitir parcial o totalmente los actuales contenidos de la web cuando lo considere oportuno y sin previo aviso, así como impedir o restringir el acceso a la misma de forma temporal o permanente.</p>
              <p>Queda prohibida cualquier modalidad de explotación, incluyendo todo tipo de reproducción, distribución, cesión a terceros, comunicación pública y transformación, mediante cualquier tipo de soporte y medio, de los contenidos del Sitio Web, incluyendo signos distintivos, sin la previa y expresa autorización de la Sociedad.</p>
              <p >Errores o discrepancias</p>
              <p>La Sociedad ha adoptado todas las medidas necesarias para mantener la información actualizada y evitar cualquier error u omisión en los contenidos. No obstante, si existiesen errores u omisiones, BLOCKRIGHTS excluye toda responsabilidad por daños y perjuicios o por la información facilitada por terceros.</p>
              <p>En caso de discrepancias entre la documentación original y la versión electrónica publicada en la web prevalecerá la documentación original, y en caso de discrepancia entre la información contenida en la versión inglesa de la web y la versión en español, prevalecerá esta última.</p>
              <p >Hipervínculos y enlaces a/de BLOCKRIGHTS</p>
              <p>Queda prohibido, salvo en los casos en que expresamente lo autorice BLOCKRIGHTS, establecer enlaces, hipervínculos o links, desde sitios web de terceros a las páginas web titularidad de la Sociedad, así como presentar la página web de BLOCKRIGHTS o la información contenida en ella bajo frames o marcos, signos distintivos, marcas o denominaciones sociales o comerciales de otra persona, empresa o entidad.</p>
              <p>Los contenidos, productos, servicios y actividades, ya sean de terceros, a los que se pueda acceder (de forma directa o indirecta) mediante vínculos (links) incluidos en la web de BLOCKRIGHTS tienen una finalidad meramente informativa, salvo que se indique lo contrario, no haciéndose la Sociedad responsable sobre su contenido, sin que su publicación suponga ninguna recomendación, sugerencia o invitación respecto de los mismos, ni tampoco supone la existencia de una relación entre BLOCKRIGHTS y ese tercero, titular de la web , al que remite, reservándose la Sociedad la facultad de eliminar en cualquier momento y de forma unilateral el link incluido. El acceso a una web no propia se hace por voluntad y bajo la responsabilidad del usuario, por lo que BLOCKRIGHTS no responderá por incidencias, perjuicios, daños, o pérdidas para el usuario por dicho acceso.</p>
              <p>Información y comentarios emitidos por usuarios</p>
              <p>Queda prohibido transmitir o enviar a través de la web de BLOCKRIGHTS cualquier contenido ilícito, virus informáticos, o mensajes que, en general, afecten o violen derechos de la Sociedad o de terceros, así como realizar manifestaciones que impliquen un quebranto de la normativa o de contenido difamatorio, amenazante, pornográfico, obsceno o de cualquier otra índole que implique o no un ilícito penal, siendo responsabilidad del usuario que lo haya emitido.</p>
              <p>Las transmisiones de información por los usuarios que se realicen por medio de la web, o aquella que se transmita desde o a esta web es responsabilidad de quien lo realice. Para el caso de que en la web de BLOCKRIGHTS se alojarán webs de terceros, serán estos terceros los responsables de sus contenidos y en su caso el usuario deberá remitir directamente a esos terceros cualquier queja o reclamación sobre sus contenidos.</p>
              <p>Cualquier comentario, información, manifestación vinculada a la web y su contenido, emitido por cualquier medio sobre la Sociedad no es responsabilidad de BLOCKRIGHTS. La sociedad podrá limitar o prohibir el acceso a la web a cualquier usuario, así como eliminar la información o los comentarios emitidos y trasladados por los usuarios cuando sea contrario a las normas o a la moral o por las circunstancias que considere BLOCKRIGHTS. </p>
              <h3>Navegación</h3>
              <p>Para el uso correcto del Portal de BLOCKRIGHTS el proveedor debe de tener instalado en su ordenador la última versión del navegador Chrome. Si la versión de Chrome que el proveedor tiene instalada es inferior a la misma, deberá actualizar la aplicación a una versión más reciente. Si la versión de Chrome es inferior, el proveedor podría tener que activar la vista de compatibilidad para una correcta navegación. La resolución de pantalla óptima para el uso del Portal es 1280x1024. </p>
              <p>Utilizando otros navegadores de internet la navegación por el portal puede ser correcta pero podrían originarse incompatibilidades o fallos en la visualización. Proveedor no es responsable en ningún caso de cualquier perjuicio que pudiera sufrir el proveedor, siendo siempre recomendado el uso de navegadores compatibles</p>
              <h3>Propiedad Intelectual e Industrial </h3>
              <p>La presente web, la información o elementos contenidos en la misma, (textos, documentos, fotografías, dibujos, representaciones gráficas, bases de datos, programas informáticos, así como logotipos, marcas, nombres comerciales, u otros signos distintivos) protegidos por derechos de propiedad intelectual o industrial, son de la titularidad de BLOCKRIGHTS. BLOCKRIGHTS no otorga garantía alguna sobre la licitud y legalidad de la información o elementos contenidos en las páginas web de la Sociedad cuando la titularidad de los mismos no corresponda a BLOCKRIGHTS.</p>
              <p>No podrán ser objeto de explotación, reproducción, distribución, modificación, comunicación pública, cesión o transformación, los contenidos que alberga esta web de BLOCKRIGHTS, sin la previa y expresa autorización de BLOCKRIGHTS. El acceso a esta web no otorga a los usuarios derecho ni titularidad alguna sobre los derechos de propiedad intelectual de los contenidos que alberga.</p>
              <p>El contenido de esta web que pueda ser descargado al terminal del usuario (download), siempre será para su uso privado y sin ningún fin comercial, no pudiendo explotar, reproducir, distribuir, modificar, comunicar públicamente, ceder, transformar o usar dicho contenido con fines públicos o comerciales, salvo que expresamente se haya indicado lo contrario. </p>
              <p>La Sociedad no transfiere a los usuarios la propiedad de su software. El usuario es el propietario del soporte en el cual el software es grabado. Si el usuario transfiere software de esta web a su terminal, no podrá diseccionarlo para su estudio, o descompilarlo, traducir la versión del código objeto original o su lenguaje a otro código o lenguaje. </p>
              <p>La Sociedad se reserva la posibilidad de ejercer las acciones judiciales que correspondan contra los usuarios que violen o infrinjan los derechos de propiedad intelectual e industrial de la Sociedad.</p>
              <h3>Legislación aplicable y jurisdicción competente</h3>
              <p>Las presentes condiciones generales se regirán por la legislación española.</p>
              <p>Cualquier controversia que pudiera derivarse del acceso o la utilización de esta web, titularidad de BLOCKRIGHTS, se someterá a los juzgados y tribunales competentes en el Reino de España. </p>
            </div>
          </div>
        </div>
	  </div>
    </React.Fragment>
  );
}

export default NFTClaimTerms;
