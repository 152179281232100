// API
import {request, requestBlob} from '../api';

// Types
import {
  GetPassByQrCodePayload,
  CreateNewPassPayload
} from './types';

export const getPassByQrCode = async(qrCode: GetPassByQrCodePayload['payload']['qrCode']): Promise<GetPassByQrCodePayload['response']> =>
  await request(`/pass/${qrCode}`, {
    method: 'GET'
  });

export const createNewApplePass = async(
  email: CreateNewPassPayload['payload']['email'],
  passType: CreateNewPassPayload['payload']['passType'],
  pass: CreateNewPassPayload['payload']['pass']): Promise<CreateNewPassPayload['responseApple']> =>
  await requestBlob('POST', `/pass/${pass.nftId}`, {
    email,
    passType
  });

export const createNewGooglePass = async(
  email: CreateNewPassPayload['payload']['email'],
  passType: CreateNewPassPayload['payload']['passType'],
  pass: CreateNewPassPayload['payload']['pass']): Promise<CreateNewPassPayload['responseGoogle']> =>
  await request(`/pass/${pass.nftId}`, {
    method: 'POST',
    body: {
      email,
      passType: passType.toString()
    }
  });

export const generateNFTApplePass = async(nftItemId: number): Promise<Blob> =>
  await requestBlob('POST', `/pass/generate-apple-pass/${nftItemId}`, {});
