// Types
import {
  SetRewardsAction,
  FetchRewardsAction,
  DownloadRewardsCSVAction,
  SetRewardsCSVAction,
  rewardActionsTypes
} from './types';

import {IReward} from '@type/reward';

/**
   * ======================
   *  Fetch Rewards
   * ====================== */
export function fetchRewards(type: FetchRewardsAction['payload']['type'], rewardId: number): FetchRewardsAction {
  return {
    type: rewardActionsTypes.FETCH_REWARDS,
    payload: {
      type,
      rewardId
    }
  };
}

/**
   * ======================
   *    Set Rewards.
   * ====================== */
export function setRewards(reward: IReward): SetRewardsAction {
  return {
    type: rewardActionsTypes.SET_REWARDS,
    payload: {
      reward
    }
  };
}

export function downloadCSV(rewardId: number): DownloadRewardsCSVAction {
  return {
    type: rewardActionsTypes.DOWNLOAD_REWARDS_CSV,
    payload: {
      rewardId
    }
  };
}

export function setCSV(csv: string): SetRewardsCSVAction {
  return {
    type: rewardActionsTypes.SET_REWARDS_CSV,
    payload: {
      csv
    }
  };
}
