// Dependencies
import React from 'react';

// Hooks
import useToast from '@hooks/useToast';

// Styles
import styles from './SuperToast.module.scss';

// Components
import Toast from '../Toast';

function SuperToast(): React.ReactElement {
  const {toasts, deleteToast} = useToast();

  return (
    <div className={styles.layout}>
      <div className={styles.list}>
        {toasts.map((toast, index) => (
          <Toast
            key={`--toast-id-${index.toString()}`}
            index={index}
            type={toast.type}
            message={toast.message}
            deleteToast={deleteToast}
          />
        ))}
      </div>
    </div>
  );
}

export default SuperToast;
