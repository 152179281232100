// Dependencies
import {FormikErrors, FormikTouched, FormikValues, FormikHelpers} from 'formik';

/**
 * Interface for the props of the useForm custom hook.
 *
 * @template FormValues - The shape of the form data, extending from FormikValues.
 *
 * @property {FormikErrors<FormValues>} errors - An object containing all the errors in the form.
 * @property {FormikTouched<FormValues>} touched - An object containing all the touched fields in the form.
 * @property {FormikHelpers<FormValues>['setTouched']} setTouched - A function to set the touched fields in the form.
 * @property {FormikHelpers<FormValues>['setFieldValue']} setFieldValue - A function to set the values of the form fields.
 */
interface UseFormProps<FormValues extends FormikValues> {
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  setTouched: FormikHelpers<FormValues>['setTouched'];
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'];
}

/**
 * Interface for the return type of the useForm custom hook.
 *
 * @template FormValues - The shape of the form data, extending from FormikValues.
 *
 * @property {function(field: keyof FormValues, value: any): void} handleChangeField - A function to handle changes to a form field.
 * @property {function(field: keyof FormValues): string | undefined} getErrorFromField - A function to get the error for a form field.
 */
interface UseFormReturn<FormValues extends FormikValues> {
  handleChangeField: (field: keyof FormValues, value: any) => void;
  getErrorFromField: (field: keyof FormValues) => string | undefined;
}

/**
 * Custom hook for handling form fields using Formik.
 *
 * @template FormValues - The shape of the form data, extending from FormikValues.
 *
 * @param {object} params - The parameters for the hook.
 * @param {Record<string, string>} params.errors - The errors object from Formik context.
 * @param {Record<string, boolean>} params.touched - The touched object from Formik context.
 * @param {(touched: Record<string, boolean>) => void} params.setTouched - The setTouched function from Formik context.
 * @param {(field: string, value: any) => void} params.setFieldValue - The setFieldValue function from Formik context.
 *
 * @returns {object} An object with two functions: handleChangeField and getErrorFromField.
 * @returns {function(field: keyof FormValues, value: any): void} handleChangeField - A function to handle changes to a form field.
 * @returns {function(field: keyof FormValues): string | undefined} getErrorFromField - A function to get the error for a form field.
 */
function useForm<FormValues extends FormikValues>({
  errors,
  touched,
  setTouched,
  setFieldValue
}: UseFormProps<FormValues>): UseFormReturn<FormValues> {
  const handleChangeField = (field: keyof FormValues, value: any) => {
    void setTouched({...touched, [field]: true});
    void setFieldValue(field.toString(), value);
  };

  const getErrorFromField = (field: keyof FormValues) => {
    return (errors[field.toString()] && touched[field.toString()]) ? errors[field] as string : undefined;
  };

  return {
    handleChangeField,
    getErrorFromField
  };
}

export default useForm;
