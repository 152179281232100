// Types
import {ResetAppAction, SetRouterAction, settingsActionTypes} from './types';

/**
 * @function resetApp();
 * @description Dispatch RESET_APP action to reset redux-persist info.
 */
export function resetApp(): ResetAppAction {
  return {
    type: settingsActionTypes.RESET_APP
  };
}

export function setRouter(router: SetRouterAction['payload']['router']): SetRouterAction {
  return {
    type: settingsActionTypes.SET_ROUTER,
    payload: {
      router
    }
  };
}
