// Dependencies
import {call, put} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {MobileLoginAction} from '../types';
import {GetMeResponse} from '@services/auth/types';

// Services
import * as authServices from '@services/auth';
import {setAuthUser} from '@store/auth/actions';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {resetApp} from '@store/settings/actions';

// Types
import {LoadersId} from '@type/loaders';

// Error Handler
import errorHandlerLogin from '@store/errorHandlerLogin';
import {ErrorMessage} from '@type/error';

/**
 * @function verifyAccountProcess()
 * @description Verify account token process flow.
 */
function * mobileLoginProcess({payload: {token}}: MobileLoginAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.MOBILE_LOGIN, true));

    yield call(authServices.mobileLogin, {token});
    const response: GetMeResponse = yield call(authServices.getMe);
    if (response) {
      yield put(resetApp());
      yield put(setAuthUser(response));
    }
    yield call(() => {
      window.location.href = '/';
    });

    yield put(setIsLoading(LoadersId.MOBILE_LOGIN, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.MOBILE_LOGIN, false));
    yield call(() => errorHandlerLogin(e as ErrorMessage));
  }
}

export default mobileLoginProcess;
