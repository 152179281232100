// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setGiftList} from '@store/gift/actions';

// Services
import * as giftServices from '@services/gift';

// Types
import {GetGiftListResponse} from '@services/gift/types';
import {normalizeState} from '@utils/state';
import {IGift} from '@type/gift';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {FetchGiftListAction} from '../types';

/**
 * Handles the process of fetching a list of gifts.
 * @generator
 * @function
 * @param {FetchGiftListAction} action - The action object with the type and payload.
 * @param {boolean} action.payload.onlyPublished - Whether to only fetch published gifts.
 * @returns {SagaIterator<void>} A saga iterator that handles the fetching process of the gift list.
 */
function * fetchGiftListProcess({payload: {onlyPublished}}: FetchGiftListAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, true));

    const response: GetGiftListResponse = onlyPublished ? yield call(giftServices.getPublishedGiftList) : yield call(giftServices.getAllGiftList);

    const normalizedItems: {[key: string]: IGift} = normalizeState(
      response.records?.map((gift) => ({
        ...gift
      }))
      , 'id');

    yield put(setGiftList(normalizedItems));

    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchGiftListProcess;
