// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setIsNFTLastPage, setNFTList, setNFTPage, setNFTPendingToClaim} from '@store/nft/actions';

// Services
import * as nftServices from '@services/nft';

// Types
import {GetNFTListResponse} from '@services/nft/types';
import {normalizeState} from '@utils/state';
import {INFT, NFTState} from '@type/nft';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {selectNFTList, selectNFTListPagination} from '@store/nft/selectors';
import {FetchNFTListAction, NFTListState} from '@store/nft/types';

/**
 * @function fetchNFTListProcess
 * @generator
 * @description A generator function that handles the process of fetching the NFT list.
 *
 * @param {FetchNFTListAction} action - The action object containing the payload data.
 * @param {string} action.payload.searchName - The nft name for filter
 * @param {number} action.payload.page - The page number to fetch.
 *
 * @yields {Object} - A series of actions and effects.
 * @throws {Error} - Throws an error if the fetching process fails.
 * @returns {void} - Returns nothing.
 */
function * fetchNFTListProcess({payload: {searchName, page}}: FetchNFTListAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, true));
    if (searchName !== '') yield put(setIsLoading(LoadersId.IS_SEARCHING_NFTS, true));

    const nftList = yield select(selectNFTList);
    const pagination: NFTListState['pagination'] = yield select(selectNFTListPagination);

    if (page === 0) {
      yield put(setIsNFTLastPage(false));
    }

    yield put(setNFTPage(page));

    const response: GetNFTListResponse = yield call(nftServices.getNFTList, {
      searchName,
      page,
      size: 20
    });

    if (response.records?.length !== pagination.itemsPeerPage) {
      yield put(setIsNFTLastPage(true));
    }

    const data = response.records?.map((nft) => ({
      ...nft,
      properties: nft.properties ? nft.properties : [],
      state: (nft.tx && nft.status) ? NFTState.CLAIMED : (nft.tx && !nft.status) ? NFTState.IS_WAITING_CONFIRMATION : NFTState.READY_TO_CLAIM
    }));

    const normalizedItems: Record<string, INFT> = normalizeState(data, 'userNftItemId');
    const normalizedNFTList: Record<string, INFT> = normalizeState(nftList, 'userNftItemId');

    const amount: number = yield call(nftServices.getPendingNFTs);
    yield put(setNFTPendingToClaim(amount));

    if (searchName !== '' && response?.currentPage === 0) {
      yield put(setNFTList({
        ...normalizedItems
      }));
      yield put(setIsLoading(LoadersId.IS_SEARCHING_NFTS, false));
    } else {
      yield put(setNFTList({
        ...normalizedNFTList,
        ...normalizedItems
      }));
    }

    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, false));
    yield put(setIsLoading(LoadersId.IS_SEARCHING_NFTS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchNFTListProcess;
