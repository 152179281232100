// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './Badge.module.scss';

// Assets
import CloseIcon from '@assets/icons/x-close.svg';

// Components
import Typography, {TypographyProps} from '@components/Typography';

interface BadgeProps {
  color: 'grey' | 'blue' | 'red' | 'yellow' | 'green';
  variant: 'solid' | 'outline';
  size: 'sm' | 'md' | 'lg';
  dot?: boolean;
  image?: string;
  label: string;
  onPressRemove?: () => void;
  className?: string;
  iconRight?: React.ReactElement;
}

function Badge({
  color = 'grey',
  size = 'md',
  variant = 'solid',
  dot,
  image,
  label,
  className = '',
  onPressRemove,
  iconRight
}: BadgeProps): React.ReactElement {
  const typographySize: {[key: string]: TypographyProps['size']} = {
    sm: 'xs',
    md: 'sm',
    lg: 'sm'
  };

  return (
    <div className={classNames([styles.layout, styles[size], styles[color], styles[variant], className])}>
      {dot && (
        <div className={styles.dot} />
      )}
      {image && (
        <img
          className={styles.image}
          alt={label}
          width={16}
          height={16}
          src={image}
        />
      )}
      <div className={styles.label}>
        <Typography component={'span'} size={typographySize[size]}>{label}</Typography>
      </div>
      {onPressRemove && (
        <div className={styles.icon} onClick={onPressRemove}>
          <CloseIcon />
        </div>
      )}
      {iconRight && (iconRight)}
    </div>
  );
}

export default Badge;
