// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {questActionsTypes} from '../types';

// Sagas
import fetchQuestsByCollectionIdProcess from './fetchQuestsByCollectionIdProcess';
import fetchQuestTypesProcess from './fetchQuestTypesProcess';
import addQuestProcess from './addQuestProcess';
import updateQuestProcess from './updateQuestProcess';
import deleteQuestProcess from './deleteQuestProcess';
import fetchUserQuestsProcess from './fetchUserQuestsProcess';
import twitterAuthQuestProcess from './twitterAuthQuestProcess';
import discordAuthQuestProcess from './discordAuthQuestProcess';
import instagramAuthQuestProcess from './instagramAuthQuestProcess';
import googleAuthQuestProcess from './googleAuthQuestProcess';
import tiktokAuthQuestProcess from './tiktokAuthQuestProcess';
import checkQuestActionIsCompletedProcess from './checkQuestActionIsCompletedProcess';

function * quest(): SagaIterator<void> {
  yield takeLatest(questActionsTypes.FETCH_QUESTS, fetchQuestsByCollectionIdProcess);
  yield takeLatest(questActionsTypes.FETCH_QUEST_TYPES, fetchQuestTypesProcess);
  yield takeLatest(questActionsTypes.ADD_QUEST, addQuestProcess);
  yield takeLatest(questActionsTypes.UPDATE_QUEST, updateQuestProcess);
  yield takeLatest(questActionsTypes.DELETE_QUEST, deleteQuestProcess);
  yield takeLatest(questActionsTypes.FETCH_USER_QUESTS, fetchUserQuestsProcess);
  yield takeLatest(questActionsTypes.TWITTER_AUTH_QUEST, twitterAuthQuestProcess);
  yield takeLatest(questActionsTypes.DISCORD_AUTH_QUEST, discordAuthQuestProcess);
  yield takeLatest(questActionsTypes.INSTAGRAM_AUTH_QUEST, instagramAuthQuestProcess);
  yield takeLatest(questActionsTypes.GOOGLE_AUTH_QUEST, googleAuthQuestProcess);
  yield takeLatest(questActionsTypes.TIKTOK_AUTH_QUEST, tiktokAuthQuestProcess);
  yield takeLatest(questActionsTypes.CHECK_QUEST_ACTION_IS_COMPLETED, checkQuestActionIsCompletedProcess);
}

export default quest;
