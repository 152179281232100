// Dependencies
import {useEffect} from 'react';
import {useWeb3React} from '@web3-react/core';

// Hooks
import useAuth from '@hooks/useAuth';

// Types
import {Web3LayoutProps} from './types';

// Utils
import {getErrorMessage} from '@utils/connectors/errors';

function Web3Layout({children}: Web3LayoutProps): React.ReactElement {
  // Web3
  const {library, account, error} = useWeb3React();
  const {setWeb3Provider} = useAuth();

  /**
   * Fetch Authentication
   * @description Invoke authenticity check based on web3 account and provider changes.
   */
  useEffect(() => {
    setWeb3Provider(library);
  }, [library, account]);

  /**
   * @effect OnErrorHandler
   * @description Executes when the web3 sends an error.
   */
  useEffect(() => {
    if (error) {
      const errorInfo = getErrorMessage(error);

      if (errorInfo) {
        // TODO: Dispatch a modal or whatever that catch a web3 error.
      }
    }
  }, [error]);

  return children;
}

export default Web3Layout;
