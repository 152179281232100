// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, delay} from 'redux-saga/effects';

// Types
import {ClaimAirdropCollectionNFTAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {openModal, closeModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function claimAirdropCollectionNFTProcess()
 * @description Delete a specific collection
 */
function * claimAirdropCollectionNFTProcess({payload: {nftId, nftUrl}}: ClaimAirdropCollectionNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CLAIMING_COLLECTION_NFT, true));
    yield put(openModal(ModalsId.CLAIMING_AIRDROP_COLLECTION_NFT, {
      type: 'loading'
    }));

    yield call(services.claimAirdropCollectionNFT, nftId);

    yield put(closeModal());
    yield put(openModal(ModalsId.CLAIMING_AIRDROP_COLLECTION_NFT, {
      type: 'completed',
      nftUrl,
      action: () => {
        window.location.href = '/';
      }
    }));

    yield put(setIsLoading(LoadersId.IS_CLAIMING_COLLECTION_NFT, false));
  } catch (e) {
    yield delay(1000);
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CLAIMING_COLLECTION_NFT, false));
    yield put(closeModal());
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default claimAirdropCollectionNFTProcess;
