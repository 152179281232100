// Dependencies
import React from 'react';
import classNames from 'clsx';
import {useDropzone} from 'react-dropzone';

// StyleSheet
import styles from './EmailsUpload.module.scss';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';

// Types
import {EmailsUploadProps} from './types';

// Config
import {TEXT_UPLOAD_FORMATS} from '@config/index';

// Assets
import {RiUploadCloud2Line, RiCheckboxCircleFill, RiErrorWarningFill} from 'react-icons/ri';

function EmailsUpload({emails, onDrop, error}: EmailsUploadProps): React.ReactElement {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'text/*': TEXT_UPLOAD_FORMATS
    }
  });

  return (
    <div className={classNames([styles.image, {[styles.active]: isDragActive}])} {...getRootProps()}>
      <input {...getInputProps()} />
      {error ? (
        <div className={styles.container}>
          <FeaturedIcon icon={(<RiErrorWarningFill />)} />
          <Typography
            variant={'text'}
            size={'sm'}
            weight={'regular'}
            className={styles.texterror}
            textAlign={'center'}>
            <b>There was an error validating the file</b><br />{error}<br />Please, try again.
          </Typography>
        </div>
      ) : (emails as string[]).length > 0 ? (
        <div className={styles.container}>
          <FeaturedIcon icon={(<RiCheckboxCircleFill />)} />
          <Typography
            variant={'text'}
            size={'sm'}
            weight={'regular'}
            className={styles.textok}
            textAlign={'center'}>
            <b>Success</b><br />If you want to upload another file, <b>click again</b>
          </Typography>
        </div>
      ) : (
        <div className={styles.container}>
          <FeaturedIcon icon={(<RiUploadCloud2Line />)} />
          <Typography
            variant={'text'}
            size={'sm'}
            weight={'regular'}
            className={styles.text}
            textAlign={'center'}>
            <b>Click to upload</b><br />or drag and drop<br /> a file with emails separated by ;
          </Typography>
        </div>
      )}
    </div>
  );
}

export default EmailsUpload;
