// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './DateInput.module.scss';

// Components
import Typography from '@components/Typography';

// Types
import {DateInputProps} from './types';

const DateInput = ({
  label,
  value,
  placeholder,
  onChange,
  error
}: DateInputProps): React.ReactElement => (
  <div className={classNames([styles.layout, {[styles.withError]: error}])}>
    {label && (
      <label htmlFor={label} className={styles.label}>{label}</label>
    )}
    <div className={styles.container}>
      <input
        type={'datetime-local'}
        placeholder={placeholder}
        className={styles.input}
        value={value?.toString().substring(0, 16)}
        onChange={e => onChange(e.target.value)}
      />
    </div>
    {error && (
      <Typography variant={'text'} size={'sm'} className={styles.error}>{error}</Typography>
    )}
  </div>
);

export default DateInput;
