// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {ConfirmMessageProps} from './types';

// Assets
import {RiQuestionnaireLine} from 'react-icons/ri';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import FeaturedIcon from '@components/FeaturedIcon';

// Hooks
import useModal from '@hooks/useModal';

// StyleSheet
import styles from './ConfirmMessageModal.module.scss';
import modalStyle from '../../modals.module.scss';

function ConfirmMessageModal({
  title,
  description,
  action
}: ConfirmMessageProps): React.ReactElement {
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'yellow'} icon={(<RiQuestionnaireLine />)} />

      <div className={styles.field}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{title}</Typography>
        {(!Array.isArray(description)) ? (
          <Typography variant={'text'} size={'md'} weight={'regular'}>{description}</Typography>
        ) : (description).map((phrase: string, index: number) => (
          <Typography key={`--phrase-${index.toString()}`} variant={'text'} size={'md'} weight={'regular'}>{phrase}</Typography>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button onClick={() => closeModal()} type={'button'} variant={'outline'} color={'primary'}>
            Cancel
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} onClick={action}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmMessageModal;
