// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {ResendVerifyEmailAction} from '../types';
import {LoadersId} from '@type/loaders';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

// ErrorHandler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

// Services
import * as authServices from '@services/auth';

/**
 * @function resendVerifyEmailProcess
 * @description Resend the signup verification email.
 */
function * resendVerifyEmailProcess({payload: {email}}: ResendVerifyEmailAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_RESENDING_EMAIL_VERIFY, true));

    yield call(authServices.resendVerifyEmail, email);

    yield put(setIsLoading(LoadersId.IS_RESENDING_EMAIL_VERIFY, false));
  } catch (e) {
    yield put(setIsLoading(LoadersId.IS_RESENDING_EMAIL_VERIFY, false));
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default resendVerifyEmailProcess;
