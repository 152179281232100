// Dependencies
import React, {useEffect, useCallback, useMemo} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router';
import classNames from 'clsx';

// Styles
import styles from './CollectionDetails.module.scss';

// Types
import {FormValues as LinksFormValues} from '@forms/EditLinksForm/types';
import {INFTCollection} from '@type/nftCollection';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import CollectionNFTCard from '@components/cards/CollectionNFTCard';
import PageTitle from '@components/PageTitle';
import EmptyState from '@components/EmptyState';
import GiftCard from '@components/cards/GiftCard';
import SocialLinks, {generateLinksItem} from '@components/SocialLinks';
import QuillTextInput from '@components/QuillTextInput';
import Loader from '@components/Loader';

// Hooks
import useCollections from '@hooks/useCollections';
import useGifts from '@hooks/useGifts';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal, {ModalsId} from '@hooks/useModal';
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';

// Assets
import {RiGift2Line, RiImage2Line, RiDownloadCloud2Line} from 'react-icons/ri';

import language_es from 'src/locales/es/pages/user/collectionDetails.json';
import language_en from 'src/locales/en/pages/user/collectionDetails.json';

function CollectionDetails(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  const params = useParams();

  const {
    collection,
    collectionNFTsList: nfts,
    userCollectionNFTsList,
    fetchCollectionByName,
    fetchCollectionNFTsList,
    fetchUserCollectionNFTsList,
    editCollection,
    claimAirdropCollectionNFT,
    claimQuestCollectionNFT,
    downloadActivityCSV
  } = useCollections(params.name);

  const {giftListByCollectionId: gifts, fetchGiftListByCollectionId} = useGifts(collection?.id);
  const {user} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const {isMobile} = useResponsive();
  const {openModal, closeModal} = useModal();
  const {isLoading} = useLoader(LoadersId.FETCH_COLLECTION_BY_NAME);
  const {isLoading: isLoadingNFTs} = useLoader(LoadersId.IS_FETCHING_COLLECTION_NFTS_LIST);
  const {isLoading: isLoadingGifts} = useLoader(LoadersId.IS_FETCHING_GIFT_LIST);
  const {isLoading: isLoadingUserCollectionNFTs} = useLoader(LoadersId.FETCH_USER_COLLECTION_NFTS);

  useEffect(() => {
    if (!collection) {
      if (params.name) {
        fetchCollectionByName(params.name);
      }
    } else {
      fetchCollectionNFTsList(collection.id);
      fetchGiftListByCollectionId(collection.id, user?.id !== collection.user.id);
      if (user) fetchUserCollectionNFTsList(collection.id);
    }
  }, [collection]);

  const handleFreeClaimNFT = useCallback((collectionNft: INFTCollection) => {
    claimAirdropCollectionNFT(collectionNft.id, collectionNft.image);
  }, []);

  const handleQuestClaimNFT = useCallback((collectionNft: INFTCollection) => {
    claimQuestCollectionNFT(collectionNft.id, collectionNft.image);
  }, []);

  const handleLogin = useCallback(() => {
    if (location.pathname.includes('/collection/')) {
      const url = encodeURIComponent(location.pathname);
      navigate('/login?redirect=' + url.toString());
    } else {
      navigate('/login');
    }
  }, [location]);

  /**
   * Callback function to handle the click event for adding a new reward (NFT) to a collection.
   *
   * When invoked, this function navigates to the page for creating a new reward (NFT) associated with a specific collection.
   *
   * @function
   * @name handleClickNewReward
   * @returns {void}
   */
  const handleClickNewReward = useCallback(() => {
    openModal(ModalsId.CREATE_OR_EDIT_REWARD, {
      collectionId: collection?.id
    });
  }, []);

  const handleClickDownloadActivityData = useCallback(() => {
    if (collection) {
      downloadActivityCSV(collection.id);
    }
  }, []);

  /**
   * Handle click event to add/update social links for a collection.
   *
   * @returns {void}
   */
  const handleClickAddSocialLink = useCallback(() => {
    openModal(ModalsId.EDIT_COLLECTION_LINKS, {
      initialValues: {
        discord: collection?.discord ?? '',
        instagram: collection?.instagramUrl ?? '',
        openSea: collection?.openSeaUrl ?? '',
        twitter: collection?.twitter ?? '',
        url: collection?.url ?? '',
        calendy: collection?.calendy ?? ''
      },
      onSave: (values: LinksFormValues) => {
        const data = {
          discord: values.discord,
          instagramUrl: values.instagram,
          openSeaUrl: values.openSea,
          twitter: values.twitter,
          url: values.url,
          calendy: values.calendy
        };

        editCollection(collection?.id as number, data);

        closeModal();
      }
    });
  }, [collection]);

  const renderNFTsList = useMemo(() => (
    nfts?.length ? (
      <div className={classNames([styles.grid, styles.nfts])}>
        {nfts?.map((collectionNft, index) => (
          <CollectionNFTCard
            key={`--collection-nft-card-${index.toString()}`}
            user={user}
            isCreator={user?.id === collection?.user?.id}
            isOpen={false}
            isMobile={isMobile}
            isClaimed={userCollectionNFTsList.includes(collectionNft.id)}
            collection={collection}
            onLogin={handleLogin}
            onFreeClaim={handleFreeClaimNFT}
            onQuestClaim={handleQuestClaimNFT}
            {...collectionNft}
          />
        ))}
      </div>
    ) : (
      <div className={styles.center}>
        <EmptyState
          icon={<RiImage2Line />}
          text={language.nftsWillLiveHere}
        />
      </div>
    )
  ), [nfts]);

  const renderRewards = useMemo(() => (
    gifts?.length ? (
      <div className={classNames([styles.grid, styles.rewards])}>
        {gifts?.map((gift, index) => (
          <GiftCard
            key={`--gift-card-${index.toString()}`}
            isMobile={isMobile}
            isCreator={user?.id === collection?.user?.id}
            collectionId={collection?.id as number}
            {...gift}
          />
        ))}
      </div>
    ) : (
      <div className={styles.center}>
        <EmptyState
          icon={<RiGift2Line />}
          text={language.noRewardsAvailable}
        />
      </div>
    )
  ), [gifts]);

  if (!collection || isLoading || isLoadingNFTs || isLoadingGifts || isLoadingUserCollectionNFTs) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.banner}>
        <img src={collection?.banner} alt={collection?.name} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <img src={collection?.avatar} alt={collection?.name} />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h2'} weight={'semiBold'}>{collection?.name}</Typography>
            <div className={styles.social}>
              <SocialLinks
                data={generateLinksItem({
                  etherscan: collection.chain ? `${collection.chain?.explorerUrl}/address/${collection.contract}#code` : '',
                  discord: collection.discord ?? '',
                  instagram: collection.instagramUrl ?? '',
                  openSea: collection.openSeaUrl ?? '',
                  twitter: collection.twitter ?? '',
                  url: collection.url ?? '',
                  calendy: collection.calendy ?? ''
                })}
                showAdd={user?.id === collection.user?.id}
                onClickAdd={handleClickAddSocialLink}
              />
            </div>
          </div>
        </div>
        {collection.user && user?.id === collection.user.id && (
          <div className={styles.buttonsMenu}>
            <Button size={'md'} variant={'solid'} onClick={handleClickNewReward} iconLeft={(<RiGift2Line />)}>{language.newReward}</Button>
            <Button size={'md'} variant={'solid'} onClick={handleClickDownloadActivityData} iconLeft={(<RiDownloadCloud2Line />)}>{language.downloadActivityData}</Button>
          </div>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.field}>
          <div className={styles.row}>
            <QuillTextInput
              theme='snow'
              value={collection?.description}
              readOnly={true}
              modules={{toolbar: false}}
            />
          </div>
        </div>

        <div className={styles.sections}>
          <div className={styles.section}>
            <PageTitle
              text={language.step2GetPass.replace('{{collectionName}}', collection?.name)}
            />
            {renderNFTsList}
          </div>

          <div className={styles.section}>
            <PageTitle
              text={language.step3AccessExclusiveRewards}
            />
            {renderRewards}
          </div>

        </div>
      </div>
    </div>
  );
}

export default CollectionDetails;
