import * as Yup from 'yup';

const ResetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .min(6)
    .min(6, 'The password must have a minimum of 6 characters'),
  confirmPassword: Yup.string()
    .min(6, 'The password must have a minimum of 6 characters')
    .oneOf([Yup.ref('password'), null], 'The passwords are not equal'),
  score: Yup
    .number()
    .min(2, 'The password is too weak')
});

export default ResetPasswordValidation;
