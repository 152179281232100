// Dependencies
import React, {useState, useCallback, useEffect} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import TwitchConnectAction from '../TwitchConnectAction';

// StyleSheet
import styles from './TwitchFollowAction.module.scss';

// Types
import {TwitchFollowActionProps} from './types';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

function TwitchFollowAction({
  avatar,
  creator,
  channelId,
  checkEnabled,
  user,
  collectionName,
  questId,
  onAction,
  onCheck,
  onConnection
}: TwitchFollowActionProps): React.ReactElement {
  const {isLoading: isLoadingCompletingQuest} = useLoader(LoadersId.IS_COMPLETING_QUEST);
  const [clickButton, setClickButton] = useState<boolean>(false);

  useEffect(() => {
    setClickButton(checkEnabled);
  }, []);

  const handleClickCheckAction = useCallback(() => {
    setClickButton(true);
    onAction();
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <img className={styles.photo} width={'42px'} height={'42px'} src={avatar} />
        <div className={styles.title}>
          <Typography variant={'text'} size={'md'} weight={'semiBold'}>{creator}</Typography>
          <Typography variant={'text'} size={'xs'}>{channelId}</Typography>
        </div>
        <div className={styles.action}>
          <Button onClick={handleClickCheckAction}>Follow</Button>
        </div>
      </div>
      <div className={styles.body}>
        <TwitchConnectAction isLoading={isLoadingCompletingQuest} disabled={!clickButton} questId={questId} collectionName={collectionName} />
      </div>
    </div>
  );
}

export default TwitchFollowAction;
