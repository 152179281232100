// @ts-nocheck
// Dependencies
import * as Yup from 'yup';

const SendMultipleNFTsSchema = Yup.object().shape({
  emails: Yup.array()
    .of(Yup.string().email((params) => `${params.value} is invalid.`))
    .min(1, 'At least one email is required.')
    .required('Emails are mandatory.')
});

export default SendMultipleNFTsSchema;
