import {ReportHandler} from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): any => {
  if ((onPerfEntry != null)) {
    (import('web-vitals') as any).then(({getCLS, getFID, getFCP, getLCP, getTTFB}: any) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
