// Action Types
import {INFT, NFTState} from '@type/nft';

export enum nftActionsTypes {
  FETCH_NFT_LIST = 'FETCH_NFT_LIST',
  SET_NFT_LIST = 'SET_NFT_LIST',
  CLAIM_NFT = 'CLAIM_NFT',
  IMPORT_NFT = 'IMPORT_NFT',
  SET_NFT_CLAIM_PENDING = 'SET_NFT_CLAIM_PENDING',
  DECREASE_NFTS_PENDING_CLAIM = 'DECREASE_NFTS_PENDING_CLAIM',
  SET_NFT_STATE = 'SET_NFT_STATE',
  SET_NFT_DATA = 'SET_NFT_DATA',
  SEND_NFT = 'SEND_NFT',
  SEND_MULTIPLE_NFTS = 'SEND_MULTIPLE_NFTS',
  ADD_NFT = 'ADD_NFT',
  REMOVE_NFT = 'REMOVE_NFT',
  SET_NFT_PAGE = 'SET_NFT_PAGE',
  SET_IS_NFT_LAST_PAGE = 'SET_IS_NFT_LAST_PAGE',
  FETCH_NFT_BY_ID = 'FETCH_NFT_BY_ID'
}

// State Management
export interface NFTListState {
  list?: {[key: string]: INFT};
  pendingToClaim: number;

  pagination: {
    page: number;
    isLastPage: boolean;
    itemsPeerPage: number;
  }
}

/**
 * Represents the action for fetching the NFT list.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.FETCH_NFT_LIST.
 * @property {Object} payload - The payload of the action.
 * @property {number} payload.page - The page number for the NFT list to fetch, should be of type NFTListState['pagination']['page'].
 */
export interface FetchNFTListAction {
  type: typeof nftActionsTypes.FETCH_NFT_LIST;
  payload: {
    searchName: string,
    page: NFTListState['pagination']['page'];
  }
}

/**
 * Represents the action for claiming an NFT.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.CLAIM_NFT.
 * @property {Object} payload - The payload of the action.
 * @property {INFT} payload.nft - The NFT to be claimed, should be of type INFT.
 */
export interface ClaimNFTAction {
  type: typeof nftActionsTypes.CLAIM_NFT;
  payload: {
    nft: INFT;
  }
}

export interface ImportNFTAction {
  type: typeof nftActionsTypes.IMPORT_NFT;
  payload: {
    chainId: number;
    collectionAddress: string;
    nftId: number;
  }
}

/**
 * Represents the action for sending an NFT.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.SEND_NFT.
 * @property {Object} payload - The payload of the action.
 * @property {INFT} payload.nft - The NFT to be sent, should be of type INFT.
 * @property {string} payload.email - The email address to which the NFT should be sent.
 * @property {string} payload.text - The text to be included with the NFT.
 */
export interface SendNFTAction {
  type: typeof nftActionsTypes.SEND_NFT;
  payload: {
    nft: INFT;
    email: string;
    text: string;
  }
}

export interface SendMultipleNFTsAction {
  type: typeof nftActionsTypes.SEND_MULTIPLE_NFTS;
  payload: {
    nft: INFT;
    emails: string[];
    text: string;
  }
}

/**
 * Represents the action for setting the NFT list.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.SET_NFT_LIST.
 * @property {Object} payload - The payload of the action.
 * @property {Object.<string, INFT>} [payload.list] - An optional object representing the list of NFTs, where the keys are strings and the values are of type INFT.
 */
export interface SetNFTListAction {
  type: typeof nftActionsTypes.SET_NFT_LIST;
  payload: {
    list?: {[key: string]: INFT}
  }
}

/**
 * Represents the action for setting the amount of pending NFT claims.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.SET_NFT_CLAIM_PENDING.
 * @property {Object} payload - The payload of the action.
 * @property {number} payload.amount - The amount of pending NFT claims.
 */
export interface SetNFTClaimPendingAction {
  type: typeof nftActionsTypes.SET_NFT_CLAIM_PENDING;
  payload: {
    amount: number;
  }
}

export interface DecreaseNFTsPendingToClaimAction {
  type: typeof nftActionsTypes.DECREASE_NFTS_PENDING_CLAIM;
  payload: {
  }
}

/**
 * Represents the action for setting the current page of the NFT list.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.SET_NFT_PAGE.
 * @property {Object} payload - The payload of the action.
 * @property {number} payload.page - The current page number of the NFT list.
 */
export interface SetNFTPageAction {
  type: typeof nftActionsTypes.SET_NFT_PAGE;
  payload: {
    page: number;
  }
}

/**
 * Represents the action for setting whether the current page is the last page of the NFT list.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.SET_IS_NFT_LAST_PAGE.
 * @property {Object} payload - The payload of the action.
 * @property {boolean} payload.isLastPage - Indicates whether the current page is the last page of the NFT list.
 */
export interface SetIsNFTLastPageAction {
  type: typeof nftActionsTypes.SET_IS_NFT_LAST_PAGE;
  payload: {
    isLastPage: boolean;
  }
}

/**
 * @interface SetNFTStateAction
 * @description Represents an action to set the state of an NFT.
 *
 * @property {typeof nftActionsTypes.SET_NFT_STATE} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.id - The ID of the NFT.
 * @property {NFTState} payload.state - The new state of the NFT.
 */
export interface SetNFTStateAction {
  type: typeof nftActionsTypes.SET_NFT_STATE;
  payload: {
    id: number;
    state: NFTState;
  }
}

export interface AddNFTAction {
  type: typeof nftActionsTypes.ADD_NFT;
  payload: {
    userNFT: INFT;
  }
}

/**
 * @interface RemoveNFTAction
 * @description Represents an action to remove an NFT.
 *
 * @property {typeof nftActionsTypes.REMOVE_NFT} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.id - The ID of the NFT to be removed.
 */
export interface RemoveNFTAction {
  type: typeof nftActionsTypes.REMOVE_NFT;
  payload: {
    id: number;
  }
}

/**
 * @interface SetNFTDataAction
 * @description Represents an action to set the data of an NFT.
 *
 * @property {typeof nftActionsTypes.SET_NFT_DATA} type - The action type.
 * @property {Object} payload - The action payload.
 * @property {number} payload.id - The ID of the NFT.
 * @property {Partial<INFT>} payload.data - The new data of the NFT.
 */
export interface SetNFTDataAction {
  type: typeof nftActionsTypes.SET_NFT_DATA;
  payload: {
    id: number;
    data: Partial<INFT>;
  }
}

/**
 * Represents the action for fetching an NFT by its ID.
 * @interface
 * @property {string} type - The type of the action, should be nftActionsTypes.FETCH_NFT_BY_ID.
 * @property {Object} payload - The payload of the action.
 * @property {INFT['nftId']} payload.id - The ID of the NFT to be fetched.
 */
export interface FetchNFTByIdAction {
  type: typeof nftActionsTypes.FETCH_NFT_BY_ID;
  payload: {
    id: INFT['nftId'];
  }
}

/**
 * @typedef NFTActions
 * @description Represents a union of all possible NFT actions.
 */
export type NFTActions =
    SetNFTListAction |
    ClaimNFTAction |
    ImportNFTAction |
    SendNFTAction |
    SetNFTStateAction |
    AddNFTAction |
    RemoveNFTAction |
    SetNFTClaimPendingAction |
    DecreaseNFTsPendingToClaimAction |
    SetNFTDataAction |
    SetNFTPageAction |
    SetIsNFTLastPageAction |
    FetchNFTByIdAction |
    FetchNFTListAction;
