// Dependencies
import React from 'react';

// StylesSheet
import styles from './PrivacyPolicy.module.scss';

function PrivacyPolicy(): React.ReactElement {
  return (
    <React.Fragment>
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.field}>
            <div className={styles.row}>
              <h3>POLÍTICA DE PRIVACIDAD</h3>
                Siguiendo los principios de licitud, lealtad y transparencia, ponemos a su disposición la
                presente Política de Privacidad.
              <br />
              <br />
              <b>TITULARIDAD</b>
              <br />
                    En cumplimiento de lo establecido en el <a href="https://www.boe.es/doue/2016/119/L00001-00088.pdf">Reglamento (UE) 2016/679 del
                        Parlamento</a> <a href="https://www.boe.es/doue/2016/119/L00001-00088.pdf">Europeo y del
                        Consejo, de 27 de abril de 2016 (en adelante RGPD)</a>, de la <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673">Ley Orgánica</a> <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673">3/2018, de 5 de diciembre, de
                        Protección de Datos Personales y garantía de los</a> <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673">derechos digitales (en adelante
                        LOPDGDD)</a> y cualquier otra normativa de protección de datos aplicable, le informamos que
                    los datos personales comunicados serán incorporados a una Base de Datos titularidad de:
              <ul>
                <li>Denominación social: BLOCKRIGHTS SL. (en adelante <b>BLOCKRIGHTS</b>)</li>
                <li>NIF: B02779924</li>
                <li>Dirección postal: Calle Pez Austral 4 7ºD, 28007 MADRID SPAIN</li>
                <li>Correo electrónico: info@givitnft.com</li>
              </ul>
              <br />
              <br />
              <b>CONTACTO</b>
              <br />
                    Para cualquier asunto relacionado con el tratamiento de datos de personas físicas por parte de
              <b>BLOCKRIGHTS </b>podrá dirigirse a la dirección de correo electrónico info@givitnft.com, o bien podrá también dirigirse a
                    nuestro Delegado de Protección de Datos a través de la siguiente dirección de correo electrónico
              <a href="mailto:legal@grupoadaptalia.es">legal@grupoadaptalia.es</a>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Datos del DPO:</td>
                    <td>GRUPO ADAPTALIA – LEGAL FORMATIVO S.L.</td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td><a href="mailto:legal@grupoadaptalia.es">legal@grupoadaptalia.es</a></td>
                  </tr>
                  <tr>
                    <td>91 553 34 08</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <b>¿Con qué finalidad tratamos sus datos personales?</b>
              <br />
                    En BLOCKRIGHTS tratamos la información que nos facilita con la finalidad de gestionar la
                    relación contractual que nos une, gestionar el envío de la información que nos solicita,
                    facilitar a los interesados ofertas de nuestros servicios y/o productos de su interés y/o
                    gestionar su candidatura.
              <br />
              <br />
              <b>¿Por cuánto tiempo conservaremos sus datos personales?</b>
              <br />
                    Sus datos, serán conservados el tiempo mínimo necesario para la correcta prestación del servicio
                    ofrecido así como para atender las responsabilidades que se pudieran derivar del mismo y de
                    cualquier otra exigencia legal.
              <br />
              <br />
              <b>¿Cuál es la legitimación para el tratamiento de sus datos?</b>
              <br />
                    La base legal para el tratamiento de sus datos personales puede ser la ejecución de una relación
                    contractual potencial y/o suscrita, el interés legítimo, la habilitación legal y/o el
                    consentimiento del propio interesado. Los datos que le solicitamos son adecuados, pertinentes y
                    estrictamente necesarios y en ningún caso está obligado a facilitárnoslos, pero su no
                    comunicación podrá afectar a la finalidad del servicio o la imposibilidad de prestarlo.&nbsp;
              <br />
              <br />
              <b>¿A qué destinatarios se comunicarán sus datos?</b>
              <br />
              BLOCKRIGHTS no comunicará sus datos a ningún tercero, salvo que se informe de ello expresamente.
              <br />
              <br />
              <b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b>
              <br />
                    Los derechos de protección de datos de los que son titulares los interesados son:
              <ul>
                <li>Derecho a solicitar el acceso a los datos
                            personales relativos al interesado</li>
                <li>Derecho de rectificación o supresión</li>
                <li>Derecho de oposición</li>
                <li>Derecho a solicitar la limitación de su
                            tratamiento&nbsp;</li>
                <li>Derecho a la portabilidad de los datos</li>
              </ul>
                    Los titulares de los datos personales obtenidos, podrán ejercer sus derechos de protección de
                    datos personales dirigiendo una comunicación por escrito al domicilio social de BLOCKRIGHTS o al
                    correo electrónico habilitado a tal efecto, info@givitnft.com, incluyendo en ambos casos fotocopia
                    de su DNI u otro documento de identificación equivalente.
              <br />
                    Modelos, formularios y más información disponible sobre sus derechos en la página web de la
                    autoridad de control nacional, Agencia Española de Protección de Datos, en adelante, AEPD, <a href="http://www.agpd.es/">www.agpd.es</a>.
              <br />
              <br />
              <b>¿Puedo retirar el consentimiento?</b>
              <br />
                    Usted tiene la posibilidad y el derecho a retirar el consentimiento para cualquiera finalidad
                    específica otorgada en su momento, sin que ello afecte a la licitud del tratamiento basado en el
                    consentimiento previo a su retirada.
              <br />
              <br />
              <b>¿Dónde puedo reclamar en caso de que considere que no se tratan mis datos correctamente?</b>
              <br />
                    Si algún interesado considera que sus datos no son tratados correctamente por BLOCKRIGHTS puede
                    dirigir sus reclamaciones al correo info@givitnft.com o a la autoridad de protección de datos
                    que corresponda, siendo la AEPD la indicada en el territorio nacional, <a href="http://www.agpd.es/">www.agpd.es</a><br />
              <br />
              <b>Seguridad y actualización de sus datos personales</b>
              <br />
                    Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que
                    BLOCKRIGHTS ha adoptado todas las medidas de índole técnica y organizativa necesarias para
                    garantizar la seguridad de los datos personales suministrados. Todo ello para evitar su
                    alteración, pérdida, y/o tratamientos o accesos no autorizados, tal como exige la normativa, si
                    bien la seguridad absoluta no existe.&nbsp;
              <br />
                    Es importante que, para que podamos mantener sus datos personales actualizados, nos informe
                    siempre que se produzca una modificación de los mismos.&nbsp;
              <br />
              <br />
              <b>Confidencialidad</b>
              <br />
                    BLOCKRIGHTS le informa que sus datos serán tratados con el máximo celo y confidencialidad por
                    todo el personal que intervenga en cualquiera de las fases del tratamiento. No cederemos ni
                    comunicaremos a ningún tercero sus datos, excepto en los casos legalmente previstos, o salvo que
                    el interesado nos hubiera autorizado expresamente<br />
              <a href="https://policies.google.com/technologies/partner-sites" rel="noreferrer" target="_blank">Google’s Privacy &amp; Terms site</a>
            </div>
          </div>
        </div>
	  </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
