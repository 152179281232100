// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {EditProfileModalProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './EditProfileModal.module.scss';

// Components
import Typography from '@components/Typography';
import EditProfileForm, {FormValues} from '@forms/EditProfileForm';

// Hooks
import useModal from '@hooks/useModal';
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

import language_es from 'src/locales/es/modals/editProfile.json';
import language_en from 'src/locales/en/modals/editProfile.json';

function EditProfileModal({initialValues}: EditProfileModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {editUser} = useAuth();
  const {isLoading} = useLoader(LoadersId.IS_LOADING_EDIT_USER);

  /**
   * Handles the submission of the Edit Profile form.
   *
   * @param {FormValues} formValues - The values from the form.
   * @param {File} formValues.avatar - The new avatar image.
   * @param {File} formValues.banner - The new banner image.
   * @param {string} formValues.name - The new name.
   * @param {string} formValues.url - The new profile URL.
   */
  const handleSubmitEditProfile = useCallback(({name}: FormValues) => {
    editUser({
      name
    });
    closeModal();
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <>
        <div className={styles.header}>
          <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.editProfile}</Typography>
          <Typography variant={'text'} size={'md'} weight={'regular'}>{language.setName}</Typography>
        </div>
        <EditProfileForm
          initialValues={initialValues}
          isLoading={isLoading}
          onSubmit={handleSubmitEditProfile}
          onCancel={() => closeModal()}
        />
      </>
    </div>
  );
}

export default EditProfileModal;
