export interface ISettingsState {
  router?: any; // TODO: Refactor this.
}

export enum settingsActionTypes {
  RESET_APP = 'RESET_APP',
  SET_ROUTER = 'SET_ROUTER',
}

export interface ResetAppAction {
  type: settingsActionTypes.RESET_APP,
}

export interface SetRouterAction {
  type: settingsActionTypes.SET_ROUTER,
  payload: {
    router: ISettingsState['router'];
  }
}

export type SettingsActions =
  | SetRouterAction
  | ResetAppAction;
