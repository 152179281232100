// Action Types
import {Web3Provider} from '@ethersproject/providers';
import {IUser} from '@type/user';

export enum authActionsTypes {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  LOGOUT = 'LOGOUT',
  FETCH_AUTH = 'FETCH_AUTH',
  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
  SET_PROVIDER = 'SET_PROVIDER',
  SET_AUTH_USER = 'SET_AUTH_USER',
  VALIDATE_SIGNING_MESSAGE = 'VALIDATE_SIGNING_MESSAGE',
  RESEND_VERIFY_EMAIL = 'RESEND_VERIFY_EMAIL',
  MOBILE_LOGIN = 'MOBILE_LOGIN',
  REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  EDIT_USER = 'EDIT_USER',
}

// State Management
export interface IAuthState {
  data: IUser | null,
  web3?: Web3Provider | null;
}

/**
 * ======================
 *  Fetch Authentication
 * ====================== */
export interface FetchAuthAction {
  type: typeof authActionsTypes.FETCH_AUTH;
  payload: {
    redirectToLogin?: boolean;
  }
}

/**
 * ======================
 *    Verify Account
 * ====================== */
export interface VerifyAccountAction {
  type: typeof authActionsTypes.VERIFY_ACCOUNT;
  payload: {
    token: string;
  }
}

/**
 * ======================
 *        Login
 * ====================== */
export interface ILogin {
  email: string;
  password: string;
}

export interface LoginAction {
  type: typeof authActionsTypes.LOGIN;
  payload: {
    email: string;
    password: string;
    redirectTo?: string;
  };
}

/**
 * ======================
 *        SignUp
 * ====================== */
export interface SignUpAction {
  type: typeof authActionsTypes.SIGNUP;
  payload: {
    email: string;
    password: string;
    confirmPassword: string;
  }
}

/**
 * ======================
 *        LogOut
 * ====================== */
export interface LogOutAction {
  type: typeof authActionsTypes.LOGOUT;
  payload: {
    redirectUrl?: string;
  }
}

/**
 * ======================
 *     Set User Data
 * ====================== */
export interface SetAuthUserAction {
  type: typeof authActionsTypes.SET_AUTH_USER;
  payload: {
    data: IUser | null
  }
}

/**
 * ==================
 *    Set Provider
 * ================== */
export interface SetProviderAction {
  type: typeof authActionsTypes.SET_PROVIDER;
  payload: {
    provider?: Web3Provider;
  };
}

/**
 * ==================
 * Validate Signing Message
 * ================== */
export interface ValidateSigningMessageAction {
  type: typeof authActionsTypes.VALIDATE_SIGNING_MESSAGE;
}

/**
 * ==================
 * Resend Email Verify
 * ================== */
export interface ResendVerifyEmailAction {
  type: typeof authActionsTypes.RESEND_VERIFY_EMAIL;
  payload: {
    email: string;
  };
}

/**
 * ==================
 * Moible Login Verify
 * ================== */
export interface MobileLoginAction {
  type: typeof authActionsTypes.MOBILE_LOGIN;
  payload: {
    token: string;
  };
}

export interface RequestResetPasswordAction {
  type: typeof authActionsTypes.REQUEST_RESET_PASSWORD;
  payload: {
    email: string;
  };
}

export interface ResetPasswordAction {
  type: typeof authActionsTypes.RESET_PASSWORD;
  payload: {
    token: string;
    password: string;
  };
}

export interface EditUserAction {
  type: typeof authActionsTypes.EDIT_USER;
  payload: {
    name: IUser['name'];
  }
}

export type AuthActions =
  LoginAction |
  SignUpAction |
  LogOutAction |
  FetchAuthAction |
  SetAuthUserAction |
  SetProviderAction |
  VerifyAccountAction |
  ResendVerifyEmailAction |
  ValidateSigningMessageAction |
  MobileLoginAction |
  RequestResetPasswordAction |
  EditUserAction |
  ResetPasswordAction;
