// Types
export enum MainMenuActions {
  SET_TAB_CHANGE = 'SET_TAB_CHANGE',
}

// State Management
export interface IMainMenuState {
  tabId: number;
}

export interface SetTabChangeAction {
  type: MainMenuActions.SET_TAB_CHANGE;
  payload: {
    tabId: number;
  }
}

export type MainMenuActionsTypes = SetTabChangeAction;
