import * as Yup from 'yup';

const CreateNFTValidation = Yup.object().shape({
  image: Yup.mixed().required('The multimedia is required to create the NFT'),
  name: Yup.string().required('The title is required'),
  welcomeMessage: Yup.string().required('The welcome message is required'),
  passBackgroundColor: Yup.string().required('The background color is required'),
  passForegroundColor: Yup.string().required('The foreground color is required'),
  passLabelColor: Yup.string().required('The label color is required')
});

export default CreateNFTValidation;
