// API
import request from '../api';

// Types
import {
  GetChainsIdResponse,
  GetChainsResponse
} from './types';

/**
 * @function getChainsId(production)
 */
export const getChainsId = async(production: boolean): Promise<GetChainsIdResponse> =>
  await request('/chains/id/' + production.toString(), {
    method: 'GET'
  });

/**
 * @function getChains(production)
 */
export const getChains = async(production: boolean): Promise<GetChainsResponse> =>
  await request('/chains/' + production.toString(), {
    method: 'GET'
  });
