// Dependencies
import React from 'react';
import classNames from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './UserNavigationMenuModal.module.scss';

// Components
import IconButton from '@components/IconButton';
import NavItem, {NavItemProps} from '@components/navigator/Header/partials/NavItem';
import UserNavigation from '@components/UserNavigation';

// Assets
import Logo from '@assets/images/logo.png';
import {RiCloseLine} from 'react-icons/ri';

// Config
import {getMenuItemsWithBadge} from '@config/index';
import {isActivePath} from '@utils/url';

// Hooks
import useModal from '@hooks/useModal';
import useAuth from '@hooks/useAuth';

import language_es from 'src/locales/es/components/header.json';
import language_en from 'src/locales/en/components/header.json';

function UserNavigationMenuModal(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user} = useAuth();
  const {pathname} = useLocation();
  const modal = useModal();

  const items: NavItemProps[] = getMenuItemsWithBadge(
    user?.counts?.userNFTs ?? 0,
    user?.counts?.userRewards ?? 0,
    user?.counts?.userCollections ?? 0
  );

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.header}>
        <Link to={'/'}>
          <img src={Logo} alt={'GivitPass'} width={154} height={40} />
        </Link>
        <IconButton icon={(<RiCloseLine />)} variant={'ghost'} onClick={modal.closeModal} />
      </div>
      <div className={styles.body}>
        <nav className={styles.navigation}>
          {user && (
            items.map((item, index: number) => (
              <NavItem
                key={`--menu-item-key-${index.toString()}`}
                icon={item.icon}
                text={language[item.text as keyof typeof language]}
                quantity={item.quantity}
                active={isActivePath(pathname, item.path) }
                path={item.path}
                handleClick={modal.closeModal}
              />
            ))
          )}
        </nav>
        <div className={styles.footer}>
          <UserNavigation />
        </div>
      </div>
    </div>
  );
}
export default UserNavigationMenuModal;
