// Dependencies
import React from 'react';
import classNames from 'clsx';
import {TailSpin} from 'react-loader-spinner';

// Types
import {ClaimAirdropCollectionNFTProps} from './types';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';

// StyleSheet
import styles from './ClaimAirdropCollectionNFTModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Assets
import {RiGift2Line} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/claimAirdropCollectionNFT.json';
import language_en from 'src/locales/en/modals/claimAirdropCollectionNFT.json';

function ClaimAirdropCollectionNFTModal({
  type,
  nftUrl,
  action = undefined
}: ClaimAirdropCollectionNFTProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'green'} icon={(<RiGift2Line />)} />
      {type === 'completed' ? (
        <>
          <div className={styles.image}>
            <img src={nftUrl} width={'100%'} height={'100%'} />
          </div>
          <div className={styles.text}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>{language.congratulations}</Typography>
            <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{language.nftEnjoyment}</Typography>
          </div>
          <div className={styles.footer}>
            <div className={styles.actions}>
              <Button variant={'solid'} color={'primary'} onClick={action}>
                {language.goToYourNfts}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.field}>
            <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.claimingNft}</Typography>
            <Typography variant={'text'} size={'sm'} weight={'regular'}>{language.pleaseWait}</Typography>
          </div>
          <TailSpin
            width={48}
            height={48}
          />
        </>
      )}
    </div>
  );
}

export default ClaimAirdropCollectionNFTModal;
