// Dependencies
import React, {useMemo} from 'react';

// Types
import {AlertProps} from './types';

// Assets
import {RiAlertLine, RiErrorWarningLine} from 'react-icons/ri';
import {FaUsers} from 'react-icons/fa';

// StyleSheet
import styles from './Alert.module.scss';
import classNames from 'clsx';
import Typography from '@components/Typography';

function Alert({variant = 'warning', title, description}: AlertProps): React.ReactElement {
  const renderIcon = useMemo(() => {
    switch (variant) {
      case 'error':
        return (
          <RiErrorWarningLine />
        );

      case 'message':
        return (
          <FaUsers />
        );

      case 'warning':
      default:
        return (
          <RiAlertLine/>
        );
    }
  }, [variant]);

  return (
    <div className={classNames([styles.layout, styles[variant]])}>
      <div className={styles.icon}>
        {renderIcon}
      </div>
      <div className={styles.container}>
        <Typography variant={'text'} component={'h3'} size={'sm'} weight={'semiBold'}>{title}</Typography>
        <Typography variant={'text'} component={'p'} size={'sm'} weight={'regular'}>{description}</Typography>
      </div>
    </div>
  );
}

export default Alert;
