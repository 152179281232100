// Types
import {
  passActionsTypes,
  SetPassStateAction,
  FetchPassByQrCodeAction,
  CreateNewPassAction,
  GeneratePassAction,
  SetPassCreatedAction
} from './types';

import {IPass} from '@type/pass';

export function setPassState(pass: IPass): SetPassStateAction {
  return {
    type: passActionsTypes.SET_PASS_STATE,
    payload: {
      pass
    }
  };
}

export function fetchPassByQrCode(qrCode: string): FetchPassByQrCodeAction {
  return {
    type: passActionsTypes.FETCH_PASS_BY_QR,
    payload: {
      qrCode
    }
  };
}

export function createNewPass(email: string, passType: number, pass: IPass): CreateNewPassAction {
  return {
    type: passActionsTypes.CREATE_NEW_PASS,
    payload: {
      email,
      passType,
      pass
    }
  };
}

export function generatePass(nftItemId: number, pass: IPass): GeneratePassAction {
  return {
    type: passActionsTypes.GENERATE_PASS,
    payload: {
      nftItemId,
      pass
    }
  };
}

export function setPassCreated(passCreated: boolean): SetPassCreatedAction {
  return {
    type: passActionsTypes.SET_PASS_CREATED,
    payload: {
      passCreated
    }
  };
}
