import * as Yup from 'yup';

const NewCollectionValidation = Yup.object().shape({
  banner: Yup.mixed().required('This field is required'),
  name: Yup.string().required('The name is required'),
  description: Yup.string().required('The description is required'),
  givitUrl: Yup.string()
    .required('The public url is required')
    .test('no-spaces', 'Spaces are not allowed', (value) => !/\s/.test(value ?? ''))
    .test('no-special-chars', 'Special characters are not allowed', (value) => !/[.,;]/.test(value ?? ''))
    .test('no-dots-commas', 'Dots, commas, and semicolons are not allowed', (value) => !/[.,;]/.test(value ?? ''))
    .test(
      'only-valid-characters',
      'Invalid characters. Only A-Z, a-z, 0-9, and - are allowed',
      (value) => /^[A-Za-z0-9-]+$/.test(value ?? '')
    )
});

export default NewCollectionValidation;
