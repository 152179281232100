// Dependencies
import React from 'react';
import {FormikProps, useFormik} from 'formik';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// Types
import {EditProfileFormProps, FormValues} from './types';

// StyleSheet
import styles from './EditProfileForm.module.scss';

// Validation
import EditProfileFormValidation from './validations';

import language_es from 'src/locales/es/forms/editProfileForm.json';
import language_en from 'src/locales/en/forms/editProfileForm.json';

function EditProfileForm({
  initialValues,
  isLoading = false,
  onSubmit,
  onCancel
}: EditProfileFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      name: initialValues?.name ?? ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: EditProfileFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div className={styles.list}>
          <TextInput
            name={'name'}
            label={language.nameLabel}
            error={getErrorFromField('name')}
            value={formik.values.name}
            onChangeText={(text) => handleChangeField('name', text)}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} disabled={isLoading} color={'primary'} onClick={onCancel}>
              {language.cancelButton}
            </Button>
            <Button type={'submit'} isLoading={isLoading} variant={'solid'} color={'primary'} disabled={!formik.isValid}>
              {language.saveChangesButton}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default EditProfileForm;
