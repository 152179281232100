// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {DeleteCollectionAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function deleteCollectionProcess()
 * @description Delete a specific collection
 */
function * deleteCollectionProcess({payload: {collectionId}}: DeleteCollectionAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DELETING_COLLECTION, true));

    yield call(services.deleteCollection, collectionId);

    yield call(() => {
      window.location.href = '/creator';
    });
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DELETING_COLLECTION, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default deleteCollectionProcess;
