// Dependencies
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSpring, animated} from 'react-spring';
import classNames from 'clsx';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import QuillTextInput from '@components/QuillTextInput';
import IconButton from '@components/IconButton';

// Hooks
import useRewards from '@hooks/useRewards';
import useGifts from '@hooks/useGifts';
import useModal, {ModalsId} from '@hooks/useModal';

// Utils
// import {formatDate} from '@utils/date';

// Styles
import styles from './GiftCard.module.scss';

// Assets
import {FaCaretRight, FaPercentage, FaGlobe, FaFile} from 'react-icons/fa';

// Types
import {GiftCardProps} from './types';
import {getRewardMessage} from '@type/rewardType';

import language_es from 'src/locales/es/pages/user/giftDetails.json';
import language_en from 'src/locales/en/pages/user/giftDetails.json';
import useLoader, {LoadersId} from '@hooks/useLoader';

function GiftCard({isCreator, isMobile, collectionId, ...gift}: GiftCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {fetchRewards} = useRewards(gift.id);
  const {deleteGift, publishGift} = useGifts(gift.id);
  const {openModal, closeModal} = useModal();
  const dropDownContainer = useRef<HTMLDivElement>();
  const [isGiftOpen, setGiftOpen] = useState<boolean>(false);
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_REWARDS);

  const [dropDownStyle, dropDownApi] = useSpring(() => ({
    height: 0,
    opacity: 0
  }));

  const rewardMessage = getRewardMessage(gift.rewardTypeId);

  useEffect(() => {
    if (dropDownContainer) {
      dropDownApi.start({
        opacity: isGiftOpen ? 1 : 0,
        height: isGiftOpen ? dropDownContainer.current?.clientHeight : 0
      });
    }
  }, [isGiftOpen, dropDownApi]);

  const renderIcon = useMemo(() => {
    switch (gift.rewardTypeId) {
      case 1:
        return <FaPercentage />;
      case 2:
        return <FaPercentage />;
      case 3:
        return <FaGlobe />;
      case 4:
        return <FaGlobe />;
      case 5:
        return <FaFile />;

      default:
        return null;
    }
  }, [gift]);

  /**
   * Callback function to handle the click event when interacting with a gift.
   *
   * @function
   * @name handleClickGiftEdit
   * @param {IGift} gift - The gift object representing the clicked gift.
   * @returns {void}
   */
  const handleClickGiftEdit = useCallback(() => {
    if (gift?.id) {
      openModal(ModalsId.CREATE_OR_EDIT_REWARD, {
        collectionId,
        reward: {
          id: gift.id,
          description: gift.description,
          rewardTypeId: gift.rewardTypeId,
          rewardContent: gift.rewardContent,
          availableFrom: gift.availableFrom,
          availableUntil: gift.availableUntil,
          notificationTitle: gift.notificationTitle,
          notificationDescription: gift.notificationDescription
        }
      });
    }
  }, [gift]);

  const handleClickGiftDelete = useCallback(() => {
    openModal(ModalsId.CONFIRM_MESSAGE, {
      title: 'Are you sure you want to delete this gift?',
      description: ['Once deleted, you couldn`t recover it'],
      action: () => {
        closeModal();
        deleteGift(collectionId, gift.id);
      }
    });
  }, [gift]);

  const handleClickGiftPublish = useCallback(() => {
    openModal(ModalsId.CONFIRM_MESSAGE, {
      title: 'Publish Gift',
      description: ['Are you sure you want to publish this gift?', 'Remember that once the publication has been made, you will not be able to modify it.'],
      action: () => {
        closeModal();
        publishGift(gift.id);
      }
    });
  }, [gift]);

  const handleSendPushNotification = useCallback(() => {
    console.log('handleSendPushNotification clicked');
  }, [gift]);

  const handleShowReward = useCallback(() => {
    fetchRewards('user', gift.id);
  }, [gift]);

  return (
    <div className={classNames([styles.card, {[styles.open]: isGiftOpen}])}>
      <div className={styles.header}>
        <IconButton
          className={styles.icon}
          onClick={() => setGiftOpen(!isGiftOpen)}
          size={'sm'}
          variant={'ghost'}
          icon={<FaCaretRight />}
        />
        <div className={styles.headTitle}>
          <div className={styles.title}>
            {renderIcon}
            <Typography variant={'text'} size={isMobile ? 'sm' : 'md'}>{`${gift.collection?.name} - ${rewardMessage.label}`}</Typography>
          </div>
          <div className={styles.actions}>
            {isCreator && (<div className={styles.inline}>
              <Button size={'md'} onClick={handleClickGiftEdit}>{language.edit}</Button>
              <Button size={'md'} onClick={handleClickGiftDelete} variant={'solid'} color={'error'}>{language.delete}</Button>
              {!gift.published && <Button size={'md'} onClick={handleClickGiftPublish} variant={'solid'} color={'tertiary'}>{language.publish}</Button>}
            </div>)}
            {(gift.published && isCreator) && (<div className={styles.inline}>
              <Button size={'md'} onClick={handleSendPushNotification}>{language.sendPush}</Button>
            </div>)}
            {gift.published && (<div className={styles.inline}>
              <Button size={'md'} isLoading={isLoading} onClick={handleShowReward}>{language.viewReward}</Button>
            </div>)}
          </div>
        </div>
      </div>
      <animated.div className={styles.body} style={dropDownStyle}>
        <div ref={ref => { if (ref) dropDownContainer.current = ref; }}>
          <Typography variant={'text'} size={isMobile ? 'sm' : 'md'}>{`Available from: ${new Date(gift.availableFrom).toLocaleTimeString([], {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}`}</Typography>
          {gift.availableUntil && (<Typography variant={'text'} size={isMobile ? 'sm' : 'md'}>{` - Until: ${new Date(gift.availableUntil).toLocaleTimeString([], {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}`}</Typography>)}
          <QuillTextInput
            className={styles.description}
            theme='snow'
            value={gift.description}
            readOnly={true}
            modules={{toolbar: false}}
          />
        </div>
      </animated.div>
    </div>
  );
}
export default GiftCard;
