// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './Typography.module.scss';

// Types
import {TypographyProps} from './types';

const Typography = ({
  component = 'span',
  variant = 'text',
  size = 'md',
  weight = 'regular',
  className = '',
  children,
  textAlign = 'left'
}: TypographyProps): React.ReactElement => {
  const Tag = component;

  return (
    <Tag className={classNames([styles.layout, styles[variant], styles[size], styles[weight], styles[textAlign], className, 'notranslate'])}>
      {children}
    </Tag>
  );
};

export type {
  TypographyProps
};
export default Typography;
