import {getChainsId} from '@services/chain';

/**
 * ===================
 *       TYPES
 * ===================
 */

export interface ChainsId {
  chainIds: number[];
}

export enum ConnectorNames {
  Injected = 'Injected',
}

/**
 * ===================
 *      CONSTANTS
 * ===================
 */

/**
 * Why are surplus networks added here?
 *
 * Because the web3 library ignores those networks that you do not specify,
 * affecting their connectivity. For example, if you don't define Ethereum Mainnet,
 * if the users connects his wallet and is on that network,
 * the library will not detect the connection and will create a glitch.
 *
 * The solution, register it as a compatible network.
 * But then limit the network when executing a transaction.
 *
 */
export const supportedChainIds = async(production: boolean): Promise<ChainsId> => {
  return await getChainsId(production);
};
