// Dependencies
import React, {useEffect, useState} from 'react';

// Components
import CookieConsent, {OPTIONS, getCookieConsentValue, resetCookieConsentValue} from 'react-cookie-consent';
import Typography from '@components/Typography';

// StyleSheet
// import styles from './ConsentCookies.module.scss';

// Import languages
import language_es from '../../../locales/es/components/consentCookies.json';
import language_en from '../../../locales/en/components/consentCookies.json';

function ConsentCookies(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const [cookieValue, setCookieValue] = useState('');
  const [visibleBanner, setVisibleBanner] = useState('show');

  useEffect(() => {
    const value = getCookieConsentValue();
    setCookieValue(value as string);

    if (value === 'true') {
      setVisibleBanner('hidden');
    }

    if (value === 'false') {
      resetCookieConsentValue();
      setVisibleBanner('show');
    }
  }, [cookieValue]);

  return (
    <div>
      <CookieConsent
        visible={visibleBanner}
        location={OPTIONS.BOTTOM}
        enableDeclineButton={true}
        declineButtonText={'Decline'}
        buttonText={'Accept'}

        onDecline={() => { window.location.href = 'https://google.com'; }}
        onAccept={() => { setVisibleBanner('hidden'); }}
      >
        <Typography variant={'text'} size={'xs'}>{language.message}</Typography>
      </CookieConsent>
    </div>
  );
}

export default ConsentCookies;
