// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './FeaturedIcon.module.scss';

// Types
import {FeaturedIconProps} from './types';

const FeaturedIcon = ({variant = 'grey', icon}: FeaturedIconProps): React.ReactElement => (
  <div className={classNames([styles.layout, styles[variant]])}>
    {icon}
  </div>
);

export default FeaturedIcon;
