// Actions
import {IToastState, ToastActions, ToastActionsTypes} from './types';

const initialToastState: IToastState = {
  list: []
};

function toastReducer(state: IToastState = initialToastState, action: ToastActionsTypes): IToastState {
  switch (action.type) {
    case ToastActions.CREATE_TOAST:
      return {
        ...state,
        list: [
          ...state.list,
          {
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case ToastActions.DELETE_TOAST:
      return {
        ...state,
        list: [...state.list.slice(0, action.payload.id), ...state.list.slice(action.payload.id + 1)]
      };

    default:
      return state;
  }
}

export default toastReducer;
