import {CloseModalAction, ModalActions, ModalData, OpenModalAction} from './types';
import {ModalsId} from '@type/modals';

/**
 * @function openModal():
 * @description Open a modal in the interface.
 * @param id Unique identifier of the modal.
 * @param data? Data to be used by the modal.
 */
export function openModal<T extends ModalData>(id: ModalsId | null, data: T): OpenModalAction<T> {
  return {
    type: ModalActions.OPEN_MODAL,
    payload: {
      id,
      data
    }
  };
}

/**
 * @function closeModal():
 * @description Close the current modal in the interface.
 */
export function closeModal(): CloseModalAction {
  return {
    type: ModalActions.CLOSE_MODAL
  };
}
