// API
import request from '../api';

// Types
import {GetRewardsResponse, GetCSVRewardsResponse} from './types';

/**
 * @function getRewards()
 */
export const getRewards = async(rewardId: number): Promise<GetRewardsResponse> =>
  await request('/rewards/' + rewardId.toString() + '/content', {
    method: 'GET'
  });

/**
 * @function getRewardsPreview()
 */
export const getRewardsPreview = async(rewardId: number): Promise<GetRewardsResponse> =>
  await request('/rewards/' + rewardId.toString() + '/draft-content', {
    method: 'GET'
  });

/**
 * @function getCSVRewards()
 */
export const getCSVRewards = async(rewardId: number): Promise<GetCSVRewardsResponse> =>
  await request('/rewards/' + rewardId.toString() + '/csv', {
    method: 'GET'
  });
