// Dependencies
import React from 'react';

// Assets
import TicketIcon from '@assets/icons/x-ticket.svg';
import GiftIcon from '@assets/icons/x-gift.svg';
import CommunityIcon from '@assets/icons/x-community.svg';

export const IMAGE_UPLOAD_FORMATS = ['.jpg', '.png', '.gif', '.svg'];
export const VIDEO_UPLOAD_FORMATS = ['.mp4', '.mov', '.avi', '.wmv'];
export const PDF_UPLOAD_FORMATS = ['.pdf'];
export const TEXT_UPLOAD_FORMATS = ['.txt', '.csv'];

export const menuItems = [
  {
    icon: <TicketIcon />,
    text: 'nftPasses',
    path: '/'
  },
  {
    icon: <GiftIcon />,
    text: 'myRewards',
    path: '/rewards'
  },
  {
    icon: <CommunityIcon />,
    text: 'collections',
    path: '/collections'
  }
];

export const getMenuItemsWithBadge = (...args: number[]) =>
  menuItems.map((item, index) => ({
    ...item,
    quantity: args[index]
  }));
