// Dependencies
import {NFTListState, NFTActions, nftActionsTypes} from './types';
import {INFT} from '@type/nft';
import {normalizeState, unNormalizeState} from '@utils/state';

const initialNFTState: NFTListState = {
  list: {},
  pendingToClaim: 0,

  pagination: {
    page: 0,
    isLastPage: false,
    itemsPeerPage: 20
  }
};

function nftReducer(state: NFTListState = initialNFTState, action: NFTActions): NFTListState {
  switch (action.type) {
    case nftActionsTypes.SET_NFT_STATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: {
            ...state.list?.[action.payload.id],
            state: action.payload.state
          }
        }
      };

    case nftActionsTypes.SET_IS_NFT_LAST_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          isLastPage: action.payload.isLastPage
        }
      };

    case nftActionsTypes.SET_NFT_PAGE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page
        }
      };
    }

    case nftActionsTypes.ADD_NFT: {
      const nft: INFT = action.payload.userNFT;

      return {
        ...state,
        list: {
          ...state.list,
          [nft.id]: nft
        }
      };
    }

    case nftActionsTypes.REMOVE_NFT: {
      const newState = unNormalizeState(state.list)
        .filter(e => e.userNftItemId !== action.payload.id);

      return {
        ...state,
        list: normalizeState(newState, 'userNftItemId')
      };
    }

    case nftActionsTypes.SET_NFT_DATA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: {
            ...state.list?.[action.payload.id],
            ...action.payload.data
          }
        }
      };

    case nftActionsTypes.SET_NFT_LIST:
      return {
        ...state,
        list: action.payload.list
      };

    case nftActionsTypes.SET_NFT_CLAIM_PENDING:
      return {
        ...state,
        pendingToClaim: action.payload.amount
      };

    case nftActionsTypes.DECREASE_NFTS_PENDING_CLAIM:
      return {
        ...state,
        pendingToClaim: state.pendingToClaim - 1
      };
    default:
      return state;
  }
}

export default nftReducer;
