// @ts-nocheck
// Dependencies
import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('validations.email')
    .required('validations.email-required'),
  password: Yup.string()
    .required('validations.password-required')
});

export default LoginSchema;
