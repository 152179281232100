// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {InstallWalletModalProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Button from '@components/Button';
import Typography from '@components/Typography';

// StyleSheet
import styles from './InstallWalletModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useModal from '@hooks/useModal';

// Assets
import {RiAlertFill} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/installWallet.json';
import language_en from 'src/locales/en/modals/installWallet.json';

function InstallWalletModal({
  action = undefined
}: InstallWalletModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.text}><FeaturedIcon variant={'yellow'} icon={(<RiAlertFill />)} /></div>
      <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.title}</Typography>
      <div className={styles.container}>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>{language.description1}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>1. <b>{language.step1Title}</b> {language.step1}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>2. <b>{language.step2Title}</b> {language.step2}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>3. <b>{language.step3Title}</b> {language.step3}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>{language.reminder}</Typography>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} color={'primary'} onClick={closeModal}>
              {language.notNowButton}
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.installMetamaskButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstallWalletModal;
