// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {UpdateQuestAction} from '@store/quest/types';

// Services
import * as services from '@services/quest';

// Actions
import {setUpdateQuest} from '@store/quest/actions';
import {closeModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function updateQuestProcess()
 * @description Update a quest.
 */
function * updateQuestProcess({payload: {quest}}: UpdateQuestAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_QUEST, true));

    yield call(services.editQuest, quest);

    yield put(setUpdateQuest(quest));
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_QUEST, false));
    yield put(closeModal());
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_QUEST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default updateQuestProcess;
