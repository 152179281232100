// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {fetchPassByQrCode, createNewPass, generatePass, setPassCreated} from '@store/pass/actions';

// Types
import {FetchPassByQrCodeAction, CreateNewPassAction, GeneratePassAction, SetPassCreatedAction} from '@store/pass/types';
import {IPass} from '@type/pass';

// Selectors
import {selectPass, selectPassCreated} from '@store/pass/selectors';

interface IUsePass {
  pass: IPass | undefined;
  passCreated: boolean;
  fetchPassByQrCode: (qrCode: string) => FetchPassByQrCodeAction;
  createNewPass: (email: string, passType: number, pass: IPass) => CreateNewPassAction;
  generatePass: (nftItemId: number, pass: IPass) => GeneratePassAction;
  setPassCreated: (passCreated: boolean) => SetPassCreatedAction;
}

function usePass(): IUsePass {
  const dispatch = useDispatch();
  const pass = useSelector(selectPass);
  const passCreated = useSelector(selectPassCreated);

  return {
    pass,
    passCreated,
    fetchPassByQrCode: (qrCode) => dispatch(fetchPassByQrCode(qrCode)),
    createNewPass: (email, passType, pass) => dispatch(createNewPass(email, passType, pass)),
    generatePass: (nftItemId, pass) => dispatch(generatePass(nftItemId, pass)),
    setPassCreated: (passCreated: boolean) => dispatch(setPassCreated(passCreated))
  };
}

export default usePass;
