// Dependencies
import React, {useCallback, useState} from 'react';
import classNames from 'clsx';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './RequestResetPasswordModal.module.scss';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';

// Hooks
import useModal from '@hooks/useModal';
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Assets
// import sentEmailLottie from '@assets/lotties/sent-email.json';
import {RiLockPasswordLine} from 'react-icons/ri';

// Forms
import RequestResetPasswordForm, {FormValues} from '@forms/RequestResetPasswordForm';

import language_es from 'src/locales/es/modals/requestResetPassword.json';
import language_en from 'src/locales/en/modals/requestResetPassword.json';

function RequestResetPasswordModal(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {requestResetPassword} = useAuth();
  const {closeModal} = useModal();
  const {isLoading} = useLoader(LoadersId.IS_SENDING_REQUEST_RESET_PASSWORD);
  const {isLoading: resetPassword} = useLoader(LoadersId.RESET_PASSWORD);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmitRequestPassword = useCallback((values: FormValues) => {
    requestResetPassword(values.email);
    setIsOpen(true);
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'yellow'} icon={(<RiLockPasswordLine />)} />

      <div className={styles.field}>
        <Typography size={'lg'} weight={'semiBold'}>{language.reset_password}</Typography>
        <Typography size={'sm'} weight={'regular'}>{language.reset_password_description}</Typography>
      </div>

      <div className={styles.field}>
        <RequestResetPasswordForm
          onSubmit={handleSubmitRequestPassword}
          onCancel={() => closeModal()}
          isLoading={isLoading}
        />
      </div>

      {resetPassword && isOpen ? (
        <div className={styles.field}>
          <Typography size={'sm'} textAlign={'center'} weight={'regular'}>{language.email_sent_message}</Typography>
        </div>
      ) : ''}
    </div>
  );
}

export default RequestResetPasswordModal;
