// Dependencies
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

// Styles
import styles from './CreatorDetails.module.scss';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import CollectionCard from '@components/cards/CollectionCard';
import PageTitle from '@components/PageTitle';
import FeaturedIcon from '@components/FeaturedIcon';
import Loader from '@components/Loader';

// Assets
import {RiAddLine, RiSearchLine} from 'react-icons/ri';

// Types
import {ICollection} from '@type/collection';

// Hooks
import useAuth from '@hooks/useAuth';
import useModal, {ModalsId} from '@hooks/useModal';

import language_es from 'src/locales/es/pages/creator/creatorDetails.json';
import language_en from 'src/locales/en/pages/creator/creatorDetails.json';

function CreatorDetails(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user} = useAuth();
  const navigate = useNavigate();
  const {openModal} = useModal();

  /**
   * Callback function to handle clicking on a collection.
   *
   * @function
   * @name handleClickCollection
   * @param {Object} collection - The collection object of type ICollection.
   * @returns {void}
   */
  const handleClickCollection = useCallback((collection: ICollection) => {
    collection?.published
      ? navigate(`/collection/${collection.givitUrl}`)
      : navigate(`/creator/collection/${collection.id}`);
  }, [navigate]);

  const handleClickShareQr = useCallback((collection: ICollection) => {
    if (collection.nfts) {
      openModal(ModalsId.SHARE_SHOW_QR_CODE, {
        nft: collection.nfts[0],
        collection,
        action: () => {
          if (collection.nfts && collection.nfts.length > 0) {
            const nft = collection.nfts[0];
            if (nft) {
              const textarea = document.createElement('textarea');
              textarea.value = `${window.location.protocol}//${window.location.host}/collection/${collection.givitUrl}/qr/${collection.nfts[0].generatePassQrCode}`;
              document.body.appendChild(textarea);
              textarea.select();
              textarea.setSelectionRange(0, textarea.value.length);
              document.execCommand('copy');
              document.body.removeChild(textarea);
            }
          };
        }
      });
    }
  }, []);

  /**
   * Callback function to handle clicking the "New Collection" button.
   *
   * @function
   * @name handleClickNewCollection
   * @returns {void}
   */
  const handleClickNewCollection = useCallback(() => {
    navigate('/creator/new-collection');
  }, [navigate]);

  const handleOpenEditProfile = useCallback(() => {
    if (user) {
      openModal(ModalsId.EDIT_PROFILE, {
        initialValues: {
          name: user.name
        }
      });
    }
  }, [user]);

  if (!user?.collections || !user.counts || !user.license) return <Loader />;

  return (
    <div className={styles.container}>
      <PageTitle
        text={'User data'}
      />
      <div className={styles.row}>
        <Typography variant={'text'} weight={'semiBold'} size={'xl'} textAlign={'center'}>{`You have created ${user.counts?.userPassCreated} passes until now`}</Typography>
        <Typography variant={'text'} weight={'regular'} size={'md'} textAlign={'center'}>{`You have left ${user.license.maxNFTs - user.counts.userPassCreated} passes available to create`}</Typography>
      </div>
      <div className={styles.field}>
        <div className={styles.button}>
          <Button onClick={handleOpenEditProfile}>{language.editProfile}</Button>
        </div>
      </div>
      <PageTitle
        text={language.myCollections}
        rightElement={user.collections.length > 0 && (
          <div>
            <Button
              onClick={handleClickNewCollection}
              iconLeft={(
                <RiAddLine />
              )}>
              {language.newCollection}
            </Button>
          </div>
        )}
      />
      {user?.collections.length ? (
        <div className={styles.grid}>
          {user?.collections?.map((collection, index) => (
            <CollectionCard
              key={`--collection-card-${index.toString()}`}
              onClick={() => handleClickCollection(collection)}
              onShareClick={() => handleClickShareQr(collection)}
              name={collection.name}
              bannerUrl={collection.banner}
              description={collection.description}
              published={collection.published}
              creator={{
                name: user.name,
                photoUrl: collection.avatar,
                isCreator: true
              }}
              nfts={collection.nfts ? collection.nfts.length : 0}
            />
          ))}
        </div>
      ) : (
        <div className={styles.empty}>
          <FeaturedIcon
            icon={(
              <RiSearchLine />
            )}
          />
          <div className={styles.row}>
            <Typography variant={'text'} weight={'semiBold'} size={'xl'} textAlign={'center'}>{language.startByCreatingCollection}</Typography>
            <Typography variant={'text'} weight={'regular'} size={'md'} textAlign={'center'}>{language.yourCollectionsWillLiveHere}</Typography>
            <div className={styles.actions}>
              <div>
                <Button
                  onClick={handleClickNewCollection}
                  iconLeft={(
                    <RiAddLine />
                  )}>
                  {language.newCollection}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default CreatorDetails;
