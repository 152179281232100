// Dependencies
import {ExternalProvider, Web3Provider} from '@ethersproject/providers';

function getLibrary(provider: ExternalProvider): Web3Provider {
  const library = new Web3Provider(provider, 'any');
  library.pollingInterval = 12000;
  return library;
}

export default getLibrary;
