// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {ResetPasswordAction} from '../types';
import {LoadersId} from '@type/loaders';
import {ModalsId} from '@type/modals';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {openModal, closeModal} from '@store/ui/modal/actions';

// ErrorHandler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

// Services
import * as authServices from '@services/auth';

/**
 * @function resetPasswordProcess
 * @description reset the user's password
 */
function * resetPasswordProcess({payload: {token, password}}: ResetPasswordAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, true));

    yield call(authServices.resetPassword, token, password);
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, false));

    yield put(openModal(ModalsId.SIMPLE_MESSAGE, {
      title: 'Password reset sucessfully',
      description: 'Now, login with your new password.',
      buttonCaption: 'Go to login',
      action: () => {
        window.location.href = '/login';
        closeModal();
      }
    }));
  } catch (e) {
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, false));
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default resetPasswordProcess;
