// Dependencies
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

// Components
import Typography from '@components/Typography';
import SignInForm, {FormValues} from '@forms/SignInForm';

// Assets
import GivitLogo from '@assets/images/logo.png';

// Styles
import styles from './SignIn.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

import language_es from 'src/locales/es/pages/auth/signIn.json';
import language_en from 'src/locales/en/pages/auth/signIn.json';

function SignIn(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {signUp} = useAuth();
  const {isLoading} = useLoader(LoadersId.IS_SIGNUP);

  /**
   * @function handleSubmitSignIn
   */
  const handleSubmitSignIn = useCallback(({email, password, confirmPassword}: FormValues) => {
    signUp(email, password, confirmPassword);
  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <img src={GivitLogo} alt={language.welcomeToGivitNFT} />
      </div>
      <div className={styles.layout}>
        <div className={styles.box}>
          <div className={styles.head}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.signUp}</Typography>
            <Typography variant={'text'} size={'md'}>{language.startWithUsNow}</Typography>
          </div>
          <SignInForm
            onSubmit={handleSubmitSignIn}
            isLoading={isLoading}
          />
          <div className={styles.foot}>
            <Typography variant={'text'} textAlign={'center'} size={'sm'}>
              {language.alreadyHaveAccount}
              <Link to={'/login'} className={styles.link}>
                {language.logIn}
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
