// Actions
import {IMainMenuState, MainMenuActions, MainMenuActionsTypes} from './types';

const initialMainMenuState: IMainMenuState = {
  tabId: 0
};

function loaderReducer(state: IMainMenuState = initialMainMenuState, action: MainMenuActionsTypes): IMainMenuState {
  switch (action.type) {
    case MainMenuActions.SET_TAB_CHANGE:
      return {
        ...state,
        tabId: action.payload.tabId
      };

    default:
      return state;
  }
}

export default loaderReducer;
