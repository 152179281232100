// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {CompleteQuestProps} from './types';
import {QuestTypeEnum} from '@type/quest';

// Hooks
import useResponsive from '@hooks/useResponsive';
// import useLoader from '@hooks/useLoader';
import useModal from '@hooks/useModal';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CompleteQuestModal.module.scss';

// Components
import Typography from '@components/Typography';
import DiscordQuest from '@components/quests/DiscordQuest';
import YouTubeQuest from '@components/quests/YouTubeQuest';
import TwitchQuest from '@components/quests/TwitchQuest';
import QuillTextInput from '@components/QuillTextInput';
import IconButton from '@components/IconButton';

// Assets
import {RiCloseLine} from 'react-icons/ri';

function CompleteQuestModal({collectionName, status, quest, checkEnabled}: CompleteQuestProps): React.ReactElement | null {
  const {isMobile} = useResponsive();
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={classNames([styles.row, styles.title])}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{quest.title}</Typography>

        <IconButton
          type={'button'}
          variant={'ghost'}
          color={'primary'}
          size={'lg'}
          icon={<RiCloseLine />}
          onClick={closeModal}
        />
      </div>
      <div className={styles.row}>
        <QuillTextInput
          theme='snow'
          value={quest.description}
          readOnly={true}
          modules={{toolbar: false}}
        />
      </div>

      {!status && (<div className={styles.inline}>
        {quest.questActionType.questType?.id === QuestTypeEnum.Discord && (
          <DiscordQuest
            collectionName={collectionName}
            quest={quest}
            isMobile={isMobile}
            checkEnabled={checkEnabled}
          />
        )}
        {quest.questActionType.questType?.id === QuestTypeEnum.YouTube && (
          <YouTubeQuest
            collectionName={collectionName}
            quest={quest}
            isMobile={isMobile}
            checkEnabled={checkEnabled}
          />
        )}
        {quest.questActionType.questType?.id === QuestTypeEnum.Twitch && (
          <TwitchQuest
            collectionName={collectionName}
            quest={quest}
            isMobile={isMobile}
            checkEnabled={checkEnabled}
          />
        )}
      </div>
      )}
    </div>
  );
}

export default CompleteQuestModal;
