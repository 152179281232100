// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {DeleteNFTAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {unSetNFTToMyCollection} from '@store/collection/actions';
import {closeModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function deleteNFTProcess()
 * @description Delete a selected NFT.
 */
function * deleteNFTProcess({payload: {collectionId, nftId}}: DeleteNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DELETING_NFT, true));

    const response = yield call(services.deleteNFT, collectionId, nftId);

    if (response) {
      yield put(unSetNFTToMyCollection(collectionId, nftId));
      yield put(setIsLoading(LoadersId.IS_DELETING_NFT, false));
      yield put(closeModal());
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DELETING_NFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default deleteNFTProcess;
