// Dependencies
import React from 'react';

// Components
import Typography from '@components/Typography';

// Types
import {PageTitleProps} from './types';

// StyleSheet
import styles from './PageTitle.module.scss';

// Hooks
import useResponsive from '@hooks/useResponsive';

function PageTitle({text, rightElement}: PageTitleProps): React.ReactElement {
  const {isMobile} = useResponsive();
  return (
    <div className={styles.layout}>
      <Typography variant={'display'} size={isMobile ? 'xs' : 'sm'} weight={'semiBold'}>{text}</Typography>
      {rightElement && rightElement}
    </div>
  );
}

export default PageTitle;
