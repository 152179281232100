// State
import {RootState} from '../reducer';
import {ICollection} from '@type/collection';
import {INFTCollection} from '@type/nftCollection';
import {unNormalizeState} from '@utils/state';

export const selectCollectionList = (state: RootState): ICollection[] | undefined => unNormalizeState(state.collection.list);

export const selectCollectionByName = (state: RootState, name: string | undefined): ICollection | undefined => name ? unNormalizeState(state.collection.list)?.filter(e => e.givitUrl === name)[0] : undefined;

export const selectCollectionNFTList = (state: RootState): INFTCollection[] | undefined => unNormalizeState(state.collection.nftList);

export const selectMyCollectionsList = (state: RootState): {[key: string]: ICollection} | undefined => state.collection.myCollections;

export const selectUserCollectionNFTsList = (state: RootState): number[] => state.collection.userNftIds;
