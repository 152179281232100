// Dependencies
import React from 'react';
import classNames from 'clsx';
import {TailSpin} from 'react-loader-spinner';

// Types
import {ButtonProps} from './types';

// Styles
import styles from './Button.module.scss';

function Button({
  type,
  variant = 'solid',
  size = 'md',
  color = 'primary',
  children,
  isLoading,
  showTextOnLoading = false,
  iconLeft,
  iconRight,
  disabled = false,
  className = '',
  onClick
}: ButtonProps): React.ReactElement {
  return (
    <button
      type={type}
      className={classNames([
        'notranslate',
        styles.layout,
        styles[variant],
        styles[size],
        styles[color],
        {[className]: className, [styles.disabled]: disabled}]
      )}
      disabled={disabled || isLoading}
      onClick={onClick}>
      <>
        {isLoading && (
          <div className={styles.loader}>
            <TailSpin
              width={22}
              height={22}
            />
            {showTextOnLoading && (
              children
            )}
          </div>
        )}
        <div className={classNames([{[styles.isLoading]: isLoading}, styles.container])}>
          {iconLeft && <div className={styles.icon}>{iconLeft}</div>}
          {children && <div className={styles.text}>{children}</div>}
          {iconRight && <div className={styles.icon}>{iconRight}</div>}
        </div>
      </>
    </button>
  );
}

export default Button;
