// Dependencies
import React, {useEffect} from 'react';

// Types
import {LoadersId} from '@type/loaders';

// StyleSheet
import styles from './MobileLogin.module.scss';

// Components
import Typography from '@components/Typography';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader from '@hooks/useLoader';
import {useParams} from 'react-router-dom';

import language_es from 'src/locales/es/pages/auth/mobileLogin.json';
import language_en from 'src/locales/en/pages/auth/mobileLogin.json';

function MobileLogin(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {mobileLogin} = useAuth();
  const {token} = useParams<{token: string}>();
  const {isLoading} = useLoader(LoadersId.MOBILE_LOGIN);

  useEffect(() => {
    if (token) {
      mobileLogin(token);
    }
  }, [token]);

  return (
    <div className={styles.layout}>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.head}>
            {isLoading ? (
              <>
                <Typography variant={'text'} size={'lg'} weight={'bold'}>{language.metamaskDetected}</Typography>
                <Typography variant={'text'} size={'sm'}>{language.waitAMoment}</Typography>
              </>
            ) : (
              <>
                <Typography variant={'text'} size={'lg'} weight={'bold'}>{language.metamaskLoginSuccess}</Typography>
                <Typography variant={'text'} size={'sm'}>{language.applicationWillOpen}</Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileLogin;
