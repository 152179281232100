// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchQuestTypesAction} from '@store/quest/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/quest';
import {IQuestType, IQuestActionType} from '@type/quest';

// Actions
import {setQuestTypes} from '@store/quest/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchQuestTypesProcess()
 * @description Fetching the quest types
 */
// eslint-disable-next-line no-empty-pattern
function * fetchQuestTypesProcess({payload: {}}: FetchQuestTypesAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_QUEST_TYPES, true));

    const questTypes: IQuestType[] = yield call(services.getQuestTypes);
    const questActionTypes: IQuestActionType[] = yield call(services.getQuestActionTypes);

    if (questTypes && questActionTypes && questTypes.length > 0 && questActionTypes.length > 0) yield put(setQuestTypes(questTypes, questActionTypes));

    yield put(setIsLoading(LoadersId.FETCH_QUEST_TYPES, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_QUEST_TYPES, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchQuestTypesProcess;
