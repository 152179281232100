import {put} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Actions
import {createToast} from '@store/ui/toast/actions';

// Types
import {ErrorMessage} from '@type/error';

function * errorHandlerLogin(error: ErrorMessage): SagaIterator<void> {
  const {message} = error;

  if (process.env.REACT_APP_NODE_ENV === 'development') yield put(createToast('error', message));
  else error.code === 501 || error.code === 401 ? yield put(createToast('error', message)) : yield put(createToast('error', 'There was an error. If it persist, please, contact to support.'));
}

export default errorHandlerLogin;
