// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Selectors
import {selectToastList} from '@store/ui/toast/selectors';

// Types
import {IToast, ToastTypeEnum} from '@type/toasts';
import {CreateToastAction, DeleteToastAction} from '@store/ui/toast/types';

// Actions
import {createToast, deleteToast} from '@store/ui/toast/actions';

/**
 * Interface for the return value of the useToast custom hook.
 *
 * @property {IToast[]} toasts - An array of toasts in the store.
 * @property {(type: ToastTypeEnum, message: string) => CreateToastAction} createToast - A function to create a new toast with a specified type and message.
 * @property {(id: number) => DeleteToastAction} deleteToast - A function to delete a toast with a specified ID.
 */
interface IUseToast {
  toasts: IToast[];
  createToast: (type: ToastTypeEnum, message: string) => CreateToastAction;
  deleteToast: (id: number) => DeleteToastAction;
}

/**
 * Custom hook to manage toasts.
 *
 * @returns {IUseToast} - An object containing the current toasts in the store, and functions to create and delete toasts.
 */
function useToast(): IUseToast {
  const toasts = useSelector(selectToastList);
  const dispatch = useDispatch();

  return {
    toasts,
    createToast: (type, message) => dispatch(createToast(type, message)),
    deleteToast: (id) => dispatch(deleteToast(id))
  };
}

export default useToast;
