// Dependencies
import React, {useState} from 'react';

// Styles
import styles from './TextInput.module.scss';

// Types
import {TextInputProps} from './types';
import Typography from '@components/Typography';
import classNames from 'clsx';
import {RiEyeLine, RiEyeOffLine} from 'react-icons/ri';

function TextInput({
  label,
  type = 'text',
  name,
  value = '',
  placeholder,
  onChangeText,
  onBlur,
  onFocus,
  disabled,
  error,
  textarea,
  min,
  max,
  icon = undefined,
  className,
  secureEntry = false
}: TextInputProps): React.ReactElement | null {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  if (value instanceof File) {
    return null;
  }

  return (
    <div className={classNames([styles.layout, {[styles.disabled]: disabled}, {[styles.withError]: error}, 'notranslate'])}>
      {(label) && (
        <label htmlFor={label} className={styles.label}>{label}</label>
      )}
      <div className={classNames([styles.inputContainer, className])}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {textarea ? (
          <textarea
            name={name}
            className={styles.input}
            placeholder={placeholder}
            onChange={(e) => onChangeText?.(e.target.value)}
            value={value ?? ''}
            disabled={disabled}
          />
        ) : (
          <input
            type={type === 'password' ? isPasswordVisible ? 'text' : 'password' : type}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            className={styles.input}
            placeholder={placeholder}
            onChange={(e) => onChangeText?.(e.target.value)}
            value={value ?? ''}
            disabled={disabled}
            max={max}
            min={min}
          />
        )}
        {secureEntry && (
          <button type={'button'} title={'show or hide password'} className={styles.textInputButton} onClick={() => setPasswordVisible(!isPasswordVisible)}>
            {!isPasswordVisible ? (
              <RiEyeLine />
            ) : (
              <RiEyeOffLine />
            )}
          </button>
        )}
      </div>
      {error && (
        <Typography variant={'text'} size={'sm'} className={styles.error}>{error}</Typography>
      )}
    </div>
  );
}

export default TextInput;
