// Dependencies
import React from 'react';
import classNames from 'clsx';
import {TailSpin} from 'react-loader-spinner';

// Types
import {ButtonProps} from './types';

// Styles
import styles from './IconButton.module.scss';

function IconButton({
  type = 'button',
  variant = 'solid',
  size = 'md',
  color = 'primary',
  isLoading,
  icon,
  disabled = false,
  className = '',
  onClick
}: ButtonProps): React.ReactElement {
  return (
    <button
      type={type}
      className={classNames([
        styles.layout,
        styles[variant],
        styles[size],
        styles[color],
        {[className]: className, [styles.disabled]: disabled}]
      )}
      disabled={disabled || isLoading}
      onClick={onClick}>
      <>
        {isLoading && (
          <div className={styles.loader}>
            <TailSpin
              width={22}
              height={22}
              color={'#fff'}
            />
          </div>
        )}
        <div className={classNames([{[styles.isLoading]: isLoading}, styles.container])}>
          {icon}
        </div>
      </>
    </button>
  );
}

export default IconButton;
