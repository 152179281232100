// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {DeleteGiftAction} from '@store/gift/types';
import {LoadersId} from '@type/loaders';
import {ErrorMessage} from '@type/error';

// Services
import * as services from '@services/gift';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {deleteGift} from '@store/gift/actions';

// Utils
import errorHandler from '@store/errorHandler';

/**
 * Handles the process of deleting a gift.
 * @generator
 * @function
 * @param {DeleteGiftAction} action - The action object with the type and payload.
 * @param {number} action.payload.giftId - The ID of the gift to be deleted.
 * @returns {SagaIterator<void>} A saga iterator that handles the deletion process of the gift.
 */
function * deleteGiftProcess({payload: {collectionId, giftId}}: DeleteGiftAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DELETING_GIFT, true));

    yield call(services.deleteGift, giftId);

    yield put(deleteGift(giftId));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DELETING_GIFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default deleteGiftProcess;
