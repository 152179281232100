// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, delay} from 'redux-saga/effects';

// Types
import {ClaimQuestCollectionNFTAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {openModal, closeModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';
import {setUserCollectionNFTsList} from '@store/collection/actions';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function claimQuestCollectionNFTProcess()
 * @description Delete a specific collection
 */
function * claimQuestCollectionNFTProcess({payload: {nftId, nftUrl}}: ClaimQuestCollectionNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CLAIMING_QUEST_COLLECTION_NFT, true));
    yield put(openModal(ModalsId.CLAIMING_AIRDROP_COLLECTION_NFT, {
      type: 'loading'
    }));

    yield call(services.claimQuestCollectionNFT, nftId);

    yield put(setUserCollectionNFTsList([nftId]));
    yield put(closeModal());
    yield put(openModal(ModalsId.CLAIMING_AIRDROP_COLLECTION_NFT, {
      type: 'completed',
      nftUrl,
      action: () => {
        window.location.href = '/';
      }
    }));

    yield put(setIsLoading(LoadersId.IS_CLAIMING_QUEST_COLLECTION_NFT, false));
  } catch (e) {
    yield delay(1000);
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CLAIMING_QUEST_COLLECTION_NFT, false));
    yield put(closeModal());
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default claimQuestCollectionNFTProcess;
