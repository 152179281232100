// Dependencies
import React from 'react';

// Types
import {AudienceCardProps} from './types';

// Styles
import styles from './AudienceCard.module.scss';

// Components
import Typography from '@components/Typography';

const AudienceCard: React.FC<AudienceCardProps> = ({title, icon, description}) => (
  <div className={styles.layout}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.text}>
      <Typography variant="display" size="xs" weight="semiBold">
        {title}
      </Typography>
      <Typography variant="text" size="md" weight="regular" className={styles.description}>
        {description}
      </Typography>
    </div>
  </div>
);

export default AudienceCard;
