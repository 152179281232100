// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {setRouter} from '../../store/settings/actions';
import {ISettingsState, SetRouterAction} from '@store/settings/types';

import {selectSettingsRouter} from '@store/settings/selectors';

export interface IUseSettingsProps {
  router: ISettingsState['router'];
  setRouter: (router: SetRouterAction['payload']['router']) => SetRouterAction;
}

function useSettings(): IUseSettingsProps {
  const dispatch = useDispatch();
  const router = useSelector(selectSettingsRouter);

  return {
    router,
    setRouter: (router) => dispatch(setRouter(router))
  };
}

export default useSettings;
