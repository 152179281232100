// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setNFTList, setNFTPendingToClaim} from '@store/nft/actions';
import {selectNFTList} from '@store/nft/selectors';

// Services
import * as nftServices from '@services/nft';

// Types
import {GetNFTByIdPayload} from '@services/nft/types';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {FetchNFTByIdAction} from '@store/nft/types';
import {normalizeState} from '../../../shared/utils/state';
import {INFT, NFTState} from '../../../types/nft';

function * fetchNFTByIdProcess({payload: {id}}: FetchNFTByIdAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, true));

    const nftList = yield select(selectNFTList);

    const nft: GetNFTByIdPayload['response'] = yield call(nftServices.getNFTById, id);

    const data = {
      ...nft,
      properties: nft.properties ? nft.properties : [],
      state: (nft.tx && nft.status) ? NFTState.CLAIMED : (nft.tx && !nft.status) ? NFTState.IS_WAITING_CONFIRMATION : NFTState.READY_TO_CLAIM
    };

    const normalizedNFTList: Record<string, INFT> = normalizeState(nftList, 'userNftItemId');

    const amount: number = yield call(nftServices.getPendingNFTs);
    yield put(setNFTPendingToClaim(amount));

    yield put(setNFTList({
      ...normalizedNFTList,
      ...{[nft.nftItemId]: data}
    }));

    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_NFT_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchNFTByIdProcess;
