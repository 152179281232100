// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {
  createNFT,
  createNewCollection,
  deleteCollection,
  deleteNFT,
  editCollection,
  editNFT,
  fetchCollectionById,
  fetchCollectionByName,
  fetchCollectionList,
  fetchCollectionNFTsList,
  fetchUserCollectionNFTsList,
  publishCollection,
  claimAirdropCollectionNFT,
  claimQuestCollectionNFT,
  downloadActivityCSV
} from '@store/collection/actions';

// Types
import {
  CreateNFTAction,
  CreateNFTActionProps,
  CreateNewCollectionAction,
  CreateNewCollectionActionProps,
  DeleteCollectionAction,
  DeleteNFTAction,
  EditCollectionAction,
  EditCollectionActionProps,
  EditNFTAction,
  EditNFTActionProps,
  FetchCollectionByIdAction,
  FetchCollectionByNameAction,
  FetchCollectionListAction,
  FetchCollectionNFTsListAction,
  FetchUserCollectionNFTsListAction,
  PublishCollectionAction,
  ClaimAirdropCollectionNFTAction,
  ClaimQuestCollectionNFTAction,
  DownloadActivityDataAction
} from '@store/collection/types';
import {ICollection} from '@type/collection';
import {INFTCollection} from '@type/nftCollection';
import {RootState} from '@store/reducer';

// Selectors
import {
  selectCollectionList,
  selectCollectionByName,
  selectCollectionNFTList,
  selectMyCollectionsList,
  selectUserCollectionNFTsList
} from '@store/collection/selectors';

interface IUseCollections {
  collection?: ICollection;
  collectionList?: ICollection[];
  collectionNFTsList?: INFTCollection[];
  myCollectionsList?: {[key: number]: ICollection};
  userCollectionNFTsList: number[];
  createNewCollection: (collection: CreateNewCollectionActionProps) => CreateNewCollectionAction;
  createNFT: (collectionId: number, nft: CreateNFTActionProps['nft']) => CreateNFTAction;
  deleteNFT: (collectionId: number, nftId: number) => DeleteNFTAction;
  editNFT: (collectionId: number, nft: EditNFTActionProps['nft']) => EditNFTAction;
  editCollection: (collectionId: number, data: EditCollectionActionProps['data']) => EditCollectionAction;
  deleteCollection: (collectionId: number) => DeleteCollectionAction;
  publishCollection: (collectionId: number) => PublishCollectionAction;
  fetchCollectionById: (id: number) => FetchCollectionByIdAction;
  fetchCollectionByName: (name: string) => FetchCollectionByNameAction;
  fetchCollectionList: () => FetchCollectionListAction;
  fetchCollectionNFTsList: (id: number) => FetchCollectionNFTsListAction;
  fetchUserCollectionNFTsList: (id: number) => FetchUserCollectionNFTsListAction;
  claimAirdropCollectionNFT: (nftId: number, nftUrl: string) => ClaimAirdropCollectionNFTAction;
  claimQuestCollectionNFT: (nftId: number, nftUrl: string) => ClaimQuestCollectionNFTAction;
  downloadActivityCSV: (collectionId: number) => DownloadActivityDataAction;
}

function useCollections(name?: string): IUseCollections {
  const dispatch = useDispatch();

  // Selectors
  const collectionList = useSelector(selectCollectionList);
  const collection = useSelector((state: RootState) => selectCollectionByName(state, name));
  const collectionNFTsList = useSelector(selectCollectionNFTList);
  const myCollectionsList = useSelector(selectMyCollectionsList);
  const userCollectionNFTsList = useSelector(selectUserCollectionNFTsList);

  return {
    collection,
    collectionList,
    myCollectionsList,
    collectionNFTsList,
    userCollectionNFTsList,
    createNewCollection: (collection) => dispatch(createNewCollection(collection)),
    editCollection: (collectionId, data) => dispatch(editCollection(collectionId, data)),
    createNFT: (collectionId, nft) => dispatch(createNFT(collectionId, nft)),
    deleteNFT: (collectionId, nftId) => dispatch(deleteNFT(collectionId, nftId)),
    editNFT: (collectionId, nft) => dispatch(editNFT(collectionId, nft)),
    deleteCollection: (collectionId) => dispatch(deleteCollection(collectionId)),
    publishCollection: (collectionId) => dispatch(publishCollection(collectionId)),
    fetchCollectionById: (id) => dispatch(fetchCollectionById(id)),
    fetchCollectionByName: (name) => dispatch(fetchCollectionByName(name)),
    fetchCollectionList: () => dispatch(fetchCollectionList()),
    fetchCollectionNFTsList: (id) => dispatch(fetchCollectionNFTsList(id)),
    fetchUserCollectionNFTsList: (id) => dispatch(fetchUserCollectionNFTsList(id)),
    claimAirdropCollectionNFT: (nftId, nftUrl) => dispatch(claimAirdropCollectionNFT(nftId, nftUrl)),
    claimQuestCollectionNFT: (nftId, nftUrl) => dispatch(claimQuestCollectionNFT(nftId, nftUrl)),
    downloadActivityCSV: (collectionId) => dispatch(downloadActivityCSV(collectionId))
  };
}

export default useCollections;
