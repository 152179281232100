// Dependencies
import React, {useEffect, useRef} from 'react';
import {usePrevious, useClickAway} from 'react-use';

// Styles
import styles from './SuperModal.module.scss';

// Hooks
import useModal from '@hooks/useModal';

// Contents
import RenderModal from './contents';

function SuperModal() {
  const {modal: {id, data}, closeModal} = useModal();
  const backdropRef = useRef<HTMLDivElement>(null);
  const previousId = usePrevious(id);

  useEffect(() => {
    const hasId = !!id;
    const body = document.querySelector<HTMLElement>('body');

    if (body) {
      body.style.overflow = hasId ? 'hidden' : 'auto';
    }
  }, [id, previousId]);

  useClickAway(backdropRef, () => {
    closeModal();
  });

  return id ? (
    <div className={styles.backdrop}>
      <div ref={backdropRef}>
        <RenderModal id={id} data={data} />
      </div>
    </div>
  ) : null;
}

export default SuperModal;
