// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {EditNFTAction} from '@store/collection/types';
import {EditNFTResponse} from '@services/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {closeModal} from '@store/ui/modal/actions';
import {setNFTDataFromMyCollection} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * Generator function for editing an NFT.
 *
 * @function
 * @name editNFTProcess
 * @param {Object} action - Action object with properties: payload {Object}, collectionId {string}, nft {Object}
 * @returns {SagaIterator<void>} A saga iterator representing the edit NFT process.
 */
function * editNFTProcess({payload: {collectionId, nft}}: EditNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, true));

    const formData = yield call(() => {
      const data = new FormData();

      if (nft.photo instanceof File) {
        data.append('media', nft.photo);
      }

      data.append('nft', JSON.stringify({
        name: nft.name,
        welcomeMessage: nft.welcomeMessage,
        passBackgroundColor: nft.passBackgroundColor,
        passForegroundColor: nft.passForegroundColor,
        passLabelColor: nft.passLabelColor,
        passTypeId: nft.passTypeId,
        ...(!(nft.photo instanceof File) && ({
          media: nft.photo
        })),
        imageTypeId: nft.imageTypeId,
        properties: []
      }));

      return data;
    });

    const response: EditNFTResponse = yield call(services.editNFT, nft.id, formData);

    if (response) {
      yield put(setNFTDataFromMyCollection(collectionId, response));
      yield put(closeModal());

      yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, false));
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, false));
    yield call(errorHandler, e as ErrorMessage);
  }
}

export default editNFTProcess;
