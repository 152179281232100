// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {DeleteQuestAction} from '@store/quest/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/quest';

// Actions
import {unsetQuest} from '@store/quest/actions';
import {closeModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function deleteQuestProcess()
 * @description Delete a selected quest.
 */
function * deleteQuestProcess({payload: {questId}}: DeleteQuestAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DELETING_QUEST, true));

    const response = yield call(services.deleteQuest, questId);

    if (response) {
      yield put(unsetQuest(questId));
      yield put(setIsLoading(LoadersId.IS_DELETING_QUEST, false));
      yield put(closeModal());
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DELETING_QUEST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default deleteQuestProcess;
