// State
import {RootState} from '../reducer';
import {IPass} from '@type/pass';

/**
 * @function selectPass
 * @description Retrieves an NFT by its ID.
 *
 * @param {RootState} state - The global state of the application.
 *
 * @returns {IPass | undefined} - The Pass.
 */
export const selectPass = (state: RootState): IPass | undefined => state.pass.pass;

export const selectPassCreated = (state: RootState): boolean => state.pass.passCreated;
