// Dependencies
import React, {useCallback, useEffect, useState} from 'react';
import {TailSpin} from 'react-loader-spinner';
import classNames from 'clsx';

// Types
import {FlatListProps} from './types';

// StyleSheet
import styles from './FlatList.module.scss';

const FlatList = <T extends unknown>({
  data,
  className,
  onEndReached,
  renderItem,
  isLoading
}: FlatListProps<T>): JSX.Element => {
  const [hasTriggered, setHasTriggered] = useState<boolean>(false);

  /**
	 * Handles the scroll event and triggers the onEndReached callback when the user scrolls to the bottom.
	 * @function
	 * @callback
	 */
  const handleScroll = useCallback(() => {
    if (!isLoading && onEndReached && !hasTriggered) {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100;
      if (isAtBottom) {
        setHasTriggered(true);
        onEndReached();
      }
    }
  }, [onEndReached, isLoading, hasTriggered]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading) {
      setHasTriggered(false);
    }
  }, [isLoading]);

  return (
    <>
      <div className={classNames([styles.layout, className])}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem(item)}
          </React.Fragment>
        ))}
      </div>
      {isLoading && (
        <div className={styles.loader}>
          <div>
            <TailSpin width={34} height={34} color={'#4e8cff'} />
          </div>
        </div>
      )}
    </>
  );
};

export default FlatList;
