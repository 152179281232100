// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {fetchNFTById, claimNFT, sendNFT, fetchNFTList, setNFTState, importNFT, sendMultipleNFTs} from '@store/nft/actions';

// Types
import {ClaimNFTAction, FetchNFTByIdAction, ImportNFTAction, FetchNFTListAction, SetNFTStateAction, SendNFTAction, SendMultipleNFTsAction, NFTListState} from '@store/nft/types';
import {INFT, NFTState} from '@type/nft';
import {RootState} from '@store/reducer';

// Selectors
import {selectNFTById, selectNFTList, selectNFTPendingToClaim, selectNFTListPagination} from '@store/nft/selectors';

interface IUseNFTs {
  pagination: NFTListState['pagination'];
  nftList?: INFT[];
  nft?: INFT,
  pendingToClaim: number;
  fetchNFTList: (searchName: string, page: number) => FetchNFTListAction;
  fetchNFTById: (nftId: INFT['nftId']) => FetchNFTByIdAction;
  claimNFT: (nft: INFT) => ClaimNFTAction;
  importNFT: (chainId: number, nftAddress: string, nftId: number) => ImportNFTAction;
  sendNFT: (nft: INFT, email: string, text: string) => SendNFTAction;
  sendMultipleNFTs: (nft: INFT, emails: string[], text: string) => SendMultipleNFTsAction;
  setNFTState: (id: number, state: NFTState) => SetNFTStateAction;
}

function useNFTs(id?: number): IUseNFTs {
  const dispatch = useDispatch();
  const nftList = useSelector(selectNFTList);
  const nft = useSelector((state: RootState) => selectNFTById(state, id));
  const pendingToClaim = useSelector(selectNFTPendingToClaim);
  const pagination = useSelector(selectNFTListPagination);

  return {
    pagination,
    nftList,
    nft,
    pendingToClaim,
    setNFTState: (id, state) => dispatch(setNFTState(id, state)),
    fetchNFTList: (searchName, page) => dispatch(fetchNFTList(searchName, page)),
    fetchNFTById: (nftId) => dispatch(fetchNFTById(nftId)),
    claimNFT: (nft: INFT) => dispatch(claimNFT(nft)),
    importNFT: (chainId: number, nftAddress: string, nftId: number) => dispatch(importNFT(chainId, nftAddress, nftId)),
    sendNFT: (nft: INFT, email: string, text: string) => dispatch(sendNFT(nft, email, text)),
    sendMultipleNFTs: (nft: INFT, emails: string[], text: string) => dispatch(sendMultipleNFTs(nft, emails, text))
  };
}

export default useNFTs;
