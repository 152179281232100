// Dependencies
import React, {useCallback, useEffect, useState} from 'react';
import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router-dom';

// StyleSheet
import styles from './MyNFTs.module.scss';

// Hooks
import useNFTs from '@hooks/useNFTs';
import useOnBoarding from '@hooks/useOnBoarding';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Components
import FlatList from '@components/FlatList';
import NFTCard from '@components/cards/NFTCard';
import EmptyState from '@components/EmptyState';
import PageTitle from '@components/PageTitle';
import Alert from '@components/Alert';
import RequireInstallMetamask from '@components/RequireInstallMetamask';
import TextInput from '@components/TextInput';
import {TailSpin} from 'react-loader-spinner';

// Types
import {INFT, NFTState} from '@type/nft';

// Hooks
import useModal, {ModalsId} from '@hooks/useModal';
import useResponsive from '@hooks/useResponsive';

// Services
import * as authServices from '@services/auth';

// Utils
import useMetamask from '@utils/connectors/metamask';
import Web3Utils from '@utils/web3';

// Assets
import {RiImageLine} from 'react-icons/ri';

import language_es from 'src/locales/es/pages/user/myNFTs.json';
import language_en from 'src/locales/en/pages/user/myNFTs.json';

function MyNFTs(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {activate, chainId, active} = useWeb3React();
  const {
    nftList,
    pendingToClaim,
    fetchNFTList,
    claimNFT,
    setNFTState,
    pagination
  } = useNFTs();
  const {openModal, closeModal} = useModal();
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_NFT_LIST);
  const {isMobile} = useResponsive();
  const [searchName, setSearchName] = useState<string>('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  // Navigation
  const navigate = useNavigate();

  // Metamask
  const metamask = useMetamask();
  const {isInstalled, handleInstall, handleLaunch} = useOnBoarding();

  useEffect(() => {
    fetchNFTList('', 0);
  }, []);

  /**
   * Handles the process of claiming an NFT.
   * @async
   * @function
   * @param {INFT} nft - The NFT to be claimed, should be of type INFT.
   * @returns {Promise<void>} A promise that resolves when the claim process is completed.
   */
  const claimProcess = async(nft: INFT): Promise<void> => {
    if (chainId === nft.chainId) {
      claimNFT(nft);
    } else {
      try {
        setNFTState(nft.userNftItemId, NFTState.IS_WAITING_CHANGE_CHAIN);
        await Web3Utils.switchToChain(nft.chainId);
        claimNFT(nft);
      } catch (e) {
        setNFTState(nft.userNftItemId, NFTState.READY_TO_CLAIM);
        console.error(e);
      }
    }
  };

  /**
   * Handles the event when the user presses the button to claim an NFT.
   * @function
   * @callback
   * @async
   * @param {INFT} nft - The NFT to be claimed, should be of type INFT.
   */
  const handlePressClaimNFT = useCallback(async(nft: INFT) => {
    if (!isInstalled) {
      openModal(ModalsId.INSTALL_WALLET, {
        action: () => {
          closeModal();
          handleInstall();
        }
      });
    } else if (!active) {
      setNFTState(nft.userNftItemId, NFTState.IS_WAITING_CONNECT);

      void activate(metamask, () => null, true)
        .then(async() => {
          await claimProcess(nft);
        })
        .catch((e) => {
          console.error(e);
          setNFTState(nft.userNftItemId, NFTState.READY_TO_CLAIM);
        });
    } else {
      await claimProcess(nft);
    }
  }, [chainId, active, isInstalled]);

  /**
   * Handles the event when the user presses the button to send an NFT.
   * @function
   * @callback
   * @param {INFT} nft - The NFT to be sent, should be of type INFT.
   */
  const handlePressGetPass = useCallback((nft: INFT) => {
    openModal(ModalsId.GET_NFT_PASS, {
      nft,
      isCompleted: false
    });
  }, []);

  /**
   * Handles the event when the user presses the button to open Metamask.
   * @function
   * @callback
   * @async
   */
  const handlePressOpenMetamask = useCallback(async() => {
    openModal(ModalsId.MINT_ON_MOBILE, {
      action: async() => {
        const loginResponse = await authServices.askMobileToken();
        handleLaunch(loginResponse.token);
      }
    });
  }, []);

  const handlePressOnSearchText = useCallback((nftName: string) => {
    setSearchName(nftName);
    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fetchNFTList(nftName, 0);
    }, 500);

    setTimer(newTimer);

    return () => clearTimeout(newTimer);
  }, [searchName]);

  return (
    <div className={styles.layout}>
      <PageTitle
        text={language.title}
        rightElement={(
        (!isInstalled && isMobile)
          ? (pendingToClaim > 0) && <Alert
            title={language['require-setup'].title}
            description={language['require-setup'].description}
          />
          : (pendingToClaim > 0) && <Alert
            title={language.metaMask.title.replace('{{pendingToClaim}}', pendingToClaim.toString())}
            description={language.metaMask.description}
          />
        )}
      />

      <TextInput
        name={'nftSearch'}
        type={'text'}
        placeholder={language.searchByName}
        value={searchName}
        onChangeText={handlePressOnSearchText}
      />

      {isLoading && pagination.page === 0
        ? <div className={styles.loader}>
          <TailSpin
            width={32}
            height={32}
            color={'#1B2C71'}
          />
        </div> : (nftList && nftList.length > 0 ? (
        <FlatList
          data={nftList}
          isLoading={isLoading}
          onEndReached={() => {
            if (!pagination.isLastPage) {
              fetchNFTList(searchName, pagination.page + 1);
            }
          }}
          className={styles.grid}
          renderItem={(nft) => (
            <NFTCard
              onClick={() => navigate(`/nft/${nft.nftItemId}`)}
              onClaim={handlePressClaimNFT}
              onGetPass={handlePressGetPass}
              onOpenMetamask={handlePressOpenMetamask}
              disabled={!isInstalled && !isMobile}
              isInstalled={isInstalled}
              isMobile={isMobile}
              {...nft}
            />
          )}
        />
        ) : (
            <div className={styles.empty}>
              <EmptyState
                icon={<RiImageLine />}
                text={language['empty-state'].label}
              />
            </div>
        ))}
      {(!isInstalled && !isMobile) && (
        <div className={styles.stickyFooter}>
          <RequireInstallMetamask />
        </div>
      )}
    </div>
  );
}

export default MyNFTs;
