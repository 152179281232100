// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, delay} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setRewards} from '@store/reward/actions';
import {openModal, closeModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';

// Services
import * as rewardServices from '@services/reward';

// Types
import {FetchRewardsAction} from '@store/reward/types';
import {GetRewardsResponse} from '@services/reward/types';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchRewardsProcess()
 * @description Fetching Rewards
 */
function * fetchRewardsProcess({payload: {type, rewardId}}: FetchRewardsAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_REWARDS, true));

    const response: GetRewardsResponse = yield call((type === 'creator' ? rewardServices.getRewardsPreview : rewardServices.getRewards), rewardId);

    if (response.codes) {
      yield call(() => {
        const url = window.URL.createObjectURL(new Blob([response.codes], {type: 'text/plain'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'codes.txt');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } else {
      yield put(setRewards(response.reward));
      yield put(openModal(ModalsId.CLAIM_REWARD, {
        reward: response.reward
      }));
    }

    yield put(setIsLoading(LoadersId.IS_FETCHING_REWARDS, false));
  } catch (e) {
    yield delay(1000);
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_REWARDS, false));
    yield put(closeModal());
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchRewardsProcess;
