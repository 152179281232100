// @ts-nocheck
// Dependencies
import * as Yup from 'yup';

const SendNFTSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address invalid.')
    .required('Email address mandatory.')
});

export default SendNFTSchema;
