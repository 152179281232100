// Dependencies
import React, {useCallback} from 'react';
import {FormikProps, useFormik} from 'formik';

// Types
import {CreateNFTFormProps, FormValues} from './types';

// Hooks
import useForm from '@hooks/useForm';
import useModal from '@hooks/useModal';

// Validation
import CreateNFTValidation from './validations';

// Components
import ImageUpload from '@components/ImageUpload';
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// StyleSheet
import styles from './CreateOrEditNFTForm.module.scss';

import language_es from 'src/locales/es/forms/createOrEditNFTForm.json';
import language_en from 'src/locales/en/forms/createOrEditNFTForm.json';

const initialDefaultValues: FormValues = {
  image: undefined,
  imageTypeId: 0,
  name: '',
  welcomeMessage: '',
  passBackgroundColor: 'rgb(255,255,255)',
  passForegroundColor: 'rgb(0,0,0)',
  passLabelColor: 'rgb(0,0,0)',
  passTypeId: 1
};

function CreateOrEditNFTForm({initialValues = initialDefaultValues, isLoading, onSubmit}: CreateNFTFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
	  initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: CreateNFTValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  const isEditing = (
    (initialValues.welcomeMessage !== initialDefaultValues.welcomeMessage) ||
    (initialValues.image !== initialDefaultValues.image) ||
    (initialValues.imageTypeId !== initialDefaultValues.imageTypeId) ||
    (initialValues.name !== initialDefaultValues.name) ||
    (initialValues.passBackgroundColor !== initialDefaultValues.passBackgroundColor) ||
    (initialValues.passForegroundColor !== initialDefaultValues.passForegroundColor) ||
    (initialValues.passLabelColor !== initialDefaultValues.passLabelColor) ||
    (initialValues.passTypeId !== initialDefaultValues.passTypeId)
  );

  /**
   * Callback function to handle dropping photos into a field.
   *
   * @function
   * @name handleDropPhoto
   * @param {string} field - The name of the field where the photos are dropped.
   * @param {File[]} photos - An array of File objects representing the dropped photos.
   * @returns {void}
   */
  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
        handleChangeField('imageTypeId', photo.type.startsWith('video/') ? 2 : 1);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <ImageUpload
        onDrop={(photos) => handleDropPhoto('image', photos)}
        photo={formik.values.image}
      />
      <div className={styles.field}>
        <TextInput
          name={'name'}
          value={formik.values.name}
          placeholder={language.namePlaceholder}
          onChangeText={(name) => handleChangeField('name', name)}
          error={getErrorFromField('name')}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'welcomeMessage'}
          placeholder={language.welcomeMessagePlaceholder}
          value={formik.values.welcomeMessage}
          textarea={true}
          onChangeText={(welcomeMessage) => handleChangeField('welcomeMessage', welcomeMessage)}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'passBackgroundColor'}
          label={language.passBackgroundColorLabel}
          value={formik.values.passBackgroundColor}
          placeholder={language.passBackgroundColorPlaceholder}
          onChangeText={(name) => handleChangeField('passBackgroundColor', name)}
          error={getErrorFromField('passBackgroundColor')}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'passForegroundColor'}
          label={language.passForegroundColorLabel}
          value={formik.values.passForegroundColor}
          placeholder={language.passForegroundColorPlaceholder}
          onChangeText={(name) => handleChangeField('passForegroundColor', name)}
          error={getErrorFromField('passForegroundColor')}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'passLabelColor'}
          label={language.passLabelColorLabel}
          value={formik.values.passLabelColor}
          placeholder={language.passLabelColorPlaceholder}
          onChangeText={(name) => handleChangeField('passLabelColor', name)}
          error={getErrorFromField('passLabelColor')}
        />
      </div>
      <div className={styles.actions}>
        <Button type={'button'} variant={'outline'} color={'primary'} size={'lg'} onClick={closeModal}>{language.cancelButton}</Button>
        <Button type={'submit'} variant={'solid'} color={'primary'} size={'lg'} isLoading={isLoading}>{isEditing ? language.editNftButton : language.createNftButton}</Button>
      </div>
    </form>
  );
}

export type {FormValues};

export default CreateOrEditNFTForm;
