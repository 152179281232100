// Dependencies
import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';

// StyleSheet
import styles from './ResetPassword.module.scss';

// Components
import Typography from '@components/Typography';
import ResetPasswordForm, {FormValues} from '@forms/ResetPasswordForm';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

import language_es from 'src/locales/es/pages/auth/resetPassword.json';
import language_en from 'src/locales/en/pages/auth/resetPassword.json';

function ResetPassword(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isLoading} = useLoader(LoadersId.RESET_PASSWORD);
  const {resetPassword} = useAuth();
  const {token} = useParams<{token: string}>();

  const handleSetNewPassword = useCallback(({password}: FormValues) => {
    if (token) resetPassword(token, password);
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.head}>
            <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.writeNewPassword}</Typography>
          </div>
          <ResetPasswordForm
            onSubmit={handleSetNewPassword}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
