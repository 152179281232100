// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './Footer.module.scss';

// Components
import Typography from '@components/Typography';

// Assets
import GivitNFTLogo from '@assets/images/logo-alt.svg';
import {RiDiscordLine /*, RiTwitterLine */} from 'react-icons/ri';

// Import languages
import language_es from 'src/locales/es/components/footer.json';
import language_en from 'src/locales/en/components/footer.json';

const links = [
  {
    label: 'Discord',
    icon: <RiDiscordLine />,
    path: 'https://discord.gg/uMBPwjPQ33'
  }
];

function Footer(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  return (
    <footer className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.row}>
            <div className={styles.logo}>
              <GivitNFTLogo />
            </div>
          </div>
          <div className={styles.row}>
            <Typography variant={'text'} component={'p'} size={'md'} weight={'regular'}>{language.contactUs} <a href='mailto:info@givitnft.com'>info@givitnft.com</a></Typography>
            <Typography variant={'text'} component={'p'} size={'md'} weight={'semiBold'}>
              <a className={styles.link} rel="noreferrer" target="_blank" href={'/privacy-policy'}>{language['privacy-link']}</a>
            </Typography>
          </div>
        </div>
        <div className={classNames([styles.column, styles.bottom])}>
          <Typography variant={'text'} size={'md'} weight={'regular'}>{language.copyright.replace('{{age}}', new Date().getFullYear().toString())}</Typography>
          {links && (
            <ul className={styles.links}>
              {links.map((link, index) => (
                <li key={`--link-id-${index.toString()}`} className={styles.link}>
                  <a href={link.path} rel='noreferrer' target='_blank'>{link.icon}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </footer>
  );
}
export default Footer;
