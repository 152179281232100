// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {EditGiftAction} from '@store/gift/types';
import {EditGiftResponse} from '@services/gift/types';
import {LoadersId} from '@type/loaders';
import {ErrorMessage} from '@type/error';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {setGiftData} from '@store/gift/actions';
import {closeModal} from '@store/ui/modal/actions';

// Services
import * as services from '@services/gift';

// Utils
import errorHandler from '@store/errorHandler';

/**
 * Handles the process of editing a gift.
 * @generator
 * @function
 * @param {EditGiftAction} action - The action object with the type and payload.
 * @param {number} action.payload.giftId - The ID of the gift to be edited.
 * @param {Object} action.payload.data - The data to be updated for the gift.
 * @returns {SagaIterator<void>} A saga iterator that handles the editing process of the gift.
 */
function * editGiftProcess({payload: {giftId, data}}: EditGiftAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_REWARD, true));

    // const gift: IGift = yield select(state => selectGiftById(state, giftId));

    const formData = yield call(() => {
      const result = new FormData();

      const contentIsPhoto = data.rewardContent instanceof File;
      if (contentIsPhoto) result.append('rewardContent', data.rewardContent);

      result.append('reward', JSON.stringify({
        description: data.description,
        availableFrom: data.availableFrom,
        availableUntil: data.availableUntil,
        rewardTypeId: data.type?.value,
        ...(!contentIsPhoto && ({
          rewardContent: data.rewardContent
        })),
        notificationTitle: data.notificationTitle,
        notificationDescription: data.notificationDescription
      }));

      return result;
    });

    const response: EditGiftResponse = yield call(services.editGift, giftId, formData);

    if (response) {
      yield put(setGiftData(giftId, response));
      yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_REWARD, false));
      yield put(closeModal());
    }
  } catch (e) {
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_REWARD, false));
  }
}

export default editGiftProcess;
