// Dependencies
import React from 'react';

// Components
import Typography from '@components/Typography';
import Badge from '@components/Badge';
import QuillTextInput from '@components/QuillTextInput';
import Button from '@components/Button';

// Styles
import styles from './CollectionCard.module.scss';

// Types
import {CollectionCardProps} from './types';

// Assets
import {FaUsers} from 'react-icons/fa';
import {RiShareLine} from 'react-icons/ri';

import language_es from 'src/locales/es/components/cards/collectionCard.json';
import language_en from 'src/locales/en/components/cards/collectionCard.json';

const CollectionCard: React.FC<CollectionCardProps> = ({
  name,
  bannerUrl,
  creator,
  onClick,
  onShareClick,
  published,
  description,
  nfts
}) => {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  return (
    <div className={styles.layout}>
      <div className={styles.field}>
        <div className={styles.title}>
          <Typography variant={'display'} size={'xs'} weight={'semiBold'}>{name}</Typography>
        </div>
        <div className={styles.icon}>
          <FaUsers />
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.photo} onClick={onClick}>
          <img src={bannerUrl} width={'100%'} height={'290px'} alt={name} />
        </div>

        {!published ? (
          <div className={styles.badge}>
            <Badge
              color={'blue'}
              variant={'solid'}
              size={'sm'}
              label={'Draft'}
            />
          </div>
        ) : (
          <div className={styles.badge}>
            <Badge
              color={'blue'}
              variant={'outline'}
              size={'sm'}
              label={'New'}
              className={styles.badgeInner}
            />
          </div>
        )}
      </div>
      <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.description}>
        <QuillTextInput
          theme='snow'
          value={description.slice(0, 128) + '...'}
          readOnly={true}
          modules={{toolbar: false}}
        />
      </Typography>
      {creator && (
        <div className={styles.field}>
          <div className={styles.creator}>
            <img src={creator.photoUrl} className={styles.creatorPhoto} alt={creator.name} />
            <div className={styles.row}>
              <Typography variant={'text'} size={'xs'} weight={'regular'} className={styles.label}>{language.creator}</Typography>
              <Typography variant={'text'} size={'xs'} weight={'bold'}>{creator.name}</Typography>
            </div>
          </div>
          {(creator.isCreator && nfts === 1) && (
            <div className={styles.creator}>
              <Button
                size={'md'}
                variant={'solid'}
                onClick={onShareClick}
                iconLeft={(<RiShareLine />)}>
                {''}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollectionCard;
