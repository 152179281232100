// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {nftActionsTypes} from '../types';

// Sagas
import fetchNFTListProcess from './fetchNFTListProcess';
import claimNFTProcess from '@store/nft/sagas/claimNFTProcess';
import sendNFTProcess from '@store/nft/sagas/sendNFTProcess';
import importNFTProcess from '@store/nft/sagas/importNFTProcess';
import sendMultipleNFTsProcess from './sendMultipleNFTsProcess';
import fetchNFTByIdProcess from './fetchNFTByIdProcess';

/**
 * @function nft
 * @generator
 * @description A root saga that watches for specific NFT-related actions and triggers the corresponding processes.
 *
 * @yields {Object} - A series of `takeLatest` effects to handle specific NFT actions.
 *
 * @returns {void} - Returns nothing.
 */
function * nft(): SagaIterator<void> {
  yield takeLatest(nftActionsTypes.FETCH_NFT_LIST, fetchNFTListProcess);
  yield takeLatest(nftActionsTypes.CLAIM_NFT, claimNFTProcess);
  yield takeLatest(nftActionsTypes.SEND_NFT, sendNFTProcess);
  yield takeLatest(nftActionsTypes.IMPORT_NFT, importNFTProcess);
  yield takeLatest(nftActionsTypes.SEND_MULTIPLE_NFTS, sendMultipleNFTsProcess);
  yield takeLatest(nftActionsTypes.FETCH_NFT_BY_ID, fetchNFTByIdProcess);
}

export default nft;
