// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';
import {TailSpin} from 'react-loader-spinner';

// Types
import {ConfirmMessageProps} from './types';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';

// Hooks
import useModal from '@hooks/useModal';

// StyleSheet
import styles from './GiftPublishingModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Assets
import verifiedLottie from '@assets/lotties/verified-lottie.json';

import language_es from 'src/locales/es/modals/giftPublishing.json';
import language_en from 'src/locales/en/modals/giftPublishing.json';

function GiftPublishingModal({
  type,
  action = undefined
}: ConfirmMessageProps): React.ReactElement {
  const {closeModal} = useModal();
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  const handleClick = useCallback(() => {
    closeModal();
    window.location.reload();
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.container}>
        {type === 'completed' ? (
          <>
            <div className={styles.lottie}>
              <Lottie
                animationData={verifiedLottie}
                loop={false}
                autoplay={true}
                width={300}
              />
            </div>
            <div className={styles.text}>
              <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>{language.congratulationsTitle}</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{language.giftReadyMessage}</Typography>
            </div>
          </>
        ) : (
          <>
            <TailSpin
              width={64}
              height={64}
              color={'#1B2C71'}
            />
            <div className={styles.text}>
              <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>{language.publishingGiftTitle}</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{language.waitMessage}</Typography>
            </div>
          </>
        )}
      </div>
      {type === 'completed' && (
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button variant={'solid'} color={'primary'} onClick={handleClick}>
              {language.close}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GiftPublishingModal;
