import {RootState} from '@store/reducer';
import {ISettingsState, SetRouterAction} from './types';

/**
 * Selects the router settings from the state.
 * @param state The root state of the application.
 * @returns The router settings.
 */
export const selectSettingsRouter = (state: RootState): ISettingsState['router'] => state.settings.router;
export type RouterFunc = SetRouterAction['payload']['router'];
