// Dependencies
import * as Yup from 'yup';

const usernameValidation = Yup.string().matches(
  /^[a-zA-Z0-9_.-]+$/,
  'The name is not valid'
);

const usernameValidationTwitter = Yup.string().matches(
  /^[a-zA-Z0-9_.-]+$/,
  'The name is not valid'
);

const EditLinksFormValidation = Yup.object().shape({
  discord: usernameValidation,
  twitter: usernameValidationTwitter,
  openSea: usernameValidation,
  instagram: usernameValidation
});

export default EditLinksFormValidation;
