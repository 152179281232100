// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {collectionActionsTypes} from '../types';

// Sagas
import createNFTProcess from './createNFTProcess';
import createNewCollectionProcess from './createNewCollectionProcess';
import deleteCollectionProcess from './deleteCollectionProcess';
import deleteNFTProcess from './deleteNFTProcess';
import editCollectionProcess from './editCollectionProcess';
import editNFTProcess from './editNFTProcess';
import fetchCollectionByIdProcess from './fetchCollectionByIdProcess';
import fetchCollectionByNameProcess from './fetchCollectionByNameProcess';
import fetchCollectionListProcess from './fetchCollectionListProcess';
import fetchCollectionNFTsListProcess from './fetchCollectionNFTsProcess';
import fetchUserCollectionNFTsProcess from './fetchUserCollectionNFTsProcess';
import publishCollectionProcess from './publishCollectionProcess';
import claimAirdropCollectionNFTProcess from './claimAirdropCollectionNFTProcess';
import claimQuestCollectionNFTProcess from './claimQuestCollectionNFTProcess';
import downloadActivityDataProcess from './downloadActivityDataProcess';

function * collection(): SagaIterator<void> {
  yield takeLatest(collectionActionsTypes.CREATE_NEW_COLLECTION, createNewCollectionProcess);
  yield takeLatest(collectionActionsTypes.CREATE_NFT, createNFTProcess);
  yield takeLatest(collectionActionsTypes.DELETE_COLLECTION, deleteCollectionProcess);
  yield takeLatest(collectionActionsTypes.DELETE_NFT, deleteNFTProcess);
  yield takeLatest(collectionActionsTypes.EDIT_COLLECTION, editCollectionProcess);
  yield takeLatest(collectionActionsTypes.EDIT_NFT, editNFTProcess);
  yield takeLatest(collectionActionsTypes.FETCH_COLLECTION_BY_ID, fetchCollectionByIdProcess);
  yield takeLatest(collectionActionsTypes.FETCH_COLLECTION_BY_NAME, fetchCollectionByNameProcess);
  yield takeLatest(collectionActionsTypes.FETCH_COLLECTION_LIST, fetchCollectionListProcess);
  yield takeLatest(collectionActionsTypes.FETCH_COLLECTION_NFTS_LIST, fetchCollectionNFTsListProcess);
  yield takeLatest(collectionActionsTypes.FETCH_USER_COLLECTION_NFTS_LIST, fetchUserCollectionNFTsProcess);
  yield takeLatest(collectionActionsTypes.PUBLISH_COLLECTION, publishCollectionProcess);
  yield takeLatest(collectionActionsTypes.CLAIM_AIRDROP_COLLECTION_NFT, claimAirdropCollectionNFTProcess);
  yield takeLatest(collectionActionsTypes.CLAIM_QUEST_COLLECTION_NFT, claimQuestCollectionNFTProcess);
  yield takeLatest(collectionActionsTypes.DOWNLOAD_ACTIVITY_DATA, downloadActivityDataProcess);
}

export default collection;
