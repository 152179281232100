// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Services
import * as nftServices from '@services/nft';

// Types
import {SendMultipleNFTsAction} from '@store/nft/types';
import {LoadersId} from '@type/loaders';
import {ModalsId} from '@type/modals';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

// Actions
import {openModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';

function * sendMultipleNFTsProcess({payload: {nft, emails, text}}: SendMultipleNFTsAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_SENDING_MULTIPLE_NFTS, true));
    const response = yield call(nftServices.sendMultipleNFTs, nft.nftId, emails, text);

    if (response) {
      yield put(openModal(ModalsId.SEND_MULTIPLE_NFTS, {
        isComplete: true,
        emails,
        nft
      }));

      yield put(setIsLoading(LoadersId.IS_SENDING_MULTIPLE_NFTS, false));
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_SENDING_MULTIPLE_NFTS, false));
    yield call(() => errorHandler(e as ErrorMessage));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default sendMultipleNFTsProcess;
