// Dependencies
import React, {useCallback} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// Hooks
import useOnBoarding from '@hooks/useOnBoarding';
import useModal, {ModalsId} from '@hooks/useModal';

// StyleSheet
import styles from './RequireInstallMetamask.module.scss';

import language_es from 'src/locales/es/components/requireInstallMetamask.json';
import language_en from 'src/locales/en/components/requireInstallMetamask.json';

function RequireInstallMetamask(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {handleInstall} = useOnBoarding();
  const {openModal, closeModal} = useModal();

  /**
   * Handles the installation of the wallet.
   * Opens a modal for wallet installation and sets up the action to close the modal and install the wallet.
   * @callback
   */
  const handleInstallWallet = useCallback(() => {
    openModal(ModalsId.INSTALL_WALLET, {
      action: () => {
        closeModal();
        handleInstall();
      }
    });
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.text}>
          <Typography variant={'text'} size={'sm'} weight={'semiBold'} className={styles.title}>{language.title}</Typography>
          <Typography variant={'text'} size={'sm'} weight={'regular'} className={styles.description}>{language.description}</Typography>
        </div>
        <div>
          <Button variant={'solid'} color={'primary'} onClick={handleInstallWallet}>{language.action}</Button>
        </div>
      </div>
    </div>
  );
}

export default RequireInstallMetamask;
