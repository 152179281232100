// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {fetchRewards, downloadCSV} from '@store/reward/actions';

// Types
import {FetchRewardsAction, DownloadRewardsCSVAction} from '@store/reward/types';
import {IReward} from '@type/reward';
import {RootState} from '@store/reducer';

// Selectors
import {selectRewardById} from '@store/reward/selectors';

interface IUseRewards {
  reward?: IReward | null;
  csv: string | null;
  fetchRewards: (type: FetchRewardsAction['payload']['type'], id: number) => FetchRewardsAction;
  downloadRewardsCSV: (id: number) => DownloadRewardsCSVAction;
}

function useRewards(id?: number): IUseRewards {
  const dispatch = useDispatch();
  const reward = useSelector((state: RootState) => selectRewardById(state, id));
  const csv = useSelector((state: RootState) => state.reward.csv);

  return {
    reward,
    csv,
    fetchRewards: (type, id) => dispatch(fetchRewards(type, id)),
    downloadRewardsCSV: (id: number) => dispatch(downloadCSV(id))
  };
}

export default useRewards;
