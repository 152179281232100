// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {CheckQuestActionIsCompletedAction} from '@store/quest/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/quest';

// Actions
import {fetchUserQuests} from '@store/quest/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {closeModal} from '@store/ui/modal/actions';
import {createToast} from '@store/ui/toast/actions';
import errorHandler from '@store/errorHandler';

// Types
import {ErrorMessage} from '@type/error';

/**
 * @function checkQuestActionIsCompletedProcess()
 * @description Fetching the quest action types
 */
function * checkQuestActionIsCompletedProcess({payload: {quest}}: CheckQuestActionIsCompletedAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_COMPLETING_QUEST, true));
    const response: boolean = yield call(services.checkQuestActionIsCompleted, quest.id);
    if (response) {
      yield put(fetchUserQuests(quest.collectionId as number)); // Reload all user quests
      yield put(closeModal());
    } else {
      yield put(createToast('error', 'Oops! It seems the quest is still incomplete. Could you please give it another try?'));
    }

    yield put(setIsLoading(LoadersId.IS_COMPLETING_QUEST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_COMPLETING_QUEST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default checkQuestActionIsCompletedProcess;
