// Dependencies
import {ISettingsState, SettingsActions, settingsActionTypes} from './types';

const initialRewardState: ISettingsState = {
  router: undefined
};

function settingsReducer(state: ISettingsState = initialRewardState, action: SettingsActions): ISettingsState {
  switch (action.type) {
    case settingsActionTypes.SET_ROUTER:
      return {
        ...state,
        router: action.payload.router
      };
    default:
      return state;
  }
}

export default settingsReducer;
