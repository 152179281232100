// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchUserQuestsAction} from '@store/quest/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/quest';
import {IUserQuest} from '@type/quest';

// Actions
import {setUserQuests} from '@store/quest/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchUserQuestsProcess()
 * @description Fetching the quest action types
 */
function * fetchUserQuestsProcess({payload: {collectionId}}: FetchUserQuestsAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, true));

    const response: IUserQuest[] = yield call(services.getUserQuests, collectionId);
    if (response) {
      yield put(setUserQuests(
        response
      ));
    }

    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_USER_QUESTS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchUserQuestsProcess;
