// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchQuestsAction} from '@store/quest/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/quest';
import {IQuest} from '@type/quest';

// Actions
import {setQuests} from '@store/quest/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchQuestsByCollectionIdProcess()
 * @description Fetching the quest action types
 */
function * fetchQuestsByCollectionIdProcess({payload: {collectionId}}: FetchQuestsAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_QUESTS, true));

    const response: IQuest[] = yield call(services.getQuests, collectionId);

    if (response) {
      yield put(setQuests(
        response
      ));
    }

    yield put(setIsLoading(LoadersId.FETCH_QUESTS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_QUESTS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchQuestsByCollectionIdProcess;
