// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {FeatureCardProps} from './types';

// Styles
import styles from './FeatureCard.module.scss';

// Components
import Typography from '@components/Typography';

const FeatureCard: React.FC<FeatureCardProps> = ({number, title, text, images = []}): React.ReactElement => (
  <div className={styles.layout}>
    <div className={styles.number}>
      <Typography variant="text" weight="medium">
        {number}
      </Typography>
    </div>
    <Typography variant="text" size="lg" weight="bold" textAlign="center" className={styles.title}>
      {title}
    </Typography>
    <Typography variant="text" size="sm" weight="regular" textAlign="center" className={styles.title}>
      {text}
    </Typography>
    <div className={styles.grid}>
      <img src={images[0]} alt="Image 1" />
      <div className={classNames([styles.grid, styles.column])}>
        <div className={classNames([styles.grid, styles.inline])}>
          <img src={images[1]} alt="Image 2" />
          <img src={images[2]} alt="Image 3" />
        </div>
        <img src={images[3]} alt="Image 4" />
      </div>
    </div>
  </div>
);

export default FeatureCard;
