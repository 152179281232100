// Types
import {
  ClaimNFTAction,
  ImportNFTAction,
  FetchNFTListAction,
  nftActionsTypes,
  SetNFTClaimPendingAction,
  DecreaseNFTsPendingToClaimAction,
  SetNFTListAction,
  SetNFTStateAction,
  SetNFTDataAction,
  SendNFTAction,
  AddNFTAction,
  RemoveNFTAction,
  SetNFTPageAction,
  SetIsNFTLastPageAction,
  SendMultipleNFTsAction,
  FetchNFTByIdAction
} from './types';
import {INFT, NFTState} from '@type/nft';

/**
 * Creates an action to fetch the NFT list.
 * @function
 * @param {number} page - The page number for the NFT list to fetch.
 * @returns {FetchNFTListAction} The action object with the type and payload.
 */
export function fetchNFTList(searchName: string, page: number): FetchNFTListAction {
  return {
    type: nftActionsTypes.FETCH_NFT_LIST,
    payload: {
      searchName,
      page
    }
  };
}

/**
 * Creates an action to set the list of NFTs.
 * @function
 * @param {Object.<string, INFT>} [list] - An optional object representing the list of NFTs, where the keys are strings and the values are of type INFT.
 * @returns {SetNFTListAction} The action object with the type and payload.
 */
export function setNFTList(list?: {[key: string]: INFT}): SetNFTListAction {
  return {
    type: nftActionsTypes.SET_NFT_LIST,
    payload: {
      list
    }
  };
}

/**
 * Creates an action to set the current page of the NFT list.
 * @function
 * @param {number} page - The current page number of the NFT list.
 * @returns {SetNFTPageAction} The action object with the type and payload.
 */
export function setNFTPage(page: number): SetNFTPageAction {
  return {
    type: nftActionsTypes.SET_NFT_PAGE,
    payload: {
      page
    }
  };
}

/**
 * Creates an action to set whether the current page is the last page of the NFT list.
 * @function
 * @param {boolean} isLastPage - Indicates whether the current page is the last page of the NFT list.
 * @returns {SetIsNFTLastPageAction} The action object with the type and payload.
 */
export function setIsNFTLastPage(isLastPage: boolean): SetIsNFTLastPageAction {
  return {
    type: nftActionsTypes.SET_IS_NFT_LAST_PAGE,
    payload: {
      isLastPage
    }
  };
}

/**
 * Creates an action to set the amount of pending NFT claims.
 * @function
 * @param {number} amount - The amount of pending NFT claims.
 * @returns {SetNFTClaimPendingAction} The action object with the type and payload.
 */
export function setNFTPendingToClaim(amount: number): SetNFTClaimPendingAction {
  return {
    type: nftActionsTypes.SET_NFT_CLAIM_PENDING,
    payload: {
      amount
    }
  };
}

export function decreaseNFTsPendingToClaim(): DecreaseNFTsPendingToClaimAction {
  return {
    type: nftActionsTypes.DECREASE_NFTS_PENDING_CLAIM,
    payload: {
    }
  };
}

/**
 * Creates an action to claim an NFT.
 * @function
 * @param {INFT} nft - The NFT to be claimed, should be of type INFT.
 * @returns {ClaimNFTAction} The action object with the type and payload.
 */
export function claimNFT(nft: INFT): ClaimNFTAction {
  return {
    type: nftActionsTypes.CLAIM_NFT,
    payload: {
      nft
    }
  };
}

export function importNFT(chainId: number, collectionAddress: string, nftId: number): ImportNFTAction {
  return {
    type: nftActionsTypes.IMPORT_NFT,
    payload: {
      chainId,
      collectionAddress,
      nftId
    }
  };
}

/**
 * Creates an action to send an NFT.
 * @function
 * @param {INFT} nft - The NFT to be sent, should be of type INFT.
 * @param {string} email - The email address to which the NFT should be sent.
 * @param {string} text - The text to be included with the NFT.
 * @returns {SendNFTAction} The action object with the type and payload.
 */
export function sendNFT(nft: INFT, email: string, text: string): SendNFTAction {
  return {
    type: nftActionsTypes.SEND_NFT,
    payload: {
      nft,
      email,
      text
    }
  };
}

export function sendMultipleNFTs(nft: INFT, emails: string[], text: string): SendMultipleNFTsAction {
  return {
    type: nftActionsTypes.SEND_MULTIPLE_NFTS,
    payload: {
      nft,
      emails,
      text
    }
  };
}

/**
 * Creates an action to set the state of an NFT.
 * @function
 * @param {number} id - The ID of the NFT whose state should be set.
 * @param {NFTState} state - The state to be set for the NFT, should be of type NFTState.
 * @returns {SetNFTStateAction} The action object with the type and payload.
 */
export function setNFTState(id: number, state: NFTState): SetNFTStateAction {
  return {
    type: nftActionsTypes.SET_NFT_STATE,
    payload: {
      id,
      state
    }
  };
}

export function addNFT(userNFT: INFT): AddNFTAction {
  return {
    type: nftActionsTypes.ADD_NFT,
    payload: {
      userNFT
    }
  };
}

/**
 * Creates an action to remove an NFT.
 * @function
 * @param {number} id - The ID of the NFT to be removed.
 * @returns {RemoveNFTAction} The action object with the type and payload.
 */
export function removeNFT(id: number): RemoveNFTAction {
  return {
    type: nftActionsTypes.REMOVE_NFT,
    payload: {
      id
    }
  };
}

/**
 * Creates an action to set the data of an NFT.
 * @function
 * @param {number} id - The ID of the NFT whose data should be set.
 * @param {Partial<INFT>} data - The data to be set for the NFT, should be a partial object of type INFT.
 * @returns {SetNFTDataAction} The action object with the type and payload.
 */
export function setNFTData(id: number, data: Partial<INFT>): SetNFTDataAction {
  return {
    type: nftActionsTypes.SET_NFT_DATA,
    payload: {
      id,
      data
    }
  };
}

/**
 * Creates an action to fetch an NFT by its ID.
 * @function
 * @param {INFT['nftId']} id - The ID of the NFT to be fetched.
 * @returns {FetchNFTByIdAction} The action object with the type and payload.
 */
export function fetchNFTById(id: INFT['nftId']): FetchNFTByIdAction {
  return {
    type: nftActionsTypes.FETCH_NFT_BY_ID,
    payload: {
      id
    }
  };
}
