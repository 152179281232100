// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setPassState} from '@store/pass/actions';

// Services
import * as passServices from '@services/pass';

// Types
import {GetPassByQrCodePayload} from '@services/pass/types';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {FetchPassByQrCodeAction} from '@store/pass/types';

function * fetchPassByQrCodeProcess({payload: {qrCode}}: FetchPassByQrCodeAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_PASS, true));

    const pass: GetPassByQrCodePayload['response'] = yield call(passServices.getPassByQrCode, qrCode);

    yield put(setPassState(pass));

    yield put(setIsLoading(LoadersId.IS_FETCHING_PASS, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_PASS, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchPassByQrCodeProcess;
