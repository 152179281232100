// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {LoginAction} from '../types';
import {LoadersId} from '@type/loaders';
import {GetMeResponse} from '@services/auth/types';
import {ErrorMessage} from '@type/error';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {resetApp} from '@store/settings/actions';

// Services
import * as authServices from '@services/auth';
import {setAuthUser} from '@store/auth/actions';

// Utils
import errorHandlerLogin from '@store/errorHandlerLogin';

/**
 * @function login
 * @description Account authentication and validation flow.
 */
function * logInProcess({payload: {email, password, redirectTo}}: LoginAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_LOGIN, true));

    yield call(authServices.logIn, {email, password});

    const response: GetMeResponse = yield call(authServices.getMe);
    if (response) {
      yield put(resetApp());
      yield put(setAuthUser(response));
    }

    yield call(() => {
      window.location.href = redirectTo ?? '/';
    });

    yield put(setIsLoading(LoadersId.IS_LOGIN, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_LOGIN, false));
    yield call(() => errorHandlerLogin(e as ErrorMessage));
  }
}

export default logInProcess;
