// Action Types
import {ICollection} from '@type/collection';
import {INFTCollection} from '@type/nftCollection';
import {CreateNewCollectionResponse} from '@services/collection/types';
import {INFT} from '@type/nft';
import {NFTItemPhoto} from '@forms/NewCollectionForm/types';

export enum collectionActionsTypes {
  CREATE_NEW_COLLECTION = 'CREATE_NEW_COLLECTION',
  CREATE_NFT = 'CREATE_NFT',
  DELETE_COLLECTION = 'DELETE_COLLECTION',
  DELETE_NFT = 'DELETE_NFT',
  EDIT_COLLECTION = 'EDIT_COLLECTION',
  EDIT_NFT = 'EDIT_NFT',
  FETCH_COLLECTION_BY_ID = 'FETCH_COLLECTION_BY_ID',
  FETCH_COLLECTION_BY_NAME = 'FETCH_COLLECTION_BY_NAME',
  FETCH_COLLECTION_LIST = 'FETCH_COLLECTION_LIST',
  FETCH_COLLECTION_NFTS_LIST = 'FETCH_COLLECTION_NFTS_LIST',
  FETCH_USER_COLLECTION_NFTS_LIST = 'FETCH_USER_COLLECTION_NFTS_LIST',
  PUBLISH_COLLECTION = 'PUBLISH_COLLECTION',
  SET_COLLECTION_DATA = 'SET_COLLECTION_DATA',
  SET_COLLECTION_LIST = 'SET_COLLECTION_LIST',
  SET_COLLECTION_NFTS_LIST = 'SET_COLLECTION_NFTS_LIST',
  SET_USER_COLLECTION_NFTS_LIST = 'SET_USER_COLLECTION_NFTS_LIST',
  SET_NEW_COLLECTION = 'SET_NEW_COLLECTION',
  SET_NFT_DATA_FROM_MY_COLLECTION = 'SET_NFT_DATA_FROM_MY_COLLECTION',
  SET_NFT_TO_MY_COLLECTION = 'SET_NFT_TO_MY_COLLECTION',
  UNSET_NFT_FROM_MY_COLLECTION = 'UNSET_NFT_FROM_MY_COLLECTION',
  CLAIM_AIRDROP_COLLECTION_NFT = 'CLAIM_AIRDROP_COLLECTION_NFT',
  CLAIM_QUEST_COLLECTION_NFT = 'CLAIM_QUEST_COLLECTION_NFT',
  DOWNLOAD_ACTIVITY_DATA = 'DOWNLOAD_ACTIVITY_DATA',
  SET_COLLECTION_CONTRACT = 'SET_COLLECTION_CONTRACT'
}

// State Management
export interface ICollectionState {
  list?: {[key: string]: ICollection};
  myCollections?: {[key: string]: ICollection};
  nftList?: {[key: string]: INFTCollection};
  userNftIds: number[];
}

/**
 * ======================
 *    Fetch CollectionDetails List
 * ====================== */
export interface FetchCollectionListAction {
  type: typeof collectionActionsTypes.FETCH_COLLECTION_LIST;
}

/**
 * ======================
 *    Set CollectionDetails List
 * ====================== */
export interface SetCollectionListAction {
  type: typeof collectionActionsTypes.SET_COLLECTION_LIST;
  payload: {
    list?: {[key: string]: ICollection}
  }
}

/**
 * ======================
 *    Fetch CollectionDetails List
 * ====================== */
export interface FetchCollectionNFTsListAction {
  type: typeof collectionActionsTypes.FETCH_COLLECTION_NFTS_LIST;
  payload: {
    collectionId: number
  }
}

export interface FetchUserCollectionNFTsListAction {
  type: typeof collectionActionsTypes.FETCH_USER_COLLECTION_NFTS_LIST;
  payload: {
    collectionId: number
  }
}

/**
 * ======================
 *    Set CollectionDetails List
 * ====================== */
export interface SetCollectionNFTsListAction {
  type: typeof collectionActionsTypes.SET_COLLECTION_NFTS_LIST;
  payload: {
    list?: {[key: string]: INFTCollection}
  }
}

export interface SetUserCollectionNFTsListAction {
  type: typeof collectionActionsTypes.SET_USER_COLLECTION_NFTS_LIST;
  payload: {
    list: number[]
  }
}

/**
 * ======================
 * Fetch Collection By Id
 * ====================== */
export interface FetchCollectionByIdAction {
  type: typeof collectionActionsTypes.FETCH_COLLECTION_BY_ID;
  payload: {
    id: number;
  }
}

export interface FetchCollectionByNameAction {
  type: typeof collectionActionsTypes.FETCH_COLLECTION_BY_NAME;
  payload: {
    name: string;
  }
}

/**
 * ======================
 *  Create new Collection
 * ====================== */
export interface CreateNewCollectionActionProps {
  chainId: number;
  avatar: File | string | undefined;
  banner: File;
  name: string;
  description: string;
  isPublic?: boolean;
  givitUrl: string;
  instagramUrl: string;
  openSeaUrl: string;
  url: string;
  twitter: string;
  discord: string;
  quests: boolean;
}

export interface CreateNewCollectionAction {
  type: typeof collectionActionsTypes.CREATE_NEW_COLLECTION;
  payload: CreateNewCollectionActionProps;
}

/**
 * ======================
 *  Edit Collection
 * ====================== */

/**
 * @interface EditCollectionActionProps
 * @description Interface to complement the prop types of edit collection action.
 */
export interface EditCollectionActionProps {
  collectionId: number;
  data: {
    chainId?: number;
    avatar?: NFTItemPhoto | string;
    banner?: NFTItemPhoto | string;
    name?: string;
    description?: string;
    public?: boolean;
    url?: string;
    discord?: string;
    twitter?: string;
    openSeaUrl?: string;
    instagramUrl?: string;
    givitUrl?: string;
    calendy?: string;
    quests?: number;
  }
}

/**
 * @interface EditCollectionAction
 * @description Dispatch an action to call the edit collection process.
 */
export interface EditCollectionAction {
  type: typeof collectionActionsTypes.EDIT_COLLECTION;
  payload: EditCollectionActionProps;
}

/**
 * @interface SetNewCollectionAction
 * @description Dispatch an action to set new collection on the store.
 */
export interface SetNewCollectionAction {
  type: typeof collectionActionsTypes.SET_NEW_COLLECTION;
  payload: CreateNewCollectionResponse;
}

/**
 * ======================
 *    Create new NFT
 * ====================== */
export interface CreateNFTActionProps {
  collectionId: number;
  nft: {
    name: string;
    welcomeMessage: string;
    passBackgroundColor: string;
    passForegroundColor: string;
    passLabelColor: string;
    passTypeId: number;
    photo: File;
    imageTypeId: number;
  }
}

export interface CreateNFTAction {
  type: typeof collectionActionsTypes.CREATE_NFT;
  payload: CreateNFTActionProps;
}

/**
 * ======================
 *    Edit NFT
 * ====================== */

export interface EditNFTAction {
  type: typeof collectionActionsTypes.EDIT_NFT;
  payload: EditNFTActionProps;
}

export interface EditNFTActionProps {
  collectionId: number;
  nft: {
    id: number;
    name: string;
    welcomeMessage: string;
    passBackgroundColor: string;
    passForegroundColor: string;
    passLabelColor: string;
    passTypeId: number;
    photo?: NFTItemPhoto | string;
    imageTypeId: number;
  }
}

export interface SetNFTToMyCollectionAction {
  type: typeof collectionActionsTypes.SET_NFT_TO_MY_COLLECTION;
  payload: {
    collectionId: number;
    nft: INFT;
  };
}

/**
 * ================
 *   Delete NFT
 * ================
 */
export interface DeleteNFTAction {
  type: typeof collectionActionsTypes.DELETE_NFT,
  payload: {
    collectionId: number;
    nftId: number;
  }
}

export interface UnSetNFTFromMyCollectionAction {
  type: typeof collectionActionsTypes.UNSET_NFT_FROM_MY_COLLECTION;
  payload: {
    collectionId: number;
    nftId: number;
  };
}

/**
 * ====================================
 *  Set data of NFT from My Collections.
 * ==================================== */
export interface SetNFTDataFromMyCollectionAction {
  type: typeof collectionActionsTypes.SET_NFT_DATA_FROM_MY_COLLECTION;
  payload: {
    collectionId: number;
    nft: INFT;
  }
}

export interface SetCollectionDataAction {
  type: typeof collectionActionsTypes.SET_COLLECTION_DATA;
  payload: {
    collectionId: number;
    data: ICollection;
  };
}

/**
 * @interface DeleteCollectionAction
 * @description Delete a specific collection of the creator.
 */
export interface DeleteCollectionAction {
  type: typeof collectionActionsTypes.DELETE_COLLECTION;
  payload: {
    collectionId: number;
  }
}

/**
 * @interface PublishCollectionAction
 * @description Publish a specific collection of the creator.
 */
export interface PublishCollectionAction {
  type: typeof collectionActionsTypes.PUBLISH_COLLECTION;
  payload: {
    collectionId: number;
  }
}

export interface ClaimAirdropCollectionNFTAction {
  type: typeof collectionActionsTypes.CLAIM_AIRDROP_COLLECTION_NFT;
  payload: {
    nftId: number;
    nftUrl: string;
  };
}

export interface ClaimQuestCollectionNFTAction {
  type: typeof collectionActionsTypes.CLAIM_QUEST_COLLECTION_NFT;
  payload: {
    nftId: number;
    nftUrl: string;
  };
}

export interface DownloadActivityDataAction {
  type: typeof collectionActionsTypes.DOWNLOAD_ACTIVITY_DATA;
  payload: {
    collectionId: number;
  }
}

export interface SetCollectionContractAction {
  type: collectionActionsTypes.SET_COLLECTION_CONTRACT,
  payload: {
    collectionId: number,
    contract: string
  }
}

export type CollectionActions =
  CreateNFTAction |
  CreateNewCollectionAction |
  DeleteCollectionAction |
  DeleteNFTAction |
  EditCollectionAction |
  EditNFTAction |
  FetchCollectionByIdAction |
  FetchCollectionByNameAction |
  FetchCollectionListAction |
  FetchCollectionNFTsListAction |
  PublishCollectionAction |
  SetCollectionDataAction |
  SetCollectionListAction |
  SetUserCollectionNFTsListAction |
  SetCollectionNFTsListAction |
  SetNFTDataFromMyCollectionAction |
  SetNFTToMyCollectionAction |
  SetNewCollectionAction |
  UnSetNFTFromMyCollectionAction |
  ClaimAirdropCollectionNFTAction |
  ClaimQuestCollectionNFTAction |
  DownloadActivityDataAction |
  SetCollectionContractAction;
