// Dependencies
import {IQuestState, QuestActions, questActionsTypes} from './types';

const initialQuestState: IQuestState = {
  quests: [],
  questTypes: [],
  questActionTypes: [],
  userQuests: [],
  twitterAuth: {
    codeVerifier: '',
    state: '',
    url: ''
  }
};

function questReducer(state: IQuestState = initialQuestState, action: QuestActions): IQuestState {
  switch (action.type) {
    case questActionsTypes.SET_QUEST_TYPES:
      return {
        ...state,
        questTypes: action.payload.questTypes,
        questActionTypes: action.payload.questActionTypes
      };

    case questActionsTypes.SET_QUESTS:
      return {
        ...state,
        quests: action.payload.quests
      };

    case questActionsTypes.SET_QUEST:
      return {
        ...state,
        quests: [...state.quests, action.payload.quest]
      };

    case questActionsTypes.UNSET_QUEST:
      return {
        ...state,
        quests: state.quests?.filter(quest => quest.id !== action.payload.id)
      };

    case questActionsTypes.SET_UPDATE_QUEST:
      return {
        ...state
        // quests: action.payload.quest
      };

    case questActionsTypes.SET_USER_QUESTS:
      return {
        ...state,
        userQuests: action.payload.quests
      };

    case questActionsTypes.SET_TWITTER_AUTH_DATA:
      return {
        ...state,
        twitterAuth: {
          codeVerifier: action.payload.codeVerifier,
          state: action.payload.state,
          url: action.payload.url
        }
      };

    default:
      return state;
  }
}

export default questReducer;
