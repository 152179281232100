// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {giftActionsTypes} from '../types';

// Sagas
import fetchGiftListProcess from './fetchGiftListProcess';
import fetchGiftByIdProcess from './fetchGiftByIdProcess';
import createGiftProcess from './createNewGiftProcess';
import editGiftProcess from './editGiftProcess';
import deleteGiftProcess from './deleteGiftProcess';
import publishGiftProcess from './publishGiftProcess';
import fetchGiftListByCollectionIdProcess from './fetchGiftListByCollectionIdProcess';

/**
 * Handles the gift-related saga effects.
 * @generator
 * @function
 * @returns {SagaIterator<void>} A saga iterator that handles the gift-related saga effects.
 */
function * gift(): SagaIterator<void> {
  yield takeLatest(giftActionsTypes.FETCH_GIFT_LIST, fetchGiftListProcess);
  yield takeLatest(giftActionsTypes.FETCH_GIFT_BY_ID, fetchGiftByIdProcess);
  yield takeLatest(giftActionsTypes.CREATE_GIFT, createGiftProcess);
  yield takeLatest(giftActionsTypes.EDIT_GIFT, editGiftProcess);
  yield takeLatest(giftActionsTypes.DELETE_GIFT, deleteGiftProcess);
  yield takeLatest(giftActionsTypes.PUBLISH_GIFT, publishGiftProcess);
  yield takeLatest(giftActionsTypes.FETCH_GIFT_LIST_BY_COLLECTION_ID, fetchGiftListByCollectionIdProcess);
}

export default gift;
