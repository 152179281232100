// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Services
import * as nftServices from '@services/nft';

// Types
import {SendNFTAction} from '@store/nft/types';
import {LoadersId} from '@type/loaders';
import {ModalsId} from '@type/modals';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

// Actions
import {setNFTPendingToClaim} from '@store/nft/actions';
import {openModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';

/**
 * @function sendNFTProcess
 * @generator
 * @description This saga handles the process of sending an NFT to a specified email address along with a message.
 * It handles the call to the API for sending the NFT, updating the pending NFT count, removing the NFT from the list, and showing the modal confirmation.
 *
 * @param {SendNFTAction} action - The dispatched action with the NFT to send, the recipient's email, and the message.
 *
 * @yields {Object} - A series of effects to handle the NFT sending process.
 *
 * @returns {void} - Returns nothing.
 */
function * sendNFTProcess({payload: {nft, email, text}}: SendNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_SENDING_NFT, true));
    const response = yield call(nftServices.sendNFT, nft.id, email, text);

    if (response) {
      const amount: number = yield call(nftServices.getPendingNFTs);
      yield put(setNFTPendingToClaim(amount));

      yield put(openModal(ModalsId.SEND_NFT, {
        isComplete: true,
        email,
        nft
      }));

      yield put(setIsLoading(LoadersId.IS_SENDING_NFT, false));
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_SENDING_NFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default sendNFTProcess;
