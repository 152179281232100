// Types
import {LoadersId} from '@type/loaders';

export enum LoaderActions {
  SET_IS_LOADING = 'SET_IS_LOADING',
}

export type ILoaderState = {
  [id in LoadersId]: boolean;
};

export interface SetIsLoadingAction {
  type: LoaderActions.SET_IS_LOADING;
  payload: {
    id: LoadersId;
    isLoading: boolean;
  }
}

export type LoadersActionsTypes = SetIsLoadingAction;
