// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {EditLinksModalProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './EditLinksModal.module.scss';

// Components
import Typography from '@components/Typography';
import EditLinksForm from '@forms/EditLinksForm';

// Hooks
import useModal from '@hooks/useModal';

import language_es from 'src/locales/es/modals/editLinks.json';
import language_en from 'src/locales/en/modals/editLinks.json';

function EditLinksModal({initialValues, onSave}: EditLinksModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.header}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{language.headerTitle}</Typography>
        <Typography variant={'text'} size={'sm'}>{language.headerSubtitle}</Typography>
      </div>
      <EditLinksForm
        initialValues={initialValues}
        onSubmit={onSave}
        onCancel={closeModal}
      />
    </div>
  );
}

export default EditLinksModal;
