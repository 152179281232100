// Dependencies
import {IRewardState, RewardActions, rewardActionsTypes} from './types';

const initialRewardState: IRewardState = {
  rewards: [],
  csv: null
};

function rewardReducer(state: IRewardState = initialRewardState, action: RewardActions): IRewardState {
  switch (action.type) {
    case rewardActionsTypes.SET_REWARDS:
      return {
        ...state,
        rewards: [...state.rewards, action.payload.reward]
      };

    case rewardActionsTypes.SET_REWARDS_CSV:
      return {
        ...state,
        csv: action.payload.csv
      };

    default:
      return state;
  }
}

export default rewardReducer;
