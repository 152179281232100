export enum licenseActionTypes {
  BUY_LICENSE = 'BUY_LICENSE'
}

// State Management
export interface ILicenseState {
}

/**
 * ======================
 *    Fetch Event List
 * ====================== */
export interface BuyLicenseAction {
  type: typeof licenseActionTypes.BUY_LICENSE;
  payload: {
    collectionId: number;
    description: string;
  }
}

export type LicenseActions =
  BuyLicenseAction;
