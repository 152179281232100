// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Styles
import styles from './CollectionNFTCard.module.scss';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal, {ModalsId} from '@hooks/useModal';

// Types
import {CollectionNFTCardProps} from './types';

// Assets
import {RiSendPlane2Line, RiMailSendLine, RiShareLine} from 'react-icons/ri';

import language_es from 'src/locales/es/components/cards/collectionNFTCard.json';
import language_en from 'src/locales/en/components/cards/collectionNFTCard.json';

function CollectionNFTCard({user, isCreator, isOpen, isClaimed, isMobile, collection, onLogin, onFreeClaim, onQuestClaim, ...collectionNft}: CollectionNFTCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_NFT);
  const {openModal, closeModal} = useModal();

  const handlePressFreeClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (user) {
      openModal(ModalsId.CONFIRM_CLAIM_AIRDROP_NFT, {
        nft: collectionNft,
        action: () => {
          closeModal();
          onFreeClaim(collectionNft);
        }
      });
    } else {
      onLogin();
    }
  }, [collectionNft]);

  /*
  const handlePressQuestClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.CONFIRM_CLAIM_AIRDROP_NFT, {
      nft: collectionNft,
      action: () => {
        closeModal();
        onQuestClaim(collectionNft);
      }
    });
  }, [collectionNft]);
  */

  const handlePressSendPass = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.SEND_NFT, {
      nft: collectionNft
    });
  }, [collectionNft]);

  const handlePressSendMultiplePasses = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.SEND_MULTIPLE_NFTS, {
      nft: collectionNft,
      action: () => {
        closeModal();
      }
    });
  }, [collectionNft]);

  const handleClickShare = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.SHARE_SHOW_QR_CODE, {
      nft: collectionNft,
      collection,
      action: () => {
        const textarea = document.createElement('textarea');
        textarea.value = window.location.href;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, textarea.value.length);
        document.execCommand('copy');
        document.body.removeChild(textarea);

        closeModal();
      }
    });
  }, []);

  return (
    <div className={styles.layout}>
      {/* Title */}
      <div className={styles.field}>
        <div className={styles.title}>
          <Typography variant={'display'} size={'xs'} weight={'bold'}>{collectionNft.name}</Typography>
        </div>
      </div>

      {/* Image/Video */}
      <div className={styles.field}>
        <NFTViewer
          onClick={() => {}}
          isMobile={isMobile}
          mediaTypeId={collectionNft?.imageTypeId}
          mediaSrc={collectionNft?.image}
        />
      </div>

      {/* Description */}
      <div className={classNames([styles.field, styles.descriptionRow])}>
        <Typography component={'p'} className={styles.description} variant={'text'} size={'xs'} weight={'regular'}>{collectionNft.description}</Typography>
      </div>

      {/* Actions */}
      {isCreator ? (
        <div className={styles.field}>
          <Button
            className={styles.claimNFTButton}
            onClick={handlePressSendPass}
            iconLeft={(<RiSendPlane2Line />)}>
            {''}
          </Button>
          <Button
            className={styles.claimNFTButton}
            onClick={handlePressSendMultiplePasses}
            iconLeft={(<RiMailSendLine />)}>
            {''}
          </Button>
          <Button
            size={'md'}
            variant={'solid'}
            onClick={handleClickShare}
            iconLeft={(<RiShareLine />)}>
            {''}
          </Button>
        </div>
      ) : (!isClaimed && (
        <div className={styles.field}>
          {(isOpen) && (
            <Button
              className={styles.claimNFTButton}
              onClick={handlePressFreeClaimButton}
              isLoading={isLoading}
              showTextOnLoading={true}>
              {user ? language.claimForFree : language.signInToClaim}
            </Button>
          )}

          {/*
          {(collectionNft.questCopies > 0) && (
            <Button
              className={styles.claimNFTButton}
              onClick={handlePressQuestClaimButton}
              isLoading={isLoading}
              disabled={!canClaim}
              showTextOnLoading={true}>
              {canClaim ? language.claimForFree : language.completeAllQuestsToClaim}
            </Button>
          )}
          */}
        </div>
      )
      )}
    </div>
  );
}
export default CollectionNFTCard;
