// Dependencies
import React from 'react';
import Button from '@components/Button';

function TestComponents(): React.ReactElement {
  return (
    <div style={{display: 'flex', flexDirection: 'column', padding: '6em 3em', gap: '12px'}}>
      <h2>Solid</h2>
      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'solid'} color={'primary'} size={'sm'}>Button CTA</Button>
        <Button variant={'solid'} color={'primary'} size={'md'}>Button CTA</Button>
        <Button variant={'solid'} color={'primary'} size={'lg'}>Button CTA</Button>
        <Button variant={'solid'} color={'primary'} size={'xl'}>Button CTA</Button>
        <Button variant={'solid'} color={'primary'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'solid'} color={'secondary'} size={'sm'}>Button CTA</Button>
        <Button variant={'solid'} color={'secondary'} size={'md'}>Button CTA</Button>
        <Button variant={'solid'} color={'secondary'} size={'lg'}>Button CTA</Button>
        <Button variant={'solid'} color={'secondary'} size={'xl'}>Button CTA</Button>
        <Button variant={'solid'} color={'secondary'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'solid'} color={'tertiary'} size={'sm'}>Button CTA</Button>
        <Button variant={'solid'} color={'tertiary'} size={'md'}>Button CTA</Button>
        <Button variant={'solid'} color={'tertiary'} size={'lg'}>Button CTA</Button>
        <Button variant={'solid'} color={'tertiary'} size={'xl'}>Button CTA</Button>
        <Button variant={'solid'} color={'tertiary'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'solid'} color={'error'} size={'sm'}>Button CTA</Button>
        <Button variant={'solid'} color={'error'} size={'md'}>Button CTA</Button>
        <Button variant={'solid'} color={'error'} size={'lg'}>Button CTA</Button>
        <Button variant={'solid'} color={'error'} size={'xl'}>Button CTA</Button>
        <Button variant={'solid'} color={'error'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'outline'} color={'error'} size={'sm'}>Button CTA</Button>
        <Button variant={'outline'} color={'error'} size={'md'}>Button CTA</Button>
        <Button variant={'outline'} color={'error'} size={'lg'}>Button CTA</Button>
        <Button variant={'outline'} color={'error'} size={'xl'}>Button CTA</Button>
        <Button variant={'outline'} color={'error'} size={'xxl'}>Button CTA</Button>
      </div>

      {/**
       Secondary
       ===================================================
       **/}
      <h2>Ghost</h2>
      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'ghost'} color={'primary'} size={'sm'}>Button CTA</Button>
        <Button variant={'ghost'} color={'primary'} size={'md'}>Button CTA</Button>
        <Button variant={'ghost'} color={'primary'} size={'lg'}>Button CTA</Button>
        <Button variant={'ghost'} color={'primary'} size={'xl'}>Button CTA</Button>
        <Button variant={'ghost'} color={'primary'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'ghost'} color={'secondary'} size={'sm'}>Button CTA</Button>
        <Button variant={'ghost'} color={'secondary'} size={'md'}>Button CTA</Button>
        <Button variant={'ghost'} color={'secondary'} size={'lg'}>Button CTA</Button>
        <Button variant={'ghost'} color={'secondary'} size={'xl'}>Button CTA</Button>
        <Button variant={'ghost'} color={'secondary'} size={'xxl'}>Button CTA</Button>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', padding: '22px', gap: '12px'}}>
        <Button variant={'ghost'} color={'error'} size={'sm'}>Button CTA</Button>
        <Button variant={'ghost'} color={'error'} size={'md'}>Button CTA</Button>
        <Button variant={'ghost'} color={'error'} size={'lg'}>Button CTA</Button>
        <Button variant={'ghost'} color={'error'} size={'xl'}>Button CTA</Button>
        <Button variant={'ghost'} color={'error'} size={'xxl'}>Button CTA</Button>
      </div>

    </div>
  );
}

export default TestComponents;
