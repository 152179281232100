// Dependencies
import React, {useEffect} from 'react';
import ScrollToTop from '@navigator/partials/ScrollTopRouter';

// Components
import Header from '@components/navigator/Header';
import UIManager from '@managers/UIManager';
import Footer from '@components/navigator/Footer';

// Types
import {AppLayoutProps} from './types';

// StyleSheet
import styles from './AppLayout.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Service
// import * as NavigatorService from '@navigator/service';

function AppLayout({children}: AppLayoutProps): React.ReactElement {
  const {fetchAuth} = useAuth();
  const {isLoading, setLoading} = useLoader(LoadersId.IS_FETCHING_AUTH);

  useEffect(() => {
    if (location.pathname !== '/login') {
      if (location.pathname.includes('/collection/') ||
         (location.pathname === '/') ||
         (location.pathname.includes('/resetpassword/')) ||
         (location.pathname.includes('/use-terms')) ||
         (location.pathname.includes('/privacy-policy'))) {
        fetchAuth(false);
      } else {
        fetchAuth(true);
      }
    } else {
      setLoading(LoadersId.IS_FETCHING_AUTH, false);
    }
  }, []);

  return (
    <UIManager>
      <Header />
      <ScrollToTop />
      <main className={styles.view}>
        {!isLoading && children}
      </main>
      <Footer />
    </UIManager>
  );
}

export default AppLayout;
