// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {authActionsTypes} from '../types';

// Sagas
import logInProcess from './logInProcess';
import fetchAuthProcess from './fetchAuthProcess';
import signUpProcess from './signUpProcess';
import logOutProcess from './logOutProcess';
import verifyAccountProcess from './verifyAccountProcess';
import resendVerifyEmailProcess from '@store/auth/sagas/resendVerifyEmailProcess';
import mobileLoginProcess from '@store/auth/sagas/mobileLoginProcess';
import requestResetPasswordProcess from '@store/auth/sagas/requestResetPasswordProcess';
import resetPasswordProcess from '@store/auth/sagas/resetPasswordProcess';
import editUserProcess from './editUserProcess';

function * auth(): SagaIterator<void> {
  yield takeLatest(authActionsTypes.LOGIN, logInProcess);
  yield takeLatest(authActionsTypes.FETCH_AUTH, fetchAuthProcess);
  yield takeLatest(authActionsTypes.SIGNUP, signUpProcess);
  yield takeLatest(authActionsTypes.LOGOUT, logOutProcess);
  yield takeLatest(authActionsTypes.VERIFY_ACCOUNT, verifyAccountProcess);
  yield takeLatest(authActionsTypes.RESEND_VERIFY_EMAIL, resendVerifyEmailProcess);
  yield takeLatest(authActionsTypes.MOBILE_LOGIN, mobileLoginProcess);
  yield takeLatest(authActionsTypes.REQUEST_RESET_PASSWORD, requestResetPasswordProcess);
  yield takeLatest(authActionsTypes.RESET_PASSWORD, resetPasswordProcess);
  yield takeLatest(authActionsTypes.EDIT_USER, editUserProcess);
}

export default auth;
