import {put} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Actions
import {createToast} from '@store/ui/toast/actions';
import {logOut} from './auth/actions';

// Types
import {ErrorMessage} from '@type/error';

function * errorHandler(error: ErrorMessage, fromLogin: Boolean = false): SagaIterator<void> {
  const {message, code} = error;

  if (fromLogin === false) {
    if (code === 401) {
      yield put(logOut('/login'));
    } else {
      if (process.env.REACT_APP_NODE_ENV === 'development') yield put(createToast('error', message));
      else error.code === 501 ? yield put(createToast('error', message)) : yield put(createToast('error', 'There was an error. If it persist, please, contact to support.'));
    }
  }
}

export default errorHandler;
