// Dependencies
import {useEffect, useState} from 'react';
import platform from 'platform';

type DeviceType = 'apple' | 'google' | 'desktop' | 'unknown';

interface IUseDeviceType {
  deviceType: DeviceType;
  isAppleDevice: boolean;
  isGoogleDevice: boolean;
  isDesktopDevice: boolean;
}

/**
 * @function useDeviceType():
 * @description Detects the type of device the app is running on using platform.js.
 */
function useDeviceType(): IUseDeviceType {
  const [deviceType, setDeviceType] = useState<DeviceType>('unknown');

  useEffect(() => {
    const getDeviceType = (): DeviceType => {
      const osFamily = platform.os?.family ?? '';
      const manufacturer = platform.manufacturer ?? '';
      const product = platform.product ?? '';

      // Check for Apple devices
      if (
        /iPad|iPhone|iPod|Mac OS X/i.test(osFamily) ||
        /iPad|iPhone|iPod/i.test(product) ||
        /Apple/i.test(manufacturer)
      ) {
        return 'apple';
      }

      // Check for Google devices (Android)
      if (/Android/i.test(osFamily) || /Android/i.test(product) || /Google/i.test(manufacturer)) {
        return 'google';
      }

      // For desktop devices
      if (/Windows|Linux|Mac OS|Ubuntu|Debian|Fedora/i.test(osFamily)) {
        return 'desktop';
      }

      return 'unknown';
    };

    const detectedDeviceType = getDeviceType();
    setDeviceType(detectedDeviceType);
  }, []);

  return {
    deviceType,
    isAppleDevice: deviceType === 'apple',
    isGoogleDevice: deviceType === 'google',
    isDesktopDevice: deviceType === 'desktop'
  };
}

export default useDeviceType;
