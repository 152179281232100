// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setCollectionList} from '@store/collection/actions';

// Services
import * as collectionServices from '@services/collection';

// Types
import {GetCollectionListResponse} from '@services/collection/types';
import {normalizeState} from '@utils/state';
import {ICollection} from '@type/collection';

// ErrroHandler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchCollectionListProcess()
 * @description Fetching Collection List
 */
function * fetchCollectionListProcess(): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_LIST, true));

    const response: GetCollectionListResponse = yield call(collectionServices.getCollectionList);
    const normalizedItems: {[key: string]: ICollection} = normalizeState(
      response.records?.map((collection) => ({
        ...collection
      }))
      , 'id');

    yield put(setCollectionList(normalizedItems));

    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchCollectionListProcess;
