// Dependencies
import {SagaIterator, channel} from 'redux-saga';
import {call, put, take} from 'redux-saga/effects';

// Types
import {PublishCollectionAction} from '@store/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';
import * as licenseServices from '@services/license';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {closeModal, openModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';
import {setCollectionContract} from '@store/collection/actions';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

import {FormValues} from '@forms/BuyLicenseForm';

/**
 * @function publishCollectionProcess()
 * @description Publidh a collection
 */
function * publishCollectionProcess({payload: {collectionId}}: PublishCollectionAction): SagaIterator<void> {
  const modalCloseChannel = channel();
  try {
    yield put(setIsLoading(LoadersId.IS_PUBLISHING_COLLECTION, true));
    yield put(openModal(ModalsId.PUBLISHING_COLLECTION, {
      type: 'loading'
    }));

    const validLicense: boolean = yield call(licenseServices.hasValidLicense);
    if (validLicense) {
      const contractAddress: string = yield call(services.publishCollection, collectionId);
      yield put(setCollectionContract(collectionId, contractAddress));

      const collection = yield call(services.getCollectionById, collectionId);

      yield put(closeModal());
      yield put(openModal(ModalsId.PUBLISHING_COLLECTION, {
        type: 'completed',
        action: () => {
          window.location.href = `/collection/${collection.givitUrl}`;
        }
      }));
    } else {
      let description = '';
      yield put(closeModal());
      yield put(openModal(ModalsId.BUY_LICENSE, {
        type: 'initial',
        action: (values: FormValues) => {
          description = values.description;
          modalCloseChannel.put({type: 'MODAL_CLOSED'});
        }
      }));
      yield take(modalCloseChannel);
      yield call(licenseServices.buyLicense, collectionId, description);
      yield put(closeModal());
      yield put(openModal(ModalsId.BUY_LICENSE, {
        type: 'complete'
      }));
    }

    yield put(setIsLoading(LoadersId.IS_PUBLISHING_COLLECTION, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_PUBLISHING_COLLECTION, false));
    yield put(closeModal());
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default publishCollectionProcess;
