// Dependencies
import React from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './AvatarUpload.module.scss';

// Components
import Typography from '@components/Typography';
import {useDropzone} from 'react-dropzone';

// Types
import {AvatarUploadProps} from './types';

// Config
import {IMAGE_UPLOAD_FORMATS} from '@config/index';

import language_es from 'src/locales/es/components/avatarUpload.json';
import language_en from 'src/locales/en/components/avatarUpload.json';

function AvatarUpload({photo, onDrop}: AvatarUploadProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': IMAGE_UPLOAD_FORMATS
    }
  });

  return (
    <div className={classNames([styles.avatar, {[styles.active]: isDragActive}])} {...getRootProps()}>
      <input {...getInputProps()} />

      {photo ? (
        <img src={typeof photo === 'string' ? photo : photo.preview} className={styles.photo} alt={'Collection Main Photo'} />
      ) : (
        <>
          <Typography
            variant={'text'}
            size={'sm'}
            weight={'regular'}
            className={styles.text}
            textAlign={'center'}>
            <b>{language.clickUpload}</b> <br />{language.dragDrop}
          </Typography>
        </>
      )}
    </div>
  );
}

export default AvatarUpload;
