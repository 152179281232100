// Dependencies
import * as Yup from 'yup';
import Web3Utils from '@utils/web3';

const isValidAddress = (value?: string): boolean => {
  try {
    Web3Utils.isValidAddress(value as string);
    return true;
  } catch (error) {
    return false;
  }
};

const ImportNFTFormValidation = Yup.object().shape({
  collectionAddress: Yup.string().required('Collection address is mandatory')
    .test(
      'Check address',
      'The address entered is not a valid Ethereum address',
      isValidAddress
    ),
  chain: Yup.object().required('Select a network'),
  nftId: Yup.number().required('NFT id is mandatory')
});

export default ImportNFTFormValidation;
