// Dependencies
import {SagaIterator} from 'redux-saga';
import {call} from 'redux-saga/effects';

// Types
import {DiscordAuthQuestAction} from '@store/quest/types';

// Services
import * as services from '@services/quest';
import {DiscordAuthQuestResponse} from '@services/quest/types';

// Actions
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function discordAuthQuestProcess()
 * @description Init the discord auth process
 */
function * discordAuthQuestProcess({payload: {collectionName, questId}}: DiscordAuthQuestAction): SagaIterator<void> {
  try {
    const response: DiscordAuthQuestResponse = yield call(services.discordAuthQuest, collectionName, questId);
    if (response) {
      window.location.href = response.url;
    }
  } catch (e) {
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default discordAuthQuestProcess;
