// Dependencies
import {call, delay, put} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {VerifyAccountAction} from '../types';

// Services
import * as authServices from '@services/auth';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

// Types
import {LoadersId} from '@type/loaders';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function verifyAccountProcess()
 * @description Verify account token process flow.
 */
function * verifyAccountProcess({payload: {token}}: VerifyAccountAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.VERIFY_ACCOUNT, true));

    yield call(authServices.verifyAccount, token);
    yield delay(1000);

    yield put(setIsLoading(LoadersId.VERIFY_ACCOUNT, false));

    yield delay(1000);
    yield call(() => {
      window.location.href = '/login';
    });
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.VERIFY_ACCOUNT, false));

    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default verifyAccountProcess;
