// Dependencies
import {IGiftState, GiftActions, giftActionsTypes} from './types';

const initialGiftState: IGiftState = {
  list: {},
  collectionList: {}
};

function giftReducer(state: IGiftState = initialGiftState, action: GiftActions): IGiftState {
  switch (action.type) {
    case giftActionsTypes.SET_GIFT_LIST:
      return {
        ...state,
        list: action.payload.list
      };

    case giftActionsTypes.SET_GIFT_LIST_COLLECTION:
      return {
        ...state,
        collectionList: action.payload.list
      };

    case giftActionsTypes.CREATE_GIFT:
      return {
        ...state,
        list: {
          ...state.list
          // TODO... action.payload
        }
      };

    case giftActionsTypes.ADD_GIFT_DATA:
      return {
        ...state,
        collectionList: {
          ...state.collectionList,
          [action.payload.data.id]: action.payload.data
        }
      };

    case giftActionsTypes.DELETE_GIFT_DATA: {
      const collectionList = state.collectionList ?? {};
      const {[action.payload.giftId.toString()]: _, ...newCollectionList} = collectionList;
      return {
        ...state,
        collectionList: newCollectionList
      };
    }

    case giftActionsTypes.SET_GIFT_DATA:
      return {
        ...state,
        collectionList: {
          ...state.collectionList,
          [action.payload.giftId]: {
            ...state.collectionList?.[action.payload.giftId],
            ...action.payload.data
          }
        }
      };

    default:
      return state;
  }
}

export default giftReducer;
