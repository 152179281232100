// Dependencies
import React from 'react';
import {Link} from 'react-router-dom';

// StyleSheet
import styles from './NavItem.module.scss';
import Typography from '@components/Typography';
import Badge from '@components/Badge';
import classNames from 'clsx';

interface NavItemProps {
  icon: React.ReactElement;
  text: string;
  quantity: number;
  active?: boolean;
  path: string;
  handleClick?: () => void;
}

function NavItem({
  active,
  icon,
  text,
  quantity,
  path,
  handleClick
}: NavItemProps): React.ReactElement {
  return (
    <Link to={path} onClick={handleClick} className={classNames([styles.layout, {[styles.active]: active}])}>
      {icon && (
        <div className={styles.icon}>{icon}</div>
      )}
      <Typography variant={'text'} component={'span'} size={'md'} weight={'semiBold'}>{text}</Typography>
      {quantity > 0 && (
        <Badge
          variant={'solid'}
          dot={false}
          size={'sm'}
          color={'grey'}
          className={styles.badge}
          label={quantity.toString()}
        />
      )}
    </Link>
  );
}

export type {NavItemProps};

export default NavItem;
