// Dependencies
import React, {useLayoutEffect} from 'react';
import {useParams} from 'react-router-dom';

// Assets
import verifiedLottie from '@assets/lotties/verified-lottie.json';
import Lottie from 'lottie-react';

// Types
import {LoadersId} from '@type/loaders';

// StyleSheet
import styles from './VerifyAccount.module.scss';

// Components
import Typography from '@components/Typography';
import {TailSpin} from 'react-loader-spinner';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader from '@hooks/useLoader';

import language_es from 'src/locales/es/pages/auth/verifyAccount.json';
import language_en from 'src/locales/en/pages/auth/verifyAccount.json';

function VerifyAccount(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {verifyAccount} = useAuth();
  const {token} = useParams<{token: string}>();
  const {isLoading} = useLoader(LoadersId.VERIFY_ACCOUNT);

  useLayoutEffect(() => {
    if (token) verifyAccount(token);
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.indicator}>
            {isLoading ? (
              <TailSpin width={80} height={80} color={'#4e8cff'} />
            ) : (
              <div className={styles.lottie}>
                <Lottie
                  animationData={verifiedLottie}
                  loop={false}
                  autoplay={true}
                />
              </div>
            )}
          </div>
          <div className={styles.head}>
            {isLoading ? (
              <>
                <Typography variant={'text'} size={'lg'} weight={'bold'}>{language.verifyingCredentials}</Typography>
                <Typography variant={'text'} size={'md'}>{language.waitAMoment}</Typography>
              </>
            ) : (
              <>
                <Typography variant={'text'} size={'lg'} weight={'bold'}>{language.credentialsVerified}</Typography>
                <Typography variant={'text'} size={'md'}>{language.redirectingTo}<a href={'/login'} className={styles.link}>{language.login}</a> {language.inAFewSeconds}</Typography>
              </>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default VerifyAccount;
