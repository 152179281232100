// State
import {RootState} from '../reducer';
import {IGift} from '@type/gift';
import {unNormalizeState} from '@utils/state';

/**
 * Selects the list of all gifts from the application state.
 *
 * @function selectGiftList
 * @param {RootState} state - The root state of the application.
 * @returns {IGift[] | undefined} The list of all gifts, or undefined if no gifts are found.
 */
export const selectGiftList = (state: RootState): IGift[] | undefined =>
  unNormalizeState(state.gift.list);

/**
 * Selects a specific gift by its ID from the application state.
 *
 * @function selectGiftById
 * @param {RootState} state - The root state of the application.
 * @param {number | undefined} id - The ID of the gift to select.
 * @returns {IGift} The selected gift, or undefined if no gift is found with the specified ID.
 */
export const selectGiftById = (state: RootState, id?: number | undefined): IGift =>
  unNormalizeState(state.gift.list)?.filter(e => e.id === Number(id))[0];

/**
 * Selects the list of gifts associated with a specific collection.
 *
 * @function selectGiftListByCollectionId
 * @param {RootState} state - The root state of the application.
 * @returns {IGift[] | undefined} The list of gifts associated with the collection, or undefined if no gifts are found.
 */
export const selectGiftListByCollectionId = (state: RootState): IGift[] | undefined =>
  unNormalizeState(state.gift.collectionList);

/**
 * Selects a specific gift from the collection by its ID.
 *
 * @function selectCollectionGiftById
 * @param {RootState} state - The root state of the application.
 * @param {number | undefined} id - The ID of the gift to be selected.
 * @returns {IGift} The selected gift.
 */
export const selectCollectionGiftById = (state: RootState, id?: number | undefined): IGift =>
  unNormalizeState(state.gift.collectionList)?.filter(e => e.id === Number(id))[0];
