// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {CreateNFTProps} from './types';

// Hooks
import useCollections from '@hooks/useCollections';
import useLoader, {LoadersId} from '@hooks/useLoader';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CreateOrEditNFTModal.module.scss';

// Components
import Typography from '@components/Typography';

// Forms
import CreateOrEditNFTForm, {FormValues} from '@forms/CreateOrEditNFTForm';

import language_es from 'src/locales/es/modals/createOrEditNFT.json';
import language_en from 'src/locales/en/modals/createOrEditNFT.json';

function CreateOrEditNFTModal({collectionId, nft}: CreateNFTProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {createNFT, editNFT} = useCollections();
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_NFT);

  /**
   * Callback function to handle submitting the creation of a new NFT.
   *
   * @function
   * @name handleSubmitCreateNFT
   * @returns {void}
   */
  const handleSubmitCreateNFT = useCallback((values: FormValues) => {
    if (nft) {
      editNFT(collectionId, {
        id: nft.id,
        name: values.name,
        welcomeMessage: values.welcomeMessage,
        passBackgroundColor: values.passBackgroundColor,
        passForegroundColor: values.passForegroundColor,
        passLabelColor: values.passLabelColor,
        passTypeId: values.passTypeId,
        photo: values.image,
        imageTypeId: values.imageTypeId
      });
    } else {
      if (values.image instanceof File && (values.name)) {
        createNFT(collectionId, {
          name: values.name,
          photo: values.image,
          passBackgroundColor: values.passBackgroundColor,
          passForegroundColor: values.passForegroundColor,
          passLabelColor: values.passLabelColor,
          passTypeId: values.passTypeId,
          welcomeMessage: values.welcomeMessage,
          imageTypeId: values.imageTypeId
        });
      }
    }
  }, [collectionId, nft]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.row}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.nftTitle}</Typography>
        <Typography>{language.nftDescription}</Typography>
      </div>
      <div className={styles.row}>
        <CreateOrEditNFTForm
          isLoading={isLoading}
          {...nft ? ({
            initialValues: {
              name: nft.name,
              image: nft.image,
              passBackgroundColor: nft.passBackgroundColor,
              passForegroundColor: nft.passForegroundColor,
              passLabelColor: nft.passLabelColor,
              passTypeId: nft.passTypeId,
              welcomeMessage: nft.welcomeMessage,
              imageTypeId: nft.imageTypeId
            }
          }) : ({
            initialValues: {
              image: undefined,
              imageTypeId: 0,
              name: '',
              welcomeMessage: '',
              passBackgroundColor: 'rgb(255,255,255)',
              passForegroundColor: 'rgb(0,0,0)',
              passLabelColor: 'rgb(0,0,0)',
              passTypeId: 1
            }
          })}
          onSubmit={handleSubmitCreateNFT}
        />
      </div>
    </div>
  );
}

export default CreateOrEditNFTModal;
