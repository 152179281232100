// Dependencies
import createSagaMiddleware, {Task} from 'redux-saga';
import {Persistor} from 'redux-persist/es/types';
import {Store} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {createTransform, persistReducer, persistStore} from 'redux-persist';
import {stringify, parse} from 'flatted';

// Modules
import rootReducer from './reducer';
import rootSaga from './sagas';

// Persistor
import storage from './persistor';

export interface SagaStore extends Store {
  persistor?: Persistor;
  sagaTask?: Task;
}

export const transformCircular = createTransform(
  (inboundState) => stringify(inboundState),
  (outboundState) => parse(outboundState)
);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui', 'nft'],
  transforms: [transformCircular]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store: SagaStore = configureStore(
    {
      reducer: persistedReducer,
      middleware: [sagaMiddleware]
    });

  store.sagaTask = sagaMiddleware.run(rootSaga);
  store.persistor = persistStore(store);

  return store;
};
