// Dependencies
import React, {useCallback, useEffect, useMemo} from 'react';
import classNames from 'clsx';
import {useNavigate, Link, useParams} from 'react-router-dom';
import {usePrevious} from 'react-use';

// Styles
import styles from './EditCollection.module.scss';

// Forms
import NewCollectionForm, {FormValues} from '@forms/NewCollectionForm';

// Components
import Typography from '@components/Typography';

// Hooks
import useCollections from '@hooks/useCollections';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Config
import {networks} from '@utils/web3';

import language_es from 'src/locales/es/pages/creator/editCollection.json';
import language_en from 'src/locales/en/pages/creator/editCollection.json';

function EditCollection(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {
    editCollection,
    fetchCollectionById,
    myCollectionsList
  } = useCollections();
  const navigate = useNavigate();
  const {id: collectionId} = useParams<{id: string}>();

  // Loading Management
  const {isLoading: isLoadingEditing} = useLoader(LoadersId.IS_EDITING_COLLECTION);
  const previousLoading = usePrevious(isLoadingEditing);

  useEffect(() => {
    if (collectionId) {
      fetchCollectionById(Number(collectionId));
    }
  }, [collectionId]);

  useEffect(() => {
    if (previousLoading && !isLoadingEditing) {
      navigate(`/creator/collection/${collectionId}`);
    }
  }, [isLoadingEditing]);

  /**
   * Callback function to handle saving changes for a collection.
   *
   * @function
   * @name handleSaveChanges
   * @param {Object} formValues - Object containing form field values: chain {Object}, name {string}, description {string},
   * givitUrl {string}, avatar {File}, banner {File}, instagramUrl {string}, url {string}, openSeaUrl {string}, twitter {string},
   * discord {string}, calendy {string}
   * @returns {void}
   */
  const handleSaveChanges = useCallback(({chain, name, description, givitUrl, avatar, banner, instagramUrl, url, openSeaUrl, twitter, discord, calendy, canCreateQuests}: FormValues) => {
    editCollection(Number(collectionId), {
      chainId: Number(chain?.value),
      name,
      avatar,
      banner,
      description,
      instagramUrl,
      calendy,
      url,
      givitUrl,
      twitter,
      discord,
      openSeaUrl,
      quests: canCreateQuests ? 1 : 0
    });
  }, [collectionId]);

  /**
   * Collection object representing the collection being edited.
   *
   * @type {Object}
   * @name collection
   * @property {string} id - The ID of the collection.
   * @property {string} name - The name of the collection.
   * @property {string} avatar - The URL of the small logo of the collection.
   * @property {string} banner - The URL of the banner image of the collection.
   * @property {string} description - The description of the collection.
   * @property {string} givitUrl - The Givit URL of the collection.
   * @property {string} discord - The Discord URL of the collection.
   * @property {string} instagramUrl - The Instagram URL of the collection.
   * @property {string} openSeaUrl - The OpenSea URL of the collection.
   * @property {string} twitter - The Twitter URL of the collection.
   * @property {string} url - The URL of the collection.
   */
  const collection = useMemo(() => myCollectionsList?.[Number(collectionId)] ?? undefined, [Number(collectionId), myCollectionsList]);

  if (collection?.published) {
    return (
      <div className={classNames([styles.container, styles.alreadyPublished])}>
        <div className={styles.photoContainer}>
          <img src={collection.avatar} alt={collection.name} className={styles.photo} width={148} height={148} />
        </div>
        <div className={styles.phrases}>
          <Typography variant={'display'} textAlign={'center'} size={'xl'} weight={'bold'}>{language.collectionAlreadyPublished}</Typography>
          <Typography variant={'text'} textAlign={'center'} size={'md'}>{language.collectionCannotBeEdited}</Typography>
          <Typography variant={'text'} textAlign={'center'} size={'md'}>{language.viewCollection1} <Link to={`/collection/${collection.givitUrl}`}>{language.viewCollection2}</Link></Typography>
        </div>
      </div>
    );
  }

  return (
    <NewCollectionForm
      id={Number(collectionId)}
      initialValues={{
        chain: networks().filter(e => e.value === collection?.chain?.id)[0] ?? undefined,
        avatar: collection?.avatar ?? undefined,
        banner: collection?.banner ?? undefined,
        name: collection?.name ?? '',
        description: collection?.description ?? '',
        canCreateQuests: collection?.quests ?? false,
        givitUrl: collection?.givitUrl ?? '',
        discord: collection?.discord ?? '',
        instagramUrl: collection?.instagramUrl ?? '',
        openSeaUrl: collection?.openSeaUrl ?? '',
        twitter: collection?.twitter ?? '',
        url: collection?.url ?? '',
        calendy: collection?.calendy ?? ''
      }}
      data={collection}
      isLoading={isLoadingEditing}
      onSubmit={handleSaveChanges}
      onCancel={() => navigate(`/creator/collection/${collection?.id}`)}
    />
  );
}

export default EditCollection;
