// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {SignUpAction} from '@store/auth/types';
import {LoadersId} from '@type/loaders';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

// Services
import * as authServices from '@services/auth';
import {openModal} from '@store/ui/modal/actions';
import {ModalsId} from '@type/modals';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function signUpProcess()
 * @description SignUp authentication
 */
function * signUpProcess({payload: {email, password, confirmPassword}}: SignUpAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_SIGNUP, true));
    if (password === confirmPassword) {
      const response = yield call(authServices.signUp, {
        email,
        password
      });

      if (response) {
        yield put(openModal(ModalsId.VERIFY_EMAIL_SIGNUP, {
          email
        }));
      }
    }
    yield put(setIsLoading(LoadersId.IS_SIGNUP, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_SIGNUP, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default signUpProcess;
