
// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {passActionsTypes} from '../types';

// Sagas
import fetchPassByQrCodeProcess from './fetchPassByQrCodeProcess';
import createNewPassProcess from './createNewPassProcess';
import generatePassProcess from './generatePassProcess';

/**
 * @function pass
 * @generator
 * @description A root saga that watches for specific Pass-related actions and triggers the corresponding processes.
 *
 * @yields {Object} - A series of `takeLatest` effects to handle specific Pass actions.
 *
 * @returns {void} - Returns nothing.
 */
function * pass(): SagaIterator<void> {
  yield takeLatest(passActionsTypes.FETCH_PASS_BY_QR, fetchPassByQrCodeProcess);
  yield takeLatest(passActionsTypes.CREATE_NEW_PASS, createNewPassProcess);
  yield takeLatest(passActionsTypes.GENERATE_PASS, generatePassProcess);
}

export default pass;
