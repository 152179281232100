// Dependencies
import React from 'react';

// Components
import Typography from '@components/Typography';
import QRCode from 'react-qr-code';

// Styles
import styles from './PassCard.module.scss';

// Types
import {PassCardProps} from './types';

// Assets
import language_es from 'src/locales/es/components/cards/passCard.json';
import language_en from 'src/locales/en/components/cards/passCard.json';

function PassCard({isMobile, ...pass}: PassCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  const style: React.CSSProperties = {
    '--background-color': pass.passBackgroundColor,
    '--foreground-color': pass.passForegroundColor,
    '--label-color': pass.passLabelColor
  } as unknown as React.CSSProperties;

  return (
    <div className={styles.layout} style={style}>
      {/* Header */}
      <div>
        <div className={styles.field}>
          <div className={styles.row}>
            <div className={styles.avatar}>
              <img src={pass.creatorLogo} />
            </div>
          </div>
          <Typography variant={'display'} size={'xs'} weight={'bold'}>{pass.creatorName}</Typography>
        </div>
      </div>
      <div className={styles.fieldjustify}>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.name}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xl'} weight={'bold'}>{pass.passName}</Typography>
        </div>
        <div className={styles.image}>
          <img src={pass.passImage} />
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.number}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{pass.passId}</Typography>
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.qr}>
          <QRCode
            value={pass.qrCode as string}
          />
        </div>
      </div>
    </div>
  );
}
export default PassCard;
