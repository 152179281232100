// Dependencies
import React, {useEffect} from 'react';

// StyleSheet
import styles from './MyRewards.module.scss';

// Hooks
import useGifts from '@hooks/useGifts';
import useResponsive from '@hooks/useResponsive';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useAuth from '@hooks/useAuth';

// Components
import GiftCard from '@components/cards/GiftCard';
import PageTitle from '@components/PageTitle';
import EmptyState from '@components/EmptyState';
import {TailSpin} from 'react-loader-spinner';

// Assets
import {RiGift2Line} from 'react-icons/ri';

import language_es from 'src/locales/es/pages/user/myRewards.json';
import language_en from 'src/locales/en/pages/user/myRewards.json';

function MyRewards(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {giftList, fetchGiftList} = useGifts();
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_GIFT_LIST);
  const {isMobile} = useResponsive();
  const {user} = useAuth();

  useEffect(() => {
    fetchGiftList(true);
  }, []);

  return (
    <div className={styles.layout}>
      <PageTitle text={language.myRewardsTitle} />
      {isLoading ? (
          <div className={styles.loader}>
            <TailSpin
              width={32}
              height={32}
              color={'#1B2C71'}
            />
          </div>
      ) : (
        giftList?.length ? (
            <div className={styles.grid}>
              {giftList?.map((gift, index) => (
                <GiftCard
                  key={`--gift-card-${index.toString()}`}
                  isCreator={user?.id === gift.collection.user.id}
                  isMobile={isMobile}
                  collectionId={gift.collection.id}
                  {...gift}
                />
              ))}
            </div>
        ) : (
            <div className={styles.empty}>
              <EmptyState
                icon={<RiGift2Line />}
                text={language.noRewardsAvailable}
              />
            </div>
        )
      )}
    </div>
  );
}

export default MyRewards;
