// Dependencies
import {InjectedConnector} from '@web3-react/injected-connector';
import {useState, useEffect} from 'react';

// Chains
import {supportedChainIds, ChainsId} from './chains';

const useMetamask = (): InjectedConnector => {
  const [chains, setChains] = useState<ChainsId>();

  useEffect(() => {
    const fetchChains = async() => {
      const result = await supportedChainIds(process.env.REACT_APP_NODE_ENV !== 'development');
      setChains(result);
    };

    fetchChains().catch(error => console.error(error));
  }, []);

  return new InjectedConnector({
    supportedChainIds: chains?.chainIds
  });
};

export default useMetamask;
