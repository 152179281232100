// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchAuthAction} from '../types';
import {GetMeResponse} from '@services/auth/types';
import {LoadersId} from '@type/loaders';

// Services
import * as authServices from '@services/auth';

// Actions
import {setAuthUser, logOut} from '@store/auth/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {ErrorMessage} from '@type/error';

// Store
import errorHandler from '@store/errorHandler';

/**
 * @function fetchAuthProcess()
 * @description Fetching authentication flow.
 */
function * fetchAuthProcess({payload: {redirectToLogin}}: FetchAuthAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_AUTH, true));

    const response: GetMeResponse = yield call(authServices.getMe);
    if (response) {
      yield put(setAuthUser(response));
    }

    yield put(setIsLoading(LoadersId.IS_FETCHING_AUTH, false));
  } catch (e) {
    console.error(e);
    yield put(setAuthUser(null));
    yield put(setIsLoading(LoadersId.IS_FETCHING_AUTH, false));

    if (redirectToLogin) {
      if ((e as ErrorMessage).code === 401) yield put(logOut('/login'));
      else yield call(() => errorHandler(e as ErrorMessage, true));
    }
  }
}

export default fetchAuthProcess;
