// API
import request from '../api';
import {IQuest, IQuestType, IQuestActionType, IUserQuest} from '@type/quest';
import {TwitterAuthQuestResponse, DiscordAuthQuestResponse, InstagramAuthQuestResponse, GoogleAuthQuestResponse, TikTokAuthQuestResponse} from './types';

export const getQuestTypes = async(): Promise<IQuestType[]> =>
  await request('/quests/types', {
    method: 'GET'
  });

export const getQuestActionTypes = async(): Promise<IQuestActionType[]> =>
  await request('/quests/actions', {
    method: 'GET'
  });

export const getQuests = async(collectionId: number): Promise<IQuest[]> =>
  await request(`/quests/${collectionId}`, {
    method: 'GET'
  });

export const getUserQuests = async(collectionId: number): Promise<IUserQuest[]> =>
  await request(`/quests/user/${collectionId}`, {
    method: 'GET'
  });

export const addQuest = async(collectionId: number, quest: IQuest): Promise<IQuest> =>
  await request(`/quests/${collectionId}`, {
    method: 'POST',
    body: {
      title: quest.title,
      description: quest.description,
      questActionTypeId: quest.questActionType?.id.toString(),
      handler: quest.handler,
      extraHandler: quest.extraHandler as string,
      xp: quest.xp.toString()
    }
  });

export const editQuest = async(quest: IQuest): Promise<IQuest> =>
  await request(`/quests/${quest.id}`, {
    method: 'PUT',
    body: {
      title: quest.title,
      description: quest.description,
      questActionTypeId: quest.questActionType?.id.toString(),
      handler: quest.handler,
      extraHandler: quest.extraHandler as string,
      xp: quest.xp.toString()
    }
  });

export const deleteQuest = async(questId: number): Promise<void> =>
  await request(`/quests/${questId}`, {
    method: 'DELETE'
  });

export const twitterAuthQuest = async(collectionName: string, questId: number): Promise<TwitterAuthQuestResponse> =>
  await request(`/quests-twitter/auth/${collectionName}/${questId}`, {
    method: 'GET'
  });

export const twitterAuthQuestCallback = async(name: string, questId: string, code: string, codeVerifier: string): Promise<boolean> =>
  await request('/quests-twitter/auth/callback', {
    method: 'POST',
    body: {
      name,
      questId,
      code,
      codeVerifier
    }
  });

export const discordAuthQuest = async(collectionName: string, questId: number): Promise<DiscordAuthQuestResponse> =>
  await request(`/quests-discord/auth/${collectionName}/${questId}`, {
    method: 'GET'
  });

export const discordAuthQuestCallback = async(code: string, name: string, questId: string): Promise<boolean> =>
  await request('/quests-discord/auth/callback', {
    method: 'POST',
    body: {
      code,
      name,
      questId
    }
  });

export const instagramAuthQuest = async(collectionName: string, questId: number): Promise<InstagramAuthQuestResponse> =>
  await request(`/quests-instagram/auth/${collectionName}/${questId}`, {
    method: 'GET'
  });

export const instagramAuthQuestCallback = async(name: string, questId: string, code: string): Promise<boolean> =>
  await request('/quests-instagram/auth/callback', {
    method: 'POST',
    body: {
      name,
      questId,
      code
    }
  });

export const googleAuthQuest = async(collectionName: string, questId: number): Promise<GoogleAuthQuestResponse> =>
  await request(`/quests-google/auth/${collectionName}/${questId}`, {
    method: 'GET'
  });

export const googleAuthQuestCallback = async(code: string, name: string, questId: string): Promise<boolean> =>
  await request('/quests-google/auth/callback', {
    method: 'POST',
    body: {
      code,
      name,
      questId
    }
  });

export const twitchAuthQuestCallback = async(code: string, name: string, questId: string): Promise<boolean> =>
  await request('/quests-twitch/auth/callback', {
    method: 'POST',
    body: {
      code,
      name,
      questId
    }
  });

export const tiktokAuthQuest = async(collectionName: string, questId: number): Promise<TikTokAuthQuestResponse> =>
  await request(`/quests-tiktok/auth/${collectionName}/${questId}`, {
    method: 'GET'
  });

export const tiktokAuthQuestCallback = async(code: string, name: string, questId: string): Promise<boolean> =>
  await request('/quests-tiktok/auth/callback', {
    method: 'POST',
    body: {
      code,
      name,
      questId
    }
  });

export const checkQuestActionIsCompleted = async(questId: number): Promise<boolean> =>
  await request(`/quests/check-quest-action/${questId}`, {
    method: 'GET'
  });
