import React, {useRef, useEffect, useCallback} from 'react';
import styles from './NFTViewer.module.scss';

// Types
import {NFTViewerProps} from './types';

function NFTViewer({onClick, isMobile, mediaTypeId, mediaSrc}: NFTViewerProps): React.ReactElement {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  };

  useEffect(() => {
    if (isSafari() && videoRef.current) {
      // obtain reference to the video element
      const player = videoRef.current;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute('muted', ''); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch((error) => {
                console.error(error);
              });
          }
        }, 0);
      }
    }
  }, [videoRef, mediaSrc, mediaTypeId]);

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      void videoRef.current.play();
    }
  }, [videoRef]);

  const handlePauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      void videoRef.current.pause();
    }
  }, [videoRef]);

  return (
    <div onClick={onClick} onMouseLeave={handlePauseVideo} onMouseEnter={handlePlayVideo} className={styles.image}>
      {mediaTypeId === 2 ? (
        <video key={mediaSrc} ref={videoRef} loop muted playsInline autoPlay={isMobile} className={styles.video}>
          <source src={mediaSrc} />
        </video>
      ) : (
        <img src={mediaSrc} />
      )}
    </div>
  );
};

export default NFTViewer;
