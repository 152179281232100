// Dependencies
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';

// Components
import Typography from '@components/Typography';
import IconButton from '@components/IconButton';
import Badge from '@components/Badge';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Styles
import styles from './NFTCard.module.scss';

// Types
import {NFTCardProps} from './types';
import {NFTState} from '@type/nft';

// Assets
import {RiNftFill} from 'react-icons/ri';
import {FaUsers} from 'react-icons/fa';

import language_es from 'src/locales/es/components/cards/nftCard.json';
import language_en from 'src/locales/en/components/cards/nftCard.json';

function NFTCard({onClaim, onGetPass, onOpenMetamask, onClick, disabled, isInstalled, isMobile, ...nft}: NFTCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;

  /**
   * @function handlePressClaimButton()
   * @description Handle press claim button.
   */
  const handlePressClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClaim(nft);
  }, [nft]);

  /**
   * @function handlePressGetPassButton()
   * @description Handle press the get pass button.
   */
  const handlePressGetPassButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onGetPass(nft);
  }, [nft]);

  /**
   * @function handlePressOpenMetamask
   */
  const handlePressOpenMetamask = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpenMetamask();
  }, []);

  const isLoading = useMemo(() =>
    nft.state === NFTState.PROCESSING ||
    nft.state === NFTState.IS_WAITING_CONNECT ||
    nft.state === NFTState.IS_WAITING_TRANSACTION ||
    nft.state === NFTState.IS_WAITING_CHANGE_CHAIN,
  [nft.state]);

  return (
    <div className={styles.layout}>
      {/* Header */}
      <div>
        <div className={styles.field}>
          <Link to={`/collection/${nft.collectionGivitUrl}`} className={styles.link}>
            <Badge
              className={'collectionTitle'}
              color={'blue'}
              variant={'outline'}
              size={'sm'}
              iconRight={(
                <FaUsers />
              )}
              label={nft.collectionName}
            />
          </Link>
        </div>
        <div className={styles.field}>
          <div className={styles.row}>
            <div className={styles.title}>
              <Typography variant={'display'} size={'xs'} weight={'bold'}>{nft.name}</Typography>
            </div>
          </div>
          <div className={styles.row}>
            {!nft.tx ? (!isInstalled && isMobile) ? (
              <IconButton
                icon={(<RiNftFill />)}
                onClick={handlePressOpenMetamask}
                isLoading={isLoading}
                disabled={isLoading}
              />
            ) : (
              <IconButton
                icon={(<RiNftFill />)}
                onClick={handlePressClaimButton}
                isLoading={isLoading}
                disabled={isLoading}
              />
            ) : <></>}
          </div>
        </div>
      </div>
      <NFTViewer
        onClick={onClick}
        isMobile={isMobile}
        mediaTypeId={nft.imageTypeId}
        mediaSrc={nft.image}
      />
      <div className={styles.field}>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.creator}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{nft.creator}</Typography>
        </div>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.serie}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>#{nft.smartContractNFTId}</Typography>
        </div>
      </div>
      <div className={styles.field}>
        <Button
          className={styles.claimNFTButton}
          onClick={handlePressGetPassButton}
          showTextOnLoading={true}>
          {language.getPass}
        </Button>
      </div>
    </div>
  );
}
export default NFTCard;
