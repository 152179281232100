// Dependencies
import React, {useCallback, useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';

// Components
import Typography from '@components/Typography';
import PassCard from '@components/cards/PassCard';
import Loader from '@components/Loader';
import Button from '@components/Button';
import TextInput from '@components/TextInput';
import QRCode from 'react-qr-code';

// Hooks
import useResponsive from '@hooks/useResponsive';
import usePass from '@hooks/usePass';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useDisableHorizontalScrollBody from '@hooks/useDisableHorizontalScrollBody';

// Styles
import styles from './PassDetails.module.scss';

// Types
import {IPass} from '@type/pass';

// Assets
import addAppleWalletLogo from '@assets/images/add-to-apple-wallet-logo.png';
import addGoogleWalletLogo from '@assets/images/add_to_google_wallet-logo.png';

import language_es from 'src/locales/es/pages/user/passDetails.json';
import language_en from 'src/locales/en/pages/user/passDetails.json';
import useDeviceType from '@hooks/useDeviceType';

function PassDetails(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isMobile} = useResponsive();
  const {pass, passCreated, fetchPassByQrCode, createNewPass, setPassCreated} = usePass();
  const {qrCode} = useParams<{name: string, qrCode: string}>();
  const [email, setEmail] = useState<string>('');
  const {isLoading} = useLoader(LoadersId.IS_CREATING_NEW_PASS);
  const navigate = useNavigate();
  const {isAppleDevice, isGoogleDevice, isDesktopDevice} = useDeviceType();
  useDisableHorizontalScrollBody();

  useEffect(() => {
    setPassCreated(false);
    fetchPassByQrCode(qrCode as string);
  }, []);

  const handleGetPass = useCallback(() => {
    if (email && email !== '') {
      if (isAppleDevice) createNewPass(email, 1, pass as IPass);
      else if (isGoogleDevice) createNewPass(email, 2, pass as IPass);
    }
  }, [email]);

  const handleClose = useCallback(() => {
    setPassCreated(false);
    navigate('/sign-in');
  }, []);

  if (!pass) return <Loader />;

  if (isLoading) {
    return (
      <div>
        <div className={styles.banner}>
          <img src={pass?.banner} alt={pass?.collectionName} width={'100%'} height={'100%'} />
        </div>
        <div className={styles.body}>
          <div className={styles.layout}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>
              {language.generatingPass}
            </Typography>
            <div className={styles.box}>
              <Loader variant={'section'} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {!passCreated
        ? (<div className={styles.body}>
          <div className={styles.banner}>
            <img src={pass?.banner} alt={pass?.collectionName} width={'100%'} height={'100%'} />
          </div>
          <div className={styles.layout}>
            <div className={styles.box}>
              <div className={styles.head}>
                <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>
                  {language.getPass.replace('{{name}}', pass.creatorName)}
                </Typography>
              </div>
              {isDesktopDevice ? (
                <div className={styles.head}>
                  <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{'Scan this QR from your mobile to install the pass'}</Typography>
                  <div className={styles.qr}>
                    <QRCode
                      value={window.location.href}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.pass}>
                  <div className={styles.field}>
                    <TextInput
                      name={'email'}
                      type={'email'}
                      placeholder={language.enterEmail}
                      value={email}
                      onChangeText={(email) => setEmail(email)}
                      disabled={isLoading}
                    />
                  </div>
                  <div className={styles.field}>
                    <Typography variant={'text'} size={'sm'} weight={'medium'}>
                      {language.termsAndConditions1}<a className={styles.link} href={'/use-terms'} rel="noreferrer" target="_blank">{language.termsAndConditions2}</a>{language.termsAndConditions3}<a className={styles.link} rel="noreferrer" target="_blank" href={'/privacy-policy'}>{language.termsAndConditions4}</a>
                    </Typography>
                  </div>
                  <div className={styles.field}>
                    {isAppleDevice && (
                      <Button
                        className={styles.claimPassButton}
                        onClick={handleGetPass}
                        disabled={email === ''}
                        showTextOnLoading={true}>
                        <img src={addAppleWalletLogo} />
                      </Button>
                    )}
                    {isGoogleDevice && (
                      <Button
                        className={styles.claimPassButton}
                        onClick={handleGetPass}
                        disabled={email === ''}
                        showTextOnLoading={true}>
                        <img src={addGoogleWalletLogo} />
                      </Button>
                    )}
                  </div>
                </div>
              )}

              <PassCard
                isMobile={isMobile}
                {...pass}
              />
            </div>
          </div>
        </div>
          ) : (
          <div className={styles.body}>
            <div className={styles.banner}>
              <img src={pass?.banner} alt={pass?.collectionName} width={'100%'} height={'100%'} />
            </div>
            <div className={styles.layout}>
              <div className={styles.box}>
                <div className={styles.head}>
                  <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>{language.congrats}</Typography>
                </div>
                <div className={styles.welcomeMessage}>
                  <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{pass.passText}</Typography>
                </div>
                <PassCard
                  isMobile={isMobile}
                  {...pass}
                />
                <div className={styles.field}>
                  <Button
                    className={styles.claimNFTButton}
                    onClick={handleClose}
                    showTextOnLoading={true}>
                    {language.goToGivePass}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          )
      }
      <div className={styles.foot}>
        <Typography variant={'text'} textAlign={'center'} size={'sm'}>
          {language.poweredBy}
          <Link to={'/'} className={styles.link}>GivitPass</Link>
        </Typography>
      </div>
    </div>
  );
}

export default PassDetails;
