import {IUser} from '@type/user';

export enum QuestTypeEnum {
  Discord = 1,
  YouTube = 2,
  Twitch = 3
}

export enum QuestActionTypeEnum {
  JoinDiscord = 1,
  YouTubeSubscribe = 2,
  TwitchFollow = 3
}

export interface IQuestType {
  id: number;
  name?: string;
  icon?: string;
}

export interface IQuestActionType {
  id: number;
  name?: string;
  title?: string;
  description?: string;
  questType?: IQuestType
}

export interface IQuest {
  id: number;
  title: string;
  questActionType: IQuestActionType;
  questActionTypeId?: number;
  description: string;
  handler: string;
  extraHandler?: string;
  xp: number;
  collectionId?: number;
  collection?: {
    id: number;
    avatar: string;
    user: IUser;
  }
}

export interface IUserQuest extends IQuest {
  quest: IQuest;
  status: boolean;
}

export interface ITwitterAuthData {
  codeVerifier: string;
  state: string;
  url: string;
}

export interface IDiscordAuthData {
  code: string;
  state: string;
}
