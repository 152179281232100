// Dependencies
import {takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Types
import {rewardActionsTypes} from '../types';

// Sagas
import fetchRewardsProcess from './fetchRewardsProcess';
import downloadRewardsCSVProcess from './downloadRewardsCSVProcess';

function * reward(): SagaIterator<void> {
  yield takeLatest(rewardActionsTypes.FETCH_REWARDS, fetchRewardsProcess);
  yield takeLatest(rewardActionsTypes.DOWNLOAD_REWARDS_CSV, downloadRewardsCSVProcess);
}

export default reward;
