// State
import {RootState} from '../reducer';
import {IQuest, IQuestType, IQuestActionType, IUserQuest, ITwitterAuthData} from '@type/quest';
import {unNormalizeState} from '@utils/state';

export const selectQuestTypesList = (state: RootState): IQuestType[] =>
  state.quest.questTypes?.length > 0 ? unNormalizeState(state.quest.questTypes) : [];

export const selectQuestActionTypesList = (state: RootState): IQuestActionType[] =>
  unNormalizeState(state.quest.questActionTypes);

export const selectQuestsByCollectionId = (state: RootState): IQuest[] =>
  unNormalizeState(state.quest.quests);

export const selectQuestById = (state: RootState, id?: number | undefined): IQuest =>
  unNormalizeState(state.quest.quests)?.filter(e => e.id === Number(id))[0];

export const selectUserQuests = (state: RootState): IUserQuest[] =>
  unNormalizeState(state.quest.userQuests);

export const selectTwitterAuthData = (state: RootState): ITwitterAuthData =>
  state.quest.twitterAuth;
