// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {TabsProps} from './types';

// StyleSheet
import styles from './Tabs.module.scss';

// Components
import Typography from '@components/Typography';

function Tabs({
  selected,
  onSelectItem,
  items
}: TabsProps): React.ReactElement {
  return (
    <ul className={styles.layout}>
      {items.map((item, index) => (
        <li
          key={`--item-key-${index.toString()}`}
          onClick={() => onSelectItem(item, index)}
          className={classNames([styles.item, {[styles.active]: selected === index}])}>
          <Typography variant={'text'} size={'md'} weight={'semiBold'}>{item.label}</Typography>
        </li>
      ))}
    </ul>
  );
}

export default Tabs;
