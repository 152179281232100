// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {BuyLicenseProps} from './types';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';
import Lottie from 'lottie-react';
import Button from '@components/Button';

// Forms
import BuyLicenseForm from '@forms/BuyLicenseForm';

// Hooks
import useModal from '@hooks/useModal';
import useLoader, {LoadersId} from '@hooks/useLoader';

// StyleSheet
import styles from './BuyLicenseModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Assets
import {RiGift2Line} from 'react-icons/ri';
import verifiedLottie from '@assets/lotties/verified-lottie.json';

import language_es from 'src/locales/es/modals/buyLicense.json';
import language_en from 'src/locales/en/modals/buyLicense.json';

function BuyLicenseModal({
  type,
  action
}: BuyLicenseProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();
  const {isLoading} = useLoader(LoadersId.IS_SENDING_COLLECTION_INFO);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'green'} icon={(<RiGift2Line />)} />
      {type === 'initial' ? (
        <>
          <div className={styles.text}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'} textAlign={'center'}>{language.reviewTitle}</Typography>
            <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{language.reviewSubtitle}</Typography>
          </div>
          <div className={styles.text}>
            <Typography variant={'text'} size={'md'} weight={'regular'} textAlign={'center'}>{language.sendInfo}</Typography>
            <BuyLicenseForm
              isLoading={isLoading}
              onSubmit={action}
              onCancel={closeModal}
            />
          </div>
        </>
      ) : (
        <>
          <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.thankyouTitle}</Typography>
          <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.thankyouSubtitle}</Typography>
          <div className={styles.lottie}>
            <Lottie
              animationData={verifiedLottie}
              loop={false}
              autoplay={true}
            />
          </div>
          <div className={styles.footer}>
            <div className={styles.actions}>
              <Button type={'button'} variant={'solid'} color={'primary'} onClick={closeModal} disabled={isLoading}>
                {language.closeButton}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BuyLicenseModal;
