// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {CreateQuestProps} from './types';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useQuests from '@hooks/useQuests';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CreateOrEditQuestModal.module.scss';

// Components
import Typography from '@components/Typography';

// Forms
import CreateOrEditQuestForm, {FormValues} from '@forms/CreateOrEditQuestForm';

import language_es from 'src/locales/es/modals/createOrEditQuest.json';
import language_en from 'src/locales/en/modals/createOrEditQuest.json';

function CreateOrEditQuestModal({collectionId, quest}: CreateQuestProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_QUEST);
  const {addQuest, updateQuest} = useQuests();

  const handleSubmitCreateQuest = useCallback((values: FormValues) => {
    if (quest) {
      updateQuest({
        id: quest.id,
        title: values.title,
        description: values.description,
        questActionType: {
          id: values.actionTypeId as number,
          questType: {
            id: values.typeId as number
          }
        },
        handler: values.handler,
        extraHandler: values.extraHandler,
        xp: values.xp
      });
    } else {
      if (values.handler) {
        addQuest(collectionId, {
          id: 0,
          title: values.title,
          description: values.description,
          questActionType: {
            id: values.actionTypeId as number
          },
          handler: values.handler,
          extraHandler: values.extraHandler,
          xp: values.xp
        });
      }
    }
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.row}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.questTitle}</Typography>
        <Typography>{language.questDescription}</Typography>
      </div>
      <div className={styles.row}>
        <CreateOrEditQuestForm
          isLoading={isLoading}
          {...quest && ({
            initialValues: {
              title: quest.title,
              type: {
                value: quest.questActionType?.questType?.id as number,
                label: quest.questActionType?.questType?.name as string
              },
              typeId: quest.questActionType?.questType?.id,
              actionType: {
                value: quest.questActionType?.id,
                label: quest.questActionType?.name as string
              },
              actionTypeId: quest.questActionType?.id,
              description: quest.description,
              handler: quest.handler,
              extraHandler: quest.extraHandler,
              xp: quest.xp
            }
          })}
          onSubmit={handleSubmitCreateQuest}
        />
      </div>
    </div>
  );
}

export default CreateOrEditQuestModal;
