// Actions
import {IModalState, ModalActions, ModalActionsTypes} from './types';

const initialModalState = {
  id: null,
  data: {}
};

function modalReducer(state: IModalState = initialModalState, action: ModalActionsTypes): IModalState {
  switch (action.type) {
    case ModalActions.OPEN_MODAL:
      return action.payload;

    case ModalActions.CLOSE_MODAL:
      return initialModalState;

    default:
      return state;
  }
}

export default modalReducer;
