// Dependencies
import React, {useCallback, useState} from 'react';
import classNames from 'clsx';
import Lottie from 'lottie-react';
import {useNavigate} from 'react-router-dom';

// Types
import {VerifyEmailSignupProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './VerifyEmailSignup.module.scss';

// Assets
import verifiedLottie from '@assets/lotties/verified-lottie.json';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// Hooks
import useAuth from '@hooks/useAuth';
import useModal from '@hooks/useModal';
import useLoader, {LoadersId} from '@hooks/useLoader';

import language_es from 'src/locales/es/modals/verifyEmailSignup.json';
import language_en from 'src/locales/en/modals/verifyEmailSignup.json';

function VerifyEmailSignup({email}: VerifyEmailSignupProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {resendVerifyEmail} = useAuth();
  const {closeModal} = useModal();
  const navigate = useNavigate();
  const {isLoading} = useLoader(LoadersId.IS_RESENDING_EMAIL_VERIFY);
  const [isSent, setIsSent] = useState<boolean>(false);

  const handleClickClose = useCallback(() => {
    navigate('/login');
    closeModal();
  }, []);

  const handleResendVerifyEmail = useCallback(() => {
    resendVerifyEmail(email);
    setIsSent(true);
  }, []);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.data}>
        <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.registration_success_title}</Typography>
        <div className={styles.lottie}>
          <Lottie
            animationData={verifiedLottie}
            loop={false}
            autoplay={true}
          />
        </div>
        <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.registration_success_description.replace('{{email}}', email)}</Typography>
        <Button variant={'solid'} color={'primary'} onClick={handleClickClose}>{language.continue_button}</Button>
        <Button variant={'outline'} color={'primary'} isLoading={isLoading} onClick={() => handleResendVerifyEmail()}>{language.resend_email_button}</Button>
        {isSent && <Typography component={'p'} variant={'text'} textAlign={'center'} size={'sm'}>{language.email_resent_message}</Typography>}
      </div>
    </div>
  );
}

export default VerifyEmailSignup;
