// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
// import {setCSV} from '@store/reward/actions';

// Services
import * as rewardServices from '@services/reward';

// Types
import {FetchRewardsAction} from '@store/reward/types';
import {ICSVRewards} from '@type/reward';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function downloadRewardsCSVProcess()
 * @description download csv
 */
function * downloadRewardsCSVProcess({payload: {rewardId}}: FetchRewardsAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_REWARDS_CSV, true));

    const response: ICSVRewards = yield call(rewardServices.getCSVRewards, rewardId);

    yield call(() => {
      const url = window.URL.createObjectURL(new Blob([response.codes], {type: 'text/plain'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'codes.txt');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_REWARDS_CSV, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_DOWNLOADING_REWARDS_CSV, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default downloadRewardsCSVProcess;
