// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setGiftList} from '@store/gift/actions';

// Services
import * as giftServices from '@services/gift';

// Types
import {GetGiftByIdResponse} from '@services/gift/types';
import {IGift} from '@type/gift';
import {normalizeState} from '@utils/state';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';
import {FetchGiftByIdAction} from '@store/gift/types';

/**
 * Handles the process of fetching a gift by its ID.
 * @generator
 * @function
 * @param {FetchGiftByIdAction} action - The action object with the type and payload.
 * @param {number} action.payload.id - The ID of the gift to be fetched.
 * @returns {SagaIterator<void>} A saga iterator that handles the fetching process of the gift.
 */
function * fetchGiftByIdProcess({payload: {id}}: FetchGiftByIdAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, true));

    const response: GetGiftByIdResponse = yield call(giftServices.getGiftById, id);
    if (response) {
      const normalizedItems: {[key: string]: IGift} = normalizeState(
        [response]
        , 'id');

      yield put(setGiftList(normalizedItems));
    }

    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_GIFT_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchGiftByIdProcess;
