// Dependencies
import React, {useEffect} from 'react';
import {useSpring, animated} from 'react-spring';

// Types
import {ToastProps} from './types';
import classNames from 'clsx';

// Styles
import styles from '../SuperToast/SuperToast.module.scss';

// Components
import Typography from '@components/Typography';

// Assets
import {RiCloseLine} from 'react-icons/ri';

function Toast({index, type, message, deleteToast}: ToastProps): React.ReactElement {
  const [animationStyle, animationApi] = useSpring(() => ({
    opacity: 0,
    transform: 'scale(0.7)',
    config: {
      duration: 100
    }
  }));

  useEffect(() => {
    animationApi.start({
      opacity: 1,
      transform: 'scale(1)',
      onRest: () => {
        setTimeout(() => {
          animationApi.start({
            opacity: 0,
            transform: 'scale(0.7)',
            onRest: () => {
              deleteToast(index);
            }
          });
        }, 100000);
      }
    });
  }, []);

  return (
    <animated.div className={classNames([styles.toast, styles[type]])} style={animationStyle}>
      <Typography variant={'text'} size={'sm'}>{message}</Typography>
      <div className={styles.close} onClick={() => deleteToast(index)}>
        <RiCloseLine size={22} color={'#fff'} />
      </div>
    </animated.div>
  );
}

export default Toast;
