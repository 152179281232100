// Dependencies
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import classNames from 'clsx';

// Styles
import styles from './NFT.module.scss';

// Hooks
import useNFTs from '@hooks/useNFTs';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useResponsive from '@hooks/useResponsive';

// Assets
import {RiExternalLinkLine, RiWalletLine} from 'react-icons/ri';
import {FaUsers} from 'react-icons/fa';

// Components
import Typography from '@components/Typography';
import Tabs from '@components/Tabs';
import Button from '@components/Button';
import Breadcrumbs from '@components/Breadcrumbs';
import Alert from '@components/Alert';
import NFTViewer from '@components/NFTViewer';
import QuillTextInput from '@components/QuillTextInput';

// Utils
import Web3Utils from '@utils/web3';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/pages/user/nftDetails.json';
import language_en from 'src/locales/en/pages/user/nftDetails.json';

function NFTDetails(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {id} = useParams<{id: string}>();
  const [sectionId, setSectionId] = useState<number>(0);
  const {nft, fetchNFTById} = useNFTs(Number(id));
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_NFT_LIST);
  const {isMobile} = useResponsive();

  useEffect(() => {
    fetchNFTById(Number(id));
  }, []);

  const renderContent = useMemo(() => {
    switch (sectionId) {
      case 1:
        return (
          <div className={classNames([styles.grid, styles.properties])}>
            <div className={styles.property}>
              <Typography variant={'text'} size={'md'} weight={'bold'} className={styles.label}>{language.collectionDescription}</Typography>
              <QuillTextInput
                theme='snow'
                value={nft?.collectionDescription}
                readOnly={true}
                modules={{toolbar: false}}
              />
            </div>
            {nft?.collectionUrl !== '' && (
              <div className={classNames([styles.property, styles.action])}>
                <Button
                  variant={'ghost'}
                  onClick={() => { window.open(nft?.collectionUrl, '_blank'); }}
                  iconRight={(
                    <RiExternalLinkLine
                      size={'20px'}
                    />
                  )}>
                  {language.visitTheWebsite}
                </Button>
              </div>
            )}
          </div>
        );

      case 0:
        return (
          <div className={classNames([styles.grid, styles.properties])}>
            <div className={styles.property}>
              <Typography variant={'text'} size={'md'} weight={'semiBold'} className={styles.label}>{language.nftId}</Typography>
              <a
                title={language.viewInBlockExplorer}
                target={'_blank'}
                href={`${nft?.explorerUrl}/nft/${nft?.nftAddress}/${nft?.smartContractNFTId}`}
                rel="noreferrer"
              >
                <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.value}>{nft?.smartContractNFTId}</Typography>
              </a>
            </div>
            <div className={styles.property}>
              <Typography variant={'text'} size={'md'} weight={'semiBold'} className={styles.label}>SmartContract</Typography>
              <a
                title={language.viewInBlockExplorer}
                target={'_blank'}
                href={`${nft?.explorerUrl}/address/${nft?.nftAddress}`}
                rel="noreferrer"
              >
                <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.value}>{isMobile ? Web3Utils.shortHexAddress(nft?.nftAddress, 24) : nft?.nftAddress}</Typography>
              </a>
            </div>
            <div className={styles.property}>
              <Typography variant={'text'} size={'md'} weight={'semiBold'} className={styles.label}>Blockchain</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.value}>{nft?.chain}</Typography>
            </div>
            <div className={styles.property}>
              <Typography variant={'text'} size={'md'} weight={'semiBold'} className={styles.label}>Token Standard</Typography>
              <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.value}>ERC721</Typography>
            </div>
          </div>
        );

      case 2:
      default:
        return (
          <div className={classNames([styles.grid, styles.details])}>
            {(nft?.properties ?? []).map((property, index): React.ReactElement => (
              <div key={`--property-key-${index.toString()}`} className={styles.detailRow}>
                <Typography variant={'text'} size={'md'} weight={'semiBold'} className={styles.label}>{property.trait_type}</Typography>
                <Typography variant={'text'} size={'md'} weight={'regular'} className={styles.value}>{property.display_type === 'date' ? new Date((property.value as number) * 1000).toLocaleDateString() : property.value}</Typography>
              </div>
            ))}
          </div>
        );
    }
  }, [sectionId, nft, isMobile]);

  if (isLoading || !nft) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Breadcrumbs links={[
          {
            icon: (
              <RiWalletLine
                size={'20px'}
              />
            ),
            path: '/'
          },
          {
            label: nft.name,
            path: `/nft/${nft.nftItemId}`
          }
        ]}
        />
        {!nft.tx && (
          <Alert
            variant={'error'}
            title={language.nftNotInWallet}
            description={language.mintNftOutsideGivitNFT}
          />
        )}
      </div>

      <div className={classNames([styles.row, styles.title, styles.mobile])}>
        {/* NFT Name */}
        <Typography variant={'display'} size={'md'} weight={'semiBold'}>{nft.name}</Typography>

        {/* Collection */}
        <div className={styles.field}>
          <Link to={`/collection/${nft.collectionGivitUrl}`} className={styles.inline}>
            <Typography variant={'text'} size={'md'} weight={'semiBold'}>{nft.collectionName}</Typography>
            <FaUsers size={'24px'} />
          </Link>

          <div className={styles.inline}>
            <Typography variant={'text'} size={'md'} weight={'medium'}>{language.createdAt}</Typography>
            <img alt={nft.creator} width={'24px'} height={'24px'} src={nft.collectionAvatar} />
            <Typography variant={'text'} size={'md'} weight={'regular'}>{nft.creator}</Typography>
          </div>
        </div>
      </div>

      <div className={styles.grid}>
        <NFTViewer
          onClick={() => {}}
          isMobile={isMobile}
          mediaTypeId={nft?.imageTypeId}
          mediaSrc={nft?.image}
        />

        {/* Details */}
        <div className={styles.body}>

          <div className={classNames([styles.row, styles.title, styles.desktop])}>
            {/* NFT Name */}
            <Typography variant={'display'} size={'md'} weight={'semiBold'}>{nft.name}</Typography>

            {/* Collection */}
            <div className={styles.field}>
              <Link to={`/collection/${nft.collectionGivitUrl}`} className={styles.inline}>
                <Typography variant={'text'} size={'md'} weight={'semiBold'}>{nft.collectionName}</Typography>
                <FaUsers size={'24px'} />
              </Link>

              <div className={styles.inline}>
                <Typography variant={'text'} size={'md'} weight={'medium'}>{language.createdAt}</Typography>
                <img alt={nft.creator} width={'24px'} height={'24px'} src={nft.collectionAvatar} />
                <Typography variant={'text'} size={'md'} weight={'regular'}>{nft.creator}</Typography>
              </div>
            </div>
          </div>

          {/* welcomeMessage */}
          <div className={classNames([styles.row, styles.welcomeMessage])}>

            {/* Navigation */}
            <div className={styles.navigator}>
              <Tabs
                selected={sectionId}
                onSelectItem={(item, index) => setSectionId(index)}
                items={[
                  {
                    label: language.nftDetails
                  },
                  {
                    label: language.collectionDetails
                  },
                  {
                    label: language.properties
                  }
                ]}
              />
              {renderContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFTDetails;
