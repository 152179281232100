// Dependencies
import React from 'react';

// Types
import {CheckInputProps} from './types';

// StyleSheet
import styles from './CheckInput.module.scss';

// Components
import Typography from '@components/Typography';

const CheckInput = ({
  label,
  labelWithLink,
  description,
  checked = false,
  disabled = false,
  onChange
}: CheckInputProps): React.ReactElement => (
  <div className={styles.layout}>
    <div className={styles.input}>
      <input type={'checkbox'} defaultChecked={checked} disabled={disabled} onChange={onChange} />
      {!labelWithLink ? <label htmlFor={label} className={styles.label}>{label}</label> : labelWithLink}
    </div>
    {description && (
      <div className={styles.description}>
        <Typography variant={'text'} weight={'regular'} size={'sm'} disabled={disabled}>
          {description}
        </Typography>
      </div>
    )}
  </div>
);

export default CheckInput;
