// Dependencies
import React from 'react';
import {FormikProps, useFormik} from 'formik';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// Types
import {ContactSalesFormProps, FormValues} from './types';

// StyleSheet
import styles from './ContactSalesForm.module.scss';

// Validation
import ContactSalesFormValidation from './validations';

import language_es from 'src/locales/es/forms/contactSalesForm.json';
import language_en from 'src/locales/en/forms/contactSalesForm.json';

function ContactSalesForm({
  isLoading,
  onSubmit,
  onCancel
}: ContactSalesFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      email: '',
      company: '',
      question: ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: ContactSalesFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.list}>
        <TextInput
          name={'email'}
          label={language.emailLabel}
          error={getErrorFromField('email')}
          value={formik.values.email}
          onChangeText={(email) => handleChangeField('email', email)}
          placeholder={'you@email.com'}
          disabled={isLoading}
        />
        <TextInput
          name={'company'}
          label={language.companyOrProjectLabel}
          error={getErrorFromField('company')}
          value={formik.values.company}
          onChangeText={(company) => handleChangeField('company', company)}
          placeholder={language.companyNamePlaceholder}
          disabled={isLoading}
        />
        <TextInput
          name={'question'}
          label={language.questionLabel}
          value={formik.values.question}
          textarea={true}
          onChangeText={(comment) => handleChangeField('question', comment)}
          placeholder={language.questionPlaceholder}
          disabled={isLoading}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button type={'button'} variant={'outline'} color={'primary'} onClick={onCancel} disabled={isLoading}>
            {language.cancelButton}
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} disabled={!formik.isValid} isLoading={isLoading}>
            {language.sendButton}
          </Button>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default ContactSalesForm;
