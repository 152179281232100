// Dependencies
import React from 'react';
import {FormikProps, useFormik} from 'formik';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import Selector from '@components/Selector';

// Types
import {ImportNFTFormProps, FormValues} from './types';

// StyleSheet
import styles from './ImportNFTForm.module.scss';

// Validation
import ImportNFTFormValidation from './validations';

// Config
import {networks} from '@utils/web3';

import language_es from 'src/locales/es/forms/importNFTForm.json';
import language_en from 'src/locales/en/forms/importNFTForm.json';

function ImportNFTForm({
  isLoading,
  onSubmit,
  onCancel
}: ImportNFTFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      collectionAddress: '',
      chain: networks()[0],
      nftId: ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: ImportNFTFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.list}>
        <Selector
          placeholder={language.collectionNetworkPlaceholder}
          options={networks()}
          value={formik.values.chain}
          onChange={(item) => handleChangeField('chain', item)}
        />
        <TextInput
          name={'collectionAddress'}
          label={language.collectionAddressLabel}
          error={getErrorFromField('collectionAddress')}
          value={formik.values.collectionAddress}
          onChangeText={(collectionAddress) => handleChangeField('collectionAddress', collectionAddress)}
          placeholder={'0x...'}
          disabled={isLoading}
        />
        <TextInput
          name={'nftId'}
          label={language.nftIdLabel}
          error={getErrorFromField('nftId')}
          value={formik.values.nftId}
          onChangeText={(nftId) => handleChangeField('nftId', nftId)}
          placeholder={language.nftIdPlaceholder}
          disabled={isLoading}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button type={'button'} variant={'outline'} color={'primary'} onClick={onCancel} disabled={isLoading}>
            {language.cancelButton}
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} disabled={!formik.isValid} isLoading={isLoading}>
            {language.importNftButton}
          </Button>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default ImportNFTForm;
