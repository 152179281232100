// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {fetchQuestTypes, fetchQuests, addQuest, deleteQuest, updateQuest, fetchUserQuests, twitterAuthQuest, discordAuthQuest, instagramAuthQuest, googleAuthQuest, tiktokAuthQuest, checkQuestActionIsCompleted} from '@store/quest/actions';

// Types
import {FetchQuestTypesAction, FetchQuestsAction, AddQuestAction, DeleteQuestAction, UpdateQuestAction, FetchUserQuestsAction, TwitterAuthQuestAction, DiscordAuthQuestAction, InstagramAuthQuestAction, GoogleAuthQuestAction, TikTokAuthQuestAction, CheckQuestActionIsCompletedAction} from '@store/quest/types';
import {IQuest, IQuestActionType, IQuestType, ITwitterAuthData, IUserQuest} from '@type/quest';
import {RootState} from '@store/reducer';

// Selectors
import {selectQuestTypesList, selectQuestActionTypesList, selectQuestsByCollectionId, selectQuestById, selectUserQuests, selectTwitterAuthData} from '@store/quest/selectors';

interface IUseQuests {
  questTypes: IQuestType[];
  questActionTypes: IQuestActionType[];
  quests: IQuest[];
  userQuests: IUserQuest[];
  quest: IQuest;
  twitterAuth: ITwitterAuthData;
  fetchQuestTypes: () => FetchQuestTypesAction;
  fetchQuests: (collectionId: number) => FetchQuestsAction;
  fetchUserQuests: (collectionId: number) => FetchUserQuestsAction
  addQuest: (collectionId: number, quest: IQuest) => AddQuestAction;
  updateQuest: (quest: IQuest) => UpdateQuestAction;
  deleteQuest: (questId: number) => DeleteQuestAction;
  twitterAuthQuest: (collectionName: string, questId: number) => TwitterAuthQuestAction;
  discordAuthQuest: (collectionName: string, questId: number) => DiscordAuthQuestAction;
  instagramAuthQuest: (collectionName: string, questId: number) => InstagramAuthQuestAction;
  googleAuthQuest: (collectionName: string, questId: number) => GoogleAuthQuestAction;
  tiktokAuthQuest: (collectionName: string, questId: number) => TikTokAuthQuestAction;
  checkQuestActionIsCompleted: (quest: IQuest) => CheckQuestActionIsCompletedAction;
}

function useQuests(id?: number): IUseQuests {
  const dispatch = useDispatch();
  const questTypes = useSelector(selectQuestTypesList);
  const questActionTypes = useSelector(selectQuestActionTypesList);
  const quests = useSelector(selectQuestsByCollectionId);
  const userQuests = useSelector(selectUserQuests);
  const quest = useSelector((state: RootState) => selectQuestById(state, id));
  const twitterAuth = useSelector(selectTwitterAuthData);

  return {
    questTypes,
    questActionTypes,
    quests,
    userQuests,
    quest,
    twitterAuth,
    fetchQuestTypes: () => dispatch(fetchQuestTypes()),
    fetchQuests: (collectionId: number) => dispatch(fetchQuests(collectionId)),
    fetchUserQuests: (collectionId: number) => dispatch(fetchUserQuests(collectionId)),
    addQuest: (collectionId: number, quest: IQuest) => dispatch(addQuest(collectionId, quest)),
    updateQuest: (quest: IQuest) => dispatch(updateQuest(quest)),
    deleteQuest: (questId: number) => dispatch(deleteQuest(questId)),
    twitterAuthQuest: (collectionName: string, questId: number) => dispatch(twitterAuthQuest(collectionName, questId)),
    discordAuthQuest: (collectionName: string, questId: number) => dispatch(discordAuthQuest(collectionName, questId)),
    instagramAuthQuest: (collectionName: string, questId: number) => dispatch(instagramAuthQuest(collectionName, questId)),
    googleAuthQuest: (collectionName: string, questId: number) => dispatch(googleAuthQuest(collectionName, questId)),
    tiktokAuthQuest: (collectionName: string, questId: number) => dispatch(tiktokAuthQuest(collectionName, questId)),
    checkQuestActionIsCompleted: (quest: IQuest) => dispatch(checkQuestActionIsCompleted(quest))
  };
}

export default useQuests;
