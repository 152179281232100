// Dependencies
import React from 'react';
import {useFormik, FormikHelpers} from 'formik';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// Types
import {SendNFTFormProps, FormValues} from './types';

// StyleSheet
import styles from './SendNFTForm.module.scss';

// Hooks
import useForm from '@hooks/useForm';

// Validations
import SendNFTSchema from './validations';

import language_es from 'src/locales/es/forms/sendNFTForm.json';
import language_en from 'src/locales/en/forms/sendNFTForm.json';

function SendNFTForm({onSubmit, onCancel, isLoading}: SendNFTFormProps) {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik = useFormik({
    initialValues: {
      email: '',
      text: ''
    },
    validateOnMount: true,
    validationSchema: SendNFTSchema,
    onSubmit
  });

  const {getErrorFromField, handleChangeField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.layout}>
      <div className={styles.field}>
        <TextInput
          name={'email'}
          label={language.emailLabel}
          placeholder={'name@email.com'}
          value={formik.values.email}
          onChangeText={(email) => handleChangeField('email', email)}
          error={getErrorFromField('email')}
        />
        <TextInput
          name={'send-text'}
          label={language.commentLabel}
          placeholder={language.commentPlaceholder}
          value={formik.values.text}
          onChangeText={(text) => handleChangeField('text', text)}
        />
      </div>

      <div className={styles.action}>
        <Button
          onClick={onCancel}
          variant={'outline'}
          color={'primary'}
          disabled={isLoading}>
          {language.cancelButton}
        </Button>
        <Button
          type={'submit'}
          variant={'solid'}
          color={'primary'}
          disabled={!formik.isValid}
          isLoading={isLoading}>
          {language.sendButton}
        </Button>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default SendNFTForm;
