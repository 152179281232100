// Action Types
import {IPass} from '@type/pass';

// State Management
export interface PassState {
  pass?: IPass;
  passCreated: boolean;
}

export enum passActionsTypes {
  FETCH_PASS_BY_QR = 'FETCH_PASS_BY_QR',
  SET_PASS_STATE = 'SET_PASS_STATE',
  CREATE_NEW_PASS = 'CREATE_NEW_PASS',
  GENERATE_PASS = 'GENERATE_PASS',
  SET_PASS_CREATED = 'SET_PASS_CREATED',
  CLEAR_PASS_CREATED = 'CLEAR_PASS_CREATED'
}

export interface FetchPassByQrCodeAction {
  type: typeof passActionsTypes.FETCH_PASS_BY_QR;
  payload: {
    qrCode: string;
  }
}

export interface SetPassStateAction {
  type: typeof passActionsTypes.SET_PASS_STATE;
  payload: {
    pass: IPass;
  }
}

export interface CreateNewPassAction {
  type: typeof passActionsTypes.CREATE_NEW_PASS;
  payload: {
    email: string;
    passType: number;
    pass: IPass;
  }
}

export interface GeneratePassAction {
  type: typeof passActionsTypes.GENERATE_PASS;
  payload: {
    nftItemId: number;
    pass: IPass;
  }
}

export interface SetPassCreatedAction {
  type: typeof passActionsTypes.SET_PASS_CREATED;
  payload: {
    passCreated: boolean;
  }
}

export interface ClearPassCreatedPassAction {
  type: typeof passActionsTypes.CLEAR_PASS_CREATED;
  payload: {
  }
}

export type PassActions =
  FetchPassByQrCodeAction |
  SetPassStateAction |
  CreateNewPassAction |
  SetPassCreatedAction |
  ClearPassCreatedPassAction;
