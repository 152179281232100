import {useEffect} from 'react';

const useDisableHorizontalScrollBody = () => {
  useEffect(() => {
    const body = document.body;

    // Store the original overflow-x style
    const originalOverflowXBody = body.style.overflowX;

    // Set overflow-x to hidden when component mounts
    body.style.overflowX = 'hidden';

    // Reset overflow-x to its original value when component unmounts
    return () => {
      body.style.overflowX = originalOverflowXBody;
    };
  }, []);
};

export default useDisableHorizontalScrollBody;
