// Dependencies
import React, {useCallback, useRef, useState} from 'react';
import classNames from 'clsx';
import {useClickAway} from 'react-use';

// Styles
import styles from './Selector.module.scss';

// Types
import {SelectorProps, OptionItem} from './types';

// Components
import Typography from '@components/Typography';

// Assets
import {RiArrowDownSLine} from 'react-icons/ri';

function Selector({
  label,
  options,
  value,
  placeholder,
  onChange,
  disabled,
  error,
  className
}: SelectorProps) {
  const innerRef = useRef(null);

  const [isMenuShowing, setMenuShowing] = useState<boolean>(false);

  /**
   * Handle click event for an option item in the menu.
   *
   * @param {OptionItem} option - The selected option item.
   * @param {number} key - The key associated with the option item.
   * @returns {void}
   */
  const handleClickOption = useCallback((option: OptionItem, key: number) => {
    onChange(option, key);
    setMenuShowing(false);
  }, []);

  useClickAway(innerRef, () => {
    setMenuShowing(false);
  });

  return (
    <div ref={innerRef} className={classNames([styles.layout, {[styles.disabled]: disabled}, {[styles.withError]: error}])}>
      {(label) && (
        <label htmlFor={label} className={styles.label}>{label}</label>
      )}
      <div className={classNames([styles.inputContainer, className])} onClick={() => !disabled && setMenuShowing(!isMenuShowing)}>
        {(value?.icon) && <div className={styles.icon}>{value.icon}</div>}
        <div className={styles.value}>
          <Typography className={classNames([{[styles.placeholder]: !value}])}>{value ? value.label : placeholder}</Typography>
        </div>
        <RiArrowDownSLine size={'18px'} />
      </div>
      {isMenuShowing && (
        <ul className={styles.menu}>
          {options.map((option, key) => (
            <li key={`--option-${key.toString()}`} className={styles.item} onClick={() => handleClickOption(option, key)}>
              {option.icon}
              <Typography variant={'text'} size={'md'}>{option.label}</Typography>
            </li>
          ))}
        </ul>
      )}
      {error && (
        <Typography variant={'text'} size={'sm'} className={styles.error}>{error}</Typography>
      )}
    </div>
  );
}

export type {
  OptionItem
};

export default Selector;
