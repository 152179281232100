// Types
import {IToast, ToastTypeEnum} from '@type/toasts';

export enum ToastActions {
  CREATE_TOAST = 'CREATE_TOAST',
  DELETE_TOAST = 'DELETE_TOAST',
}

export interface IToastState {
  list: IToast[];
}

export interface CreateToastAction {
  type: ToastActions.CREATE_TOAST;
  payload: {
    type: ToastTypeEnum;
    message: string;
  }
}

export interface DeleteToastAction {
  type: ToastActions.DELETE_TOAST;
  payload: {
    id: number;
  }
}

export type ToastActionsTypes = CreateToastAction | DeleteToastAction;
