// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {SimpleMessageProps} from './types';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// StyleSheet
import styles from './SimpleMessage.module.scss';
import modalStyle from '../../modals.module.scss';

function SimpleMessage({
  title,
  description,
  buttonCaption,
  action
}: SimpleMessageProps): React.ReactElement {
  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{title}</Typography>
      <div className={styles.description}>
        {(!Array.isArray(description)) ? (
          <Typography variant={'text'} size={'sm'}>{description}</Typography>
        ) : (description).map((phrase: string, index: number) => (
          <Typography key={`--phrase-${index.toString()}`} variant={'text'} size={'sm'}>{phrase}</Typography>
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button onClick={action} type={'button'} variant={'solid'} color={'primary'}>
            {buttonCaption}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SimpleMessage;
