// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {RequestResetPasswordAction} from '../types';
import {LoadersId} from '@type/loaders';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

// ErrorHandler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

// Services
import * as authServices from '@services/auth';

/**
 * @function requestResetPasswordProcess
 * @description reset the user's password
 */
function * requestResetPasswordProcess({payload: {email}}: RequestResetPasswordAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_SENDING_REQUEST_RESET_PASSWORD, true));
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, false));

    yield call(authServices.requestResetPassword, email);

    yield put(setIsLoading(LoadersId.IS_SENDING_REQUEST_RESET_PASSWORD, false));
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, true));
  } catch (e) {
    yield put(setIsLoading(LoadersId.IS_SENDING_REQUEST_RESET_PASSWORD, false));
    yield put(setIsLoading(LoadersId.RESET_PASSWORD, false));
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default requestResetPasswordProcess;
