// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {
  fetchAuth,
  logIn,
  logOut,
  resendVerifyEmail,
  setWeb3Provider,
  signUp,
  validateSigningMessage,
  verifyAccount,
  mobileLogin,
  requestResetPassword,
  resetPassword,
  editUser
} from '@store/auth/actions';

// Types
import {
  FetchAuthAction,
  LoginAction,
  LogOutAction,
  ResendVerifyEmailAction,
  SetProviderAction,
  SignUpAction,
  ValidateSigningMessageAction,
  VerifyAccountAction,
  MobileLoginAction,
  RequestResetPasswordAction,
  ResetPasswordAction,
  EditUserAction
} from '@store/auth/types';
import {IUser} from '@type/user';

// Selectors
import {selectAuthUser} from '@store/auth/selectors';
import {Web3Provider} from '@ethersproject/providers';

interface IUseAuth {
  user?: IUser | null,
  fetchAuth: (redirectToLogin?: boolean) => FetchAuthAction;
  verifyAccount: (token: string) => VerifyAccountAction;
  logIn: (email: string, password: string, redirectTo?: string) => LoginAction;
  signUp: (email: string, password: string, confirmPassword: string) => SignUpAction;
  logOut: (redirectUri?: string) => LogOutAction;
  validateSigningMessage: () => ValidateSigningMessageAction;
  setWeb3Provider: (provider?: Web3Provider) => SetProviderAction;
  resendVerifyEmail: (email: string) => ResendVerifyEmailAction;
  mobileLogin: (token: string) => MobileLoginAction;
  requestResetPassword: (email: string) => RequestResetPasswordAction;
  resetPassword: (token: string, password: string) => ResetPasswordAction;
  editUser: (data: EditUserAction['payload']) => EditUserAction;
}

function useAuth(): IUseAuth {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);

  return {
    user,
    logOut: (redirectUri) => dispatch(logOut(redirectUri)),
    fetchAuth: (redirectToLogin) => dispatch(fetchAuth(redirectToLogin)),
    logIn: (email, password, redirectTo?: string) => dispatch(logIn(email, password, redirectTo)),
    validateSigningMessage: () => dispatch(validateSigningMessage()),
    verifyAccount: (token: string) => dispatch(verifyAccount(token)),
    setWeb3Provider: (provider) => dispatch(setWeb3Provider(provider)),
    signUp: (email, password, confirmPassword) => dispatch(signUp(email, password, confirmPassword)),
    resendVerifyEmail: (email: string) => dispatch(resendVerifyEmail(email)),
    mobileLogin: (token: string) => dispatch(mobileLogin(token)),
    requestResetPassword: (email: string) => dispatch(requestResetPassword(email)),
    resetPassword: (token: string, password: string) => dispatch(resetPassword(token, password)),
    editUser: (data) => dispatch(editUser(data))
  };
}

export default useAuth;
