// API
import request from '../api';

/**
 * @function createContact()
 */
export const createContact = async(email: string, company: string, question: string): Promise<void> =>
  await request('/contacts/', {
    method: 'POST',
    body: {
      email,
      company,
      question
    }
  });
