// Types
import {
  authActionsTypes,
  FetchAuthAction,
  LoginAction,
  LogOutAction,
  ResendVerifyEmailAction,
  SetAuthUserAction,
  SetProviderAction,
  SignUpAction,
  ValidateSigningMessageAction,
  VerifyAccountAction,
  MobileLoginAction,
  RequestResetPasswordAction,
  ResetPasswordAction,
  EditUserAction
} from './types';
import {Web3Provider} from '@ethersproject/providers';
import {IUser} from '@type/user';

/**
 * ======================
 *  Fetch Authentication
 * ====================== */
export function fetchAuth(redirectToLogin?: boolean): FetchAuthAction {
  return {
    type: authActionsTypes.FETCH_AUTH,
    payload: {
      redirectToLogin
    }
  };
}

/**
 * ======================
 *        Login
 * ====================== */
export function logIn(email: string, password: string, redirectTo?: string): LoginAction {
  return {
    type: authActionsTypes.LOGIN,
    payload: {
      email,
      password,
      redirectTo
    }
  };
}

/**
 * ======================
 *    Verify Account
 * ====================== */
export function verifyAccount(token: string): VerifyAccountAction {
  return {
    type: authActionsTypes.VERIFY_ACCOUNT,
    payload: {
      token
    }
  };
}

/**
 * ======================
 *        SignUp
 * ====================== */
export function signUp(email: string, password: string, confirmPassword: string): SignUpAction {
  return {
    type: authActionsTypes.SIGNUP,
    payload: {
      email,
      password,
      confirmPassword
    }
  };
}

/**
 * ======================
 *        LogOut
 * ====================== */
export function logOut(redirectUrl?: string): LogOutAction {
  return {
    type: authActionsTypes.LOGOUT,
    payload: {
      redirectUrl
    }
  };
}

/**
 * ======================
 * Validate Signing Message
 * ====================== */
export function validateSigningMessage(): ValidateSigningMessageAction {
  return {
    type: authActionsTypes.VALIDATE_SIGNING_MESSAGE
  };
}

/**
 * ======================
 * Set Authentication User
 * ====================== */
export function setAuthUser(data: IUser | null): SetAuthUserAction {
  return {
    type: authActionsTypes.SET_AUTH_USER,
    payload: {
      data
    }
  };
}

/**
 * ======================
 *     Web3 Provider
 * ====================== */
export function setWeb3Provider(provider?: Web3Provider): SetProviderAction {
  return {
    type: authActionsTypes.SET_PROVIDER,
    payload: {
      provider
    }
  };
}

/**
 * ======================
 *  Resend Email Verify
 * ====================== */
export function resendVerifyEmail(email: string): ResendVerifyEmailAction {
  return {
    type: authActionsTypes.RESEND_VERIFY_EMAIL,
    payload: {
      email
    }
  };
}

export function mobileLogin(token: string): MobileLoginAction {
  return {
    type: authActionsTypes.MOBILE_LOGIN,
    payload: {
      token
    }
  };
}

export function requestResetPassword(email: string): RequestResetPasswordAction {
  return {
    type: authActionsTypes.REQUEST_RESET_PASSWORD,
    payload: {
      email
    }
  };
}

export function resetPassword(token: string, password: string): ResetPasswordAction {
  return {
    type: authActionsTypes.RESET_PASSWORD,
    payload: {
      token,
      password
    }
  };
}

/**
 * Dispatches an action to edit the user's profile information.
 *
 * @param {EditUserAction['payload']} payload - The user's profile data to be updated.
 * @param {IUserCreator['name']} payload.name - The user's new name.
 * @returns {EditUserAction} An action object with a type of EDIT_USER and the payload.
 */
export function editUser({name}: EditUserAction['payload']): EditUserAction {
  return {
    type: authActionsTypes.EDIT_USER,
    payload: {
      name
    }
  };
}
