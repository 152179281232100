// Action Types
import {IReward} from '@type/reward';

export enum rewardActionsTypes {
  FETCH_REWARDS = 'FETCH_REWARDS',
  SET_REWARDS = 'SET_REWARDS',
  DOWNLOAD_REWARDS_CSV = 'DOWNLOAD_REWARDS_CSV',
  SET_REWARDS_CSV = 'SET_REWARDS_CSV'
}

// State Management
export interface IRewardState {
  rewards: IReward[];
  csv: string | null;
}

/**
 * ======================
 *    Fetch Event List
 * ====================== */
export interface FetchRewardsAction {
  type: typeof rewardActionsTypes.FETCH_REWARDS;
  payload: {
    type: 'creator' | 'user';
    rewardId: number
  }
}

/**
 * ======================
 *    Set Event List
 * ====================== */
export interface SetRewardsAction {
  type: typeof rewardActionsTypes.SET_REWARDS;
  payload: {
    reward: IReward
  }
}

export interface DownloadRewardsCSVAction {
  type: typeof rewardActionsTypes.DOWNLOAD_REWARDS_CSV;
  payload: {
    rewardId: number
  }
}

export interface SetRewardsCSVAction {
  type: typeof rewardActionsTypes.SET_REWARDS_CSV;
  payload: {
    csv: string
  }
}

export type RewardActions =
  FetchRewardsAction |
  SetRewardsAction |
  DownloadRewardsCSVAction |
  SetRewardsCSVAction;
