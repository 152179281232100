import * as Yup from 'yup';

const CreateQuestValidation = Yup.object().shape({
  // title: Yup.mixed().required('Title is required'),
  // description: Yup.string().required('Description is required'),
  handler: Yup.string().required('Social network handler is required')
  // xp: Yup.number().required('XP points are required').min(1, 'XP cannot be less than 1')
});

export default CreateQuestValidation;
