// Dependencies
import React, {useCallback} from 'react';
import {useFormik, FormikHelpers} from 'formik';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import EmailsUpload from '@components/EmailsUpload';

// Types
import {SendMultipleNFTsFormProps, FormValues} from './types';

// StyleSheet
import styles from './SendMultipleNFTsForm.module.scss';

// Hooks
import useForm from '@hooks/useForm';

// Validations
import SendNFTSchema from './validations';

import language_es from 'src/locales/es/forms/sendMultipleNFTsForm.json';
import language_en from 'src/locales/en/forms/sendMultipleNFTsForm.json';

function SendMultipleNFTsForm({onSubmit, onCancel, isLoading}: SendMultipleNFTsFormProps) {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const initialValues: FormValues = {
    emails: [],
    text: ''
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: SendNFTSchema,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  const handleDropEmails = useCallback((field: keyof FormValues, files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const emails = (e.target?.result as string).split(';').map(email => email.trim());
        handleChangeField(field, emails);
      };
      reader.readAsText(file);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.layout}>
      <div className={styles.field}>
        <EmailsUpload
          onDrop={(emails) => handleDropEmails('emails', emails)}
          emails={formik.values.emails}
          error={getErrorFromField('emails')}
        />
        <TextInput
          name={'send-text'}
          label={language.commentLabel}
          placeholder={language.commentPlaceholder}
          value={formik.values.text}
          onChangeText={(text) => handleChangeField('text', text)}
        />
      </div>

      <div className={styles.action}>
        <Button
          onClick={onCancel}
          variant={'outline'}
          color={'primary'}
          disabled={isLoading}>
          {language.cancelButton}
        </Button>
        <Button
          type={'submit'}
          variant={'solid'}
          color={'primary'}
          disabled={!formik.isValid}
          isLoading={isLoading}>
          {language.sendButton}
        </Button>
      </div>
    </form>
  );
}

export type {FormValues, FormikHelpers};

export default SendMultipleNFTsForm;
