// Dependencies
import {call, put} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';

// Actions
import {LogOutAction} from '@store/auth/types';
import {setIsLoading} from '../../ui/loaders/actions';
import {resetApp} from '@store/settings/actions';

// Services
import {logOut} from '@services/auth';

// Types
import {LoadersId} from '../../../types/loaders';

/**
 * @function logOutProcess()
 * @description Logout process.
 */
function * logOutProcess({payload: {redirectUrl}}: LogOutAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_LOGING_OFF, true));
    yield call(logOut);

    if (redirectUrl) {
      yield put(setIsLoading(LoadersId.IS_LOGING_OFF, false));
      yield put(resetApp());
      yield call(() => {
        window.location.href = redirectUrl;
      });
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_LOGING_OFF, false));
  }
}

export default logOutProcess;
