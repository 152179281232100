// Dependencies
import React, {useCallback, useMemo, useRef} from 'react';
import classNames from 'clsx';

// StyleSheet
import styles from './ImageUpload.module.scss';

// Components
import Typography from '@components/Typography';
import FeaturedIcon from '@components/FeaturedIcon';
import {useDropzone} from 'react-dropzone';

// PDF
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Types
import {ImageUploadProps} from './types';

// Config
import {IMAGE_UPLOAD_FORMATS, VIDEO_UPLOAD_FORMATS, PDF_UPLOAD_FORMATS} from '@config/index';

// Assets
import {RiUploadCloud2Line} from 'react-icons/ri';

import language_es from 'src/locales/es/components/imageUpload.json';
import language_en from 'src/locales/en/components/imageUpload.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ImageUpload({photo, label, onDrop}: ImageUploadProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const videoRef = useRef<HTMLVideoElement>(null);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': IMAGE_UPLOAD_FORMATS,
      'video/*': VIDEO_UPLOAD_FORMATS,
      'application/pdf': PDF_UPLOAD_FORMATS
    }
  });

  const getFileType = (multimedia: string) => {
    const extension = '.' + (multimedia.split('.').pop()?.toLowerCase() as string);

    if (VIDEO_UPLOAD_FORMATS.includes(extension)) return 2;
    else if (IMAGE_UPLOAD_FORMATS.includes(extension)) return 1;
    else if (PDF_UPLOAD_FORMATS.includes(extension)) return 3;
    else return 0;
  };

  const imageTypeId = useMemo(() => {
    if (photo) {
      if (typeof photo === 'string') {
        return getFileType(photo);
      } else {
        if ((photo as File).type.startsWith('video/')) return 2;
        else if ((photo as File).type === 'application/pdf') return 3;
        else return 1;
      }
    }

    return 0;
  }, [photo]);

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      void videoRef.current.play();
    }
  }, [videoRef]);

  const handlePauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      void videoRef.current.pause();
    }
  }, [videoRef]);

  return (
    <div>
      {label && (
        <label htmlFor={label} className={styles.label}>{label}</label>
      )}
      <div onMouseLeave={handlePauseVideo} onMouseEnter={handlePlayVideo} className={classNames([styles.image, {[styles.active]: isDragActive}])} {...getRootProps()}>
        <input {...getInputProps()} />
        {imageTypeId === 3 ? (
        <div className={styles.previewContainer}>
          <Document file={(photo instanceof File) ? URL.createObjectURL(photo) : photo}>
            <Page pageNumber={1} className={styles.pdfPage} />
          </Document>
        </div>
        ) : imageTypeId === 2 ? (
          <video key={(photo instanceof File) ? URL.createObjectURL(photo) : photo} ref={videoRef} loop muted autoPlay={false} height={'100%'} width={'100%'}>
            <source src={(photo instanceof File) ? URL.createObjectURL(photo) : photo} />
          </video>
        ) : photo ? (
          <div className={styles.previewContainer}>
            <img src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} className={styles.previewImage} alt={'Multimedia reward'} />
          </div>
        ) : (
          <div className={styles.container}>
            <FeaturedIcon icon={(<RiUploadCloud2Line />)} />
            <Typography
              variant={'text'}
              size={'sm'}
              weight={'regular'}
              className={styles.text}
              textAlign={'center'}>
              <b>{language.clickUpload}</b> <br />{language.dragDrop}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
