// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Types
import {LoadersId} from '@type/loaders';
import {RootState} from '@store/reducer';
import {SetIsLoadingAction} from '@store/ui/loaders/types';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';

interface IUseLoader {
  isLoading: boolean;
  setLoading: (id: LoadersId, state: boolean) => SetIsLoadingAction
}

function useLoader(id: LoadersId): IUseLoader {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.ui.loaders[id]);

  return {
    isLoading,
    setLoading: (id: LoadersId, state: boolean) => dispatch(setIsLoading(id, state))
  };
}

export {LoadersId};

export default useLoader;
