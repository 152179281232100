// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {CreateNFTAction} from '@store/collection/types';
import {CreateNFTResponse} from '@services/collection/types';

// Services
import * as services from '@services/collection';

// Actions
import {setNFTToMyCollection} from '@store/collection/actions';
import {closeModal} from '@store/ui/modal/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function createNFTProcess()
 * @description Creating a new NFT.
 */
function * createNFTProcess({payload: {collectionId, nft}}: CreateNFTAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, true));

    const formData = yield call(() => {
      const data = new FormData();
      data.append('media', nft.photo);

      data.append('nft', JSON.stringify({
        name: nft.name,
        welcomeMessage: nft.welcomeMessage,
        passBackgroundColor: nft.passBackgroundColor,
        passForegroundColor: nft.passForegroundColor,
        passLabelColor: nft.passLabelColor,
        passTypeId: nft.passTypeId,
        imageTypeId: nft.imageTypeId,
        properties: []
      }));

      return data;
    });

    const response: CreateNFTResponse = yield call(services.createNFT, collectionId, formData);
    if (response) {
      yield put(setNFTToMyCollection(collectionId, response));
      yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, false));
      yield put(closeModal());
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATE_OR_EDIT_NFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default createNFTProcess;
