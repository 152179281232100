// Dependencies
import {AuthActions, authActionsTypes, IAuthState} from './types';

const initialAuthState: IAuthState = {
  data: null,
  web3: null
};

function authReducer(state: IAuthState = initialAuthState, action: AuthActions): IAuthState {
  switch (action.type) {
    case authActionsTypes.SET_AUTH_USER:
      return {
        ...state,
        data: action.payload.data
      };

    case authActionsTypes.SET_PROVIDER:
      return {
        ...state,
        web3: action.payload.provider
      };

    default:
      return state;
  }
}

export default authReducer;
