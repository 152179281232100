// State
import {RootState} from '../reducer';
import {INFT} from '@type/nft';
import {unNormalizeState} from '@utils/state';
import {NFTListState} from '@store/nft/types';

/**
 * @function selectNFTList
 * @description Retrieves the list of NFTs from the state.
 *
 * @param {RootState} state - The global state of the application.
 *
 * @returns {INFT[] | undefined} - The list of NFTs, or undefined if the list does not exist.
 */
export const selectNFTList = (state: RootState): INFT[] | undefined =>
  unNormalizeState(state.nft.list);

/**
 * @function selectNFTPendingToClaim
 * @description Retrieves the number of NFTs pending to be claimed.
 *
 * @param {RootState} state - The global state of the application.
 *
 * @returns {number} - The number of NFTs pending to be claimed.
 */
export const selectNFTPendingToClaim = (state: RootState): number =>
  state.nft.pendingToClaim;

/**
 * @function selectNFTListPagination
 * @description Retrieves the pagination data of the NFT list.
 *
 * @param {RootState} state - The global state of the application.
 *
 * @returns {NFTListState['pagination']} - The pagination data of the NFT list.
 */
export const selectNFTListPagination = (state: RootState): NFTListState['pagination'] =>
  state.nft.pagination;

/**
 * @function selectNFTById
 * @description Retrieves an NFT by its ID.
 *
 * @param {RootState} state - The global state of the application.
 * @param {number | undefined} id - The ID of the NFT.
 *
 * @returns {INFT | undefined} - The NFT with the specified ID, or undefined if not found.
 */
export const selectNFTById = (state: RootState, id?: number | undefined): INFT | undefined => id
  ? unNormalizeState(state.nft.list)?.filter(e => e.nftItemId === Number(id))[0] : undefined;
