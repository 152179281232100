/**
 * ===========================
 * GivitPass Frontend App
 * ===========================
 */
// Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';

// StyleSheet
import '@styles/index.scss';
import '@styles/global.scss';
import 'react-quill/dist/quill.snow.css';

// Components
import Navigator from './navigator';
import ConsentCookies from '@components/ConsentCookies';

// Store
import {makeStore} from '@store/index';
import {PersistGate} from 'redux-persist/integration/react';

// Utils
import reportWebVitals from './reportWebVitals';

// Web3 Provider
import {Web3ReactProvider} from '@web3-react/core';
import connector from '@utils/connectors';

// Google Analytics
import ReactGA from 'react-ga4';

const store = makeStore() as any;

function App(): React.ReactElement | null {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <Web3ReactProvider getLibrary={connector}>
          <Navigator />
          <ConsentCookies />
        </Web3ReactProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);

// React Initialization
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <App />
);

reportWebVitals();
