// Dependencies
import React from 'react';
import {FormikProps, useFormik} from 'formik';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';

// Types
import {BuyLicenseFormProps, FormValues} from './types';

// StyleSheet
import styles from './BuyLicenseForm.module.scss';

// Validation
import BuyLicenseFormValidation from './validations';

import language_es from 'src/locales/es/modals/buyLicense.json';
import language_en from 'src/locales/en/modals/buyLicense.json';

function BuyLicenseForm({
  isLoading,
  onSubmit,
  onCancel
}: BuyLicenseFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      description: ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: BuyLicenseFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.list}>
        <TextInput
          name={'description'}
          value={formik.values.description}
          textarea={true}
          onChangeText={(comment) => handleChangeField('description', comment)}
          placeholder={language.descriptionPlaceholder}
          disabled={isLoading}
          error={getErrorFromField('description')}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          <Button type={'button'} variant={'outline'} color={'primary'} onClick={onCancel} disabled={isLoading}>
            {language.cancelButton}
          </Button>
          <Button type={'submit'} variant={'solid'} color={'primary'} disabled={!formik.isValid} isLoading={isLoading}>
            {language.sendButton}
          </Button>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default BuyLicenseForm;
